/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fade, Grow, IconButton, Paper, Popper, Slider } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import SettingsIcon from '@material-ui/icons/Settings';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import moment from 'moment';
import {
  ChangeEvent,
  CSSProperties,
  MouseEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

const speedos = [2, 1.75, 1.5, 1.25, 1, 0.75, 0.5, 0.25];

const SpeedItemButton = styled.div`
  padding: 0px 35px;
  text-align: center;

  &:hover {
    background-color: lightgray;
  }
`;

interface ControlBarProps {
  duration: number;
  current: number;
  style?: CSSProperties;
  showControls: boolean;
  volume: number;
  setVolume: (val: number) => void;
  fullScreen?: boolean;
  onChange: (e: ChangeEvent<{}>, val: number | number[]) => void;
  onChangeCommited: (e: ChangeEvent<{}>, val: number | number[]) => void;
  onMouseEnter: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  onSpeedChange?: (val: number) => void;
  onFullScreenChange?: () => void;
}

export const ControlBar = ({
  duration,
  current,
  style,
  showControls,
  volume,
  fullScreen,
  onChange,
  onChangeCommited,
  onMouseEnter,
  onMouseLeave = () => {},
  onSpeedChange,
  onFullScreenChange,
  setVolume,
}: ControlBarProps) => {
  const [flags, setFlags] = useState({
    volume: false,
    speed: false,
    speedListener: false,
    quality: false,
  });

  const handleChange = (e: ChangeEvent<{}>, value: number | number[]) => {
    onChange(e, value);
  };

  const handleChangeCommited = (e: ChangeEvent<{}>, value: number | number[]) => {
    onChangeCommited(e, value);
  };
  const volumeRef = useRef<HTMLButtonElement>(null);
  const volumeBackupRef = useRef<number>(0);
  const speedRef = useRef<HTMLDivElement>(null);
  const qualityRef = useRef<HTMLDivElement>(null);

  const hideVolumeTimerRef = useRef<number | null>(null);

  const handleVolumeChange = (_event: ChangeEvent<{}>, val: number | number[]) => {
    if (setVolume && !Array.isArray(val)) {
      setVolume(val / 100);
    }
  };

  const handleSpeedChange = useCallback(
    (speed: number) => {
      if (onSpeedChange) {
        onSpeedChange(speed);
        setFlags({ ...flags, speed: false });
      }
    },
    [flags, onSpeedChange],
  );

  const handleFullscreenChange = useCallback(() => {
    if (onFullScreenChange) {
      onFullScreenChange();
    }
  }, [onFullScreenChange]);

  return (
    <Fade in={showControls}>
      <div
        css={{
          // margin: '0 78px 200px',
          color: '#fff',
          display: 'flex',
        }}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {/* //#region  Volume control */}
        <div
          css={{
            // width: 'min-content',
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        >
          <span>
            <IconButton
              ref={volumeRef}
              color='primary'
              onMouseEnter={() => setFlags({ ...flags, volume: true })}
              onMouseLeave={() => {
                if (hideVolumeTimerRef.current !== null) {
                  clearTimeout(hideVolumeTimerRef.current);
                  hideVolumeTimerRef.current = null;
                }
                hideVolumeTimerRef.current = window.setTimeout(
                  () => setFlags({ ...flags, volume: false }),
                  4000,
                );
              }}
              onClick={() => {
                if (volume > 0) {
                  volumeBackupRef.current = volume;
                  setVolume(0);
                } else {
                  let restore = volumeBackupRef.current === 0 ? 0.5 : volumeBackupRef.current;
                  setVolume(restore);
                }
              }}
            >
              {volume === 0 ? (
                <VolumeOffIcon />
              ) : volume < 0.5 ? (
                <VolumeDownIcon />
              ) : (
                <VolumeUpIcon />
              )}
            </IconButton>
            {volumeRef && volumeRef.current && flags.volume && (
              <Popper
                id='__control_volume_popper'
                open={flags.volume}
                container={volumeRef.current}
                anchorEl={volumeRef.current}
                placement='top'
                transition
                onMouseEnter={() => {
                  if (hideVolumeTimerRef.current !== null) {
                    clearTimeout(hideVolumeTimerRef.current);
                    hideVolumeTimerRef.current = null;
                  }
                }}
                onMouseLeave={() => {
                  if (hideVolumeTimerRef.current !== null) {
                    clearTimeout(hideVolumeTimerRef.current);
                    hideVolumeTimerRef.current = null;
                  }
                  hideVolumeTimerRef.current = window.setTimeout(
                    () => setFlags({ ...flags, volume: false }),
                    2000,
                  );
                }}
              >
                {({ TransitionProps }) => (
                  <Fade in={flags.volume} {...TransitionProps}>
                    <Grow in={flags.volume} timeout={1100}>
                      <div
                        css={{
                          height: '100px',
                          padding: '20px',
                        }}
                      >
                        <Slider
                          orientation='vertical'
                          max={100}
                          value={volume * 100}
                          scale={(x) => {
                            return (Math.pow(10, x / 100) - 1) / 9;
                          }}
                          onChange={handleVolumeChange}
                          onChangeCommitted={handleVolumeChange}
                        />
                      </div>
                    </Grow>
                  </Fade>
                )}
              </Popper>
            )}
          </span>
        </div>
        {/* //#endregion Volume control */}
        <div
          css={{
            display: 'inline-block',
            verticalAlign: 'middle',
            padding: '12px',
            flex: '1 0 auto',
          }}
        >
          <Slider
            max={duration}
            value={current}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommited}
          />
        </div>
        <div
          css={{
            display: 'inline-block',
            verticalAlign: 'middle',
            padding: '12px',
            lineHeight: 1.6,
            width: '50px',
            textAlign: 'right',
          }}
        >
          {moment(new Date())
            .startOf('day')
            .seconds(current)
            .format(current >= 3600 ? 'HH:mm:ss' : 'mm:ss')}
        </div>
        {false && (
          <div
            ref={speedRef}
            onClick={() => setFlags({ ...flags, speed: !flags.speed })}
            css={{
              display: 'inline-block',
              verticalAlign: 'middle',
              padding: '12px',
              lineHeight: 1.6,
            }}
          >
            1x
          </div>
        )}
        {false && speedRef && speedRef.current && flags.speed && (
          <Popper
            id='__control_speed'
            open={flags.speed}
            container={speedRef.current}
            anchorEl={speedRef.current}
            placement='top'
            transition
          >
            {({ TransitionProps }) => (
              <Fade in={flags.speed} {...TransitionProps}>
                <Grow in={flags.speed} timeout={1100}>
                  <div>
                    <Paper
                      onMouseLeave={() => setFlags({ ...flags, speed: false })}
                      css={{
                        backgroundColor: 'gray',
                      }}
                    >
                      {speedos.map((val) => (
                        <SpeedItemButton
                          onClick={() => handleSpeedChange(val)}
                        >{`${val}x`}</SpeedItemButton>
                      ))}
                    </Paper>
                  </div>
                </Grow>
              </Fade>
            )}
          </Popper>
        )}

        {false && (
          <div ref={qualityRef} css={{ display: 'inline-block', verticalAlign: 'middle' }}>
            <IconButton color='primary'>
              <SettingsIcon />
            </IconButton>
          </div>
        )}
        {false && volumeRef && volumeRef.current && (
          <Popper
            id='__control_quality'
            open={flags.volume}
            container={volumeRef.current}
            anchorEl={volumeRef.current}
            placement='top'
          >
            {({ TransitionProps }) => (
              <Fade in={flags.volume} {...TransitionProps}>
                <Grow in={flags.volume} timeout={1100}>
                  <div
                    css={{
                      height: '100px',
                      position: 'absolute',
                      bottom: '48px',
                      margin: '0px 10px',
                    }}
                  >
                    <Slider orientation='vertical' max={1100} />
                  </div>
                </Grow>
              </Fade>
            )}
          </Popper>
        )}

        <div css={{ display: 'inline-block', verticalAlign: 'middle' }}>
          <IconButton color='primary' onClick={handleFullscreenChange}>
            {!!fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </div>
      </div>
    </Fade>
  );
};
