import { Box, Fab, Grid, styled, useMediaQuery, useTheme } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React from 'react';
import { getCourseDuration } from 'utils/helpers';
import { useNavigation } from 'utils/useNavigation';
import { BorderLinearProgress } from '../../../views/Home/styledComponents';
import { pad } from '../../utils';
import {
  ChapterEpisodeInformation,
  ChapterName,
  CourseName,
  Description,
  PosterInfoContainer,
  SeeAllEpisodes,
  StyledLink,
} from './styledComponents';
import { Courses } from '../../../state/views';

type OverlayProps = {
  episodeTitle: string;
  courseName?: string;
  episodeDescription: string;
  chapterNumber?: number;
  episodeNumber?: number;
  hero: any;
  withSeeAllLink?: boolean;
  handleSeeAll: any;
  seeAllLabel?: string;
};

export const StyledFab = styled(Fab)({
  backgroundColor: 'transparent',
  color: '#fff',
  border: '1px solid #fff',
  cursor: 'default',
  fontSize: '1.8rem',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const StyledCheckFab = styled(Fab)(() => ({
  marginLeft: '2rem',
  color: 'white',
  background: 'transparent',
  border: `1px solid grey`,
  cursor: 'default',
  '&:hover': {
    background: 'transparent',
  },
}));

const HomePosterOverlay = (props: OverlayProps) => {
  const nav = useNavigation();

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  let courseToHighlightChallenges: Courses.IChallengeDTO[] = []

  if (props.hero.chapters) {
    props.hero.chapters.forEach((chapter: any) => {
      const challenges = chapter.challenges
      challenges.forEach((challenge: any) => courseToHighlightChallenges.push(challenge))
    })
  }

  const getChallengesAmount = () => {
    return courseToHighlightChallenges.length;
  };

  return (
    <PosterInfoContainer style={{ position: 'relative' }}>
      {props.hero.progress ? (
        <BorderLinearProgress
          style={{
            top: '-10px',
            height: '8px',
            width: '100%',
          }}
          variant='determinate'
          value={props.hero.progress * 100}
        />
      ) : null}
      <Grid container justify='space-between'>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Box ml={[2, 2, 10]} mr={[2, 2, 10]}>
              <ChapterName>
                {props.episodeTitle}
                {props.hero.completed && (
                  <StyledCheckFab disableRipple disableTouchRipple size='small'>
                    <Check />
                  </StyledCheckFab>
                )}
              </ChapterName>
              <CourseName>
                <StyledLink
                  onClick={() => {
                    nav.goToCourseverview(props.hero.course.id);
                  }}
                >
                  {props.courseName}
                </StyledLink>
              </CourseName>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box ml={[2, 2, 10]} mr={[2, 2, 10]}>
              {props.chapterNumber && props.episodeNumber && (
                <ChapterEpisodeInformation style={{ textAlign: isLarge ? 'right' : 'left' }}>
                  Chapter {pad(props.chapterNumber, 2)} / Episode {pad(props.episodeNumber, 2)}
                </ChapterEpisodeInformation>
              )}
              {props.withSeeAllLink && (
                <Box mt={!props.chapterNumber && !props.episodeNumber ? 2 : 0}>
                  <SeeAllEpisodes style={{ textAlign: isLarge ? 'right' : 'left' }}>
                    <StyledLink onClick={() => props.handleSeeAll()}>
                      See all {props.seeAllLabel}
                    </StyledLink>
                  </SeeAllEpisodes>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container justify='space-between'>
          <Box ml={[2, 2, 10]} mr={[2, 2, 10]}>
            <Description>{props.episodeDescription}</Description>
          </Box>
          {props.hero.name && (
            <Box
              ml={[2, 2, 10]}
              mr={[2, 2, 10]}
              display='flex'
              justifyContent='right'
              textAlign='center'
            >
              <Box>
                <StyledFab size='large' style={{ fontSize: '16px', textTransform: 'none' }}>
                  {getCourseDuration(props.hero) || 0}h
                </StyledFab>
                <p style={{ fontSize: '12px' }}>Duration</p>
              </Box>
              <Box ml={2}>
                <StyledFab size='large' style={{ fontSize: '16px', textTransform: 'none' }}>
                  {getChallengesAmount() || 0}
                </StyledFab>
                <p style={{ fontSize: '12px' }}>Challenges</p>
              </Box>
              <Box ml={2}>
                <StyledFab size='large' style={{ fontSize: '16px', textTransform: 'none' }}>
                  {props.hero.chapters.length}
                </StyledFab>
                <p style={{ fontSize: '12px' }}>Chapters</p>
              </Box>
              <Box ml={2}>
                <StyledFab size='large' style={{ fontSize: '16px', textTransform: 'none' }}>
                  {props.hero.chapters.reduce(
                    (prev: any, next: any) => prev + next.episodes.length,
                    0,
                  )}
                </StyledFab>
                <p style={{ fontSize: '12px' }}>Episodes</p>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </PosterInfoContainer>
  );
};

export default React.memo(HomePosterOverlay);
