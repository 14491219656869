import * as React from 'react';

const IconArrowDownLite = () => (
  <svg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='-1.77 0 22 11.67'>
    <path
      fill='currentColor'
      d='M 0 0 l -1.77 1.77 l 9.9 9.9 l 9.9 -9.9 l -1.77 -1.77 l -8.13 8.13 z'
    />
  </svg>
);

export default IconArrowDownLite;
