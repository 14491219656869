import { Box, Fade, Grow, Tooltip } from '@material-ui/core';
import { Add, ArrowDownward, Check, Clear } from '@material-ui/icons';
import { omit } from 'lodash';
import React from 'react';
import { SlideAdditionalInfo, StyledFab } from '../../styledComponents';

interface FabsProps {
  showDetails: boolean;
  withCompletedFab: boolean;
  withDownloadFab: boolean;
  withJoinFab: boolean;
  withLeaveFab: boolean;
  joinFabProps?: any;
  leaveFabProps?: any;
  downloadFabProps?: any;
  shouldShowDownloadFab: boolean
}

const Fabs: React.FC<FabsProps> = ({
  showDetails,
  withCompletedFab,
  withDownloadFab,
  downloadFabProps,
  withJoinFab,
  joinFabProps,
  withLeaveFab,
  leaveFabProps,
  shouldShowDownloadFab
}) => {
  return (
    <Box mt={[1, 1, 2]} mr={1} display='flex' justifyContent='flex-end'>
      {withCompletedFab && (
        <Fade in={showDetails}>
          <Box textAlign='center' ml={2}>
            <StyledFab disableRipple disableTouchRipple size='small' aria-label='completed'>
              <Check />
            </StyledFab>
          </Box>
        </Fade>
      )}
      {withDownloadFab && shouldShowDownloadFab && (
        <Tooltip
          title='Download instructions'
          aria-label='download intructions'
          arrow
          placement='top'
        >
          <Fade in={showDetails}>
            <Box textAlign='center' ml={2}>
              <StyledFab size='small' aria-label='download' {...downloadFabProps}>
                <ArrowDownward />
              </StyledFab>
            </Box>
          </Fade>
        </Tooltip>
      )}

      {withJoinFab && (
        <Fade in={showDetails}>
          <Box textAlign='center' ml={2}>
            <StyledFab size='small' aria-label='join course' {...omit(joinFabProps, 'userJoined')}>
              {joinFabProps.userJoined && (
                <Grow in>
                  <Check />
                </Grow>
              )}
              {!joinFabProps.userJoined && (
                <Grow in={!joinFabProps.userJoined}>
                  <Add />
                </Grow>
              )}
            </StyledFab>
            <SlideAdditionalInfo>{joinFabProps.userJoined ? 'JOINED' : 'JOIN'}</SlideAdditionalInfo>
          </Box>
        </Fade>
      )}
      {withLeaveFab && (
        <Fade in={showDetails}>
          <Box textAlign='center' ml={2}>
            <StyledFab size='small' aria-label='leave course' {...leaveFabProps}>
              <Clear />
            </StyledFab>
            <SlideAdditionalInfo>LEAVE</SlideAdditionalInfo>
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default React.memo(Fabs);
