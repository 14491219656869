import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './state/store';
import {Provider as StoreProvider} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import {ThemeProvider} from '@material-ui/styles';
import theme from './theme';
import dotenv from 'dotenv';
import * as constants from './state/session/constants';


(async function () {
    const params = new URLSearchParams(window.location.search);
    const access = params.get('access');
    const refresh = params.get('refresh');

    if (!access) {
        return;
    }

    params.delete('access');
    params.delete('refresh');
    await localStorage.setItem(constants.TOKEN_NAME, access);

    if(refresh) {
        await localStorage.setItem(constants.REFRESH_TOKEN_NAME, refresh)
    } else {
        localStorage.removeItem(constants.REFRESH_TOKEN_NAME)
    }

    const paramsString = params.toString() ? '?' + params.toString() : '';

    if (window.history.pushState) {
        const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}${paramsString}`;
        window.history.pushState({path: url}, '', url);
    }

})();

dotenv.config();

const store = configureStore();
const render = (Component: typeof App) => {
    return ReactDOM.render(
        <ThemeProvider theme={theme}>
            <StoreProvider store={store}>
                <SnackbarProvider maxSnack={3}>
                    <Component/>
                </SnackbarProvider>
            </StoreProvider>
        </ThemeProvider>,
        document.getElementById('root'),
    );
};

render(App);

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        return render(NextApp);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
