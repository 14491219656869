import { AnyAction, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';
import {
  clearSnackChannel,
  closeConfirmationDialog,
  closeSlideNotification,
  fetchFaq,
  getCurrentProfile,
  getLanguages,
  getNotifications,
  getUnreadedNotifications, invalidateSession,
  loginUser,
  logOut,
  markNotificationAsRead,
  openConfirmationDialog,
  openSlideNotification,
  postAllowEmailNotificationFlag,
  pushNotification,
  pushSnack,
  registerNotification,
  registerPassword,
  resetFlags,
  setActiveTab,
  setCourseOverview,
  setDependencyNavBarMounted,
  setLanguage,
  setNewPasswordDialog,
  setUserConsent,
  switchOpenAvatarMenu,
  switchOpenLiveChallengeDialog,
  switchOpenNotificationMenu,
} from 'state/session/actions';
import { serializeFunction } from '../helpers';
import * as constants from 'state/session/constants';

const initialConfirmationDialog = {
  isVisible: false,
  modalType: 'ROOT',
  modalProps: {
    header: 'Confirm',
    text: 'Are you sure you want to do this?',
    confirmationLabel: 'YES',
    cancellationLabel: 'NO',
  },
};

const initialSlideNotification = {
  id: '',
  action: serializeFunction(() => null),
  actionLabel: 'OK',
  isVisible: false,
};

const defaultFlags: SessionFlags = {
  openCourseOverview: false,
  avoidResetCourseOverview: false,
  openLoginDialog: false,
  openViewProfileDialog: false,
  openEditProfileDialog: false,
  openChallengeDialog: false,
  openResetPasswordDialog: false,
  openSetNewPassword: false,
  openAvatarMenu: false,
  openNotificationsMenu: false,
  openLiveChallengeDialog: false,
};

const initialState: SessionState = {
  loadingUser: false,
  user: null,
  flags: defaultFlags,
  faqCollection: {
    loading: false,
    items: [],
  },
  config: {
    loading: false,
    pendingLanguageChange: false,
    refreshSent: false,
    hasCookiesConsent: true,
    activeTab: 0,
    confirmationDialog: initialConfirmationDialog,
    slideNotification: initialSlideNotification,
    tokens: {
      reset: null,
    },
    snacks: {},
    requestedUrlMemo: null,
  },
  dependencies: {
    navBarMounted: false,
  },
  notifications: {
    loading: false,
    hasMore: false,
    unreaded: 0,
    items: [],
    pushNotifications: {
      item: null,
    },
  },
};

const isFlagRealtedAction = (action: AnyAction): action is PayloadAction<boolean, string> => {
  return action.type.substring && action.type.includes('set-flag');
};

const sessionReducer = createReducer(initialState, (builder) => {
  // plain actions

  builder.addCase(logOut, (state) => {
    state.flags = defaultFlags;
    state.user = null;
    state.notifications = initialState.notifications;
    localStorage.removeItem(constants.TOKEN_NAME);
    localStorage.removeItem(constants.REFRESH_TOKEN_NAME);
  });

  builder.addCase(setActiveTab, (state, action) => {
    state.config.activeTab = action.payload;
  });
  builder.addCase(setUserConsent, (state, action) => {
    state.config[action.payload.consent] = action.payload.value;
  });
  builder.addCase(openConfirmationDialog, (state, action) => {
    state.config.confirmationDialog = action.payload;
  });
  builder.addCase(closeConfirmationDialog, (state) => {
    state.config.confirmationDialog.isVisible = false;
  });
  builder.addCase(openSlideNotification, (state, action) => {
    state.config.slideNotification = {
      ...state.config.slideNotification,
      ...action.payload,
    };
  });
  builder.addCase(closeSlideNotification, (state) => {
    state.config.slideNotification.id = '';
  });

  builder.addCase(pushSnack, (state, action) => {
    state.config.snacks[action.payload.channel] = action.payload;
  });

  builder.addCase(clearSnackChannel, (state, action) => {
    delete state.config.snacks[action.payload];
  });

  builder.addCase(setDependencyNavBarMounted, (state, action) => {
    state.dependencies.navBarMounted = action.payload.flag;
  });

  builder.addCase(registerNotification, (state, action) => {
    state.notifications.items = [action.payload, ...state.notifications.items].sort((a, b) =>
      b.createdAt.localeCompare(a.createdAt),
    );
  });

  builder.addCase(switchOpenAvatarMenu, (state) => {
    state.flags.openAvatarMenu = !state.flags.openAvatarMenu;
  });

  builder.addCase(switchOpenNotificationMenu, (state) => {
    state.flags.openNotificationsMenu = !state.flags.openNotificationsMenu;
  });

  builder.addCase(switchOpenLiveChallengeDialog, (state) => {
    state.flags.openLiveChallengeDialog = !state.flags.openLiveChallengeDialog;
  });

  builder.addCase(pushNotification, (state, action) => {
    state.notifications.pushNotifications.item = action.payload.notification;
  });

  // async actions
  builder.addCase(invalidateSession.pending, (state) => {
    state.config.loading = true;
  });
  builder.addCase(invalidateSession.fulfilled, (state) => {
    state.config.loading = false;
  });
  builder.addCase(invalidateSession.rejected, (state) => {
    state.config.loading = false;
  });
  builder.addCase(loginUser.pending, (state) => {
    state.config.loading = true;
  });
  builder.addCase(loginUser.fulfilled, (state, action) => {
    state.user = action.payload.user;
    state.config.loading = false;
  });
  builder.addCase(loginUser.rejected, (state) => {
    state.config.loading = false;
  });

  builder.addCase(registerPassword.pending, (state) => {
    state.config.loading = true;
  });
  builder.addCase(registerPassword.fulfilled, (state, action) => {
    state.user = action.payload.user;
    state.config.loading = false;
  });
  builder.addCase(registerPassword.rejected, (state) => {
    state.config.loading = false;
  });

  builder.addCase(fetchFaq.pending, (state) => {
    state.faqCollection.loading = true;
  });
  builder.addCase(fetchFaq.fulfilled, (state, action) => {
    state.faqCollection.loading = false;
    state.faqCollection.items = action.payload;
  });
  builder.addCase(fetchFaq.rejected, (state) => {
    state.faqCollection.loading = false;
  });

  builder.addCase(getCurrentProfile.pending, (state) => {
    state.loadingUser = true;
  });

  builder.addCase(getCurrentProfile.fulfilled, (state, action) => {
    state.user = action.payload.user;
    // state.flags.openLiveChallengeDialog = !action.payload.user.liveChallengeConfirmed;
    state.loadingUser = false;
  });

  builder.addCase(getCurrentProfile.rejected, (state) => {
    state.loadingUser = false;
  });

  builder.addCase(resetFlags, (state) => {
    state.flags = {
      ...defaultFlags,
    };
  });

  builder.addCase(setNewPasswordDialog, (state, action) => {
    state.flags.openSetNewPassword = action.payload.flag;
    state.config.tokens.reset = action.payload.token;
  });

  builder.addCase(setCourseOverview, (state, action) => {
    if (state.flags.avoidResetCourseOverview && !action.payload.flag) {
      state.flags.avoidResetCourseOverview = false;
    } else {
      state.flags.openCourseOverview = action.payload.flag;
      state.flags.avoidResetCourseOverview = action.payload.avoidReset;
    }
  });

  builder.addCase(getNotifications.pending, (state) => {
    state.notifications.loading = true;
  });

  builder.addCase(getNotifications.fulfilled, (state, action) => {
    state.notifications.loading = false;

    state.notifications.items = unionBy(
      action.payload,
      state.notifications.items,
      (item) => item.id,
    ).sort((a, b) => b.createdAt.localeCompare(a.createdAt));

    state.notifications.hasMore = action.payload.length > 0;
  });

  builder.addCase(getNotifications.rejected, (state) => {
    state.notifications.loading = false;
    state.notifications.hasMore = false;
  });

  builder.addCase(markNotificationAsRead.fulfilled, (state, action) => {
    const notificationItem = state.notifications.items.find(
      (n) => n.id === action.meta.arg.notificationId,
    );
    if (notificationItem) {
      notificationItem.readAt = new Date().toISOString();
    }
  });

  builder.addCase(getUnreadedNotifications.fulfilled, (state, action) => {
    state.notifications.unreaded = action.payload;
  });

  builder.addCase(postAllowEmailNotificationFlag.pending, (state, action) => {
    if (state.user) state.user.allowMailNotifications = action.meta.arg;
  });

  builder.addCase(getLanguages.fulfilled, (state, action) => {
    state.languages = action.payload;
  });

  builder.addCase(setLanguage.pending, (state) => {
    state.config.pendingLanguageChange = true;
  });

  builder.addCase(setLanguage.fulfilled, (state) => {
    state.config.pendingLanguageChange = false;
  });

  builder.addCase(setLanguage.rejected, (state) => {
    state.config.pendingLanguageChange = false;
  });

  // matchers
  builder.addMatcher(isFlagRealtedAction, (state, action) => {
    const flagName = action.type.split('-')[2];
    let flags = state.flags as SessionFlags;
    if (flagName in flags) {
      flags[flagName as keyof SessionFlags] = action.payload;
    } else {
      throw new Error(`Key ${flagName} doesn't exist in object ${flags}`);
    }
  });
});

export default sessionReducer;
