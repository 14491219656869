import { styled } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import { tabsConfig } from '../tabsConfig';

export const StyledTabs = styled(Tabs)({
  backgroundColor: '#1f1f1f',
  color: '#ffffff',
});

export const linkTabStyles = {
  zIndex: 3,
  padding: '17px 0 11px',
  fontSize: '14px',
  marginBottom: '4px',
};

export const setBorderRadius = (activeTabIndex: number) => {
  const firstTab = 0;
  const lastTab = Object.keys(tabsConfig).length - 1;

  switch (activeTabIndex) {
    case firstTab:
      return '0 0 21px 0';
    case lastTab:
      return '0 0 0 21px';
    default:
      return '0 0 21px 21px';
  }
};

export const createTabIndicatorStyles = (activeTab: number) => {
  return {
    background: '#101010',
    marginBottom: '8px',
    height: '52px',
    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.84)',
    borderRadius: setBorderRadius(activeTab),
  };
};
