import { flatten } from 'lodash';

import { Courses } from 'state/views';

export const getCourseStats = (course: Courses.ICourseDTO) => {

  let courseChallenges: Courses.IChallengeDTO[] = []

  course.chapters.forEach(chapter => {
    const challenges = chapter.challenges
    challenges.forEach(challenge => courseChallenges.push(challenge))
  })

  let chapters = course.chapters.length;
  let episodes: number = 0;
  let challenges = courseChallenges.length;
  let duration = course.chapters.reduce((acc, chapter) => {
    episodes += chapter.episodes.length;
    acc += chapter.episodes.reduce((epiAcc, e) => {
      epiAcc += e.video?.duration || 0;
      return epiAcc;
    }, 0);
    return acc;
  }, 0);

  return {
    duration,
    chapters,
    episodes,
    challenges,
  };
};

export const getCourseById = (courseId: string, ...collections: Courses.ICourseDTO[][]) => {
  return collections
    .map((courses) => {
      return courses.find((c) => c.id === courseId);
    })
    .find((x) => x);
};

interface IGetEpisodeByIdResult {
  course: Courses.ICourseDTO | null;
  chapter: Courses.IChapterDTO | null;
  episode: any;
}

export const getEpisodeById = (
  episodeId: string,
  ...collections: Courses.ICourseDTO[][]
): IGetEpisodeByIdResult => {
  let result: IGetEpisodeByIdResult = {
    course: null,
    chapter: null,
    episode: null,
  };

  flatten(collections).some((course) => {
    return course.chapters.some((chapter) => {
      const episode = chapter.episodes.find((e) => e.id === episodeId);
      const challenge = chapter.challenges.find(c => c.id === episodeId)

      if (!!episode) {
        result = {
          course,
          chapter,
          episode,
        };
        return true;
      } else if (!!challenge) {
        result = {
          course,
          chapter,
          episode: challenge,
        };
        return true;
      }
      return false;
    });
  });

  return result;
};

export const getCoursesChallengeById = (
  challengeId: string,
  ...collections: Courses.ICourseDTO[][]
) => {
  return flatten(flatten(flatten(flatten(collections).map((course) => course.chapters))).map(chapter => chapter.challenges)).find(
    (c) => c.id === challengeId,
  );
};

export const getChallengeById = (
  challengeId: string,
  ...collections: Challenges.IChallengeDTO[][]
) => {
  return flatten(collections).find((c) => c.id === challengeId) || null;
};

export const getChallengesByIds = (ids: string[], ...collections: Challenges.IChallengeDTO[][]) => {
  return flatten(collections).filter((val) => val.id && ids.includes(val.id));
};
