import reducer from '../reducers';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

const createStore = () => {
  const store = configureStore({
    reducer: reducer,
    middleware: getDefaultMiddleware({
      // do not check serializable - makes redux persist not throw error
      serializableCheck: false,
    }),
    devTools: process.env.NODE_ENV !== 'production',
  });

  return store;
};

export default createStore;
