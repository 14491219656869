import { createMuiTheme, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';

// Need to reference spacings from the theme
// https://stackoverflow.com/a/48008406/2633602
const defaultTheme = unstable_createMuiStrictModeTheme();

export const CUSTOM_COLORS = {
  primary: '#FFFFFF',
  primaryLight: '#ffffffa6',
  primaryDark: '#1d1d1d',
  transparentBackground: '#010101a6',
  lightOpaqueBackground: '#3f3f3f',
  lightOpaqueBackgroundVariant: '#1F1F1F',
  lightTransparentBackground: '#2f2f2fa6',
  opaqueBackground: '#101010',
  contrastText: '#010101',
  brandOrange: '#FF7722',
  secondary: '#FF7722',
  secondaryLight: '#ff9b5d',
  secondaryDark: '#ff6505',
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: CUSTOM_COLORS.primary,
      light: CUSTOM_COLORS.primaryLight,
      dark: CUSTOM_COLORS.primaryDark,
      contrastText: CUSTOM_COLORS.contrastText,
    },
    secondary: {
      main: CUSTOM_COLORS.secondary,
      light: CUSTOM_COLORS.secondaryLight,
      dark: CUSTOM_COLORS.secondaryDark,
      contrastText: '#FFF',
    },
    text: {
      primary: CUSTOM_COLORS.primary,
    },
    grey: {
      ...defaultTheme.palette.grey,
      300: CUSTOM_COLORS.lightOpaqueBackgroundVariant,
      600: CUSTOM_COLORS.lightTransparentBackground,
      700: CUSTOM_COLORS.transparentBackground,
      800: CUSTOM_COLORS.lightOpaqueBackground,
      900: CUSTOM_COLORS.opaqueBackground,
    },
  },

  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: '1.2',
      textTransform: 'uppercase',
      textAlign: 'center',
      color: CUSTOM_COLORS.primary,

      marginTop: defaultTheme.spacing(10),
      marginBottom: defaultTheme.spacing(4.5),
    },
    h2: {
      fontSize: '1.8rem',
      lineHeight: '1.2',
      color: CUSTOM_COLORS.primary,
    },
    h3: {
      fontSize: '1.625rem',
      lineHeight: '1.2',
      color: CUSTOM_COLORS.primary,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.2',
      color: CUSTOM_COLORS.primary,

      marginTop: defaultTheme.spacing(3),
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '1.2',
      color: CUSTOM_COLORS.primary,

      marginTop: defaultTheme.spacing(2),
    },

    subtitle1: {
      fontSize: '1rem',
      color: CUSTOM_COLORS.primary,
      fontWeight: 300,
    },

    subtitle2: {
      fontSize: '.875rem',
      color: CUSTOM_COLORS.primary,
    },

    body1: {
      // margin: '0 2rem',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5',
      color: CUSTOM_COLORS.primary,

      // marginTop: defaultTheme.spacing(1.5),
    },

    body2: {
      fontSize: '.875rem',
      lineHeight: '1.2',
      color: CUSTOM_COLORS.primary,
      fontWeight: 300,
    },
  },
  overrides: {
    MuiDialog: {
      root: {
        zIndex: 2010,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: CUSTOM_COLORS.opaqueBackground,
      },
    },
    MuiButton: {
      root: {
        padding: '0.6875rem 2.8125rem',
        fontWeight: 'normal',
        lineHeight: '1',
        height: '50px',
      },
      outlined: {
        padding: '0.6875rem 2.8125rem',
        // backgroundColor: CUSTOM_COLORS.primaryLight,
        '&$disabled': {
          color: CUSTOM_COLORS.primaryLight,
          backgroundColor: '#9b9b9b',
        },
      },
      contained: {
        '&$disabled': {
          color: CUSTOM_COLORS.primaryLight,
          backgroundColor: '#9b9b9b',
        },
      },
      sizeSmall: {
        height: '30px',
        minWidth: '260px',
      },

      sizeLarge: {},
    },
    MuiIconButton: {
      root: {
        '&$disabled': {
          color: CUSTOM_COLORS.primaryLight,
        },
      },
    },
  },
});

export default theme;
