/** @jsx jsx */
import { jsx } from '@emotion/core';

import { BackButton } from 'common/components/General/Buttons';
import { useState } from 'react';
import styled from 'styled-components';

import { Container, Grid, Box, useTheme, useMediaQuery, Fade } from '@material-ui/core';

import { Player } from 'views/VideoPlayer/player';
import PlayButton from 'views/VideoPlayer/PlayButton';
import ReplayButton from './ReplayButton';

export const OverlayHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  width: 100%;
  padding-bottom: 20px;
`;

export const PlayerOverlay = <T extends Player.PlayerType, O extends Player.OverlayPropType>({
  show = false,
  expanded = false,
  type,
  customProps,
  onMouseMove,
  playing,
  streamRef,
  onPauseRequest,
  onAllowPlay,
  noControls = false,
  fullScreen,
}: Player.PlayerOverlayProps<T, O>) => {
  const theme = useTheme();
  const [isShowingDialogs, setIsShowingDialogs] = useState(false);
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const [showExpandedElement, setShowExpandedElement] = useState(true);

  let partial: Player.PartialCustomProps = customProps;

  const getOverlayOpacity = () => {
    const shouldShowOverlay = (show || !!partial.alwaysShow) && !isShowingDialogs;
    return shouldShowOverlay ? 1 : 0;
  };

  const {
    ExpandedElement,
    InfoBoxComponent,
    withBackButton,
    withSplitBar,
    ...restOverlayProps
  } = partial;

  return (
    <div
      id='__headerOverlay'
      onMouseEnter={() => setShowExpandedElement(true)}
      onMouseLeave={() => setShowExpandedElement(false)}
      css={{
        position: isMedium ? 'absolute' : 'relative',
        top: '0',
        display: 'block',
        opacity: getOverlayOpacity(),
        transform: '1s opacity',

        width: '100%',
        minHeight: '200px',
        height: expanded ? '100%' : 'auto',
        backgroundColor: 'transparent',
        color: '#fff',

        '&:hover': {
          opacity: '1',
          transform: '1s opacity',
        },
      }}
      onClick={async () => {
        // let result = await document.querySelector('#__headerOverlay')?.requestFullscreen();
      }}
      onMouseMove={onMouseMove}
    >
      <Fade in={!!getOverlayOpacity() || showExpandedElement}>
        <div
          css={{
            position: isMedium ? 'absolute' : 'relative',
            top: '0',
            display: 'block',
            opacity: getOverlayOpacity(),
            transform: '1s opacity',

            width: '100%',
            minHeight: '200px',
            height: expanded ? '100%' : 'auto',
            backgroundColor: !!partial.transparentBackground ? 'transparent' : '#010101a6',
            color: '#fff',

            '&:hover': {
              opacity: '1',
              transform: '1s opacity',
            },
          }}
        >
          <Grid
            container
            style={{position: 'relative', zIndex: 99}}
            // css={{ padding: '42px 102px 0' }}
          >
            {!!withBackButton && (
              <Grid item xs={12}>
                <Box mt={2} ml={2}>
                  <BackButton
                    label={customProps.backButtonLabel}
                    onClick={customProps.onBackClick}
                  />
                </Box>
              </Grid>
            )}
            {InfoBoxComponent && (
              <div style={{zIndex: 2, width: '100%'}}>
                <InfoBoxComponent
                  {...restOverlayProps}
                  expanded={expanded}
                  onDialogShow={(flag: any) => setIsShowingDialogs(flag)}
                  onPauseRequest={onPauseRequest}
                  onAllowPlay={onAllowPlay}
                />
              </div>
            )}
            {expanded && !!withSplitBar && (
              <Box borderBottom='solid 1px #ffffffa6' width='100%' ml={2} mr={2} />
            )}
          </Grid>

          <Container
            css={{
              position: 'absolute',
              marginTop: isMedium ? '-150px' : 0,
              zIndex: 1,
              height: isMedium ? '100%' : isSmall ? '50vw' : '60vw',
              maxWidth: '100%',
              textAlign: 'center',
              display: expanded ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              css={{
                width: 'fit-content',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {expanded ? (
                <ReplayButton
                  open={true}
                  streamPlayer={streamRef.current}
                  playing={playing}
                  position='relative'
                />
              ) : (
                isMedium &&
                !noControls && (
                  <PlayButton
                    open={true}
                    streamPlayer={streamRef.current}
                    playing={playing}
                    position='relative'
                  />
                )
              )}
              {ExpandedElement}
            </div>
          </Container>
        </div>
      </Fade>
    </div>
  );
};
