import React from 'react';
import SlideComponent, { SlideComponentProps } from '../index';

const CourseSlideCompleted = (props: SlideComponentProps) => {
  return (
    <SlideComponent
      withDescription
      withStars
      slideNameWithDetails
      withArrow
      withCompletedFab
      {...props}
    />
  );
};

export default React.memo(CourseSlideCompleted);
