import { Fade } from '@material-ui/core';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import './NavBar.css';
import { tabsConfig } from './tabsConfig';

// TODO: deal with commented out vars
const displayStyle = { display: 'block' };
const hideStyle = { display: 'none' };

const TabContent = () => {
  const routeMatch = useRouteMatch();

  return (
    <React.Fragment>
      {Object.values(tabsConfig).map((tab) => {
        const path = `${routeMatch.path}/${tab.route || tab.path}`;
        return (
          <Route
            key={path}
            path={path}
            exact={tab.exact}
            children={({ match }) => {
              return (
                <Fade key={tab.id} in={!!match} timeout={1000}>
                  <div style={!!match ? displayStyle : hideStyle}>
                    <tab.Component key={tab.id} tabIndex={tab.id} active={!!match} />
                  </div>
                </Fade>
              );
            }}
          />
        );
      })}
    </React.Fragment>
  );
};

export default React.memo(TabContent);
