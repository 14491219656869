import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  makeStyles,
  styled,
  Tooltip,
} from '@material-ui/core';
import {
  format,
  differenceInMilliseconds,
  toDate,
  parseISO,
  isAfter,
  isFuture,
  differenceInHours,
  isSameDay,
} from 'date-fns';
import Countdown from 'react-countdown';
import { pad } from '../../common/utils';
import { FormattedMessage } from 'react-intl';
import {ArrowDownward, Check, Clear} from '@material-ui/icons';
import { bindActionCreators } from 'redux';
import { getSessionsData } from '../../state/views/actions';
import { useDispatch } from 'react-redux';
import { StyledFab } from '../../common/components/SlickCarousel/styledComponents';

export const MediumButton = styled(Button)(({ theme }) => ({
  height: '50px',
  margin: '5px',
  '&.MuiButton-contained.Mui-disabled': {
    color: theme.palette.primary.light,
    backgroundColor: '#9b9b9b',
  },
}));

const ResponsiveGridContainer = styled(Grid)(() => ({
  flexDirection: 'row-reverse',
}));

const blinkerStyles = makeStyles({
  '@keyframes blinker': {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.4,
    },
  },
  blinker: {
    animationName: '$blinker',
    animationDuration: '750ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },
});

const actionsToBound = {
  getSessionsData,
};

const HeroSession = ({
  hero,
  signUpForSession,
  signingUp,
  joinWaitingList,
  leaveWaitingList,
  leaveSession,
  getLiveSessions
}: any) => {
  const dispatch = useDispatch();
  const { blinker } = blinkerStyles();

  const bounded = useMemo(() => bindActionCreators(actionsToBound, dispatch), [dispatch]);

  const ONLINE_SESSION_TYPE = 'Workshop';

  const [onWaitingList, setOnWaitingList] = useState(hero.onWaitingList);

  const currentSlot = hero.slots.find((slot: any) => {
    return isFuture(new Date(parseISO(slot.end)));
  });

  const heroStartDate = new Date(toDate(parseISO(currentSlot.start)));
  const heroEndDate = new Date(toDate(parseISO(currentSlot.end)));

  const hoursToStartDifference = differenceInHours(new Date(), heroStartDate);

  // const heroStartDateFormatted = format(heroStartDate, 'dd.M.yyyy')
  // const heroEndDateFormatted = format(heroStartDate, 'dd.M.yyyy')

  const millisecondsToStart = differenceInMilliseconds(heroStartDate, new Date());
  // const hourToStartInMs = 1 * 1000 * 3600

  const heroStarted = isAfter(new Date(), toDate(parseISO(hero.start)));

  if (hoursToStartDifference < -23) {
    return null;
  }

  return (
    <Fade in>
      <div style={{ height: '100vh - 132px', background: '#000' }}>
        <Box
          pt={[2, 2, 20]}
          pb={[2, 2, 20]}
          style={{
            backgroundColor: 'black',
            backgroundImage: `url(${hero.desktopCover})`,
            height: 'calc(100% - 132px)',
            width: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container justify={'center'}>
            <Grid
              item
              xs={11}
              md={hero.joined ? 10 : 9}
              lg={hero.joined ? 9 : 7}
              xl={hero.joined ? 7 : 5}
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
            >
              <Box mt={[3, 3, 5]} mb={[3, 3, 5]}>
                <ResponsiveGridContainer container>
                  <Grid item xs={12} md={4}>
                    <Countdown
                      date={Date.now() + millisecondsToStart}
                      renderer={({ days, hours, minutes, seconds, completed }) => {
                        if (days === 0 && hours === 1 && minutes === 0 && seconds === 0) {
                          getLiveSessions()
                        }
                        if (days < 1 && hours < 1) {
                          return (
                            <Box
                              display='flex'
                              height='100%'
                              alignItems='center'
                              justifyContent='center'
                              flexDirection='column'
                              borderLeft={[0, 0, '1px solid grey']}
                            >
                              {!completed && (
                                <h3 style={{ marginBottom: 5 }}>
                                  <FormattedMessage
                                    id='MySessions.Hero.NextSession'
                                    defaultMessage='MY NEXT {sessionType} SESSION'
                                    values={{
                                      sessionType: hero.type === ONLINE_SESSION_TYPE ? 'LIVE' : '',
                                    }}
                                  />
                                </h3>
                              )}
                              {!completed && (
                                <h5 style={{ fontWeight: 300, marginTop: 0 }}>
                                  <FormattedMessage
                                    id='MySessions.Hero.StartsIn'
                                    defaultMessage='STARTS IN'
                                  />
                                </h5>
                              )}
                              <Box position='relative' ml={[2, 2, 5]} mr={[2, 2, 5]}>
                                <CircularProgress
                                  variant='static'
                                  size='12rem'
                                  thickness={1}
                                  value={((60 - minutes) / 60) * 100}
                                />
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {completed ? (
                                    <h3 className={blinker}>
                                      <FormattedMessage
                                        id='MySessions.Hero.LiveNow'
                                        defaultMessage='LIVE NOW'
                                      />
                                    </h3>
                                  ) : (
                                    <h1 style={{ fontWeight: 400, fontSize: '20px' }}>
                                      {pad(minutes, 2)} min : {pad(seconds, 2)} s
                                    </h1>
                                  )}
                                </div>
                              </Box>
                            </Box>
                          );
                        } else {
                          return (
                            <Box
                              display='flex'
                              height='100%'
                              alignItems='center'
                              justifyContent='center'
                              flexDirection='column'
                              borderLeft={[0, 0, '1px solid grey']}
                            >
                              <h3 style={{ marginBottom: 5 }}>
                                <FormattedMessage
                                  id='MySessions.Hero.NextSession'
                                  defaultMessage='MY NEXT {sessionType} SESSION'
                                  values={{
                                    sessionType: hero.type === ONLINE_SESSION_TYPE ? 'LIVE' : '',
                                  }}
                                />
                              </h3>
                              <h5 style={{ fontWeight: 300, marginTop: 0 }}>
                                <FormattedMessage
                                  id='MySessions.Hero.StartsIn'
                                  defaultMessage='STARTS IN'
                                />
                              </h5>
                              <Box position='relative' ml={[2, 2, 5]} mr={[2, 2, 5]}>
                                <h1 style={{ fontWeight: 400, margin: 0, fontSize: '20px' }}>
                                  {pad(days * 24 + hours, 2)} h : {pad(minutes, 2)} min
                                </h1>
                              </Box>
                            </Box>
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box ml={[2, 2, 5]} mr={[2, 2, 5]}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12} md={8}>
                              <h3 style={{ marginBottom: 0 }}>{hero.name}</h3>
                              <h3 style={{ marginTop: 5 }}>{currentSlot.name}</h3>
                              <p>run by {hero.coach?.displayName}</p>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Box textAlign='right'>
                                <p>{hero.type}</p>
                                <h3 style={{ marginBottom: 0 }}>
                                  {format(heroStartDate, 'HH:mm')} - {format(heroEndDate, 'HH:mm')}
                                </h3>
                                <h3>
                                  {format(heroStartDate, 'dd.MM.yyyy')}
                                  {!isSameDay(heroStartDate, heroEndDate)
                                    ? ` - ${format(heroEndDate, 'dd.MM.yyyy')}`
                                    : null}
                                </h3>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              {hero.agenda && (
                                <Box mt={1} display='flex' justifyContent='flex-end'>
                                  <Button
                                    style={{
                                      minHeight: '50px',
                                      textTransform: 'none',
                                      fontSize: '20px',
                                      fontWeight: 700,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                    disableRipple
                                    onClick={() => window.open(hero.agenda)}
                                  >
                                    <StyledFab
                                      size='small'
                                      aria-label='download'
                                      style={{ marginRight: '15px', minWidth: '46px', minHeight: '46px' }}
                                    >
                                      <ArrowDownward />
                                    </StyledFab>
                                    <FormattedMessage
                                      id='MySessions.SessionTile.DownloadAgenda'
                                      defaultMessage='Download more information'
                                    />
                                  </Button>
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                          <Box mt={[2, 2, 4]} mb={[2, 2, 6]}>
                            <Box fontSize={['0.9rem', '1rem', '1.1rem']}>
                              <p>{hero.description}</p>
                            </Box>
                          </Box>
                          <Box display='flex' justifyContent='center' alignItems='center'>
                            <Box
                              display='flex'
                              justifyContent='center'
                              flexDirection='column'
                              width='100%'
                              maxWidth='450px'
                            >
                              <MediumButton
                                style={{ margin: 0 }}
                                fullWidth
                                disabled={hero.joined ? hero.joined && !hero.url : hero.isFull}
                                color='secondary'
                                variant='contained'
                                onClick={() =>
                                  hero.joined && hero.url
                                    ? window.open(hero.url, '_blank')
                                    : signUpForSession(hero.id)
                                }
                              >
                                {hero.joined ? (
                                  'Join the meeting'
                                ) : hero.isFull ? (
                                  'Session full'
                                ) : signingUp ? (
                                  <CircularProgress />
                                ) : (
                                  'Sign up'
                                )}
                              </MediumButton>
                              {hero.isFull && !hero.joined && (
                                <Box mt={1}>
                                  <MediumButton
                                    style={{ margin: 0 }}
                                    fullWidth
                                    color='primary'
                                    variant='outlined'
                                    onClick={() => {
                                      onWaitingList
                                        ? leaveWaitingList(hero, () => {
                                            setOnWaitingList(false);
                                            setTimeout(() => bounded.getSessionsData(), 5000);
                                          })
                                        : joinWaitingList(hero.id, () => {
                                            setOnWaitingList(true);
                                            setTimeout(() => bounded.getSessionsData(), 5000);
                                          });
                                    }}
                                  >
                                    {onWaitingList ? (
                                      <Box
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                      >
                                        <Check style={{ marginRight: '10px' }} />
                                        <FormattedMessage
                                          id='MySessions.SessionTile.WaitingList'
                                          defaultMessage='ON WAITING LIST'
                                        />
                                      </Box>
                                    ) : (
                                      <FormattedMessage
                                        id='MySessions.SessionTile.JoinWaitingList'
                                        defaultMessage='JOIN WAITING LIST'
                                      />
                                    )}
                                  </MediumButton>
                                </Box>
                              )}
                            </Box>
                            {hero.joined && (
                              <Box
                                display='flex'
                                justifyContent='flex-end'
                                alignItems='center'
                                height='100%'
                                ml={2}
                              >
                                <Tooltip
                                  title='Sign off'
                                  aria-label='sign off'
                                  arrow
                                  placement='top'
                                >
                                  <StyledFab
                                    size='small'
                                    aria-label='sign off'
                                    onClick={() => {
                                      leaveSession(hero);
                                    }}
                                  >
                                    <Clear />
                                  </StyledFab>
                                </Tooltip>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </ResponsiveGridContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Fade>
  );
};

export default React.memo(HeroSession);
