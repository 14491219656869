import { combineReducers } from 'redux';
import { celebrationReducer } from 'state/celebrations/celebrationReducer';
import { challengesReducer } from 'state/challenges/challengesReducer';
import sessionReducer from 'state/session/sessionReducer';
import viewsReducer from 'state/views/viewsReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  views: viewsReducer,
  challenges: challengesReducer,
  celebrations: celebrationReducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
