import React from 'react'
import {Box, IconButton, useMediaQuery} from "@material-ui/core";
import {StarRate, Stars} from "@material-ui/icons";
import {useTheme} from "@material-ui/core/styles";

const RatingSection = ({stars, setStars, maxRate, disabled, align, size}: any) => {
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  const rateScale = []

  for (let i = 1; i <= maxRate; i++) {
    rateScale.push(i)
  }

  return (
    <React.Fragment>
      <Box textAlign={align ? align : 'center'}>
        {rateScale.map((rate, index) => {
          return <IconButton
            key={rate}
            style={{padding: '6px', paddingLeft: index === 0 ? 0 : '6px'}}
            disabled={disabled}
            onClick={() => setStars(rate)}
          >
            {rate <= stars
              ? <Stars
                fontSize={size
                  ? size
                  : isMedium
                    ? 'large'
                    : 'small'
                }
                style={{color: theme.palette.secondary.main}}
              />
              : <StarRate
                fontSize={size
                  ? size
                  : isMedium
                    ? 'large'
                    : 'small'
                }
                style={{color: theme.palette.primary.light}}
              />

            }
          </IconButton>
        })}
      </Box>
    </React.Fragment>
  )
}

export default RatingSection
