import { axiosInstance as axios, url } from 'api/axiosInstance';

type ChallengesCollectionDTO = Challenges.ChallengesCollectionDTO;

const transformChallenges = (dto: ChallengesCollectionDTO) => {
  const result: Challenges.ChallengesItems = {
    inProgress: dto.filter((x) => x.submission && x.submission.status !== 'COMPLETED'),

    completed: dto.filter((x) => x.submission && x.submission.status === 'COMPLETED'),

    open: dto.filter((x) => !!x.submission === false),

    live: dto.filter((x) => x.is_live),
  };

  return result;
};

const getChallenges = (): Promise<Challenges.ChallengesItems> => {
  return axios
    .request({
      url: url('/api/challenges'),
      transformResponse: transformChallenges,
    })
    .then((response) => response.data);
};

const getChallenge = (challengeId: string): Promise<Challenges.IChallengeDTO> => {
  return axios
    .request({
      url: url(`/api/challenges/${challengeId}`),
      method: 'GET',
    })
    .then((response) => response.data);
};
const getCurrentLiveChallenge = (): Promise<Challenges.IChallengeDTO> => {
  return axios
    .request({
      url: url(`/api/challenges/current-live`),
      method: 'GET',
    })
    .then((response) => response.data);
};

const postChallengeSubmission = (challengeId: string, data: any): Promise<void> => {
  return axios
    .request({
      url: url(`/api/challenges/reply/${challengeId}`),
      method: 'POST',
      data: data,
    })
    .then(() => {
      return;
    });
};

const postRequestSubmissionToFeedback = (challengeId: string): Promise<any> => {
  return axios
    .request({
      url: url(`/api/challenges/p2p/request-submission/${challengeId}`),
      method: 'POST',
    })
    .then((response) => response.data);
};

const postReplyToFeedback = (feedbackId: string, data: any): Promise<any> => {
  return axios
    .request({
      url: url(`/api/challenges/feedback/${feedbackId}/evaluation`),
      method: 'POST',
      data: data
    })
    .then((response) => response.data)
}

const postSetP2PFeedback = (feedbackId: string, data: any): Promise<any> => {
  return axios
    .request({
      url: url(`/api/challenges/p2p/feedback/${feedbackId}`),
      method: 'POST',
      data: data,
    })
    .then((response) => response.data);
};

const postTakeChallenge = (challengeId: string): Promise<{ challengeId: string }> => {
  return axios
    .request({
      url: url(`/api/challenges/${challengeId}/take`),
      method: 'POST',
    })
    .then(() => ({ challengeId }));
};

const postRemoveSubmission = (challengeId: string) => {
  return axios
    .request({
      url: url(`/api/challenges/${challengeId}/remove`),
      method: 'POST',
    })
    .then((response) => response.data);
};

export const challengesApi = {
  getChallenges,
  getChallenge,
  getCurrentLiveChallenge,
  postChallengeSubmission,
  postTakeChallenge,
  postRemoveSubmission,
  postRequestSubmissionToFeedback,
  postSetP2PFeedback,
  postReplyToFeedback
};
