import { transformAuthResponse } from 'api/apiHandler';
import Axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import * as constants from 'state/session/constants';

export const url = (path: string, params?: Record<string, string>) => {
  let host = process.env.REACT_APP_API_URL?.endsWith('/')
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL + '/';

  let uri = path.startsWith('/') ? path.substr(1) : path;
  let qs = params ? `?${new URLSearchParams(params).toString()}` : '';

  if (process.env.REACT_APP_USE_PROXY === '1') {
    host = '/proxy/';
  }

  return `${host}${uri}${qs}`;
};

const LOGIN_ENDPOINT = '/api/auth/login';
const REFRESH_ENDPOINT = '/api/auth/refresh';

const publicEndpoints = [url(LOGIN_ENDPOINT), url(REFRESH_ENDPOINT)];

export const acceptedStatusCodes = [200, 204, 304];

const axiosInstance = Axios.create({
  responseType: 'json',
});

const getAccessToken = () => {
  return localStorage.getItem(constants.TOKEN_NAME)
}

axiosInstance.defaults.responseType = 'json';

axiosInstance.interceptors.request.use((config) => {
  let access = localStorage.getItem(constants.TOKEN_NAME);
  if (access) {
    config.responseType = 'json';
    if (config.url && !publicEndpoints.includes(config.url)) {
      config.headers.Authorization = `Bearer ${getAccessToken()}`;
    }
  }
  return config;
});

const refreshAuthLogic = (failedRequest: any) => {
  localStorage.removeItem(constants.TOKEN_NAME);
  const refresh_token = localStorage.getItem(constants.REFRESH_TOKEN_NAME);
  if (!refresh_token) {
    return Promise.reject();
  }
  return Axios.request({
    url: url(REFRESH_ENDPOINT),
    data: { refresh_token },
    method: 'POST',
  })
    .then((response) => {
      return transformAuthResponse(response.data);
    })
    .catch((error) => {
      localStorage.removeItem(constants.REFRESH_TOKEN_NAME);
      localStorage.setItem(constants.REQUEST_URL_BACKUP_NAME, window.location.href.toString());

      window.history.pushState(null, '', '/auth');
      window.history.go();
      throw error;
    });
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
  statusCodes: [403],
  retryInstance: axiosInstance,
  pauseInstanceWhileRefreshing: false,
  onRetry: (x) => {
    return x;
  },
});

axiosInstance.interceptors.response.use(
  res => res,
  error => {
    if (error.response.status === 400) {
      return error.response
    } else if (error.response.status === 422) {
      return error.response
    }

    return error
  }
)

export { axiosInstance };
