import React from 'react';
import { Box, Button, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { format, parseISO, toDate } from 'date-fns';
import {useIntl} from "react-intl";

const Name = styled('h3')({
  padding: 0,
  margin: 0,
});

const Peer = styled('h3')({
  padding: 0,
  margin: 0,
  fontWeight: 400,
});

const Deadline = styled('h3')({
  fontWeight: 400,
  padding: 0,
  margin: 0,
});

const P2PTile = ({
  challengeTitle,
  type,
  challengeItem,
  courseName,
  imageUrl,
  deadline,
  feedbacks = [],
  action = () => alert('Default action'),
  actionLabel = 'Take action',
}: any) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const intl = useIntl()

  return (
    <Box mb={5}>
      <img style={{ objectFit: 'cover', width: '100%' }} src={imageUrl} />
      <Box my={1} px={[1, 2, 3]} py={1} style={{ background: '#2F2F2F' }}>
        <Grid container>
          <Grid item xs={7} md={5}>
            <Box pr={1} display='flex' height='100%' alignItems='center' fontSize={[12, 14, 16]}>
              {challengeItem.is_live
                ? intl.formatMessage({
                    id: 'P2PTile.Literal.Challenge',
                    defaultMessage:
                      'Challenge',
                  })
                : intl.formatMessage({
                  id: 'P2PTile.Literal.ChallengeAndCourse',
                  defaultMessage:
                    'Challenge and course',
                })
              }
            </Box>
          </Grid>
          <Grid item xs={5} md={3}>
            <Box display='flex' height='100%' alignItems='center' fontSize={[12, 14, 16]}>
              {type === 'completed'
                ? intl.formatMessage({
                  id: 'P2PTile.Literal.DateCompleted',
                  defaultMessage:
                    'Date completed',
                })
                : type === 'in_progress'
                ? intl.formatMessage({
                    id: 'P2PTile.Literal.DateAdded',
                    defaultMessage:
                      'Date added',
                  })
                : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box p={[1, 2, 3]} mt={1} style={{ background: '#101010' }}>
        <Grid container>
          <Grid item xs={feedbacks.length > 1 ? 12 : 7} md={feedbacks.length > 1 ? 12 : 5}>
            <Box height='100%' display='flex' flexDirection='column' justifyContent='center'>
              <Box pr={1} fontSize={[12, 14, 16]}>
                <Name>{challengeTitle}</Name>
              </Box>
              <Box fontSize={[12, 14, 16]}>
                <Name style={{ fontWeight: 400 }}>{courseName}</Name>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5} md={3}>
            {feedbacks.length === 1 && (
              <Box
                height='100%'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                fontSize={[12, 14, 16]}
              >
                <Deadline>
                  {type === 'completed'
                    ? format(toDate(parseISO(feedbacks[0].completedAt)), 'dd.MM.yyyy HH:mm')
                    : type === 'in_progress'
                    ? format(toDate(parseISO(feedbacks[0].createdAt)), 'dd.MM.yyyy HH:mm')
                    : null}
                </Deadline>
              </Box>
            )}
          </Grid>
          {feedbacks.length === 1 && (
            <Grid item xs={12} md={4}>
              <Box mt={[2, 3, 0]} display='flex' alignItems='center'>
                <Button
                  style={{
                    maxHeight: '40px',
                    visibility: feedbacks.length > 1 ? 'hidden' : 'visible',
                  }}
                  fullWidth
                  onClick={() => {
                    action(challengeItem, feedbacks[0]);
                  }}
                  variant='contained'
                  color='secondary'
                >
                  {actionLabel}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Grid item xs={12}>
        {feedbacks.length > 1 &&
          feedbacks.map((feedback: any, index: number) => {
            return (
              <Box
                key={feedback.id}
                px={[1, 2, 3]}
                py={3}
                style={{
                  background: '#2F2F2F',
                  borderBottom: `1px solid ${
                    index !== feedbacks.length - 1 ? '#ffffffa6' : 'transparent'
                  }`,
                }}
              >
                <Grid container>
                  <Grid item xs={7} md={5}>
                    <Box fontSize={[12, 14, 16]} display='flex' alignItems='center' height='100%'>
                      <Peer>Peer {index + 1}</Peer>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={3}>
                    <Box fontSize={[12, 14, 16]} display='flex' alignItems='center' height='100%'>
                      {type === 'completed'
                        ? format(toDate(parseISO(feedback.completedAt)), 'dd.MM.yyyy HH:mm')
                        : type === 'in_progress'
                        ? format(toDate(parseISO(feedback.createdAt)), 'dd.MM.yyyy HH:mm')
                        : null}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box mt={[2, 2, 0]} height='100%' display='flex' alignItems='center'>
                      <Button
                        style={{ maxHeight: '40px' }}
                        fullWidth
                        onClick={() => action(challengeItem, feedback)}
                        variant='contained'
                        color='secondary'
                      >
                        {actionLabel}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
      </Grid>
    </Box>
  );
};

export default React.memo(P2PTile);
