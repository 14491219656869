import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import VideoPlayer from 'views/VideoPlayer';
import { Typography } from '@material-ui/core';
import { P2PIntroductionInfoBox } from './P2PIntroductionInfoBox';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useChallengesActions } from '../../state/challenges/useChallengesActions';
import { jsx } from '@emotion/core';
import { unwrapResult } from '@reduxjs/toolkit';

export const LiveChallengeHelperPlayer = ({ id, videoId, autoplay }: any) => {
  const history = useHistory();
  const intl = useIntl();

  const [challenge, setChallenge] = useState<any>(null);

  const boundedChallenges = useChallengesActions(
    'setSubmitChallengeDialog',
    'removeSubmission',
    'getChallenge',
  );

  useEffect(() => {
    boundedChallenges
      .getChallenge(id)
      .then(unwrapResult)
      .then((challengeDetails) => {
        setChallenge(challengeDetails);
      });
  }, [boundedChallenges, id, videoId]);

  const videosArray =
    challenge?.helperVideos.map((liveChallenge: any) => liveChallenge.video.videoId) || [];

  const currentVideo = challenge?.helperVideos.find(
    (helperVideo: any) => helperVideo.video.videoId === videoId,
  );

  const indexOfNextVideo = videosArray.indexOf(videoId) + 1;

  const nextVideo = videosArray[indexOfNextVideo];
  const nextVideoObj = challenge?.helperVideos.find(
    (helperVideo: any) => helperVideo.video.videoId === nextVideo,
  );

  return (
    <div id='__introductionPlayerContainer'>
      <VideoPlayer
        src={videoId}
        play
        type='introduction'
        endedTreshold={0}
        coverImage={currentVideo?.desktopCoverImage?.url}
        OverlayProps={{
          name: currentVideo?.title,
          description: currentVideo?.description,
          InfoBoxComponent: P2PIntroductionInfoBox,
          ExpandedElement: (
            <Box maxWidth={'600px'}>
              {nextVideo && (
                <Button
                  color='secondary'
                  variant='contained'
                  style={{ marginTop: '20px', padding: '20px 50px' }}
                  onClick={() => {
                    history.replace(`/watch/live-challenge/${nextVideo}/${challenge.id}`);
                    history.go(0);
                  }}
                >
                  <FormattedMessage
                    id='VideoPlayer.LiveChallengeHelperPlayer.Caption.NextHelperVideo'
                    defaultMessage='PLAY NEXT VIDEO'
                  />
                </Button>
              )}
              {nextVideoObj && <p>
                {intl.formatMessage({
                  id: 'VideoPlayer.LiveChallengeHelperPlayer.Caption.NextVideo',
                  defaultMessage:
                    'Next Video',
                })}: {nextVideoObj?.title}
              </p>}
            </Box>
          ),
          withBackButton: true,
          backButtonLabel: 'Back',
          onBackClick: () => {
            history.go(-1);
          },
        }}
      />
    </div>
  );
};
