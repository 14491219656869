import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  createStyles,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  styled,
  Theme,
  useTheme,
} from '@material-ui/core';
import PlayButton from '../../../common/components/SlickCarousel/Slide/components/PlayButton';
import { saveRouteToGoBack } from '../../../utils/goBack';
import { jsx } from '@emotion/core';
import { useHistory, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useChallengesActions } from '../../../state/challenges/useChallengesActions';
import { FormattedMessage, useIntl } from 'react-intl';
import CheckIcon from '@material-ui/icons/Check';
import { MediumButton } from '../../MySessions/HeroSession';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { useSessionActions } from '../../../state/session/useSessionActions';
import { feedbackChannelName } from '../sections/Feedback/ChallengeFeedback';
import { useSelectorWithTabCompare } from '../../../utils';
import SlickCarousel from '../../../common/components/SlickCarousel';
import { BackButton } from '../../../common/components/General/Buttons';
import { MoreVert } from '@material-ui/icons';
import {differenceInDays} from "date-fns";

const StyledSecondaryListItem = styled(ListItem)({
  transition: '0.3s all',
  border: '1px solid transparent',
  '&:hover': {
    border: '1px solid #FF7722',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 275,
      position: 'absolute',
      top: 50,
      right: 15,
      backgroundColor: theme.palette.grey[800],
    },
    secondaryRoot: {
      width: '100%',
      maxWidth: 275,
      position: 'absolute',
      right: 15,
      backgroundColor: theme.palette.grey[300],
    },
  }),
);

const LiveChallengePage = ({
  liveChallenge,
  updateCurrentLiveChallenge,
  feedbacksCreatedByMe,
}: any) => {
  const params = useParams<{ challengeId: string }>();
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();

  const [P2PShowMore, setP2PShowMore] = useState(false);
  const [P2PShowMoreSecondary, setP2PShowMoreSecondary] = useState(false);

  const user = useSelector((state: RootState) => state.session.user, shallowEqual);

  const thisChallengeGivenFeedbacks = feedbacksCreatedByMe.filter(
    (feedback: any) => feedback.submission.challenge.id === liveChallenge.id
  );

  const {
    postTakeChallenge,
    postRequestSubmissionToFeedback,
    openConfirmationDialog,
    getCurrentLiveChallenge,
  } = {
    ...useChallengesActions(
      'postTakeChallenge',
      'postRequestSubmissionToFeedback',
      'getCurrentLiveChallenge',
    ),
    ...useSessionActions('openConfirmationDialog'),
  };

  useEffect(() => {
    getCurrentLiveChallenge();
  }, [getCurrentLiveChallenge]);

  const challengeId = params.challengeId;

  const [showJoinedButtonText, setShowJoinedButtonText] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowJoinedButtonText(false);
    }, 5000);
  }, [showJoinedButtonText]);

  const goToChallenge = useCallback(() => {
    history.push(`/watch/challenge/${liveChallenge?.id}/autoplay`);
  }, [])

  const handleTakeChallenge = useCallback(() => {
    return postTakeChallenge(liveChallenge?.id || '')
      .then(() => {
        updateCurrentLiveChallenge();
        setShowJoinedButtonText(true);
      })
      .catch((error) => console.log('Error while taking challenge', error));
  }, [liveChallenge, postTakeChallenge, updateCurrentLiveChallenge]);

  const workOnFeedback = (feedback: any) => {
    const routeToGoBack = history.location.pathname;
    saveRouteToGoBack(routeToGoBack);
    history.push(`/main/my-challenges/p2p/${feedback.id}/give`);
  };

  const giveFeedback = useCallback(
    async (challenge: any) => {
      postRequestSubmissionToFeedback({ challengeId: challenge })
        .then(unwrapResult)
        .then((res) => {
          const feedbackToShow = res;

          if (feedbackToShow) {
            const routeToGoBack = history.location.pathname;
            saveRouteToGoBack(routeToGoBack);
            history.push(`/main/my-challenges/p2p/${feedbackToShow.id}/give`);
          }
        })
        .catch((error) => {
          openConfirmationDialog({
            isVisible: true,
            modalType: 'JUST_CONFIRM',
            modalProps: {
              header: liveChallenge?.title,
              text: intl.formatMessage({
                id: 'P2PFeedback.GiveFeedback.Modal.NoSubmission',
                defaultMessage:
                  'There are no available challenge submissions at the moment. Try again later.',
              }),
              confirmationLabel: 'OK',
              onlyConfirm: true,
            },
          });
        });
    },
    [history, intl, liveChallenge, openConfirmationDialog, postRequestSubmissionToFeedback],
  );

  if (!liveChallenge) {
    return null;
  }

  const unfinishedFeedback = feedbacksCreatedByMe.find(
    (feedback: any) =>
      feedback.submission.challenge.id === liveChallenge.id && !feedback.completedAt,
  );

  return (
    <Fade in={!!liveChallenge}>
      <div>
        <Grid item xs={12}>
          <Box mt={2} ml={2}>
            <BackButton
              label={intl.formatMessage({
                id: 'LiveChallengePage.BackButton.Caption.MyChallenges',
                defaultMessage: 'MY CHALLENGES',
              })}
              onClick={() => history.push('/main/my-challenges/challenges')}
            />
          </Box>
        </Grid>
        <Box
          bgcolor={theme.palette.grey[900]}
          border={`solid 1px ${theme.palette.primary.light}`}
          borderRadius='5px'
          gridColumn='span 2'
          mt='30px'
          mx={[2, 4, 6, 13]}
        >
          <Grid container style={{ position: 'relative' }}>
            <Box
              style={{ zIndex: 2 }}
              position='absolute'
              width='100%'
              right={[-10, -10, -40]}
              top={[-8, -8, -16]}
              // mr={[2, 3, 1, 6]}
            >
              <ClickAwayListener onClickAway={() => setP2PShowMore(false)}>
                <div style={{ textAlign: 'right' }}>
                  <Fade in>
                    <IconButton onClick={() => setP2PShowMore(!P2PShowMore)}>
                      <MoreVert
                        color='primary'
                        // color={p2pMode ? 'primary' : undefined}
                      />
                    </IconButton>
                  </Fade>
                  <Fade in={P2PShowMore}>
                    <div className={classes.root}>
                      <List component='nav' aria-label='p2p more' style={{ padding: 0 }}>
                        <ListItem>
                          {intl.formatMessage({
                            id: 'LiveChallengePage.MoreInfoList.HowLiveChallengesWork',
                            defaultMessage: `How LIVE Challenges work?`,
                          })}
                        </ListItem>
                      </List>
                      <Fade in={P2PShowMore}>
                        <div
                          className={classes.secondaryRoot}
                          style={{ position: 'absolute', left: 0 }}
                        >
                          <List component='nav' aria-label='p2p more' style={{ padding: 0 }}>
                            <StyledSecondaryListItem
                              button
                              onClick={() => {
                                setP2PShowMore(false);
                                history.push('/watch/live-challenge-introduction');
                              }}
                            >
                              {
                                intl.formatMessage({
                                  id: 'LiveChallengePage.WatchInstructionsVideo',
                                  defaultMessage:
                                    'Watch Instructions Video',
                                })
                              }
                            </StyledSecondaryListItem>
                            <StyledSecondaryListItem
                              button
                              onClick={() => {
                                setP2PShowMore(false);
                                if (!!user?.company.liveChallengeInstructionsFile) {
                                  window.open(user?.company.liveChallengeInstructionsFile);
                                }
                              }}
                            >
                              {
                                intl.formatMessage({
                                  id: 'LiveChallengePage.DownloadInstructions',
                                  defaultMessage:
                                    'Download instructions',
                                })
                              }
                            </StyledSecondaryListItem>
                          </List>
                        </div>
                      </Fade>
                    </div>
                  </Fade>
                </div>
              </ClickAwayListener>
            </Box>

            <Grid item xs={12} md={7}>
              <Box
                mx={['10px', '20px', '40px', '60px']}
                height='100%'
                display='flex'
                flexDirection='column'
                justifyContent='space-between'
              >
                <Box>
                  <Box
                    fontSize={['18px', '20px', '20px', '26px']}
                    mt={[1, 3, 4, 5]}
                    mb={[1]}
                    pr={4}
                    fontWeight={500}
                  >
                    LIVE P2P: {liveChallenge?.title}
                  </Box>
                  {liveChallenge?.chapter && (
                    <Box
                      fontSize={['16px', '18px', '18px', '20px']}
                      fontWeight={400}
                      mt={[2, 2, 2, 0]}
                    >{`${liveChallenge?.chapter?.course.name} / ${
                      intl.formatMessage({
                        id: 'LiveChallengePage.Literal.Chapter',
                        defaultMessage:
                          'Chapter',
                      })
                    } ${liveChallenge?.chapter?.ordinal}: ${liveChallenge?.chapter?.name}`}</Box>
                  )}
                  <Box fontSize={['12px', '14px', '14px', '14px']} my='20px'>
                    {liveChallenge?.description}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                display='flex'
                alignContent='center'
                justifyContent='center'
                position='relative'
                mx={['10px', '20px', '0px', '0px']}
                mb={['10px', '20px', '0px']}
                height='100%'
              >
                <Box
                  mr={0}
                  ml='auto'
                  maxWidth='100%'
                  position='relative'
                  display='flex'
                  maxHeight={['auto', 'auto', '40vh']}
                >
                  <img
                    src={liveChallenge?.desktopCoverImage?.url}
                    alt={intl.formatMessage({
                      id: 'ChallengeFeedback.DescriptionControl.Cover.Alt',
                      defaultMessage: 'challenge cover image',
                    })}
                    style={{
                      width: '100%',
                      objectFit: 'cover',
                      marginLeft: 'auto',
                      marginRight: '0',
                    }}
                  />
                  <Fade in>
                    <PlayButton
                      onClick={() => {
                        const routeToGoBack = history.location.pathname;
                        saveRouteToGoBack(routeToGoBack);
                        history.push(`/watch/challenge/${liveChallenge?.id}/autoplay`);
                      }}
                    />
                  </Fade>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={5} mb={2} display='flex' justifyContent='center'>
          <MediumButton
            style={{ margin: 0 }}
            color='secondary'
            variant='contained'
            disabled={
              liveChallenge.submission?.completedAt &&
              (liveChallenge.completedSubmissionsCount <= 1 ||
                (!unfinishedFeedback && (thisChallengeGivenFeedbacks.length >= liveChallenge.completedSubmissionsCount - 1)) ||
                liveChallenge.submission?.p2pFeedbackGiven && differenceInDays(new Date(), new Date(liveChallenge.submission?.completedAt)) >= 30)
            }
            onClick={() => {
              const challengeHasFeedbackInProgress = feedbacksCreatedByMe.some(
                (feedback: any) =>
                  feedback.submission.challenge.id === liveChallenge.id && !feedback.completedAt,
              );

              // const unfinishedFeedback = feedbacksCreatedByMe.find(
              //   (feedback: any) =>
              //     feedback.submission.challenge.id === liveChallenge.id && !feedback.completedAt,
              // );

              return liveChallenge.submission
                ? liveChallenge.submission.completedAt
                  ? challengeHasFeedbackInProgress
                    ? unfinishedFeedback && workOnFeedback(unfinishedFeedback)
                    : giveFeedback(liveChallenge.id)
                  : history.push(`/main/submit-challenge/${liveChallenge.id}`)
                : goToChallenge();
            }}
            startIcon={liveChallenge?.submission && showJoinedButtonText ? <CheckIcon /> : null}
          >
            {liveChallenge.submission
              ? showJoinedButtonText
                ? intl.formatMessage({
                  id: 'LiveChallenge.SubmissionButton.ChallengeAccepted',
                  defaultMessage:
                    'CHALLENGE ACCEPTED',
                })
                : liveChallenge.submission.completedAt
                ? thisChallengeGivenFeedbacks.some((feedback: any) => !feedback.completedAt)
                  ? intl.formatMessage({
                      id: 'LiveChallenge.SubmissionButton.WorkOnFeedback',
                      defaultMessage:
                        'WORK ON FEEDBACK',
                    })
                  : liveChallenge.submission.p2pFeedbackGiven
                  ? intl.formatMessage({
                        id: 'LiveChallenge.SubmissionButton.GiveAnotherFeedback',
                        defaultMessage:
                          'GIVE ANOTHER FEEDBACK',
                      })
                  : intl.formatMessage({
                        id: 'LiveChallenge.SubmissionButton.GiveFeedback',
                        defaultMessage:
                          'GIVE FEEDBACK',
                      })
                : intl.formatMessage({
                    id: 'LiveChallenge.SubmissionButton.WorkOnChallenge',
                    defaultMessage:
                      'WORK ON CHALLENGE',
                  })
              : intl.formatMessage({
                id: 'LiveChallenge.SubmissionButton.GoToChallenge',
                defaultMessage:
                  'GO TO CHALLENGE',
              })}
          </MediumButton>
          {liveChallenge.submission?.completedAt && liveChallenge.completedSubmissionsCount <= 1 &&
          <p style={{color: theme.palette.primary.light}}>
            {intl.formatMessage({
              id: 'P2PFeedback.CompletedChallenge.DidNotGiveFeedbackMessage',
              defaultMessage: `It seems like you are the first person to complete this challenge. You will be able to give feedback as soon as more people join.`,
            })}
          </p>
          }
          {liveChallenge.completedSubmissionsCount > 1 && liveChallenge.submission?.completedAt && !unfinishedFeedback && thisChallengeGivenFeedbacks.length >= liveChallenge.completedSubmissionsCount - 1 &&
          <p style={{color: theme.palette.primary.light}}>
            {intl.formatMessage({
              id: 'P2PFeedback.CompletedChallenge.AllFeedbacksGivenMessage',
              defaultMessage: `Well done, you gave feedback to all peers available at the moment. You will be able to give another feedback as more people complete this challenge.`,
            })}
          </p>
          }
        </Box>
        {liveChallenge?.helperVideos.length > 0 && (
          <Box mt={[2, 4, 5]} mb={[2, 4, 12]}>
            <SlickCarousel
              sliderType='helper-videos'
              items={liveChallenge?.helperVideos.map((video: any) => {
                return {
                  ...video,
                  challenge: liveChallenge.id,
                };
              })}
              sliderTitle={
                intl.formatMessage({
                  id: 'LiveChallengePage.SliderTitle.FindOutMore',
                  defaultMessage:
                    'FIND OUT MORE',
                })
              }
              titleCentered
              nothingToShowMessage={
                <span>
                  <FormattedMessage
                    id='LiveChallenge.Section.HelperVideos.NothingToShowMessage'
                    defaultMessage='It seems like there are no additional videos for this live challenge.'
                    values={{
                      br: () => <br />,
                    }}
                  />
                </span>
              }
              slidesToShow={3}
              slidesToScroll={3}
              speed={500}
            />
          </Box>
        )}
      </div>
    </Fade>
  );
};

export default React.memo(LiveChallengePage);
