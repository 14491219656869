/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Typography } from '@material-ui/core';
import {
  StyledContentMedium,
  StyledDialog,
  StyledDialogTitle,
  TitleButton,
} from 'common/components/Dialogs/StyledDialogComponents';
import { VerticalButtons } from 'common/components/Dialogs/VerticalButtons';
import { useCallback, useEffect, useRef, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';

export interface ConfirmationResult {
  result?: boolean;
}

export type ConfirmDialogState = 'show' | 'yes' | 'no' | 'none';

export const useConfirmationDialogState = () => {
  const [dlgState, setDlgState] = useState<ConfirmDialogState>('none');
  const confirmCallbackRef = useRef<() => void>();

  useEffect(() => {
    if (dlgState === 'yes') {
      if (confirmCallbackRef.current) {
        confirmCallbackRef.current();
      }
    }
  }, [dlgState]);

  const confirm = (callback?: () => void) => {
    confirmCallbackRef.current = callback;
    setDlgState('yes');
  };
  const cancel = () => setDlgState('no');
  const reset = () => setDlgState('none');
  const show = () => setDlgState('show');

  return {
    state: dlgState,
    confirm,
    cancel,
    reset,
    show,
  };
};

interface SimpleConfirmationDialogProps {
  open: boolean;
  header: string;
  text: string | null;
  content?: React.ReactElement;
  confirmationLabel: string;
  cancellationLabel: string;
  confirmationIconStart?: React.ReactNode;
  onClose: ({ result }: ConfirmationResult) => void;
  onCancel?: ({ result }: ConfirmationResult) => void;
  fullScreen?: boolean;
  withCloseButton?: boolean;
  customOpacity?: number;
}

export const SimpleConfirmationDialog = ({
  open,
  header,
  text,
  content,
  confirmationLabel,
  cancellationLabel,
  confirmationIconStart,
  onClose,
  onCancel,
  fullScreen = false,
  withCloseButton = false,
  customOpacity,
}: SimpleConfirmationDialogProps) => {
  const handleClose = useCallback(() => onClose && onClose({ result: false }), [onClose]);

  const handleConfirm = useCallback(() => onClose && onClose({ result: true }), [onClose]);

  const handleCancel = useCallback(() => onCancel ? onCancel({}) : onClose && onClose({}), [onCancel]);

  console.log(onCancel)

  return (
    <StyledDialog
      style={{ zIndex: 2010 }}
      fullScreen={fullScreen}
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      onEscapeKeyDown={handleClose}
      onBackdropClick={handleClose}
      customOpacity={customOpacity || 1}
    >
      <StyledDialogTitle style={{opacity: 0.6, background: '#101010'}}>
        <TitleButton onClick={handleClose}>
          {withCloseButton && <ClearIcon color='primary' />}
        </TitleButton>
        <span style={{opacity: '0'}}>Title</span>
      </StyledDialogTitle>
      <StyledContentMedium style={{ textAlign: 'center' }}>
        <Box mt={1}>
          <Typography variant='h3' component='div'>
            {header}
          </Typography>
        </Box>

        <Box mt={5} mb={3}>
          {content ? (
            content
          ) : (
            <Typography variant='body1' style={{ textAlign: 'center' }}>
              {text}
            </Typography>
          )}
        </Box>
        <VerticalButtons
          confirmationLabel={confirmationLabel}
          cancellationLabel={cancellationLabel}
          confirmationIconStart={confirmationIconStart}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      </StyledContentMedium>
    </StyledDialog>
  );
};
