import React, { useCallback, useEffect, useState, Fragment } from 'react';

import { Box, Grid } from '@material-ui/core';
import { MediumAvatar } from 'common/components/Profile/Components/Avatars';
import { Uploader } from 'common/components/Uploader';
import { useTheme } from '@material-ui/core/styles';
import { ArrowRight } from './SubmissionControl';
import { jsx } from '@emotion/core';
import { P2PFeedbackContent } from './P2PFeedbackContent';
import CoachFeedbackContent from './CoachFeedbackContent';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { useChallengesActions } from '../../../../state/challenges/useChallengesActions';
import { useSessionActions } from '../../../../state/session/useSessionActions';
import { useIntl } from 'react-intl';
import CheckIcon from '@material-ui/icons/Check';
import { MediumButton } from '../../../MySessions/HeroSession';
import { saveRouteToGoBack } from '../../../../utils/goBack';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import {
  CenteredTitleDecorationLeft, CenteredTitleDecorationRight,
  SliderTitle,
  TitleWrapper
} from "../../../../common/components/SlickCarousel/styledComponents";
import {differenceInDays} from "date-fns";

export function FeedbackControl({ item, inEditMode = false }: any) {
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();

  const { postRequestSubmissionToFeedback, openConfirmationDialog } = {
    ...useChallengesActions('postRequestSubmissionToFeedback'),
    ...useSessionActions('openConfirmationDialog'),
  };

  const { feedbacksCreatedByMe, user } = useSelector(
    (state: RootState) => ({
      feedbacksCreatedByMe: state.challenges.feedbacksCreatedByMe,
      user: state.session.user
    }),
    shallowEqual,
  );

  const feedbackFromCoachEnabled = user?.company.feedbackFromCoachEnabled

  const unfinishedFeedback = feedbacksCreatedByMe.find(
    (feedback: any) => feedback.submission.challenge.id === item.id && !feedback.completedAt,
  );

  const thisChallengeGivenFeedbacks = feedbacksCreatedByMe.filter(
    (feedback: any) => feedback.submission.challenge.id === item.id
  );

  const workOnFeedback = (feedback: any) => {
    const routeToGoBack = history.location.pathname;
    saveRouteToGoBack(routeToGoBack);
    history.push(`/main/my-challenges/p2p/${feedback.id}/give`);
  };

  const giveFeedback = useCallback(
    async (challenge: any) => {
      postRequestSubmissionToFeedback({ challengeId: challenge })
        .then(unwrapResult)
        .then((res) => {
          const feedbackToShow = res;

          if (feedbackToShow) {
            history.push(`/main/my-challenges/p2p/${feedbackToShow.id}/give`);
          }
        })
        .catch((error) => {
          openConfirmationDialog({
            isVisible: true,
            modalType: 'JUST_CONFIRM',
            modalProps: {
              header: item?.title,
              text: intl.formatMessage({
                id: 'P2PFeedback.GiveFeedback.Modal.NoSubmission',
                defaultMessage:
                  'There are no available challenge submissions at the moment. Try again later.',
              }),
              confirmationLabel: 'OK',
              onlyConfirm: true,
            },
          });
        });
    },
    [history, intl, item, openConfirmationDialog, postRequestSubmissionToFeedback],
  );

  return (
    <Box mt={5}>
      {item.submission?.feedbacks.length === 0 ? (
        <Grid container justify='flex-start' direction='row-reverse'>
          {(item.is_p2p ? true : feedbackFromCoachEnabled) && <Grid item xs={12}>
            <Box display='flex' justifyContent='flex-end'>
              <MediumAvatar user={null} src={''}/>
            </Box>
          </Grid>}
          {(item.is_p2p ? true : feedbackFromCoachEnabled) && <Grid item xs={6} sm={4} md={3} lg={2}>
            <Box
              border={`solid 1px ${theme.palette.primary.light}`}
              borderRadius='4px'
              bgcolor={theme.palette.grey[900]}
              position='relative'
              zIndex={inEditMode ? theme.zIndex.drawer + 2 : 'auto'}
            >
              <Box width='100%' display='flex' justifyContent='space-between' flexWrap='wrap'>
                <ArrowRight/>
                <Box width='100%' display='flex' justifyContent='center'>
                  <p style={{fontSize: '50px', lineHeight: '25px', margin: '0 0 30px'}}>...</p>
                </Box>
              </Box>
            </Box>
          </Grid>}
          {item.is_p2p ? <Grid item xs={12} sm={6} md={5}>
            <Box mr={4} mt={[2, 0]}>
              <h4 style={{margin: 0, fontWeight: 400}}>
                {item.is_p2p ? 'PEER-TO-PEER ' : ''}FEEDBACK
              </h4>
              <p>
                {item.is_p2p
                  ? <Fragment>
                    {item.submission?.isMatched
                      ? intl.formatMessage({
                        id: 'P2PFeedback.CompletedChallenge.MatchedBySystemMessage',
                        defaultMessage: `The system matched you with another user! Your peer will prepare feedback for your work soon.`,
                      })
                      : intl.formatMessage({
                        id: 'P2PFeedback.CompletedChallenge.NoAvailablePeersMessage',
                        defaultMessage: `There are no available feedbacks for your challenge at the moment.`,
                      })
                    }
                    <br/>
                    {intl.formatMessage({
                      id: 'P2PFeedback.CompletedChallenge.NotificationReminderMessage',
                      defaultMessage: `Please be patient and make sure your email notifications are turned on, so you would be notified as soon as there is feedback available.`,
                    })}
                  </Fragment>
                  : intl.formatMessage({
                    id: 'P2PFeedback.CompletedChallenge.CoachPreparingFeedbackMessage',
                    defaultMessage: 'Your coach is preparing feedback for your work. Make sure your feedback notifications are turned on, so you would be notified as soon as there is feedback available.',
                  })
                }
              </p>
            </Box>
          </Grid>
            : feedbackFromCoachEnabled && <Grid item xs={12} sm={6} md={5}>
              <Box mr={4} mt={[2, 0]}>
                <h4 style={{margin: 0, fontWeight: 400}}>
                  FEEDBACK
                </h4>
                <p>
                  {
                    intl.formatMessage({
                      id: 'P2PFeedback.CompletedChallenge.CoachPreparingFeedbackMessage',
                      defaultMessage: 'Your coach is preparing feedback for your work. Make sure your feedback notifications are turned on, so you would be notified as soon as there is feedback available.'
                    })
                  }
                </p>
              </Box>
            </Grid>
          }
        </Grid>
      ) : item.is_p2p ? (
        <Fragment>
          {item.submission?.feedbacks.map((feedback: any) => {
            return <P2PFeedbackContent key={feedback.id} challengeItem={item} item={feedback} />;
          })}
          {/*{!item.submission?.p2pFeedbackGiven && (*/}
          {/*  <Box display='flex' justifyContent='center' mt={2}>*/}
          {/*    <MediumButton*/}
          {/*      style={{ margin: 0 }}*/}
          {/*      color='secondary'*/}
          {/*      variant='contained'*/}
          {/*      onClick={() => {*/}
          {/*        const routeToGoBack = history.location.pathname;*/}
          {/*        saveRouteToGoBack(routeToGoBack);*/}

          {/*        unfinishedFeedback ? workOnFeedback(unfinishedFeedback) : giveFeedback(item.id);*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {intl.formatMessage({*/}
          {/*        id: 'P2PFeedback.CompletedChallenge.Button.GiveFeedback',*/}
          {/*        defaultMessage: 'GIVE FEEDBACK',*/}
          {/*      })}*/}
          {/*    </MediumButton>*/}
          {/*  </Box>*/}
          {/*)}*/}
        </Fragment>
      ) : (
        <Fragment>
          {item.submission?.feedbacks.map((feedback: any) => {
            return (
              <CoachFeedbackContent
                key={feedback.id}
                challengeItem={item}
                item={feedback}
                inEditMode={inEditMode}
              />
            );
          })}
        </Fragment>
      )}
      {item.is_p2p && differenceInDays(new Date(), new Date(item.submission?.completedAt)) < 30 &&
        (
        <Box>
          <Box mt={10} borderBottom='1px solid rgba(255, 255, 255, 0.6)' mb={5} />
          <Box mb={3} display='flex' justifyContent='center'>
            <Box maxWidth={['100%', '50%', '30%']}>
              <SliderTitle
                // centered={titleCentered || localItems.length < slidesToShow}
                centered
                bright
              >
                <TitleWrapper>
                  {intl.formatMessage({
                    id: 'P2PFeedback.CompletedChallenge.YourFeedbackSectionTitle',
                    defaultMessage: `YOUR FEEDBACK TO OTHERS WORK FOR THIS CHALLENGE`,
                  })}
                </TitleWrapper>
              </SliderTitle>
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' >
            <Box display='flex' justifyContent='center' maxWidth={['100%', '90%']}>
              <p style={{maxWidth: 'inherit', textAlign: 'center'}}>
                {unfinishedFeedback
                  ? intl.formatMessage({
                    id: 'P2PFeedback.CompletedChallenge.UnfinishedFeedbackMessage',
                    defaultMessage: `You have not finished feedback for this challenge yet. To do so, click the button below.`,
                  })
                  : item.completedSubmissionsCount <= 1
                    ? intl.formatMessage({
                      id: 'P2PFeedback.CompletedChallenge.DidNotGiveFeedbackMessage',
                      defaultMessage: `It seems like you are the first person to complete this challenge. You will be able to give feedback as soon as more people join.`,
                    })
                    : item.completedSubmissionsCount > 1 && !unfinishedFeedback && thisChallengeGivenFeedbacks.length >= item.completedSubmissionsCount - 1
                      ? intl.formatMessage({
                        id: 'P2PFeedback.CompletedChallenge.AllFeedbacksGivenMessage',
                        defaultMessage: `Well done, you gave feedback to all peers available at the moment. You will be able to give another feedback as more people complete this challenge.`,
                      })
                      : item.submission?.p2pFeedbackGiven
                        ? intl.formatMessage({
                          id: 'P2PFeedback.CompletedChallenge.GiveAnotherFeedbackMessage',
                          defaultMessage: `Give another feedback for this challenge!`,
                        })
                        : intl.formatMessage({
                          id: 'P2PFeedback.CompletedChallenge.DidNotGiveFeedbackYetMessage',
                          defaultMessage: `You did not give any feedback yet. Be sure to give one in order to be able to see received feedback.`,
                        })
                }
              </p>
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' mt={6}>
            <Box>
              <MediumButton
                style={{ margin: 0 }}
                color='secondary'
                variant='contained'
                disabled={
                  item.submission?.completedAt &&
                    (item.completedSubmissionsCount <= 1 || !unfinishedFeedback && (thisChallengeGivenFeedbacks.length >= item.completedSubmissionsCount - 1))
                }
                onClick={() => {
                  const routeToGoBack = history.location.pathname;
                  saveRouteToGoBack(routeToGoBack);

                  unfinishedFeedback ? workOnFeedback(unfinishedFeedback) : giveFeedback(item.id);
                }}
              >
                {unfinishedFeedback
                  ? intl.formatMessage({
                    id: 'P2PFeedback.CompletedChallenge.Button.WorkOnFeedback',
                    defaultMessage: 'WORK ON FEEDBACK',
                  })
                  : item.submission.p2pFeedbackGiven
                    ? intl.formatMessage({
                      id: 'P2PFeedback.CompletedChallenge.Button.GiveAnotherFeedback',
                      defaultMessage: 'GIVE ANOTHER FEEDBACK',
                    })
                    : intl.formatMessage({
                      id: 'P2PFeedback.CompletedChallenge.Button.GiveFeedback',
                      defaultMessage: 'GIVE FEEDBACK',
                    })
                }
              </MediumButton>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
