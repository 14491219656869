/** @jsx jsx */
import { jsx } from '@emotion/core';
import { padStart } from 'lodash';
import { useEffect } from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { useChallengesActions } from 'state/challenges/useChallengesActions';
import { useViewsActions } from 'state/views/useViewsActions';

import VideoPlayer from 'views/VideoPlayer';
import { ChallengeInfoBox } from 'views/VideoPlayer/ChallengeInfoBox';
import { getChallengeById, getCoursesChallengeById } from 'views/VideoPlayer/utils';
import { LiveChallengeInfoBox } from './LiveChallengeInfoBox';
import {useIntl} from "react-intl";

export const ChallengePlayer = ({ id, autoplay }: { id: string; autoplay: boolean }) => {
  const history = useHistory();
  const intl = useIntl()
  const challenge: Challenges.IChallengeDTO | undefined = useSelector((state: RootState) => {
    return (
      getChallengeById(
        id,
        state.challenges.items.open,
        state.challenges.items.inProgress,
        state.challenges.items.completed,
      ) ||
      getCoursesChallengeById(id, state.views.courses, state.views.courses, state.views.courses)
    );
  }, shallowEqual);

  const course = useSelector(
    (state: RootState) => state.views.courses.find((c) => c.id === challenge?.chapter?.course.id),
    shallowEqual,
  );

  const { getChallenges, getMyCoursesData } = {
    ...useChallengesActions('getChallenges'),
    ...useViewsActions('getMyCoursesData'),
  };

  useEffect(() => {
    if (challenge?.is_p2p) {
      return;
    } else if (!challenge || !course) {
      getMyCoursesData();
      getChallenges();
    }
  }, [challenge, course, getChallenges, getMyCoursesData]);

  const chapter = course?.chapters.find((c) => c.id === challenge?.chapter.id);
  const episode = chapter?.episodes.find((e) => e.id === challenge?.video?.videoId);

  const { currentLiveChallenge } = useSelector(
    (state: RootState) => ({
      currentLiveChallenge: state.challenges.currentLiveChallenge,
    }),
    shallowEqual,
  );

  const isCurrentLiveChallenge =
    !!currentLiveChallenge && !!challenge && currentLiveChallenge?.id === challenge?.id;

  return (
    <div id='__challengePlayer'>
      {course && challenge ? (
        <VideoPlayer
          play={autoplay}
          src={challenge?.video?.videoId}
          coverImage={challenge?.desktopCoverImage?.url}
          type='challenge'
          OverlayProps={{
            course,
            name: challenge.title,
            description: challenge.description,
            caption: `Chapter ${padStart(chapter?.ordinal.toString(), 2, '0')}`,
            challenge: challenge,
            withBackButton: true,
            InfoBoxComponent: ChallengeInfoBox,
            backButtonLabel: intl.formatMessage({
              id: 'ChallengePlayer.BackButtonLabel',
              defaultMessage:
                "MY CHALLENGES",
            }),
            onBackClick: () => history.replace('/main/my-challenges/challenges', {}),
          }}
        />
      ) : (
        challenge && (
          <VideoPlayer
            play={autoplay}
            src={challenge?.video?.videoId}
            coverImage={challenge?.desktopCoverImage?.url}
            type='challenge'
            OverlayProps={{
              name: challenge.title,
              description: challenge.description,
              caption: '',
              challenge: challenge,
              withBackButton: true,
              InfoBoxComponent: LiveChallengeInfoBox,
              backButtonLabel: 'BACK',
              onBackClick: () =>
                isCurrentLiveChallenge
                  ? history.push(`/main/my-challenges/live/${challenge.id}`)
                  : history.push('/main/my-challenges/challenges'),
            }}
          />
        )
      )}
    </div>
  );
};
