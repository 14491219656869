import { Box, makeStyles, useTheme } from '@material-ui/core';
import cx from 'classnames';
import React, { MouseEventHandler, ReactNode } from 'react';
import { StyledFab } from './StyledFab';

interface ScoreProps {
  value?: number | string;
  children?: ReactNode;
  label: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  className?: string;
  inlineLabel?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles(() => ({
  scoreBox: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const Score = ({
  value,
  label,
  children,
  onClick,
  className,
  disabled = false,
  size = 'large',
  inlineLabel
}: ScoreProps) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display='flex' flexDirection={inlineLabel ? 'row' : 'column'} className={cx(classes.scoreBox, className)}>
      <StyledFab disableRipple disableTouchRipple onClick={onClick} disabled={disabled} size={size}>
        {value?.toString() || children || ''}
      </StyledFab>
      <Box
        fontSize='12px'
        textAlign='center'
        color={disabled ? theme.palette.grey[800] : theme.palette.primary.main}
        marginTop={inlineLabel ? 0 : '10px'}
        marginLeft={inlineLabel ? '10px' : 0}
      >
        {label}
      </Box>
    </Box>
  );
};
