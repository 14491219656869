import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSessionActions } from 'state/session/useSessionActions';
import { useChallengesActions } from 'state/challenges/useChallengesActions';
import {
  SlideActionsContext,
  SlideActionsFactory,
} from 'common/components/SlickCarousel/SlideActionsContext';
import {useViewsActions} from "./state/views/useViewsActions";

export const HistoryHook: React.FC = ({ children }) => {
  const history = useHistory();

  const bounded = useSessionActions(
    'setOpenAvatarMenu',
    'setLoginDialog',
    'setOpenNotificationsMenu',
  );

  useEffect(() => {
    history.listen(() => {
      bounded.setOpenAvatarMenu(false);
      bounded.setOpenNotificationsMenu(false);
      bounded.setLoginDialog(false);
    });
  }, [bounded, history]);

  const slideActions = new SlideActionsFactory(history, {
    ...useViewsActions('getMyCoursesData'),
    ...useSessionActions('setActiveTab'),
    ...useChallengesActions('getChallenge'),
  });

  return (
    <React.Fragment>
      <SlideActionsContext.Provider value={slideActions}>{children}</SlideActionsContext.Provider>
    </React.Fragment>
  );
};
