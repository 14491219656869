import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useChallengesActions } from '../../../state/challenges/useChallengesActions';
import { Clear } from '@material-ui/icons';
import { styled as styledMui, withStyles } from '@material-ui/core/styles';
import { mandatory } from '../../../utils/form-validation';
import { EditorTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import { areFilesOverszed, FileDescriptor, Uploader } from '../../../common/components/Uploader';
import {FormattedMessage, useIntl} from 'react-intl';
import arrayMutators from 'final-form-arrays';
import { setFieldValue } from '../../../utils/setFieldValue';
import { UploaderFilesList } from '../../../common/components/Uploader/Components/UploaderFilesList';
import RatingSection from "../sections/Feedback/RatingSection";
import {jsx} from "@emotion/core";

interface IBackground {
  backgroundImage?: string;
  customOpacity?: number;
}

const DialogWithStyles = ({ ...rest }: IBackground & DialogProps) => <Dialog {...rest} />;

export const StyledDialog = styledMui(DialogWithStyles)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundImage: (props: IBackground) =>
      props.backgroundImage ? `url(${props.backgroundImage})` : 'none',
    backgroundSize: (props: IBackground) => (props.backgroundImage ? 'cover' : ''),
    backgroundPosition: 'center',
  },
  '& .MuiPaper-root': {
    opacity: (props: IBackground) => props.customOpacity || 1,
    backgroundColor: (props: IBackground) =>
      props.backgroundImage ? theme.palette.grey[700] : 'transparent',
  },
}));

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    padding: '0rem',
    borderRadius: 0,
    backgroundColor: theme.palette.grey[900],
    textAlign: 'end',
  },
}))(({ transparent, ...rest }: any) => <DialogTitle {...rest} />);

const StyledContent = styled(DialogContent)({
  padding: 0,
  background: 'black',
});

//TODO just for development
const MAX_MEDIA_SIZE_B = 500 * 1024 * 1024;

const P2PViewFeedback = ({
  challengeItem,
  feedbackItem,
  showDialog = false,
  setShowDialog,
}: any) => {
  const history = useHistory();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const intl = useIntl()

  const [feedbackFiles, setFeedbackFiles] = useState<FileDescriptor[]>([]);
  const [oversized, setOversized] = useState(false);
  const [challengeFilesDesriptors, setChallengeFilesDescriptors] = useState<FileDescriptor[]>([]);
  const [completeWithEmptyAnswersModal, setCompleteWithEmptyAnswersModal] = useState(false);
  const [initialMedia, setInitialMedia] = useState<Challenges.IMedia[]>([]);

  const { setP2PFeedback } = useChallengesActions('setP2PFeedback');

  const handleClose = () => {
    history.push('/main/my-challenges/p2p');
    setShowDialog(false);
  };

  const initialValues = {
    content: feedbackItem?.content,
    evaluationContent: feedbackItem?.evaluation?.content
  };

  const handleUploaderChange = useCallback((files: FileDescriptor[]) => {
    setFeedbackFiles(files);
    setOversized(areFilesOverszed(files, MAX_MEDIA_SIZE_B));
  }, []);

  useEffect(() => {
    let result: FileDescriptor[] = [];

    const challengeMedia = feedbackItem?.submission?.media || [];

    challengeMedia.forEach((m: any) =>
      result.push({
        id: m.id,
        file: null,
        fileName: m.name,
        url: m.url,
        isOversized: false,
      }),
    );

    setChallengeFilesDescriptors(result);
    setInitialMedia(feedbackItem?.media || []);
  }, [feedbackItem]);

  return (
    <StyledDialog
      onClose={handleClose}
      style={{ zIndex: 2010 }}
      PaperProps={{ style: { backgroundColor: 'transparent' } }}
      fullScreen={!isMedium}
      maxWidth='lg'
      fullWidth
      backgroundImage={challengeItem?.desktopCoverImage?.url}
      customOpacity={0.95}
      open={showDialog}
    >
      <StyledDialogTitle>
        <Grid container>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='flex-end'>
              <IconButton onClick={handleClose}>
                <Clear color='primary' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </StyledDialogTitle>
      <StyledContent>
        <Box mx={[2, 3, 10]}>
          <Grid container>
            <Grid item xs={12}>
              <Fade in>
                <h2 style={{ textAlign: 'center', fontWeight: 500 }}>
                  {
                    intl.formatMessage({
                      id: 'ViewFeedback.DialogTitle',
                      defaultMessage:
                        'View feedback',
                    })
                  }
                </h2>
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <h4
                style={{
                  textAlign: 'right',
                  color: 'rgba(255, 255, 255, 0.6)',
                  fontWeight: 400,
                  margin: 0,
                }}
              >
                {
                  intl.formatMessage({
                    id: 'ViewFeedback.ChallengePreview',
                    defaultMessage:
                      'CHALLENGE PREVIEW',
                  })
                }
              </h4>
            </Grid>
            <Grid item xs={12}>
              <Box
                mt={1}
                mb={5}
                px={[2, 2, 5]}
                style={{ background: '#101010', maxHeight: '450px', overflowY: 'auto' }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <h2 style={{ fontWeight: 500, marginBottom: 8 }}>{challengeItem?.title}</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <h3 style={{ fontWeight: 400, margin: 0 }}>
                      {challengeItem?.chapter?.course?.name} {challengeItem?.chapter && '/'}{' '}
                      {challengeItem?.chapter?.name} {challengeItem?.chapter?.ordinal}
                    </h3>
                  </Grid>
                  {challengeItem?.questions.map((question: any, index: number) => {
                    return (
                      <Grid item key={question.id} xs={12}>
                        <Box
                          my={[2, 2, 3]}
                          px={[2, 2, 5]}
                          py={'3px'}
                          style={{ background: 'rgb(47, 47, 47)' }}
                        >
                          <h4 style={{}}>
                            {index + 1}/{challengeItem?.questions?.length} {question.text}
                          </h4>
                        </Box>
                        <Box>
                          <p>
                            {
                              feedbackItem?.submission?.answers?.find((answer: any) => {
                                return answer.question.id === question.id;
                              })?.content
                            }
                          </p>
                        </Box>
                      </Grid>
                    );
                  })}
                  <Box mt={2} mb={5} width={'100%'}>
                    <UploaderFilesList
                      displayOnly
                      filesDescriptors={challengeFilesDesriptors}
                      onDelete={() => null}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <Box mb={[2, 0, 0]}>
                  <h4>
                    {
                      intl.formatMessage({
                        id: 'ViewFeedback.YourFeedback',
                        defaultMessage:
                          'Your feedback',
                      })
                    }
                  </h4>
                </Box>
                <Form
                  onSubmit={(values) => {}}
                  validate={(values) => {
                    let errors: Partial<Record<string, string>> = {};

                    return errors;
                  }}
                  mutators={{ ...arrayMutators, setFieldValue }}
                  initialValues={initialValues}
                >
                  {({ handleSubmit, submitting, hasValidationErrors, form }) => {
                    return (
                      <React.Fragment>
                        <Field
                          disabled
                          name='content'
                          component={EditorTextFieldAdapter}
                          color='primary'
                          placeholder='Write something...'
                          validate={value => {
                            mandatory(value, intl.formatMessage({
                              id: 'FormValidation.MandatoryField',
                              defaultMessage:
                                'This field is required.',
                            }))
                          }}
                          fullWidth
                          multiline
                          rows={5}
                          rowsMax={20}
                          variant='outlined'
                        />
                        <Box mt={-2} mb={5}>
                          <Uploader
                            allowDownload={true}
                            displayOnly
                            disabled={submitting}
                            onChange={handleUploaderChange}
                            media={initialMedia}
                          />
                        </Box>

                        {oversized && (
                          <div
                            css={{ color: theme.palette.error.dark, marginTop: theme.spacing(1) }}
                          >
                            <FormattedMessage
                              id='MyChallenges.SubmitChallengeDialog.Validation.OveralFilesSizeExceeded'
                              defaultMessage='Overall files size exceeded or one of files is too big'
                            />
                          </div>
                        )}

                        <Field name='draft'>
                          {({ input }) => <input {...input} type='hidden' />}
                        </Field>
                        {feedbackItem.evaluation &&
                          <Box mb={5}>
                            <h4 style={{marginBottom: '8px'}}>Peer {!feedbackItem.evaluation.content ? 'rate' : 'response'}</h4>
                            <Box display='flex' flexWrap='wrap' alignItems='center' justifyContent='space-between' mb={[2, 1, 1]}>
                              <Box zIndex={1} pb={1} color={theme.palette.primary.light}>
                                <Box fontWeight={600}>
                                  {intl.formatDate(feedbackItem.evaluation.createdAt, {
                                    weekday: 'long',
                                  })}
                                </Box>
                                <Box>{
                                  `${intl.formatDate(feedbackItem.evaluation.createdAt)} at ${intl.formatTime(
                                    feedbackItem.evaluation.createdAt,
                                  )}`
                                }
                                </Box>
                              </Box>
                              <RatingSection
                                size='small'
                                disabled
                                align='right'
                                maxRate={5}
                                stars={feedbackItem.evaluation?.rate}
                                setStars={() => {}}
                              />
                            </Box>
                            {feedbackItem.evaluation.content && <Field
                              disabled
                              name='evaluationContent'
                              component={EditorTextFieldAdapter}
                              color='primary'
                              placeholder='Write something...'
                              validate={value => {
                                mandatory(value, intl.formatMessage({
                                  id: 'FormValidation.MandatoryField',
                                  defaultMessage:
                                    'This field is required.',
                                }))
                              }}
                              fullWidth
                              multiline
                              rows={5}
                              rowsMax={20}
                              variant='outlined'
                            />}
                          </Box>
                        }
                      </React.Fragment>
                    );
                  }}
                </Form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </StyledContent>
    </StyledDialog>
  );
};

export default React.memo(P2PViewFeedback);
