import { acceptedStatusCodes, axiosInstance as axios, url } from 'api/axiosInstance';
import { AxiosResponse } from 'axios';
import fnsFormat from 'date-fns/format';
import fileDownload from 'js-file-download';
import { omit, pick } from 'lodash';
import * as constants from '../state/session/constants';
import { Courses, ISessionsData } from '../state/views';

interface IUserResponse {
  user: IUserDto;
}

const isUserResponse = (data: any): data is IUserResponse => {
  return !!data.user;
};

export const transformUser = <D = IUserResponse | {}>(data: D): ISessionUser | D => {
  if (isUserResponse(data)) {
    const userDto = data.user;
    if (userDto === null || userDto === undefined) throw new Error('No user in response');

    const sessionUser: Partial<ISessionUser> = omit(userDto, 'avatar');

    if (!Object.keys(sessionUser).includes('initials')) {
      sessionUser.initials = `${userDto.firstName[0]}${userDto.lastName[0]}`;
    }

    if (userDto.avatar && userDto.avatar.length > 0) {
      sessionUser.avatar = userDto.avatar[0];
    }

    return sessionUser as ISessionUser;
  } else {
    return data;
  }
};

const isAuthenticationResponse = (data: any): data is IAuthenticationResponse => {
  return data.user && data.auth;
};

export const transformAuthResponse = <D = IAuthenticationResponse | {}>(
  data: D,
): TransformedAuthenticationResponse | any => {
  if (isAuthenticationResponse(data)) {
    const result = { ...omit(data, 'user'), user: transformUser({ user: data.user }) };
    if (data?.auth?.access && data?.auth?.refresh) {
      localStorage.setItem(constants.TOKEN_NAME, data.auth.access);
      localStorage.setItem(constants.REFRESH_TOKEN_NAME, data.auth.refresh);
    }
    return result;
  } else {
    // debugger;
    return data;
  }
};

const getRegistrationToken = (token: string): Promise<{ email: string }> => {
  return axios
    .request<{ email: string }>({
      url: url('/api/auth/signUpPreset', {
        token,
        random: Date.now().toString(),
      }),
      responseType: 'json',
    })
    .then((response) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        throw new Error(`response status ${response.statusText}`);
      }
    });
};

const setP2PChecked = () => {
  return axios.request({
    url: url('/api/profile/p2p-ready-confirm'),
    method: 'POST',
  });
};

const setLiveChecked = () => {
  return axios.request({
    url: url('/api/profile/live-challenge-ready-confirm'),
    method: 'POST',
  });
};

const postNewPassword = (identity: IUserIdentity) => {
  return axios
    .request<IAuthenticationResponse, AxiosResponse<TransformedAuthenticationResponse>>({
      url: url('/api/auth/signUp'),
      method: 'POST',
      data: identity,
      transformResponse: transformAuthResponse,
    })
    .then((response: any) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        console.log(response.response)
        if (response.response.status === 422 && response.response.data?.errors?.password) {
          throw new Error(response.response.data.errors?.password)
        } else {
          throw new Error('Registration failed')
        }
      }
    });
};

const postChangePassword = (password: IPasswordChangeDTO): Promise<void> => {
  return axios
    .request({
      url: url('/api/profile/changePassword'),
      method: 'POST',
      data: password,
    })
    .then((response) => {
      return response.data
    })
    .catch(error => {
      return error
    })
};

const logInUser = (identity: IUserIdentity): Promise<TransformedAuthenticationResponse> => {
  return axios(url('/api/auth/login'), {
    method: 'POST',
    data: identity,
    transformResponse: transformAuthResponse,
  }).then((response: any) => {
    if (acceptedStatusCodes.includes(response.status)) {
      return response.data;
    } else {
      // this needs to be changed at some point, probably will need to rewrite refresh token logic
      throw new Error(response.response.data.message)
    }
  })
};

const getHomeView = (): Promise<Courses.IHomeState> => {
  return axios
    .request({
      method: 'GET',
      url: url('/api/home'),
      responseType: 'json',
    })
    .then((response) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        throw new Error(`response status ${response.statusText}`);
      }
    });
};

const getSessionsView = (): Promise<ISessionsData> => {
  return axios
    .request({
      method: 'GET',
      url: url('/api/live-sessions'),
      responseType: 'json',
    })
    .then((response) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        throw new Error(`response status ${response.statusText}`);
      }
    });
};

const postJoinSession = (sessionId: string): Promise<any> => {
  return axios(url(`/api/live-sessions/${sessionId}/join`), {
    method: 'POST',
  }).then((response) => {
    if (acceptedStatusCodes.includes(response.status)) {
      return response.data;
    } else {
      throw new Error(response.data.message);
    }
  })
};

const postJoinSessionWaitingList = (sessionId: string): Promise<any> => {
  return axios(url(`/api/live-sessions/${sessionId}/join-waiting-list`), {
    method: 'POST',
  })
    .then((response) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        return response;
        // throw new Error(`response status ${response.statusText}`);
      }
    })
    .catch((error) => error);
};

const postLeaveSession = (sessionId: string): Promise<any> => {
  return axios(url(`/api/live-sessions/${sessionId}/leave`), {
    method: 'POST',
  })
    .then((response) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        return response;
        // throw new Error(`response status ${response.statusText}`);
      }
    })
    .catch((error) => error);
};

const postLeaveSessionWaitingList = (sessionId: string): Promise<any> => {
  return axios(url(`/api/live-sessions/${sessionId}/leave-waiting-list`), {
    method: 'POST',
  })
    .then((response) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        return response;
        // throw new Error(`response status ${response.statusText}`);
      }
    })
    .catch((error) => error);
};

const getMyCoursesView = () => {
  return axios
    .request({
      method: 'GET',
      url: url('/api/courses'),
      responseType: 'json',
    })
    .then((response: AxiosResponse<Courses.ICourseDTO[]>) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        throw new Error(`response status ${response.statusText}`);
      }
    });
};

const getFeedbacksCreatedByMe = () => {
  return axios
    .request({
      method: 'GET',
      url: url('/api/challenges/p2p/feedback'),
      responseType: 'json',
    })
    .then((response: AxiosResponse<any>) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        throw new Error(`response status ${response.statusText}`);
      }
    });
};

const postJoinCourse = (courseId: string): Promise<any> => {
  return axios(url(`/api/courses/${courseId}/join`), {
    method: 'POST',
  })
    .then((response) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        return response;
        // throw new Error(`response status ${response.statusText}`);
      }
    })
    .catch((error) => error);
};

const postLeaveCourse = (courseId: string): Promise<any> => {
  return axios(url(`/api/courses/${courseId}/leave`), {
    method: 'POST',
  })
    .then((response) => {
      if (acceptedStatusCodes.includes(response.status)) {
        return response.data;
      } else {
        return response;
        // throw new Error(`response status ${response.statusText}`);
      }
    })
    .catch((error) => error);
};

const getFAQ = (): Promise<IFaqResponse> => {
  return axios({
    url: url('/api/faq'),
  }).then((response) => {
    if (acceptedStatusCodes.includes(response.status)) {
      return response.data;
    } else {
      throw new Error(`response status ${response.statusText}`);
    }
  });
};

const postContactUsData = (data: any) => {
  return axios
    .request({
      url: url('/api/contact-us'),
      method: 'POST',
      data: data,
    })
    .then((response) => {
      return response.data;
    });
};

const getCurrentProfile = (): Promise<TransformedProfileResponse> => {
  return axios
    .get(url('/api/auth/me'), {
      transformResponse: (data) => ({
        user: transformUser(data),
      }),
    })
    .then((response) => response.data);
};

export interface SetProgressPayload {
  course: string;
  chapter: string;
  watchable: string;

  progressPercent: number;
}
const setEpisodeProgress = (data: SetProgressPayload) => {
  return axios
    .request({
      url: url('/api/episodes/setProgress'),
      method: 'POST',
      data: pick(data, 'watchable', 'progressPercent'),
    })
    .then((response) => response.data);
};

const updateIdentity = (identity: IIdentity): Promise<void> => {
  return axios
    .request({
      url: url('/api/profile/editIdentity'),
      method: 'POST',
      data: identity,
    })
    .then((response) => response.data);
};

const updateAvatar = (data: FormData): Promise<void> => {
  return axios
    .request({ url: url('api/profile/changeAvatar'), method: 'POST', data })
    .then((response) => response.data);
};

const invalidateSession = (refreshToken: string): Promise<void> => {
  return axios
    .request({
      url: url('/api/auth/logout'),
      method: 'POST',
      data: {
        tokenToInvalidate: refreshToken
      }
    }).then(response => response.data)
}

const postResetPasswordRequest = (email: string): Promise<void> => {
  const data = new FormData();
  data.append('email', email);
  return axios
    .request({
      url: url('/api/auth/reset-password'),
      method: 'POST',
      data,
    })
    .then((respo) => respo.data);
};

const postResetPasswordTokenCheck = (token: string): Promise<IEmail> => {
  const data = new FormData();
  data.append('token', token);
  return axios
    .request({
      url: url('/api/auth/reset-password/check'),
      method: 'POST',
      data,
    })
    .then((respo) => respo.data);
};

const postSetNewPasswordWithToken = (token: string, password: string) => {
  const data = new FormData();
  data.append('password', password);
  data.append('token', token);
  return axios.request({
    url: url('/api/auth/reset-password/set'),
    method: 'POST',
    data,
  });
};

const downloadFile = (url: string, fileName: string): Promise<void> => {
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
  })
    .then((res) => res.blob())
    .then((res) => fileDownload(res, fileName));
};

const getNotifications = (olderThan?: Date): Promise<INotificationsDto> => {
  let query = olderThan
    ? `?olderThan=${encodeURIComponent(fnsFormat(olderThan, "yyyy-MM-dd'T'HH:mm:ssxxx"))}`
    : '';
  return axios
    .request({
      url: url(`/api/notifications${query}`),
      method: 'GET',
    })
    .then((response) => response.data);
};

const getUnreadedNotificationsCount = (): Promise<number> => {
  return axios
    .request({
      url: url('/api/notifications/unread'),
      method: 'GET',
    })
    .then((res) => res.data);
};

const markNotificationAsRead = (notificationId: string): Promise<any> => {
  return axios
    .request({
      url: url(`/api/notifications/${notificationId}/mark-read`),
      method: 'post',
    })
    .then((res) => res.data);
};

const postAllowEmailNotification = (flag: boolean) => {
  return axios
    .request({
      url: url('/api/profile/allowEmailNotifications'),
      method: 'POST',
      data: {
        allowEmailNotifications: flag,
      },
    })
    .then((response) => response.data);
};

const getLanguages = () => {
  return axios
    .request<LanguagesDictionary>({
      url: url('/api/languages'),
      method: 'GET',
    })
    .then((response) => response.data);
};

const setLanguage = (langId: string) => {
  return axios.request({
    url: url(`/api/profile/setLanguage/${langId}`),
    method: 'POST',
  });
};

const api = {
  getRegistrationToken,
  postNewPassword,
  postChangePassword,
  logInUser,
  getHomeView,
  getMyCoursesView,
  postJoinCourse,
  postLeaveCourse,
  getFAQ,
  postContactUsData,
  getCurrentProfile,
  setEpisodeProgress,
  updateIdentity,
  updateAvatar,
  postResetPasswordRequest,
  postResetPasswordTokenCheck,
  postSetNewPasswordWithToken,
  downloadFile,
  getSessionsView,
  postJoinSession,
  postLeaveSession,
  postJoinSessionWaitingList,
  postLeaveSessionWaitingList,
  getNotifications,
  markNotificationAsRead,
  getUnreadedNotificationsCount,
  postAllowEmailNotification,
  getLanguages,
  setLanguage,
  getFeedbacksCreatedByMe,
  setP2PChecked,
  setLiveChecked,
  invalidateSession
};

export default api;
