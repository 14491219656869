import React, { MouseEventHandler } from 'react';
import { Box } from '@material-ui/core';
import { VideoPlayerPlayButton } from '../../styledComponents';
import { PlayArrow } from '@material-ui/icons';

interface PlayButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const PlayButton: React.FC<PlayButtonProps> = ({ onClick }) => {
  return (
    <Box
      id='__playButtonWrapper'
      height='100%'
      width='100%'
      position='absolute'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <VideoPlayerPlayButton onClick={onClick} aria-label='play'>
        <PlayArrow style={{ fontSize: '50px' }} />
      </VideoPlayerPlayButton>
    </Box>
  );
};

export default React.memo(PlayButton);
