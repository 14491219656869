import {Box, Grid, IconButton} from '@material-ui/core';
import { SimpleDialogWithForm } from 'common/components/Dialogs/SimpleDialogWithForm';
import { EditorTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import {useTheme} from "@material-ui/core/styles";
import {Stars, StarRate} from "@material-ui/icons";
import RatingSection from "./RatingSection";
import {useChallengesActions} from "state/challenges/useChallengesActions";
import {feedbackChannelName} from "./ChallengeFeedback";
import {useSessionActions} from "../../../../state/session/useSessionActions";
import {useIntl} from "react-intl";

export const ReplyFeedbackModal = ({ open, handleClose, feedbackItem, challengeItem }: { open: boolean, handleClose: () => void, feedbackItem: any, challengeItem: any}) => {

  const intl = useIntl()

  const { replyP2PFeedback } = { ...useChallengesActions('replyP2PFeedback')};

  const { pushMessageSnack } = { ...useSessionActions('pushMessageSnack')}

  const [submitting, setSubmitting] = useState(false)
  const [content, setContent] = useState('')

  const handleSubmit = ({content}: { content: string }) => {
    setSubmitting(true)
    replyP2PFeedback({feedbackId: feedbackItem.id, data: {content, rate: stars}, challengeId: challengeItem.id}).then(
      res => {
        handleClose()
        setSubmitting(false)
        pushMessageSnack({
          channel: feedbackChannelName,
          message: intl.formatMessage({
            id: 'ReplyFeedbackModal.SubmitReply.SuccessMessage',
            defaultMessage: 'Your reply has been sent!',
          }),
        });
      }
    )
  };

  const MAX_RATE = 5
  const [stars, setStars] = useState(3)

  const theme = useTheme()

  return (
    <SimpleDialogWithForm submitting={submitting} open={open} header='Reply to feedback' handleClose={handleClose} onSubmit={handleSubmit}>
      <Box minWidth={['75vw', '65vw', '35vw']}>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={4} mb={2} textAlign='center'>
              <Box mt={2} mb={2}>
                <h3 style={{margin: 0}}>
                  {
                    intl.formatMessage({
                      id: 'ReplyToFeedbackModal.HowUsefulWasThatFeedback',
                      defaultMessage:
                        'How useful was that feedback?',
                    })
                  }
                </h3>
              </Box>
              <p style={{margin: 0, color: theme.palette.primary.light}}>
                {
                  intl.formatMessage({
                    id: 'ReplyToFeedbackModal.LetUsKnow',
                    defaultMessage:
                      'Did you find the feedback helpful? Let us know!',
                  })
                }
              </p>
            </Box>
            <Box my={3} >
              <RatingSection
                maxRate={MAX_RATE}
                stars={stars}
                setStars={setStars}
              />
            </Box>
            <h3 style={{margin: 0}}>
              {
                intl.formatMessage({
                  id: 'ReplyToFeedbackModal.ShareYourThoughtsOptional',
                  defaultMessage:
                    'Share your thoughts (optional)',
                })
              }
            </h3>
            <Box mt={1} mb={4}>
              <p style={{margin: 0, fontSize: '14px', marginBottom: '10px', color: theme.palette.primary.light}}>
                {
                  intl.formatMessage({
                    id: 'ReplyToFeedbackModal.FeelFreeToReply',
                    defaultMessage:
                      'Feel free to say something to the author of the feedback.',
                  })
                }
              </p>
              <Field
                name='content'
                component={EditorTextFieldAdapter}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                inputProps={{
                  value: content,
                  onChange: (e: any) => setContent(e.target.value),
                  maxLength: 500
                }}
                rows={5}
              />
              <p style={{textAlign: 'right', color: theme.palette.primary.light, margin: 0, fontSize: '14px', marginTop: '-15px'}}>{content.length}/500</p>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SimpleDialogWithForm>
  );
};
