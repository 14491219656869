import React, { useMemo, useState } from 'react';
import { Box, Button, Fab, Grid, Grow, makeStyles, styled, Tooltip } from '@material-ui/core';
import { StyledFab } from '../../common/components/SlickCarousel/styledComponents';
import { ArrowDownward, Check, Clear } from '@material-ui/icons';
import {
  differenceInMinutes,
  format,
  isAfter,
  isSameDay,
  parseISO,
  toDate,
} from 'date-fns';
import { getSessionsData, leaveSession } from '../../state/views/actions';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {FormattedMessage, useIntl} from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';

const SpotsFab = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.grey[700],
  color: '#fff',
  border: '1px solid #fff',
  cursor: 'default',
  fontSize: 'inherit',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme.palette.grey[700],
  },
}));

const ResponsiveGridContainer = styled(Grid)(({ theme }) => ({
  flexDirection: 'column-reverse',
}));

const blinkerStyles = makeStyles({
  '@keyframes blinker': {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.4,
    },
  },
  blinker: {
    animationName: '$blinker',
    animationDuration: '750ms',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-in-out',
  },
});

const actionsToBound = {
  getSessionsData,
  leaveSession,
};

const SessionTile = ({
  session,
  isPast,
  leaveSession,
  signUpForSession,
  joinWaitingList,
  leaveWaitingList,
}: any) => {
  const dispatch = useDispatch();
  const [signedUp, setSignedUp] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [onWaitingList, setOnWaitingList] = useState(session.onWaitingList);
  const bounded = useMemo(() => bindActionCreators(actionsToBound, dispatch), [dispatch]);
  const { blinker } = blinkerStyles();
  const intl = useIntl()

  return (
    <React.Fragment>
      <Grow in>
        <Grid item xs={12}>
          <Box mt={2} mb={2}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={5} lg={4} xl={3}>
                    <Box position='relative'>
                      {session.spots - session.count > 0 && !session.joined && (
                        <Box
                          position='absolute'
                          top={0}
                          left={0}
                          p={1}
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          flexDirection='column'
                          fontSize={['12px', '16px', '20px']}
                        >
                          <SpotsFab disableRipple disableTouchRipple size='medium'>
                          <span style={{ textAlign: 'center' }}>
                            {session.spots - session.count}
                          </span>
                        </SpotsFab>
                        <p style={{ margin: '5px 0 0 0' }}>
                          <FormattedMessage
                            id='MySessions.SessionTile.SpotsLeft.Label'
                            defaultMessage='spots left'
                          />
                        </p>
                      </Box>
                    )}
                    <img
                      style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                      src={session.desktopCover}
                      alt={session.name}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={7} lg={8} xl={9}>
                  <Box mt={[2, 2, 0]} ml={[0, 0, 2]} mr={[0, 0, 2]}>
                    <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
                      <Box>
                        <h3 style={{ marginTop: 5, marginBottom: 10 }}>{session.name}</h3>
                        <p style={{ marginTop: 5, marginBottom: 0 }}>
                          {intl.formatMessage({
                            id: 'MySessions.Literal.RunByCoachName',
                            defaultMessage:
                              'run by',
                          })} {session.coach?.displayName}
                        </p>
                        <p style={{ marginTop: 5 }}>{session.type}</p>
                      </Box>
                      {session.joined && !isPast && (
                        <Box display='flex' justifyContent='flex-end' height='100%' mb={2}>
                          <Tooltip title={intl.formatMessage({
                            id: 'MySessions.SignOffButtonTooltip.SignOff',
                            defaultMessage:
                              'Sign off',
                          })} aria-label='sign off' arrow placement='top'>
                            <StyledFab
                              size='small'
                              aria-label='sign off'
                              onClick={() => {
                                leaveSession(session);
                              }}
                            >
                              <Clear />
                            </StyledFab>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                    <Grid container>
                      <Grid item xs={12} md={7}>
                        <p>{session.description}</p>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <ResponsiveGridContainer container>
                          <Grid item xs={12} justify='flex-end'>
                            {session.slots.map((slot: any, index: number) => {
                              const slotHasEnded = isAfter(new Date(), toDate(parseISO(slot.end)));
                              const slotHasStarted = isAfter(
                                new Date(),
                                toDate(parseISO(slot.start)),
                              );
                              const startingSoon =
                                differenceInMinutes(new Date(), toDate(parseISO(slot.start))) > -60;

                                return (
                                  <Box key={index}>
                                    {slot.name && (
                                      <Box display='flex' justifyContent='flex-end'>
                                        <h3
                                          style={{
                                            margin: 5,
                                            color: slotHasEnded && !isPast ? '#909090' : '#fff',
                                          }}
                                        >
                                          {slot.name}
                                        </h3>
                                      </Box>
                                    )}
                                    {!slotHasEnded && slotHasStarted && !isPast && (
                                      <Box display='flex' justifyContent='flex-end'>
                                        <h3
                                          className={blinker}
                                          style={{
                                            color: '#FF7722',
                                            margin: 5,
                                          }}
                                        >
                                          <FormattedMessage
                                            id='MySessions.SessionTile.LiveNow'
                                            defaultMessage='LIVE NOW'
                                          />
                                        </h3>
                                      </Box>
                                    )}
                                    {!slotHasStarted && startingSoon && !isPast && (
                                      <Box display='flex' justifyContent='flex-end'>
                                        <h3
                                          className={blinker}
                                          style={{
                                            color: '#FF7722',
                                            margin: 5,
                                          }}
                                        >
                                          <FormattedMessage
                                            id='MySessions.SessionTile.StartingSoon'
                                            defaultMessage='STARTING SOON'
                                          />
                                        </h3>
                                      </Box>
                                    )}
                                    <Box display='flex' justifyContent='flex-end'>
                                      <h3
                                        style={{
                                          margin: '5px 5px 0',
                                          fontWeight: 500,
                                          color: slotHasEnded && !isPast ? '#909090' : '#fff',
                                        }}
                                      >
                                        {format(new Date(toDate(parseISO(slot.start))), 'HH:mm')} -{' '}
                                        {format(new Date(toDate(parseISO(slot.end))), 'HH:mm')}{' '}
                                      </h3>
                                    </Box>
                                    <Box display='flex' justifyContent='flex-end'>
                                      <h3
                                        style={{
                                          margin: '5px 5px 0',
                                          fontWeight: 500,
                                          color: slotHasEnded && !isPast ? '#909090' : '#fff',
                                        }}
                                      >
                                        {format(toDate(parseISO(slot.start)), 'dd.MM.yyyy')}
                                        {!isSameDay(
                                          toDate(parseISO(slot.end)),
                                          toDate(parseISO(slot.start)),
                                        )
                                          ? ` - ${format(toDate(parseISO(slot.end)), 'dd.MM.yyyy')}`
                                          : null}
                                      </h3>
                                    </Box>
                                    {/*<Box display='flex' justifyContent='flex-end'>*/}
                                    {/*  <h4 style={{ margin: 1 }}></h4>*/}
                                    {/*</Box>*/}
                                    <Box display='flex' justifyContent='flex-end'>
                                      <h3 style={{ margin: 5 }}></h3>
                                    </Box>
                                  </Box>
                                );
                              })}
                              {session.agenda && !isPast && (
                                <Box mt={1}>
                                  <Grid container>
                                    <Grid item xs={12} md={12}>
                                      <Button
                                        style={{
                                          minHeight: '50px',
                                          textTransform: 'none',
                                          fontSize: '20px',
                                          fontWeight: 700,
                                          display: 'flex',
                                          justifyContent: 'flex-end',
                                          alignItems: 'center',
                                        }}
                                        fullWidth
                                        disableRipple
                                        onClick={() => window.open(session.agenda)}
                                      >
                                        <StyledFab
                                          size='small'
                                          aria-label='download'
                                          style={{ marginRight: '15px', minWidth: '46px', minHeight: '46px' }}
                                        >
                                          <ArrowDownward />
                                        </StyledFab>
                                        <FormattedMessage
                                          id='MySessions.SessionTile.DownloadAgenda'
                                          defaultMessage='Download more information'
                                        />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              )}
                              {session.materials && isPast && (
                                <Box mt={1}>
                                  <Grid container>
                                    <Grid item md={2}></Grid>
                                    <Grid item xs={12} md={12}>
                                      <Button
                                        style={{
                                          minHeight: '50px',
                                          textTransform: 'none',
                                          fontSize: '20px',
                                          fontWeight: 700,
                                          display: 'flex',
                                          justifyContent: 'flex-end',
                                          alignItems: 'center',
                                        }}
                                        fullWidth
                                        disableRipple
                                        onClick={() => window.open(session.materials)}
                                      >
                                        <StyledFab
                                          size='small'
                                          aria-label='download'
                                          style={{ marginRight: '15px', minWidth: '46px', minHeight: '46px' }}
                                        >
                                          <ArrowDownward />
                                        </StyledFab>
                                        <FormattedMessage
                                          id='MySessions.SessionTile.DownloadMaterials'
                                          defaultMessage='Download materials'
                                        />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              )}

                              {/*{session.joined && !isPast && (*/}
                              {/*  <Box mt={3}>*/}
                              {/*    <Grid container>*/}
                              {/*      <Grid item md={4}></Grid>*/}
                              {/*      <Grid item xs={12} md={8}>*/}
                              {/*        <Button*/}
                              {/*          style={{ minHeight: '50px' }}*/}
                              {/*          fullWidth*/}
                              {/*          disabled={!session.url}*/}
                              {/*          color='secondary'*/}
                              {/*          variant='contained'*/}
                              {/*          onClick={() => window.open(session.url, '_blank')}*/}
                              {/*        >*/}
                              {/*          <FormattedMessage*/}
                              {/*            id='MySessions.SessionTilie.JoinTheMeeting'*/}
                              {/*            defaultMessage='JOIN THE MEETING'*/}
                              {/*          />*/}
                              {/*        </Button>*/}
                              {/*      </Grid>*/}
                              {/*    </Grid>*/}
                              {/*  </Box>*/}
                              {/*)}*/}
                              {signUpForSession && (
                                <Box mt={3}>
                                  <Grid container>
                                    <Grid item md={4}>
                                      {session.joined && (
                                        <Button style={{ minHeight: '50px' }} fullWidth>
                                          <FormattedMessage
                                            id='Literal.Cancel'
                                            defaultMessage='Cancel'
                                          />
                                        </Button>
                                      )}
                                    </Grid>
                                    <Tooltip
                                      title={
                                        session.joinedToGroup && !session.joined
                                          ? <FormattedMessage
                                            id='MySessions.SessionTile.SignedUpForDifferentSession'
                                            defaultMessage='To sign up for this session, you need to sign off from the similar session you already signed up for.'
                                          />
                                          : ''
                                      }
                                      aria-label='sign off'
                                      arrow
                                      placement='top'
                                    >
                                      <Grid item xs={12} md={8}>
                                        <Button
                                          style={{ minHeight: '50px' }}
                                          fullWidth
                                          disabled={
                                            session.isFull ||
                                            (session.joinedToGroup && !session.joined)
                                          }
                                          color='secondary'
                                          variant='contained'
                                          onClick={() => {
                                            if (signedUp) {
                                              return
                                            }
                                            setSigningUp(true);
                                            signUpForSession(session.id, (res: any) => {
                                              setSignedUp(true);

                                              setSigningUp(false);

                                              bounded.getSessionsData()
                                            })
                                          }}
                                        >
                                          {session.isFull ? (
                                            <FormattedMessage
                                              id='MySessions.SessionTile.SessionFull'
                                              defaultMessage='SESSION FULL'
                                            />
                                          ) : signingUp ? (
                                            <CircularProgress />
                                          ) : signedUp ? (
                                            <Box
                                              display='flex'
                                              justifyContent='center'
                                              alignItems='center'
                                            >
                                              <Check style={{ marginRight: '10px' }} />
                                              <FormattedMessage
                                                id='MySessions.SessionTile.SignedUp'
                                                defaultMessage='SIGNED UP'
                                              />
                                            </Box>
                                          ) : (
                                            <FormattedMessage
                                              id='MySessions.SessionTile.SignUp'
                                              defaultMessage='SIGN UP'
                                            />
                                          )}
                                        </Button>
                                      </Grid>
                                    </Tooltip>
                                  </Grid>
                                  {
                                    session.onWaitingList ? (
                                        <p style={{color: '#909090', textAlign: 'right'}}>
                                          <FormattedMessage
                                            id='MySessions.SessionTile.OnTheWaitingList'
                                            defaultMessage='You are on the waiting list.'
                                          />
                                        </p>
                                      )
                                      : session.joinedToGroup && !session.joined && (
                                      <p style={{ color: '#909090', textAlign: 'right' }}>
                                        <FormattedMessage
                                          id='MySessions.SessionTile.AlreadySignedUpOnAnotherDate'
                                          defaultMessage='You already signed up / joined the waiting list for this session on another date.'
                                        />
                                      </p>
                                    )
                                  }
                                </Box>
                              )}
                              {(session.joinedToGroup ? session.isFull && session.onWaitingList && !session.joined : session.isFull && !session.joined) && (
                                <Box mt={1}>
                                  <Grid container>
                                    <Grid item md={4}></Grid>
                                    <Grid item xs={12} md={8}>
                                      <Button
                                        style={{ minHeight: '50px' }}
                                        fullWidth
                                        color='primary'
                                        variant='outlined'
                                        onClick={() => {
                                          onWaitingList
                                            ? leaveWaitingList(session, () => {
                                              setOnWaitingList(false);
                                              bounded.getSessionsData()
                                            })
                                            : joinWaitingList(session.id, () => {
                                              setOnWaitingList(true);
                                              bounded.getSessionsData()
                                            });
                                        }}
                                      >
                                        {onWaitingList ? (
                                          <Box
                                            display='flex'
                                            alignItems='center'
                                            justifyContent='center'
                                          >
                                            <Check style={{ marginRight: '10px' }} />
                                            <FormattedMessage
                                              id='MySessions.SessionTile.LeaveWaitingList'
                                              defaultMessage='LEAVE WAITING LIST'
                                            />
                                          </Box>
                                        ) : (
                                          <FormattedMessage
                                            id='MySessions.SessionTile.JoinWaitingList'
                                            defaultMessage='JOIN WAITING LIST'
                                          />
                                        )}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              )}
                            </Grid>
                          </ResponsiveGridContainer>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grow>

    </React.Fragment>
  );
};

export default React.memo(SessionTile);
