import { createReducer } from '@reduxjs/toolkit';
import { setupCelebration } from 'state/celebrations/actions';

const initialState: Celebration.CelebrationState = {
  definition: null,
};

export const celebrationReducer = createReducer(initialState, (builder) => {
  builder.addCase(setupCelebration, (state, action) => {
    state.definition = action.payload;
  });
});
