/** @jsx jsx */
import { jsx } from '@emotion/core';
import curjosLogoFooter from 'assets/curjosLogoFooter.png';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { scrollToTopSmooth } from '../../utils';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getYear } from 'date-fns';

const FooterGrid = styled.div`
  width: 100%;
  background-color: #000;
  padding: 20px;
  box-sizing: border-box;
  //display: grid;
  //grid-template-columns: 90px repeat(auto-fill, minmax(200px, 1fr));
  //grid-template-rows: auto;
`;

const Logo = styled.div`
  //grid-row: 1 / span 4;
  margin: 0 20px;
  cursor: pointer;
  & img {
    //height: 85px;
  }
`;

const Copyrights = styled.div`
  margin: 10px 20px;
  font-size: 12px;
  color: #909090;
  text-align: center;
  //grid-row: 4 / 5;
  //grid-column: 2/-1;
`;

const FooterLink = styled(Link)`
  text-align: center;
  margin: 0 20px 10px;
  width: 100%;
  max-width: 100px;
  min-width: 80px;
  font-size: 16px;
  color: #909090;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const EmptyLink = styled('div')`
  text-align: center;
  margin: 0 20px 10px;
  width: 100%;
  max-width: 100px;
  min-width: 80px;
  text-decoration: none;
  font-size: 16px;
  color: #909090;
`;

export const Footer = () => {
  //const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const user = useSelector((state: RootState) => state.session.user, shallowEqual);
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <FooterGrid>
      <Box display='flex' borderTop='1px solid #2f2f2f' pt={5}>
        {isMedium && (
          <Logo onClick={() => history.push('/main/home')}>
            <img src={curjosLogoFooter} alt='logo' />
          </Logo>
        )}
        <Box display='flex' flexDirection='column' width='100%' justifyContent='center'>
          <Box
            pt={2}
            display='flex'
            flexWrap='wrap'
            flexGrow={1}
            width='100%'
            justifyContent={['center','center','left']}
          >
            {user && (
              <FooterLink to='/watch/introduction' onClick={() => scrollToTopSmooth()}>
                About us
              </FooterLink>
            )}
            <FooterLink to='/help/contact' onClick={() => scrollToTopSmooth()}>
              Contact
            </FooterLink>
            <FooterLink to='/help/privacy' onClick={() => scrollToTopSmooth()}>
              Privacy
            </FooterLink>
            {/*<FooterLink to='/help/terms-of-service' onClick={() => scrollToTopSmooth()}>*/}
            {/*  Terms*/}
            {/*</FooterLink>*/}
            {/*<FooterLink to='/help/contact' onClick={() => scrollToTopSmooth()}>*/}
            {/*  Support*/}
            {/*</FooterLink>*/}
            <FooterLink to='/help/legal' onClick={() => scrollToTopSmooth()}>
              Legal
            </FooterLink>
            {user && (
              <FooterLink to='/help/faq' onClick={() => scrollToTopSmooth()}>
                FAQ
              </FooterLink>
            )}
            {/*THIS IS A HACK THAT MAKES FLEX WORKING */}
            <EmptyLink />
            <EmptyLink />
            <EmptyLink />
            <EmptyLink />
            <EmptyLink />
          </Box>
          <div>
            <Copyrights>© {getYear(new Date())} CURJOS All rights reserved.</Copyrights>
          </div>
        </Box>
      </Box>
    </FooterGrid>
  );
};
