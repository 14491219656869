import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import * as constants from '../state/session/constants';

interface PrivateRouteProps {
  render: (props: RouteComponentProps<any>) => React.ReactNode;
  [other: string]: any;
}

function PrivateRoute({ render, component, ...rest }: PrivateRouteProps) {
  let access = localStorage.getItem(constants.TOKEN_NAME);

  const renderFunc = (props: any) => {
    localStorage.setItem(constants.REQUEST_URL_BACKUP_NAME, window.location.href);
    return !access ? (
      <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
    ) : (
      render(props)
    );
  };

  return (
    <Route {...rest} component={component} render={!component ? renderFunc : undefined} />
  );
}

export default PrivateRoute;
