import * as actions from './actions';
import _ from 'lodash';
import { bindActionCreators } from '@reduxjs/toolkit';
import { shallowEqual, useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';

export const useViewsActions = <K extends keyof typeof actions>(...keys: K[]) => {
  const dispatch = useDispatch();
  let [localKeys, setKeys] = useState(keys);

  if (!shallowEqual(localKeys, keys)) {
    setKeys(keys);
  }

  let pick = useMemo(() => _.pick(actions, localKeys), [localKeys]);

  return useMemo(() => bindActionCreators(pick, dispatch), [dispatch, pick]);
};

export type ViewsActionsType = typeof actions;
