import React from 'react';
import { KeyboardArrowDownSharp } from '@material-ui/icons';
import { Fade } from '@material-ui/core';
import './arrow.scss';

export default props => {
  const { className, to, onClick } = props;
  return (
    <Fade in>
      <button type='button' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={onClick} className={className} aria-label={to}>
        <KeyboardArrowDownSharp className={`icon ${to === 'next' ? 'right' : 'left'}`} icon={to} />
      </button>
    </Fade>
  );
};
