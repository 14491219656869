import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {scrollToTopSmooth} from "../common/utils";

export function useNavigation() {
  const history = useHistory();

  return useMemo(
    () => ({
      goToCourseverview: (courseId: string, showChapters: boolean = false) => {
        history.push(`/main/my-courses/${courseId}${showChapters ? '/showChapters' : ''}`);
        scrollToTopSmooth()
      },
    }),
    [history],
  );
}

export type Navigation = ReturnType<typeof useNavigation>;
