import { Button, createStyles, LinearProgress, styled, withStyles } from '@material-ui/core';

export const VideoPoster = styled('div')({
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top',
  overflow: 'hidden',
  height: 'calc(100vh - 130px)',
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'flex-end',
});

export const StyledButton = styled(Button)({
  height: '50px',
  backgroundColor: '#FF7722',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#ff6505',
  },
});

export const BorderLinearProgress = withStyles(() =>
  createStyles({
    root: {
      height: 10,
      opacity: 1,
    },
    colorPrimary: {
      opacity: 1,
      backgroundColor: '#909090',
    },
    bar: {
      opacity: 1,
      backgroundColor: '#FF7722',
    },
  }),
)(LinearProgress);
