export const required = (value) => (value !== undefined && value !== null ? undefined : 'Required');

export const mandatory = (value, message) => {
  return value !== undefined && value !== null ? undefined : message || 'This field is required.'
}

export const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

// TODO: translate validation messages
export const validateEmail = (email, message) => {
  return email && email.length > 0 && emailPattern.test(email)
    ? undefined
    : message || 'Incorrect email format';
};

export const validatePasswordLength = (pass) => {
  return !!pass && pass.length >= 8 ? undefined : 'Password should be at least 8 characters long';
};

export const validatePasswordHasSpecialCharacterOrDigit = (pass) => {
  const containsSpecial = /[!@#$%^&*()\-_[\];:\'"\\\\|\/?><,.+=]/m.test(pass);
  const containsDigits = /[0-9]/.test(pass)

  return !!pass && (containsSpecial || containsDigits) ? undefined : 'Password must contain a special character or digit';
};

export const validatePasswordHasWhiteSpace = (pass) => {
  const containsWhiteSpace = /\s/.test(pass)
  return !!pass && containsWhiteSpace ? 'Password must not contain whitespaces' : undefined
}

export const validatePasswordHasUpperLetter = (pass) => {
  const containsUpper = /[A-Z]/.test(pass)
  return !!pass && containsUpper ? undefined : 'Password must contain at least one uppercase letter'
}

export const validatePasswordHasLowerLetter = (pass) => {
  const containsLower = /[a-z]/.test(pass)
  return !!pass && containsLower ? undefined : 'Password must contain at least one lowercase letter'
}

export const validateSubmissionHasAllAnswers = (answers, message) => {
  const hasEmptyAnswer = answers.some((answer) => !answer.content);
  return !hasEmptyAnswer
    ? undefined
    : message || 'All the answers are required in order to complete challenge';
};

export const validatePasswordsMatch = (pass, passToMatch, message) => {
  return pass === passToMatch ? undefined : message || 'Entered passwords do not match';
};
