import { createReducer } from '@reduxjs/toolkit';
import { setEpisodeProgress } from 'state/session/actions';
import { forcePause, getHomeData, getMyCoursesData, getSessionsData } from 'state/views/actions';
import { getEpisodeById } from 'views/VideoPlayer/utils';
import { Courses } from '.';
import { getCourseToOverview } from './actions';

const initialState: Courses.IViewsState = {
  loading: false,
  myCoursesLoading: false,
  home: {
    episodesToContinue: [],
    courses: [],
  },
  courses: [],
  coursesByType: {
    open: [],
    ongoing: [],
    completed: []
  },
  courseToOverview: null,
  watch: {
    forcePause: false,
    course: null,
    chapter: null,
    episode: null,
  },
  sessions: [],
};

const viewsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getCourseToOverview, (state, action) => {
    if (action.payload.courseId) {
      state.courseToOverview = {
        courseId: action.payload.courseId,
        showChapters: action.payload.showChapters,
      };
    } else {
      state.courseToOverview = null;
    }
  });

  // HOME
  builder.addCase(getHomeData.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getHomeData.fulfilled, (state, action) => {
    state.home = action.payload;
    state.loading = false;
  });
  builder.addCase(getHomeData.rejected, (state) => {
    state.loading = false;
  });

  // MY COURSES
  builder.addCase(getMyCoursesData.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getMyCoursesData.fulfilled, (state, action) => {
    state.courses = action.payload;

    const ongoing = action.payload.filter(course => (course.joined && !course.joined.completed));
    const completed = action.payload.filter(course => (course.joined && course.joined.completed));
    const open = action.payload.filter(course => !course.joined);

    state.coursesByType.open = open
    state.coursesByType.ongoing = ongoing
    state.coursesByType.completed = completed

    state.loading = false;
  });
  builder.addCase(getMyCoursesData.rejected, (state) => {
    state.loading = false;
  });

  builder.addCase(setEpisodeProgress.pending, (state, action) => {
    let course = state.courses.find((c) => c.id === action.meta.arg.course)

    let episode: Courses.IEpisodeDTO | undefined | null;
    if (course) {
      let chapter = course.chapters.find((c) => c.id === action.meta.arg.chapter);
      if (chapter) {
        episode = chapter.episodes.find((e) => e.id === action.meta.arg.watchable);
      }
    } else {
      const result = getEpisodeById(action.meta.arg.watchable);
      episode = result.episode;
    }

    if (episode && action.meta.arg.progressPercent !== null) {
      episode.progress = action.meta.arg.progressPercent.toString();
    }
  });

  // SESSIONS
  builder.addCase(getSessionsData.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getSessionsData.fulfilled, (state, action) => {
    state.sessions = action.payload;
    state.loading = false;
  });
  builder.addCase(getSessionsData.rejected, (state) => {
    state.loading = false;
  });

  // WATCH
  builder.addCase(forcePause, (state, action) => {
    state.watch.forcePause = action.payload.flag;
  });
});

export default viewsReducer;
