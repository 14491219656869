import React, { useState } from 'react';
import { Box, Fade } from '@material-ui/core';
import { MuteButton } from '../SlickCarousel/styledComponents';
import { VolumeOff, VolumeUp } from '@material-ui/icons';
import ReactPlayer from 'react-player/lazy';

const StreamPlayer = ({ item, setVideoCanPlay, isHighlighted, videoCanPlay, initialHover }) => {
  const [muted, setMuted] = useState(true);

  // useEffect(() => {
  //   if (videoCanPlay) {
  //     isHighlighted ? playTrailer() : pauseTrailer();
  //   }
  // }, [isHighlighted, videoCanPlay]);

  // useEffect(() => {
  //   muted ? (videoPlayer.current.volume = 0) : (videoPlayer.current.volume = 1);
  // }, [muted]);

  // const playTrailer = () => videoPlayer.current.play();
  // const pauseTrailer = () => videoPlayer.current.pause();

  return (
    <Box position='relative'>
      {initialHover && (
        <ReactPlayer
          preload='auto'
          height='100%'
          width='100%'
          playsinline
          muted={muted}
          playing={videoCanPlay && isHighlighted}
          url={`https://watch.cloudflarestream.com/${item.trailerVideo.videoId}`}
          onReady={() => setVideoCanPlay(true)}
        />
      )}
      {/*<Stream*/}
      {/*  src={item.trailerVideoId}*/}
      {/*  streamRef={videoPlayer}*/}
      {/*  onCanPlay={() => setVideoCanPlay(true)}*/}
      {/*  preload*/}
      {/*  loop*/}
      {/*/>*/}
      <div
        id='__playerTile'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
          <Fade in={isHighlighted}>
            <Box m={1}>
              <MuteButton onClick={() => setMuted(!muted)} size='small' aria-label='mute'>
                {muted ? <VolumeOff /> : <VolumeUp />}
              </MuteButton>
            </Box>
          </Fade>
        </div>
      </div>
    </Box>
  );
};

export default React.memo(StreamPlayer);
