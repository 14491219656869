import {createAction, createAsyncThunk, unwrapResult} from '@reduxjs/toolkit';
import { challengesApi } from 'api/challenges';
import { getMyCoursesData } from 'state/views/actions';
import api from '../../api/apiHandler';

const GET_CHALLENGES = 'GET_CHALLENGES';
const GET_FEEDBACKS_BY_ME = 'GET_FEEDBACKS_BY_ME';
const POST_REQUEST_SUBMISSION_TO_FEEDBACK = 'POST_REQUEST_SUBMISSION_TO_FEEDBACK';
const SET_CHALLENGE_TO_SUBMIT = 'SET_CHALLENGE_TO_SUBMIT';
const POST_CHALLENGE_SUBMISSION = 'POST_CHALLENGE_SUBMISSION';
const POST_P2P_FEEDBACK = 'POST_P2P_FEEDBACK';
const POST_P2P_FEEDBACK_REPLY = 'POST_P2P_FEEDBACK_REPLY';
const POST_TAKE_CHALLENGE = 'POST_TAKE_CHALLENGE';
const SET_FEEDBACK_CHALLENGE = 'SET_FEEDBACK_CHALLENGE';
const GET_CHALLENGE_BY_ID = 'GET_CHALLENGE_BY_ID';
const GET_CURRENT_LIVE = 'GET_CURRENT_LIVE';
const POST_REMOVE_SUBMISSION = 'POST_REMOVE_SUBMISSION';
const CLEAR_CURRENT_LIVE_CHALLENGE = 'CLEAR_CURRENT_LIVE_CHALLENGE';

export const getChallenges = createAsyncThunk(GET_CHALLENGES, async () => {
  return await challengesApi.getChallenges();
});

export const getFeedbacksCreatedByMeData = createAsyncThunk(GET_FEEDBACKS_BY_ME, async () => {
  return await api.getFeedbacksCreatedByMe();
});

export const setP2PFeedback = createAsyncThunk(
  POST_P2P_FEEDBACK,
  async ({ feedbackId, data, challengeId }: { feedbackId: string; data: any, challengeId: string }, thunkApi) => {
    return await challengesApi
      .postSetP2PFeedback(feedbackId, data)
      .then(async (result) => {
        thunkApi.dispatch(getFeedbacksCreatedByMeData());
        thunkApi.dispatch(getChallenge(challengeId))
          .then(unwrapResult)
          .then(res => thunkApi.dispatch(setFeedbackChallenge(res)))
        return result;
      })
      .catch(() => {
        throw new Error('Error while updating challenge');
      });
  },
);

export const replyP2PFeedback = createAsyncThunk(
  POST_P2P_FEEDBACK_REPLY,
  async ({ feedbackId, data, challengeId }: { feedbackId: string; data: any, challengeId: string }, thunkApi) => {
    return await challengesApi
      .postReplyToFeedback(feedbackId, data)
      .then(async (result) => {
        thunkApi.dispatch(getFeedbacksCreatedByMeData());
        thunkApi.dispatch(getChallenge(challengeId))
          .then(unwrapResult)
          .then(res => thunkApi.dispatch(setFeedbackChallenge(res)))
        return result;
      })
      .catch(() => {
        throw new Error('Error while updating challenge');
      });
  },
);

export const setSubmitChallengeDialog = createAction(
  SET_CHALLENGE_TO_SUBMIT,
  (challengeItem: Challenges.IChallengeDTO | null, submitted: boolean = false) => ({
    payload: { challengeItem, submitted },
  }),
);

export const postChallengeSubmission = createAsyncThunk(
  POST_CHALLENGE_SUBMISSION,
  async ({ challengeId, data }: { challengeId: string; data: FormData }, thunkApi) => {
    return await challengesApi
      .postChallengeSubmission(challengeId, data)
      .then(async (result) => {
        // thunkApi.dispatch(getMyCoursesData());
        // thunkApi.dispatch(getChallenges());
        thunkApi.dispatch(getChallenge(challengeId))
          .then(unwrapResult)
          .then(res => thunkApi.dispatch(setFeedbackChallenge(res)))
        return result;
      })
      .catch(() => {
        throw new Error('Error while updating challenge');
      });
  },
);

export const postRequestSubmissionToFeedback = createAsyncThunk(
  POST_REQUEST_SUBMISSION_TO_FEEDBACK,
  async ({ challengeId }: { challengeId: string }, thunkApi) => {
    return await challengesApi
      .postRequestSubmissionToFeedback(challengeId)
      .then(async (result) => {
        // thunkApi.dispatch(getMyCoursesData());
        thunkApi.dispatch(getChallenges());
        thunkApi.dispatch(getFeedbacksCreatedByMeData());
        thunkApi.dispatch(getChallenge(challengeId))
          .then(unwrapResult)
          .then(res => thunkApi.dispatch(setFeedbackChallenge(res)))
        if (result.error) {
          return thunkApi.rejectWithValue(result.error)
        } else {
          return result;
        }
      })
  },
);

export const postTakeChallenge = createAsyncThunk(
  POST_TAKE_CHALLENGE,
  async (challengeId: string) => {
    return await challengesApi.postTakeChallenge(challengeId).catch((error) => {
      throw error;
    });
  },
);

export const setFeedbackChallenge = createAction(
  SET_FEEDBACK_CHALLENGE,
  (challengeItem: Challenges.IChallengeDTO | null) => ({
    payload: { challengeItem },
  }),
);

export const getChallenge = createAsyncThunk(
  GET_CHALLENGE_BY_ID,
  async (challengeId: string) => await challengesApi.getChallenge(challengeId),
);
export const getCurrentLiveChallenge = createAsyncThunk(
  GET_CURRENT_LIVE,
  async () => await challengesApi.getCurrentLiveChallenge(),
);

export const clearCurrentLiveChallenge = createAction(CLEAR_CURRENT_LIVE_CHALLENGE, () => ({
  payload: null,
}));

export const removeSubmission = createAsyncThunk(
  POST_REMOVE_SUBMISSION,
  async (challengeId: string, thunkApi) => {
    return await challengesApi
      .postRemoveSubmission(challengeId)
      .then(async (result) => {
        await thunkApi.dispatch(getChallenges());
        await thunkApi.dispatch(setFeedbackChallenge(null));
        return result;
      })
      .catch((error) => {
        throw error;
      });
  },
);

const SET_LOCAL_CHALLENGE_SUBMISSION_STATE = 'SET_LOCAL_CHALLENGE_SUBMISSION_STATE';
export const setLocalChallengeSubmissionState = createAction(
  SET_LOCAL_CHALLENGE_SUBMISSION_STATE,
  (challengeId: string, state: 'taken' | 'none') => ({
    payload: {
      challengeId,
      state,
    },
  }),
);
