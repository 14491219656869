import React from 'react';
import { Box, Button, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import styled from 'styled-components';
import { format, parseISO, toDate } from 'date-fns';
import {useIntl} from "react-intl";

const Name = styled('h3')({
  padding: 0,
  margin: 0,
});

const Deadline = styled('h3')({
  fontWeight: 400,
  padding: 0,
  margin: 0,
});

const P2PTileGiveFeedback = ({
  challengeTitle,
  type,
  challengeItem,
  courseName,
  imageUrl,
  challengeCompletedAt,
  feedbacks = [],
  action = () => alert('Default action'),
  actionLabel = 'Take action',
}: any) => {
  const intl = useIntl()

  return (
    <Box mb={5}>
      <img style={{ objectFit: 'cover', width: '100%' }} src={imageUrl} />
      <Box my={1} px={[1, 2, 3]} py={1} style={{ background: '#2F2F2F' }}>
        <Grid container>
          <Grid item xs={7} md={5}>
            <Box pr={1} fontSize={[12, 14, 16]} display='flex' alignItems='center' height='100%'>
              {challengeItem.is_live
                ? intl.formatMessage({
                  id: 'P2PTileGiveFeedback.Literal.Challenge',
                  defaultMessage:
                    'Challenge',
                })
                : intl.formatMessage({
                  id: 'P2PTileGiveFeedback.Literal.ChallengeAndCourse',
                  defaultMessage:
                    'Challenge and course',
                })
              }
            </Box>
          </Grid>
          <Grid item xs={5} md={3}>
            <Box fontSize={[12, 14, 16]} display='flex' alignItems='center' height='100%'>
              {
                intl.formatMessage({
                  id: 'P2PTileGiveFeedback.Literal.DateAdded',
                  defaultMessage:
                    'Date added',
                })
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box p={[1, 2, 3]} mt={1} style={{ background: '#101010' }}>
        <Grid container>
          <Grid item xs={7} md={5}>
            <Box height='100%' display='flex' flexDirection='column' justifyContent='center'>
              <Box pr={1} fontSize={[12, 14, 16]} display='flex' alignItems='center' height='100%'>
                <Name>{challengeTitle}</Name>
              </Box>
              {courseName && <Box fontSize={[12, 14, 16]} display='flex' alignItems='center' height='100%'>
                <Name style={{fontWeight: 400}}>{courseName}</Name>
              </Box>}
            </Box>
          </Grid>
          <Grid item xs={5} md={3}>
            <Box fontSize={[12, 14, 16]} display='flex' alignItems='center' height='100%'>
              <Deadline>
                {format(toDate(parseISO(challengeCompletedAt)), 'dd.MM.yyyy HH:mm')}
              </Deadline>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box height='100%' display='flex' alignItems='center' my={1}>
              <Button
                style={{
                  maxHeight: '40px',
                  // visibility: feedbacks.length > 1 ? 'hidden' : 'visible',
                }}
                fullWidth
                disabled={challengeItem.completedSubmissionsCount <= 1 || challengeItem.feedbacksCreatedByMe.length >= challengeItem.completedSubmissionsCount}
                onClick={() => {
                  action(challengeItem);
                }}
                variant='contained'
                color='secondary'
              >
                {actionLabel}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default React.memo(P2PTileGiveFeedback);
