/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Box, Button, CircularProgress, Grid, useMediaQuery} from '@material-ui/core';
import {Theme, useTheme} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {unwrapResult} from '@reduxjs/toolkit';
import {
  SimpleConfirmationDialog,
  useConfirmationDialogState,
} from 'common/components/Dialogs/SimpleConfirmationDialog';
import {
  StyledContentMedium,
  StyledDialog,
  StyledDialogTitle,
  TitleButton,
} from 'common/components/Dialogs/StyledDialogComponents';
import {StyledLabeledTextFieldAdapter} from 'common/components/Dialogs/TextFieldAdapter';
import {DownloadInstructions} from 'common/components/General/DownloadInstructions';
import {KairosSnackBar} from 'common/components/General/KairosSnackBar';
import {PointedHintBox} from 'common/components/General/PointedHintBox';
import {areFilesOverszed, FileDescriptor, Uploader} from 'common/components/Uploader';
import arrayMutators from 'final-form-arrays';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {shallowEqual, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RootState} from 'reducers';
import {useChallengesActions} from 'state/challenges/useChallengesActions';
import {useSessionActions} from 'state/session/useSessionActions';
import {setFieldValue} from 'utils/setFieldValue';
import {feedbackChannelName} from 'views/MyChallenges/sections/Feedback/ChallengeFeedback';
import {validateSubmissionHasAllAnswers} from 'utils/form-validation';
import {FormattedMessage, useIntl} from 'react-intl';

const MAX_MEDIA_SIZE_B = 500 * 1024 * 1024;

export const mainSubmissionChannel = 'submitChallengeDialogSnacks';

interface IValues {
  answers: { question: { id: string; text: string }; content: string | null }[];
  draft: boolean;
}

export const SubmitChallengeDialog = () => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();
  const [filesDesriptors, setFilesDescriptos] = useState<FileDescriptor[]>([]);
  const [oversized, setOversized] = useState(false);
  const [completeWithEmptyAnswersModal, setCompleteWithEmptyAnswersModal] = useState(false);
  const [initialMedia, setInitialMedia] = useState<Challenges.IMedia[]>([]);
  const isMediumDown = !useMediaQuery(theme.breakpoints.up('md'))

  const dirtyRef = useRef(false);
  const changesModal = useConfirmationDialogState();
  const p2pChallengeModal = useConfirmationDialogState();

  const { challengeItem, currentLiveChallenge } = useSelector(
    (state: RootState) => ({
      challengeItem: state.challenges.submission.challengeItem,
      currentLiveChallenge: state.challenges.currentLiveChallenge,
    }),
    shallowEqual,
  );

  useEffect(() => setInitialMedia(challengeItem?.submission?.media || []), [challengeItem]);

  const isVisible = !!challengeItem && !challengeItem?.submission?.completedAt;

  const {
    pushErrorSnack,
    pushMessageSnack,
    setCourseOverview,
    setSubmitChallengeDialog,
    postChallengeSubmission,
    getChallenges,
    getCurrentLiveChallenge,
    setFeedbackChallenge
  } = {
    ...useSessionActions('pushErrorSnack', 'pushMessageSnack', 'setCourseOverview'),
    ...useChallengesActions(
      'setFeedbackChallenge',
      'setSubmitChallengeDialog',
      'postChallengeSubmission',
      'getChallenges',
      'getCurrentLiveChallenge',
    ),
  };

  const handleClose = useCallback(
    (e?: any, skipDirtyCheck = false) => {
      if (!skipDirtyCheck && dirtyRef.current && changesModal.state !== 'yes') {
        return changesModal.show();
      }

      // TODO: add back suport for existing history items
      history.push('/main/my-challenges/challenges');
      setSubmitChallengeDialog(null);
    },
    [changesModal, history, setSubmitChallengeDialog],
  );

  const handleUploaderChange = useCallback((files: FileDescriptor[]) => {
    setFilesDescriptos(files);
    setOversized(areFilesOverszed(files, MAX_MEDIA_SIZE_B));
  }, []);

  const getContent = (questionId: string) => {
    return (
      challengeItem?.submission?.answers?.find((a) => a.questionId === questionId)?.content || null
    );
  };

  const initialValues: IValues = {
    answers:
      challengeItem?.questions.map((question) => ({
        question,
        content: getContent(question.id),
      })) || [],
    draft: false,
  };

  const handleSubmit = async (values: IValues) => {
    const valuesToSubmit = values

    let data = new FormData();

    valuesToSubmit.answers.forEach((a, i) => {
      data.append(`answers[${i}][question]`, a.question.id);
      data.append(`answers[${i}][content]`, a.content || '');
    });
    filesDesriptors.forEach((item, index) => {
      item.file
        ? data.append(`media[${index}]`, item.file)
        : data.append(`existsMedia[${index}]`, item.id)
    });
    if (!!valuesToSubmit.draft) {
      data.append('status', 'DRAFT');
    } else {
      data.append('status', 'COMPLETED');
    }

    if (!!!challengeItem?.id) {
      return;
    }

    try {
      unwrapResult(await postChallengeSubmission({ challengeId: challengeItem.id, data }));

      if (values.draft) {
        if (challengeItem.is_live) {
          currentLiveChallenge?.id === challengeItem.id
            ? history.push(`/main/my-challenges/live/${challengeItem.id}`)
            : history.push('/main/my-challenges/challenges');

          pushMessageSnack({
            channel: feedbackChannelName,
            message: intl.formatMessage({
              id: 'LiveChallenge.SubmitChallengeDialog.Snack.Success.DraftSaved',
              defaultMessage: 'Changes have been saved!',
            }),
          });
        } else {
          history.push('/main/my-challenges/challenges');
          pushMessageSnack({
            channel: feedbackChannelName,
            message: intl.formatMessage({
              id: 'MyChallenges.SubmitChallengeDialog.Snack.Success.DraftSaved',
              defaultMessage:
                'Changes have been saved! You can find it in "Challenges in progress".',
            }),
          });
        }
      } else {
        pushMessageSnack({
          channel: feedbackChannelName,
          message: intl.formatMessage({
            id: 'MyChallenges.SubmitChallengeDialog.Snack.Success.ResultsUploaded',
            defaultMessage: 'Your challenge results have been uploaded successfully!',
          }),
          timeout: 5000,
        });
        if (challengeItem.is_live) {
          getCurrentLiveChallenge();
          setFeedbackChallenge(challengeItem)
          history.push(`/main/my-challenges/challenges/${challengeItem.id}`);
        }
      }
      history.push('/main/my-challenges/challenges');
      setSubmitChallengeDialog(null, true);
      setCourseOverview(false);
    } catch (ex) {
      console.log('Error while saving challenge reply/draft:', ex);
      if (!!values.draft) {
        pushErrorSnack({
          channel: mainSubmissionChannel,
          message: intl.formatMessage({
            id: 'MyChallenges.SubmitChallengeDialog.Snack.Error.DraftNotSaved',
            defaultMessage: 'Your draft copy was not saved due to an error!',
          }),
        });
      } else {
        pushErrorSnack({
          channel: mainSubmissionChannel,
          message: intl.formatMessage({
            id: 'MyChallenges.SubmitChallengeDialog.Snack.Error.ResultNotSaved',
            defaultMessage: 'Your challenge reply was not saved due to an error!',
          }),
        });
      }
    } finally {
      getChallenges();
    }
  };

  return (
    <StyledDialog
      style={{ zIndex: 2010 }}
      open={isVisible}
      maxWidth='lg'
      backgroundImage={challengeItem?.desktopCoverImage?.url}
      onEscapeKeyDown={handleClose}
      onBackdropClick={handleClose}
      fullScreen={useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))}
    >
      <StyledDialogTitle transparent>
        <TitleButton onClick={handleClose}>
          <ClearIcon color='primary' />
        </TitleButton>
      </StyledDialogTitle>
      <KairosSnackBar channel={mainSubmissionChannel} scrollOnMessage />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize
        mutators={{ ...arrayMutators, setFieldValue }}
        validate={(values) => {
          let errors: Partial<Record<any, any>> = {};

          const noFiles = filesDesriptors.length === 0;

          if (noFiles) {
            errors.emptyAnswers = validateSubmissionHasAllAnswers(values.answers, intl.formatMessage({
              id: 'FormValidation.MissingAnswers',
              defaultMessage: 'All the answers are required in order to complete challenge',
            }));
          } else {
            return errors;
          }

          return errors;
        }}
      >
        {({
          handleSubmit,
          hasValidationErrors,
          submitting,
          form,
          dirtySinceLastSubmit,
          dirty,
          values,
        }) => {
          dirtyRef.current = dirty || dirtySinceLastSubmit;
          return (
            challengeItem && (
              <form onSubmit={handleSubmit}>
                <StyledContentMedium transparent>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={8}>
                      <Box fontSize={['18px', '18px', '26px']} fontWeight={500} mt={1} ml={1}>
                        {challengeItem.name || challengeItem.title}
                      </Box>
                    </Grid>
                    {challengeItem.chapter && (
                      <Grid item xs={9} sm={9} md={9}>
                        <Box fontSize={['14px', '16px', '18px']} mt={1} ml={1}>
                          {challengeItem.chapter.name} / {challengeItem.chapter.course.name}
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12} md={3}>
                      <Box display='flex' justifyContent={['flex-start','flex-start','flex-end']}>
                        <DownloadInstructions
                          inlineLabel={isMediumDown}
                          instructions={challengeItem.instructions}
                          css={{ gridArea: 'download', marginTop: '9.5px' }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <FieldArray name='answers'>
                    {({ fields }) => {
                      return fields.map((name, index) => {
                        return (
                          <React.Fragment key={name}>
                            <PointedHintBox
                              pointer={`${index + 1}/${challengeItem.questions.length + 1}`}
                            >
                              {fields.value[index].question.text}
                            </PointedHintBox>

                            <Box>
                              <Field
                                name={`${name}.content`}
                                value={fields.value[index].content}
                                component={StyledLabeledTextFieldAdapter}
                                color='primary'
                                label={intl.formatMessage({
                                  id: 'MyChallenges.SubmitChallengeDialog.Field.Content.Label',
                                  defaultMessage: 'Write something here',
                                })}
                                variant='outlined'
                                multiline
                                rows={9}
                                fullWidth
                                css={{ marginTop: theme.spacing(1.5) }}
                                InputLabelProps={{ shrink: true }}
                                disabled={submitting}
                                validate={(value) => {
                                  const noFiles = filesDesriptors.length === 0;

                                  if (noFiles && !value) {
                                    return intl.formatMessage({
                                      id:
                                        'MyChallenges.SubmitChallengeDialog.Field.Content.Validation.ContentRequiredIfNoFileAttached',
                                      defaultMessage: 'This field is required',
                                    });
                                  } else {
                                    return undefined;
                                  }
                                }}
                              />
                            </Box>
                          </React.Fragment>
                        );
                      });
                    }}
                  </FieldArray>

                  <PointedHintBox
                    pointer={`${challengeItem.questions.length + 1}/${
                      challengeItem.questions.length + 1
                    }`}
                  >
                    {
                      intl.formatMessage({
                        id: 'MyChallenges.SubmitChallengeDialog.AddFilesOptional',
                        defaultMessage: 'Add files (optional)',
                      })
                    }
                  </PointedHintBox>

                  <Uploader
                    disabled={submitting}
                    onChange={handleUploaderChange}
                    media={initialMedia}
                  />

                  {oversized && (
                    <div css={{ color: theme.palette.error.dark, marginTop: theme.spacing(1) }}>
                      <FormattedMessage
                        id='MyChallenges.SubmitChallengeDialog.Validation.OveralFilesSizeExceeded'
                        defaultMessage='Overall files size exceeded or one of files is too big'
                      />
                    </div>
                  )}

                  <Field name='draft'>{({ input }) => <input {...input} type='hidden' />}</Field>
                </StyledContentMedium>
                <Box bgcolor={theme.palette.grey[700]} pb='1px'>
                  <Box display='flex' mx='auto' mb={5} flexWrap='wrap' width='min-content'>
                    <Box minWidth={{ xs: '300px', sm: '516px', md: '716px' }} m={1}>
                      <Button
                        color='secondary'
                        variant='outlined'
                        fullWidth
                        disabled={submitting}
                        startIcon={
                          submitting &&
                          form.getFieldState('draft')?.value === true && <CircularProgress />
                        }
                        onClick={() => {
                          form.mutators.setFieldValue('draft', true);
                          form.submit();
                        }}
                      >
                        <FormattedMessage
                          id='MyChallenges.SubmitChallengeDialog.Caption.SaveChanges'
                          defaultMessage='Save changes'
                        />
                      </Button>
                    </Box>
                    <Box flex='0 0 0' m={1} minWidth={{ xs: '300px', sm: '250px', md: '350px' }}>
                      <Button
                        color='primary'
                        variant='outlined'
                        fullWidth
                        onClick={() => handleClose(null, true)}
                      >
                        <FormattedMessage
                          id='MyChallenges.SubmitChallengeDialog.Caption.Cancel'
                          defaultMessage='Cancel'
                        />
                      </Button>
                    </Box>
                    <Box flex='0 0 0' m={1} minWidth={{ xs: '300px', sm: '250px', md: '350px' }}>
                      <Button
                        color='secondary'
                        variant='contained'
                        fullWidth
                        disabled={hasValidationErrors || submitting}
                        startIcon={
                          submitting &&
                          form.getFieldState('draft')?.value === false && <CircularProgress />
                        }
                        onClick={() => {
                          if (challengeItem.is_p2p) {
                            p2pChallengeModal.show();
                          } else {
                            const submissionDoesNotHaveAllTheAnswers = validateSubmissionHasAllAnswers(
                              values.answers,
                              intl.formatMessage({
                                id: 'FormValidation.MissingAnswers',
                                defaultMessage: 'All the answers are required in order to complete challenge',
                              })
                            );
                            if (submissionDoesNotHaveAllTheAnswers) {
                              setCompleteWithEmptyAnswersModal(true);
                            } else {
                              form.mutators.setFieldValue('draft', false);
                              form.submit();
                            }
                          }
                        }}
                      >
                        <FormattedMessage
                          id='MyChallenges.SubmitChallengeDialog.Caption.SubmitMyResults'
                          defaultMessage='SUBMIT MY RESULTS'
                        />
                      </Button>
                    </Box>
                  </Box>
                </Box>
                {completeWithEmptyAnswersModal && (
                  <SimpleConfirmationDialog
                    open={completeWithEmptyAnswersModal}
                    confirmationLabel={intl.formatMessage({
                      id: 'Literal.Yes',
                      defaultMessage: 'Yes',
                      description:
                        "Literally Yes word or its equivalent",
                    })}
                    cancellationLabel={intl.formatMessage({
                      id: 'Literal.Cancel',
                      defaultMessage: 'Cancel'
                    })}
                    header={challengeItem.title}
                    text={intl.formatMessage({
                      id:
                        'MyChallenges.SubmitChallengeDialog.InclomleteResultConfirmationDialog.Text',
                      defaultMessage:
                        "You didn't fill all of the fields with answers. Are you sure you want to complete this challenge?",
                    })}
                    onClose={({ result }) => {
                      if (result) {
                        form.mutators.setFieldValue('draft', false);
                        form.submit();
                        setCompleteWithEmptyAnswersModal(false);
                      } else {
                        setCompleteWithEmptyAnswersModal(false);
                      }
                    }}
                  />
                )}
                {p2pChallengeModal.state === 'show' && (
                  <SimpleConfirmationDialog
                    open={p2pChallengeModal.state === 'show'}
                    confirmationLabel={intl.formatMessage({
                      id: 'Literal.Yes',
                      defaultMessage: 'Yes',
                      description: 'Literally Yes word or its equivalent',
                    })}
                    cancellationLabel={intl.formatMessage({
                      id: 'Literal.Cancel',
                      defaultMessage: 'Cancel'
                    })}
                    header={intl.formatMessage({
                      id: 'MyChallenges.SubmitChallengeDialog.P2PChallengeConfirmationDialog.Header',
                      defaultMessage:
                        "Reminder",
                    })}
                    text={intl.formatMessage({
                      id: 'MyChallenges.SubmitChallengeDialog.P2PChallengeConfirmationDialog.Text',
                      defaultMessage:
                        "By submitting your results, you commit to providing at least one feedback to one of your peers. All submitted results are anonymized and not linked to your profile or name. After you submit, you won't be able to edit or delete your results. Do you want to continue?",
                    })}
                    onClose={({ result }) => {
                      if (result) {
                        p2pChallengeModal.confirm(() => {
                          const submissionDoesNotHaveAllTheAnswers = validateSubmissionHasAllAnswers(
                            values.answers,
                            intl.formatMessage({
                              id: 'FormValidation.MissingAnswers',
                              defaultMessage: 'All the answers are required in order to complete challenge',
                            })
                          );
                          if (submissionDoesNotHaveAllTheAnswers) {
                            setCompleteWithEmptyAnswersModal(true);
                          } else {
                            form.mutators.setFieldValue('draft', false);
                            form.submit();
                          }
                        });
                      } else {
                        p2pChallengeModal.cancel();
                      }
                    }}
                  />
                )}
              </form>
            )
          );
        }}
      </Form>
      {changesModal.state === 'show' && (
        <SimpleConfirmationDialog
          open={changesModal.state === 'show'}
          confirmationLabel={intl.formatMessage({
            id: 'Literal.Leave',
            defaultMessage: 'Leave',
            description: 'Literally Leave word or its equivalent',
          })}
          cancellationLabel={intl.formatMessage({
            id: 'Literal.Cancel',
            defaultMessage: 'Cancel'
          })}
          header={intl.formatMessage({
            id: 'MyChallenges.SubmitChallengeDialog.UnsavedChangesConfirmationDialog.Titile',
            defaultMessage: 'Your unsaved changes!',
          })}
          text={intl.formatMessage({
            id: 'MyChallenges.SubmitChallengeDialog.UnsavedChangesConfirmationDialog.Text',
            defaultMessage:
              'Your work on reply to challenge was not saved! Are You sure You wish to leave?',
          })}
          onClose={({ result }) => {
            if (result) {
              changesModal.confirm(() => handleClose(null, true));
            } else {
              changesModal.cancel();
            }
          }}
        />
      )}
    </StyledDialog>
  );
};
