/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as constants from 'state/session/constants';
import { Button, ClickAwayListener, Collapse, Fade, Paper, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { HeaderCornerBox } from 'common/components/Header/HeaderCornerBox';
import { IWithUserProps, SmallAvatar } from 'common/components/Profile/Components/Avatars';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { MenuItem } from './Components/MenuItem';
import api from "../../../api/apiHandler";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
  popper: {
    width: 'auto',
  },
  contentContainer: {
    textAlign: 'right',
    backgroundColor: theme.palette.grey[300],
    padding: '1.625rem 0 1rem 0',
    borderRadius: '4px',
    width: '266px',
  },
}));

const AvatarMenu: React.FC<IWithUserProps & RouteComponentProps> = ({ user, history }) => {
  //#region State and callbacks
  const [anchorEl, setAnchorEl] = React.useState(null);
  const settingAnchor = useCallback((node) => {
    setAnchorEl(node);
  }, []);

  const {
    logOut,
    setEditProfileDialog,
    setOpenAvatarMenu,
    switchOpenAvatarMenu,
  } = useSessionActions(
    'setEditProfileDialog',
    'setOpenAvatarMenu',
    'setOpenNotificationsMenu',
    'logOut',
    'switchOpenAvatarMenu',
  );

  const onShowProfile = useCallback(() => {
    document.body.style.height = '100vh';
    setEditProfileDialog(true);
    setOpenAvatarMenu(false);
  }, [setEditProfileDialog, setOpenAvatarMenu]);

  const intl = useIntl()

  const onShowIntroduction = () => history.push('/watch/introduction');

  const classes = useStyles();

  const isOpen = useSelector((state: RootState) => state.session.flags.openAvatarMenu);
  const [showHelpSubmenu, setShowHelpSubmenu] = useState(false);

  const debouncedSwitchOpenState = useCallback(
    debounce(
      () => {
        switchOpenAvatarMenu();
      },
      500,
      { leading: true, trailing: false },
    ),
    [switchOpenAvatarMenu],
  );

  const handleClickAway = () => {
    if (isOpen) {
      debouncedSwitchOpenState();
    }
  };

  const handleAvatarClick = () => {
    debouncedSwitchOpenState();
  };

  //#endregion

  return (
    <React.Fragment>
      <HeaderCornerBox>
        <Button
          css={{ margin: '30px', padding: '0px', minWidth: '0px', borderRadius: '28px' }}
          onClick={handleAvatarClick}
        >
          {user ? (
            <SmallAvatar user={user} />
          ) : (
            <Skeleton variant='circle'>
              <SmallAvatar user={null} />
            </Skeleton>
          )}
        </Button>
        <div
          ref={settingAnchor}
          css={{
            position: 'absolute',
            marginTop: 'auto',
            bottom: '10px',
            marginLeft: 'auto',
            right: '32px',
            zIndex: 2000,
          }}
        ></div>
        {anchorEl && (
          <Popper
            id='avatar-popper'
            className={classes.popper}
            open={isOpen}
            container={anchorEl}
            anchorEl={anchorEl}
            placement='bottom-end'
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Fade in={isOpen}>
                <Paper className={classes.contentContainer} elevation={4}>
                  <MenuItem
                    key={1}
                    label={
                      intl.formatMessage({
                        id: 'AvatarMenu.MenuItem.MyProfile',
                        defaultMessage:
                          "My Profile",
                      })
                    }
                    onClick={onShowProfile}
                  />
                  <MenuItem
                    key={1}
                    label={
                      intl.formatMessage({
                        id: 'AvatarMenu.MenuItem.IntroductionVideo',
                        defaultMessage:
                          "Introduction video",
                      })
                    }
                    onClick={onShowIntroduction}
                  />
                  <MenuItem
                    key={2}
                    label={
                      intl.formatMessage({
                        id: 'AvatarMenu.MenuItem.Help',
                        defaultMessage:
                          "Help",
                      })
                    }
                    selected={showHelpSubmenu}
                    onClick={() => setShowHelpSubmenu(!showHelpSubmenu)}
                  />
                  <Collapse in={showHelpSubmenu}>
                    <Fade in={showHelpSubmenu} timeout={500}>
                      <div>
                        {/*<MenuItem*/}
                        {/*  key={5}*/}
                        {/*  label='Terms Of Service'*/}
                        {/*  weight='light'*/}
                        {/*  to='/help/terms-of-service'*/}
                        {/*/>*/}
                        <MenuItem
                          key={6}
                          label={
                            intl.formatMessage({
                              id: 'AvatarMenu.MenuItem.ContactUs',
                              defaultMessage:
                                "Contact us",
                            })
                          }
                          weight='light'
                          to='/help/contact'
                        />
                      </div>
                    </Fade>
                  </Collapse>
                  <div style={{ borderTop: 'solid 1px #fff', margin: '5px 28px' }} />
                  <MenuItem
                    key={10}
                    label={
                      intl.formatMessage({
                        id: 'AvatarMenu.MenuItem.LogOut',
                        defaultMessage:
                          "Log Out",
                      })
                    }
                    weight='light'
                    onClick={() => {
                      const rt = localStorage.getItem(constants.REFRESH_TOKEN_NAME)
                      if (rt) {
                        api.invalidateSession(rt).then(() => {
                          logOut()
                          history.push('/auth');
                        })
                      } else {
                        logOut()
                        history.push('/auth');
                      }
                    }}
                  />
                </Paper>
              </Fade>
            </ClickAwayListener>
          </Popper>
        )}
      </HeaderCornerBox>
    </React.Fragment>
  );
};

export default withRouter(AvatarMenu);
