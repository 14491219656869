import { Box, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { SimpleConfirmationDialog } from 'common/components/Dialogs/SimpleConfirmationDialog';
import { mainLayoutChannel } from 'layouts/Main';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useCelebrationsActions } from 'state/celebrations/useCelebrationsActions';
import { useSessionActions } from 'state/session/useSessionActions';
import { Courses } from 'state/views';
import { useViewsActions } from 'state/views/useViewsActions';

export const EpisodeInfoBox = ({
  name,
  caption,
  course,
  description,
  expanded,
}: {
  name: string;
  caption: string;
  course: Courses.ICourseDTO;
  description: string;
  expanded: boolean;
}) => {
  const history = useHistory();
  const intl = useIntl();
  const [joinCoursePromptModal, setJoinCoursePromptModal] = useState(false);
  const [ingnoreJoinCoursePrompt, setIgnoreJoinCoursePrompt] = useState(false);
  const [locallyJoined, setLocallyJoined] = useState(false);

  const { joinCourse, getHomeData, getMyCoursesData, pushMessageSnack, randomCelebration } = {
    ...useViewsActions('joinCourse', 'getHomeData', 'getMyCoursesData', 'forcePause'),
    ...useSessionActions('pushMessageSnack'),
    ...useCelebrationsActions('randomCelebration'),
  };

  const handleJoinCourseFromModalAction = useCallback(async () => {
    await joinCourse(course.id);
    setLocallyJoined(true);
    getHomeData();
    await getMyCoursesData();
    history.push('/main/my-courses');
    pushMessageSnack({
      channel: mainLayoutChannel,
      message: intl.formatMessage({
        id: 'VideoPlayer.EpisodeInfoBox.Snack.Success.YouJoinedCourse',
        defaultMessage: 'You have joined the course successfully!',
      }),
    });
    setTimeout(() => randomCelebration(0.5), 5000);
  }, [
    course.id,
    getHomeData,
    getMyCoursesData,
    history,
    intl,
    joinCourse,
    pushMessageSnack,
    randomCelebration,
  ]);

  useEffect(() => {
    if (!!!course.joined && !ingnoreJoinCoursePrompt) {
      if (expanded) {
        setJoinCoursePromptModal(true);
      }
    }
  }, [course.joined, expanded, ingnoreJoinCoursePrompt]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box fontSize={['24px', '24px', '26px']} fontWeight={500} mt={1} ml={2}>
            {name}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            fontSize={['12px', '12px', '20px']}
            textAlign={['left', 'left', 'right']}
            mt={1}
            ml={2}
            mr={2}
          >
            {caption}
          </Box>

          <Box
            fontSize={['12px', '12px', '20px']}
            textAlign={['left', 'left', 'right']}
            mt={1}
            ml={2}
            mr={2}
          >
            {course.name}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Box
            fontSize={['12px', '12px', '16px']}
            fontWeight={300}
            lineHeight={1.34}
            ml={2}
            mr={2}
            mt={1}
            pb={2}
          >
            {description}
          </Box>
        </Grid>
        <div></div>
      </Grid>
      {joinCoursePromptModal && (
        <SimpleConfirmationDialog
          open={joinCoursePromptModal}
          header={course.name}
          text={intl.formatMessage({
            id: 'VideoPlayer.EpisodeInfoBox.JoinCoursePromptModal.Text',
            defaultMessage: 'Do you want to explore the course and watch the full episodes?',
          })}
          confirmationLabel={
            locallyJoined
              ? intl.formatMessage({
                  id:
                    'VideoPlayer.EpisodeInfoBox.JoinCoursePromptModal.Confirmation.Caption.JoinedCourse',
                  defaultMessage: 'JOINED COURSE',
                })
              : intl.formatMessage({
                  id:
                    'VideoPlayer.EpisodeInfoBox.JoinCoursePromptModal.Confirmation.Caption.JoinCourse',
                  defaultMessage: 'JOIN COURSE',
                })
          }
          confirmationIconStart={
            locallyJoined && <CheckIcon style={{ marginTop: '-5px', marginBottom: '-5px' }} />
          }
          cancellationLabel={intl.formatMessage({
            id: 'VideoPlayer.EpisodeInfoBox.JoinCoursePromptModal.Cancellation.Caption',
            defaultMessage: 'Cancel',
          })}
          onClose={async ({ result }) => {
            if (result) {
              await handleJoinCourseFromModalAction();
            } else {
              setIgnoreJoinCoursePrompt(true);
            }
            setJoinCoursePromptModal(false);
          }}
        />
      )}
    </React.Fragment>
  );
};
