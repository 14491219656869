import Home from 'views/Home';
import MyChallenges from 'views/MyChallenges';
import { SubmitChallengeLoader } from 'views/MyChallenges/SubmitChallengeLoader';
import MyCourses from 'views/MyCourses';
import MySessions from 'views/MySessions';

// import WallOfIdeas from 'views/WallOfIdeas';
// import MyFavorites from 'views/MyFavorites';

export interface TabContentProps {
  tabIndex: number;
  active: boolean;
}

type tabsConfigType = {
  [key: string]: {
    name?: string;
    id: number;
    label: string;
    path: string;
    route?: string;
    disabled?: boolean;
    Component: (props: TabContentProps) => any;
    exact: boolean;
  };
};

export const tabsConfig: tabsConfigType = {
  100: {
    id: 100,
    label: 'help',
    path: 'help',
    Component: () => null,
    exact: true,
  },

  0: {
    id: 0,
    label: 'Home',
    name: 'Home',
    path: 'home',
    Component: Home,
    exact: false,
  },
  1: {
    id: 1,
    label: 'My courses',
    name: 'MyCourses',
    path: 'my-courses',
    route: 'my-courses/:courseId?/:showChapters?',

    Component: MyCourses,
    exact: false,
  },
  2: {
    id: 2,
    label: 'My challenges',
    name: 'MyChallenges',
    path: 'my-challenges',
    route: 'my-challenges/:mode?/:challengeId?/:edit?',
    Component: MyChallenges,
    exact: true,
  },
  3: {
    id: 3,
    label: 'My sessions',
    name: 'MySessions',
    path: 'my-sessions',
    Component: MySessions,
    exact: true,
  },
  // '/main/wall-of-ideas': {
  //   id: 4,
  //   label: 'Wall of ideas',
  //   Component: WallOfIdeas,
  //   href: '/main/wall-of-ideas',
  //   disabled: true
  // },
  // '/main/my-favorites': {
  //   id: 5,
  //   label: 'My favorites',
  //   Component: MyFavorites,
  //   href: '/main/my-favorites',
  // },

  101: {
    id: 101,
    label: 'submit',
    path: 'submit-challenge',
    route: 'submit-challenge/:challengeId',
    Component: SubmitChallengeLoader,
    exact: true,
  },
};
