import React from 'react';
import { Box } from '@material-ui/core';
import { ShowDetailsButton } from '../../styledComponents';
import { KeyboardArrowDownSharp } from '@material-ui/icons';

interface DescriptionProps {
  setShowDescription: any;
  showDescription: boolean;
  transitionTime: number;
}

const Description: React.FC<DescriptionProps> = ({
  setShowDescription,
  showDescription,
  transitionTime,
}) => {
  return (
    <Box borderRadius={0}>
      <ShowDetailsButton fullWidth onClick={() => setShowDescription(!showDescription)}>
        <KeyboardArrowDownSharp
          style={{
            transform: showDescription ? 'rotate(180deg)' : 'rotate(0)',
            transition: `${transitionTime - 200}ms all`,
          }}
        />
      </ShowDetailsButton>
    </Box>
  );
};

export default React.memo(Description);
