/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useCallback } from 'react';
import { Popper, Box, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  anchor: {
    height: '1px',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    top: '70px',
    margin: '0 15px',
  },
  root: {
    right: '0px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[700],
    padding: '.875rem 0.5rem',
  },

  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginLeft: theme.spacing(5),
    fontSize: '14px',
    fontWeight: 300,

    '&:hover': {
      transition: 'text-decoration',
      transitionDuration: '1s',
      textDecoration: 'underline',
    },
  },
}));

interface MainMenuPopperProps {
  open: boolean;
}

export const MainMenuPopper = ({ open }: MainMenuPopperProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const settingAnchor = useCallback((node) => {
    setAnchorEl(node);
  }, []);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Box>
        <div id='main-menu-anchor' ref={settingAnchor} className={classes.anchor}></div>
      </Box>

      {anchorEl && (
        <Popper
          id='manu-popper'
          className={classes.root}
          open={open}
          container={anchorEl}
          anchorEl={anchorEl}
          placement='bottom-start'
        >
          {({ TransitionProps }) => (
            <Fade in={open} {...TransitionProps}>
              <Box className={classes.contentContainer}>
                <Link className={classes.link} to='/help/contact'>
                  Contact
                </Link>
              </Box>
            </Fade>
          )}
        </Popper>
      )}
    </React.Fragment>
  );
};
