import {Box, Button, useMediaQuery} from '@material-ui/core';
import differenceInDays from 'date-fns/differenceInDays';
import { partition } from 'lodash';
import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { KairosSwitch } from './KairosSwitch';
import { NotificationTile } from './NotificationTile';
import { StyledControlLabel } from './StyledControlLabel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useTheme} from "@material-ui/core/styles";
import {useIntl} from "react-intl";

export const NotificationsList = ({
  emailNotifications,
  mobileVersion = false,
}: {
  emailNotifications: boolean;
  mobileVersion?: boolean;
}) => {

  const theme = useTheme()
  const intl = useIntl()

  const {
    getNotifications,
    getUnreadedNotifications,
    postAllowEmailNotificationFlag,
    getCurrentProfile,
    setOpenNotificationsMenu,
  } = useSessionActions(
    'getNotifications',
    'getUnreadedNotifications',
    'postAllowEmailNotificationFlag',
    'getCurrentProfile',
    'setOpenNotificationsMenu',
  );

  const isSmallDown = useMediaQuery(theme.breakpoints.down('xs'));

  const notifications = useSelector(
    (state: RootState) => state.session.notifications,
    shallowEqual,
  );

  const [newItems, oldItems] = partition(notifications.items, (el) => {
    let now = Date.now();
    return differenceInDays(now, new Date(el.createdAt)) <= 7;
  });

  const handleLoadMore = useCallback(() => {
    const oldestItem = notifications.items.slice(-1).pop();
    const date = oldestItem ? new Date(oldestItem.createdAt) : new Date();

    getUnreadedNotifications();
    getNotifications({ olderThan: date });
  }, [getNotifications, getUnreadedNotifications, notifications.items]);

  const handleEmailNotificationsChange = useCallback(() => {
    (async () => {
      try {
        await postAllowEmailNotificationFlag(!emailNotifications);
        await getCurrentProfile();
      } catch (ex) {
        console.log('Error while changing profile allow email notifications flag', ex);
      }
    })();
  }, [emailNotifications, getCurrentProfile, postAllowEmailNotificationFlag]);

  return (
    <Box height='100%' overflow='auto' id='__notify_list'>
      <InfiniteScroll
        threshold={500}
        pageStart={0}
        hasMore={notifications.hasMore}
        loadMore={handleLoadMore}
        loader={
          (notifications.loading && (
            <div className='loader' key={0}>
              Loading ...
            </div>
          )) ||
          undefined
        }
        useWindow={false}
      >
        {mobileVersion ? (
          <Box
            mx={1}
            mt={1}
            fontSize='18px'
            fontWeight={600}
            textAlign='left'
            display='flex'
            flexWrap='wrap'
            alignItems='center'
            overflow='hidden'
          >
            <Box flex={isSmallDown ? '1 100%' : '1 0 auto'}>
              <Button
                style={{ textTransform: 'none', fontSize: '18px', fontWeight: 700 }}
                startIcon={<ArrowBackIcon />}
                onClick={() => setOpenNotificationsMenu(false)}
              >
                Notifications
              </Button>
            </Box>
            <Box mt={0} ml={1} flex='2 0 auto' width='max-content' textAlign={isSmallDown ? 'left' : 'right'}>
              <StyledControlLabel
                labelPlacement={isSmallDown ? 'end' : 'start'}
                label={emailNotifications
                  ? intl.formatMessage({
                    id: 'NotificationsList.ToggleNotifications.On',
                    defaultMessage:
                      'E-mail notifications on',
                    })
                  : intl.formatMessage({
                    id: 'NotificationsList.ToggleNotifications.Off',
                    defaultMessage:
                      'E-mail notifications off',
                  })}
                control={
                  <KairosSwitch
                    size='small'
                    checked={emailNotifications}
                    onChange={handleEmailNotificationsChange}
                  />
                }
              />
            </Box>
          </Box>
        ) : (
          <Box mt={3} fontSize='18px' fontWeight={700} textAlign='center'>
            Notifications
          </Box>
        )}

        {newItems.length > 0 && (
          <Box
            mx={2}
            mt={1}
            mb={3}
            display='flex'
            alignItems='flex-end'
            justifyContent='center'
            borderBottom='solid 1px #FFFFFF'
            flexDirection='row-reverse'
            flexWrap='wrap'
          >
            {!mobileVersion && (
              <Box>
                <StyledControlLabel
                  labelPlacement='start'
                  label={emailNotifications
                    ? intl.formatMessage({
                      id: 'NotificationsList.ToggleNotifications.On',
                      defaultMessage:
                        'E-mail notifications on',
                    })
                    : intl.formatMessage({
                      id: 'NotificationsList.ToggleNotifications.Off',
                      defaultMessage:
                        'E-mail notifications off',
                    })
                  }
                  control={
                    <KairosSwitch
                      size='small'
                      checked={emailNotifications}
                      onChange={handleEmailNotificationsChange}
                    ></KairosSwitch>
                  }
                />
              </Box>
            )}
            <Box mb={1} flex='1 0 0'>
              NEW
            </Box>
          </Box>
        )}

        {newItems.map((notification) => {
          return <NotificationTile key={notification.id} notification={notification} />;
        })}

        <Box
          mx={2}
          mt={1}
          mb={3}
          // my='30px'
          display='flex'
          alignItems='flex-end'
          borderBottom='solid 1px #FFFFFF'
          flexDirection='row-reverse'
          flexWrap='wrap'
        >
          {!mobileVersion && newItems.length === 0 && (
            <Box mt={2}>
              <StyledControlLabel
                labelPlacement='start'
                label={emailNotifications
                  ? intl.formatMessage({
                    id: 'NotificationsList.ToggleNotifications.On',
                    defaultMessage:
                      'E-mail notifications on',
                  })
                  : intl.formatMessage({
                    id: 'NotificationsList.ToggleNotifications.Off',
                    defaultMessage:
                      'E-mail notifications off',
                  })
                }
                control={
                  <KairosSwitch
                    size='small'
                    checked={emailNotifications}
                    onChange={handleEmailNotificationsChange}
                  />
                }
              />
            </Box>
          )}
          <Box mt={2} flex='1 0 0'>
            PREVIOUS
          </Box>
        </Box>

        {oldItems.length === 0 && (
          <Box bgcolor='#2F2F2F' m={1} mb={3} py={3} textAlign='center'>
            {
              intl.formatMessage({
                id: 'NotificationsList.NoNotificationsFromLast7Days',
                defaultMessage:
                  'There are no notifications from more than 7 days ago',
              })
            }
          </Box>
        )}

        {oldItems.map((notification) => {
          return <NotificationTile key={notification.id} notification={notification} />;
        })}
      </InfiniteScroll>
    </Box>
  );
};
