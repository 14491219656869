import React, { useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import * as constants from 'state/session/constants';
import Header from 'common/components/Header/Header';
import { Footer } from 'common/components/Footer/Footer';
import { styled } from '@material-ui/core';
import PublicRoute from 'routes/PublicRoute';
import { EditProfileDialog } from 'common/components/Profile/EditProfileDialog';
import { publicRoutes } from 'routes';
import { getRoutes } from 'routes/utils';
import LoginModal from 'views/LandingPage/Components/LoginModal';
import { ResetPasswordModal } from 'views/LandingPage/Components/ResetPasswordModal';
import { NewPasswordModal } from 'views/LandingPage/Components/NewPasswordModal';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';

const Wrapper = styled('div')({
  color: '#ffffff',
});
const AuthLayout = ({ ...rest }: any) => {
  const [
    openLoginDialog,
    openResetPasswordDialog,
    openSetNewPassword,
    openEditProfileDialog,
  ] = useSelector((state: RootState) => [
    state.session.flags.openLoginDialog,
    state.session.flags.openResetPasswordDialog,
    state.session.flags.openSetNewPassword,
    state.session.flags.openEditProfileDialog,
  ]);

  // const { logOut } = useSessionActions('logOut');
  const history = useHistory();

  // const access = localStorage.getItem(constants.TOKEN_NAME);

  // useEffect(() => {
  //   access ? history.push('/main/home') : logOut();
  // }, [access, history, logOut]);

  return (
    <React.Fragment>
      <Wrapper id='__auth_wrapper'>
        <Header
          sticky={true}
          withPopper={true}
          withBoxShadow={false}
          withBackground={false}
          hasAuthDialogs={true}
          withLanguageSelector={false}
        />
        <Switch>
          {getRoutes(publicRoutes, '/auth').map((route: any) => (
            <PublicRoute
              exact={route.exact}
              key={route.path}
              path={route.layout + route.path}
              component={route.Component}
              {...rest}
            />
          ))}
        </Switch>
        <Footer />
        {openLoginDialog && <LoginModal />}
        {openResetPasswordDialog && <ResetPasswordModal />}
        {openSetNewPassword && <NewPasswordModal />}
        {openEditProfileDialog && <EditProfileDialog />}
      </Wrapper>
    </React.Fragment>
  );
};

export default React.memo(AuthLayout);
