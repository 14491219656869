/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Typography, useTheme, useMediaQuery, Theme, Box } from '@material-ui/core';
import {
  StyledContent,
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
  TitleButton,
} from 'common/components/Dialogs/StyledDialogComponents';
import { Form, Field } from 'react-final-form';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import ClearIcon from '@material-ui/icons/Clear';
import { useCallback } from 'react';
import { StyledTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import { mandatory, validateEmail } from 'utils/form-validation';
import { useSessionActions } from 'state/session/useSessionActions';
import { KairosSnackBar } from 'common/components/General/KairosSnackBar';
import { unwrapResult } from '@reduxjs/toolkit';
import { MediumButton } from 'common/components/General/Buttons';
import { FormattedMessage, useIntl } from 'react-intl';
const replaceAll = require('string.prototype.replaceall');


interface IValues {
  email: string;
}

const getChannelName = () => replaceAll(__filename, '.', '_');

export const ResetPasswordModal = (props: any) => {
  const theme = useTheme();
  const intl = useIntl();

  const [isVisible] = useSelector(
    (state: RootState) => [state.session.flags.openResetPasswordDialog],
    shallowEqual,
  );

  const bounded = useSessionActions(
    'setResetPasswordDialog',
    'postRequestPasswordReset',
    'pushMessageSnack',
    'pushErrorSnack',
  );
  const onClose = useCallback(() => {
    bounded.setResetPasswordDialog(false);
  }, [bounded]);

  return (
    <StyledDialog
      style={{ zIndex: 2010 }}
      open={isVisible}
      maxWidth='xs'
      fullScreen={useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))}
    >
      <KairosSnackBar channel={getChannelName()} />
      <Form
        onSubmit={async ({ email }: IValues) => {
          bounded
            .postRequestPasswordReset(email)
            .then(unwrapResult)
            .then(() => {
              bounded.pushMessageSnack({
                channel: getChannelName(),
                message: intl.formatMessage({
                  id: 'ResetPasswordModal.Snack.Success.EmailWithResetPasswordSent',
                  defaultMessage: 'Reset link has been sent to your inbox',
                }),
              });
              setTimeout(onClose, 3000);
            })
            .catch((error) => {
              console.log('Error while sending reset password request', error);
              bounded.pushErrorSnack({
                channel: getChannelName(),
                message: intl.formatMessage({
                  id: 'ResetPasswordModal.Snack.Error.EmailWitheResetPaswordNotSent',
                  defaultMessage: 'Error while trying to create reset link this time!',
                }),
              });
            });
        }}
        validate={(values: IValues) => {
          const result: Partial<Record<keyof IValues, string>> = {};
          result.email = validateEmail(values.email, intl.formatMessage({
            id: 'FormValidation.InCorrectEmailFormat',
            defaultMessage: 'Incorrect e-mail format',
          }));
          return result;
        }}
        render={({ handleSubmit, submitting, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <StyledDialogTitle>
              <TitleButton onClick={() => onClose()}>
                <ClearIcon color='primary' />
              </TitleButton>
            </StyledDialogTitle>
            <StyledContent stickyBottom>
              <Box textAlign='center'>
                <Typography variant='h3'>
                  <FormattedMessage
                    id='ResetPasswordModal.Title'
                    defaultMessage='Forgot password?'
                  />
                </Typography>
              </Box>

              <Field
                name='email'
                component={StyledTextFieldAdapter}
                color='primary'
                validate={value => {
                  mandatory(value, intl.formatMessage({
                    id: 'FormValidation.MandatoryField',
                    defaultMessage:
                      'This field is required.',
                  }))
                }}
                label={intl.formatMessage({
                  id: 'ResetPasswordModal.Field.Email.Label',
                  defaultMessage: 'E-mail address',
                })}
                placeholder={intl.formatMessage({
                  id: 'ResetPasswordModal.Field.Email.Placeholder',
                  defaultMessage: 'Write your e-mail here',
                })}
                fullWidth
                variant='outlined'
                css={{ marginTop: theme.spacing(8) }}
              />

              <Typography variant='body2' css={{ marginTop: theme.spacing(9.5) }}>
                <FormattedMessage
                  id='ResetPasswordModal.Subtitile'
                  defaultMessage='After you confirm this request we will send you an e-mail with a link to reset your
                password.'
                />
              </Typography>
            </StyledContent>

            <StyledDialogActions>
              <MediumButton
                type='submit'
                color='secondary'
                variant='contained'
                disabled={hasValidationErrors || submitting}
                fullWidth
              >
                <FormattedMessage
                  id='ReserPasswordModal.Caption.SendRequest'
                  defaultMessage='Send request'
                />
              </MediumButton>
            </StyledDialogActions>
          </form>
        )}
      />
    </StyledDialog>
  );
};
