import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Grow,
  makeStyles,
  Paper,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import cx from 'classnames';
import { useSessionActions } from 'state/session/useSessionActions';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  link: {
    textTransform: 'uppercase',
    paddingBottom: '4px',
    marginBottom: '-4',
    borderBottom: 'solid 1px transparent',
    transition: 'border-bottom .5s ease',
    cursor: 'pointer',
    '&:hover': {
      borderBottom: 'solid 1px #fff',
      transition: 'border-bottom .5s ease',
    },
  },
  linkLight: {
    '&:hover': {
      borderBottom: 'solid 1px #ffffffa6',
    },
  },
  arr: {
    msTransformOrigin: 'center center',
    transition: 'transform 300ms',
  },
  arrExpanded: {
    transform: 'rotateZ(-180deg)',
    transition: 'transform 300ms',
  },
});

export const LanguageChooser = () => {
  const classess = useStyles();
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const { userLang, languages, waiting } = useSelector(
    (state: RootState) => {
      return {
        userLang: state.session.user?.language,
        languages: state.session.languages,
        waiting: state.session.config.pendingLanguageChange,
      };
    },
    (next, prev) => {
      return (
        next.userLang?.id === prev.userLang?.id &&
        shallowEqual(next.languages, prev.languages) &&
        prev.waiting === next.waiting
      );
    },
  );

  const isSmallDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { setLanguage, getCurrentProfile } = useSessionActions('setLanguage', 'getCurrentProfile');

  const handleLanguageChange = useCallback(
    async (langId: string) => {
      setExpanded(false);
      try {
        await setLanguage({ langId });
      } finally {
      }
      getCurrentProfile();
    },
    [getCurrentProfile, setLanguage],
  );

  useEffect(() => {
    if (userLang === null && !!languages && !waiting) {
      const lang = languages.find((l) => l.locale.toLowerCase() === intl.locale) || languages[0];
      handleLanguageChange(lang.id);
    }
  }, [handleLanguageChange, intl.locale, languages, userLang, waiting]);

  const handleClickAway = () => setExpanded(false);

  const rwdLabel = (langName: string) => langName.substr(0, 3);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Box
          display='flex'
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Box className={classess.link}>{rwdLabel(userLang?.name || '')}</Box>
          <Box
            lineHeight={0}
            mt={-0.5}
            ml={0.5}
            className={cx({
              [classess.arr]: true,
              [classess.arrExpanded]: expanded,
            })}
          >
            {waiting ? (
              <CircularProgress
                style={{
                  width: '24px',
                  height: '24px',
                }}
              />
            ) : (
              <ExpandMoreIcon />
            )}
          </Box>
        </Box>

        <Grow in={expanded}>
          <Paper>
            {languages
              ?.filter((x) => x.id !== userLang?.id)
              .map((language) => (
                <Box
                  key={language.id}
                  className={cx(classess.link, classess.linkLight)}
                  color='#ffffffa6'
                  position='absolute'
                  onClick={() => handleLanguageChange(language.id)}
                >
                  {rwdLabel(language.name)}
                </Box>
              ))}
          </Paper>
        </Grow>
      </Box>
    </ClickAwayListener>
  );
};
