import React, { useCallback, useEffect, useState } from 'react';
import { Box, Fade, Grid } from '@material-ui/core';
import { useHistory, useParams, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import P2PMessageBox from './P2PMessageBox';
import P2PAccordion from './P2PAccordion';
import P2PGiveFeedback from './P2PGiveFeedback';
import { useChallengesActions } from '../../../state/challenges/useChallengesActions';
import P2PViewFeedback from './P2PViewFeedback';
import { unwrapResult } from '@reduxjs/toolkit';
import { useIntl } from 'react-intl';
import { useSessionActions } from '../../../state/session/useSessionActions';
import {differenceInDays, isAfter, parseISO, toDate} from 'date-fns';
import { ModeSwitchSection } from '../ModeSwitchSection';
import { jsx } from '@emotion/core';

const P2PFeedback = ({
  p2pMode,
  feedbacksCreatedByMe,
  challengesMode,
  challengesPathname,
  p2pPathname,
}: any) => {
  const history = useHistory();
  const intl = useIntl();
  const params = useParams<{ challengeId?: string; mode?: string }>();
  const { challengeId, mode } = params;

  const feedbackId = challengeId;

  const { challenges, user } = useSelector(
    (state: RootState) => ({
      challenges: state.challenges.items,
      user: state.session.user,
    }),
    shallowEqual,
  );

  const [showP2PMessage, setShowP2PMessage] = useState<boolean>(true);
  const [challengeItem, setChallengeItem] = useState<any>(null);
  const [feedbackItem, setFeedbackItem] = useState<any>(null);
  const [showGiveFeedbackDialog, setShowGiveFeedbackDialog] = useState<boolean>(false);
  const [showViewFeedbackDialog, setShowViewFeedbackDialog] = useState<boolean>(false);

  const filterOnlyP2PCompleted = (items: Challenges.IChallengeDTO[]) => {
    return items.filter((item) => item.is_p2p);
  };

  const completeP2PChallenges = filterOnlyP2PCompleted(challenges.completed);

  const completeP2PChallengesWithFeedbacksCreatedByMe = completeP2PChallenges.map(
    (completeP2PChallenge) => {
      return {
        ...completeP2PChallenge,
        feedbacksCreatedByMe: feedbacksCreatedByMe.filter(
          (feedback: any) => feedback.submission.challenge.id === completeP2PChallenge.id,
        ),
      };
    },
  );

  const toDo: any[] = [
    ...completeP2PChallengesWithFeedbacksCreatedByMe.filter((P2PChallengeWithFeedback) => {
      return P2PChallengeWithFeedback.feedbacksCreatedByMe.length === 0;
    }),
  ];

  const multipleFeedback: any[] = [
    ...completeP2PChallengesWithFeedbacksCreatedByMe.filter((P2PChallengeWithFeedback: any) => {
      const daysPassedFromCompleted = differenceInDays(new Date(), new Date(P2PChallengeWithFeedback.submission?.completedAt))

      const hasFeedbacksCreated = P2PChallengeWithFeedback.feedbacksCreatedByMe.length
      const givenFeedback = P2PChallengeWithFeedback.submission?.p2pFeedbackGiven
      const doesntHaveUnfinished = !P2PChallengeWithFeedback.feedbacksCreatedByMe.some(
        (feedback: any) => feedback.completedAt === null,
      )

      return (
        daysPassedFromCompleted < 30 &&
        hasFeedbacksCreated &&
        givenFeedback &&
        doesntHaveUnfinished
      );
    }),
  ];

  // const toDoWithoutLiveNotAvailable = toDo.filter((challenge) => {
  //   return !isAfter(new Date(), toDate(parseISO(challenge.availableTo)));
  // });

  const multipleFeedbackWithoutLiveNotAvailable = multipleFeedback.filter((challenge) => {
    return isAfter(toDate(parseISO(challenge.submission.takenAt)), toDate(parseISO(challenge.availableTo))) || !isAfter(new Date(), toDate(parseISO(challenge.availableTo)));
  });

  const P2PChallengesWithFeedbacksInProgress: any[] = [];
  const P2PChallengesWithFeedbacksCompleted: any[] = [];

  completeP2PChallengesWithFeedbacksCreatedByMe.forEach((challenge: any) => {
    if (challenge.feedbacksCreatedByMe.some((feedback: any) => feedback.completedAt === null)) {
      P2PChallengesWithFeedbacksInProgress.push({
        ...challenge,
        feedbacksCreatedByMe: challenge.feedbacksCreatedByMe.filter(
          (feedback: any) => feedback.completedAt === null,
        ),
      });
    }

    if (challenge.feedbacksCreatedByMe.some((feedback: any) => feedback.completedAt)) {
      P2PChallengesWithFeedbacksCompleted.push({
        ...challenge,
        feedbacksCreatedByMe: challenge.feedbacksCreatedByMe.filter(
          (feedback: any) => feedback.completedAt,
        ),
      });
    }
  });

  const { postRequestSubmissionToFeedback, getFeedbacksCreatedByMeData } = useChallengesActions(
    'postRequestSubmissionToFeedback',
    'getFeedbacksCreatedByMeData',
  );

  const { openConfirmationDialog } = {
    ...useSessionActions('openConfirmationDialog'),
  };

  useEffect(() => {
    getFeedbacksCreatedByMeData();
  }, [getFeedbacksCreatedByMeData]);

  const workOnFeedback = useCallback(
    (challenge: any, feedback: any, mode) => {
      setChallengeItem(challenge);
      setFeedbackItem(feedback);
      history.push(`/main/my-challenges/p2p/${feedback.id}/${mode}`);
      mode === 'give' && setShowGiveFeedbackDialog(true);
      mode === 'view' && setShowViewFeedbackDialog(true);
    },
    [history],
  );

  const giveFeedback = useCallback(
    async (challenge: any) => {
      postRequestSubmissionToFeedback({ challengeId: challenge })
        .then(unwrapResult)
        .then((res) => {
          const feedbackToShow = res;

          if (feedbackToShow) {
            const challengeToSet = completeP2PChallengesWithFeedbacksCreatedByMe.find(
              (chall) => chall.id === feedbackToShow.submission.challenge.id,
            );
            return workOnFeedback(challengeToSet, feedbackToShow, 'give');
          }
        })
        .catch((error) => {
          openConfirmationDialog({
            isVisible: true,
            modalType: 'JUST_CONFIRM',
            modalProps: {
              header: challengeItem?.title,
              text: intl.formatMessage({
                id: 'P2PFeedback.GiveFeedback.Modal.NoSubmission',
                defaultMessage:
                  'There are no available challenge submissions at the moment. Try again later.',
              }),
              confirmationLabel: 'OK',
              onlyConfirm: true,
            },
          });
        });
    },
    [
      challengeItem,
      completeP2PChallengesWithFeedbacksCreatedByMe,
      intl,
      openConfirmationDialog,
      postRequestSubmissionToFeedback,
      workOnFeedback,
    ],
  );

  useEffect(() => {
    if (
      feedbackId &&
      ((mode === 'give' && !showGiveFeedbackDialog) ||
        (mode === 'view' && !showViewFeedbackDialog)) &&
      completeP2PChallengesWithFeedbacksCreatedByMe.length
    ) {
      const feedbackToShow = feedbacksCreatedByMe.find(
        (feedback: any) => feedback.id === feedbackId,
      );

      if (feedbackToShow) {
        const challengeToSet = completeP2PChallengesWithFeedbacksCreatedByMe.find(
          (chall) => chall.id === feedbackToShow.submission.challenge.id,
        );
        workOnFeedback(challengeToSet, feedbackToShow, mode);
      }
    }
  }, [
    completeP2PChallengesWithFeedbacksCreatedByMe,
    feedbackId,
    feedbacksCreatedByMe,
    mode,
    showGiveFeedbackDialog,
    showViewFeedbackDialog,
    workOnFeedback,
  ]);

  const defaultShowGiveFeedback = p2pMode && challengeItem;

  return (
    <Switch>
      <Route exact path='/main/my-challenges/p2p'>
        <Fade in>
          <div>
            <ModeSwitchSection
              p2pMode={p2pMode}
              p2pPathname={p2pPathname}
              challengesMode={challengesMode}
              challengesPathname={challengesPathname}
            />
            <Grid container justify='center'>
              <Grid item xs={10} sm={8} md={6} lg={4}>
                {user && (
                  <Box mt={2} mb={5}>
                    <P2PMessageBox user={user} />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <P2PAccordion
                  p2pChallenges={toDo}
                  action={(challenge: any) => giveFeedback(challenge.id)}
                  actionLabel={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.ToDo.ActionLabel',
                      defaultMessage:
                        'Give feedback',
                    })
                  }
                  title={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.ToDo.Title',
                      defaultMessage:
                        'TO DO',
                    })
                  }
                  type='to_do'
                  nothingToShowMessage={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.ToDo.NothingToShowMessage',
                      defaultMessage:
                        'In this section you will see all the challenges you have been assigned to, in order to give feedback to your peer.',
                    })
                  }
                />
                <P2PAccordion
                  type='in_progress'
                  p2pChallenges={P2PChallengesWithFeedbacksInProgress}
                  action={(challenge: any, feedback: any) => {
                    workOnFeedback(challenge, feedback, 'give');
                  }}
                  actionLabel={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.InProgress.ActionLabel',
                      defaultMessage:
                        'Work on feedback',
                    })
                  }
                  title={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.InProgress.Title',
                      defaultMessage:
                        'IN PROGRESS',
                    })
                  }
                  nothingToShowMessage={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.InProgress.NothingToShowMessage',
                      defaultMessage:
                        'In this section you will see all the challenges you started working on in order to give feedback to your peer.',
                    })
                  }
                />
                <P2PAccordion
                  type='completed'
                  p2pChallenges={P2PChallengesWithFeedbacksCompleted}
                  action={(challenge: any, feedback: any) => {
                    workOnFeedback(challenge, feedback, 'view');
                  }}
                  actionLabel={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.Completed.ActionLabel',
                      defaultMessage:
                        'View feedback',
                    })
                  }
                  title={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.Completed.Title',
                      defaultMessage:
                        'COMPLETED',
                    })
                  }
                  nothingToShowMessage={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.Completed.NothingToShowMessage',
                      defaultMessage:
                        'In this section you will see all the challenges that you\'ve created feedback for.',
                    })
                  }
                />
                <P2PAccordion
                  type='multiple_feedback'
                  p2pChallenges={multipleFeedbackWithoutLiveNotAvailable}
                  action={(challenge: any) => giveFeedback(challenge.id)}
                  actionLabel={
                    intl.formatMessage({
                      id: 'P2PFeedback.Accordion.MultipleFeedback.ActionLabel',
                      defaultMessage:
                        'Give another feedback',
                    })
                  }
                  title='MULTIPLE FEEDBACK'
                  nothingToShowMessage='In this section you will see all the challenges that you have given feedback for but are still open if you want to give another feedback to different peer.'
                />
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Route>
      <Route path='/main/my-challenges/p2p/:id/give'>
        <P2PGiveFeedback
          mode={mode}
          showDialog={showGiveFeedbackDialog}
          setShowDialog={setShowGiveFeedbackDialog}
          challengeItem={challengeItem}
          feedbackItem={feedbackItem}
          defaultShowGiveFeedback={defaultShowGiveFeedback}
        />
      </Route>
      <Route path='/main/my-challenges/p2p/:id/view'>
        <P2PViewFeedback
          mode={mode}
          showDialog={showViewFeedbackDialog}
          setShowDialog={setShowViewFeedbackDialog}
          challengeItem={challengeItem}
          feedbackItem={feedbackItem}
          defaultShowGiveFeedback={defaultShowGiveFeedback}
        />
      </Route>
    </Switch>
  );
};

export default React.memo(P2PFeedback);
