import {Box, Dialog, DialogContent, Grid, Typography} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ConfirmationResult,
  SimpleConfirmationDialog,
} from 'common/components/Dialogs/SimpleConfirmationDialog';
import { MediumButton } from 'common/components/General/Buttons';
import { DownloadInstructions } from 'common/components/General/DownloadInstructions';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { useCelebrationsActions } from 'state/celebrations/useCelebrationsActions';
import { useChallengesActions } from 'state/challenges/useChallengesActions';
import { useViewsActions } from 'state/views/useViewsActions';
import styled from 'styled-components';
import { useNavigation } from 'utils/useNavigation';
import {LiveChallengeIntroductionPlayer} from "./LiveChallengeIntroductionPlayer";
import {LiveChallengeIntroductionFullscreenDialogPlayer} from "./LiveChallengeIntroductionFullscreenDialog";
import LiveChallengeMessageBox from "../MyChallenges/Live/LiveChallengeMessageBox";
import {useSessionActions} from "../../state/session/useSessionActions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../reducers";

interface ChallengeInfoBoxProps {
  name: string;
  caption: string;
  challenge: Challenges.IChallengeDTO;
  description: string;
  expanded: boolean;
  onDialogShow: (flab: boolean) => void;
  onPauseRequest: () => void;
  onAllowPlay: () => void;
}

const LinkWithIcon = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  & svg {
    vertical-align: middle;
  }
  &:hover {
    border-bottom: solid 1px #ffffff;
    transition: border-bottom 1s ease;
  }
`;

export const LiveChallengeInfoBox = ({
  name,
  caption,
  challenge,
  description,
  expanded,
  onDialogShow,
  onPauseRequest,
  onAllowPlay,
}: any) => {
  const intl = useIntl();
  const history = useHistory();
  const nav = useNavigation();
  const [takeChallengeModal, setTakeChallengeModal] = useState(false);
  const [consentP2PModal, setConsentP2PModal] = useState(false);
  const [ignoreChallenge, setIgnoreChallenge] = useState(false);
  const [challengeTakenConfirmationModal, setChallengeTakenConfirmationModal] = useState(true);
  const [liveChallengeInstructionModal, setLiveChallengeInstructionModal] = useState(false);
  const [liveChallengeMessageBox, setLiveChallengeMessageBox] = useState(false);

  const { postTakeChallenge, setSubmitChallengeDialog, getChallenge, getChallenges } = {
    ...useChallengesActions(
      'postTakeChallenge',
      'setSubmitChallengeDialog',
      'getChallenge',
      'getChallenges',
    ),
  };

  const { user } = useSelector(
    (state: RootState) => ({
      user: state.session.user,
    }),
    shallowEqual,
  );

  //#region effects
  useEffect(() => {
    if (!!challenge && challenge.submission === null && !ignoreChallenge) {
      if (expanded) {
        setTakeChallengeModal(true);
      }
    }
  }, [expanded, ignoreChallenge, challenge]);

  useEffect(() => {
    onDialogShow && onDialogShow(takeChallengeModal || challengeTakenConfirmationModal);
  }, [challengeTakenConfirmationModal, onDialogShow, takeChallengeModal]);

  const { forcePause } = useViewsActions('forcePause');

  //#endregion effects
  //#region handlers
  const handleTakeChallengeClick = (useCallback(
    () => {
      user?.liveChallengeConfirmed ? setConsentP2PModal(true) : setLiveChallengeMessageBox(true);
      forcePause(true)
    },
    []
  ))

  const handleTakeChallenge = useCallback(() => {
    return postTakeChallenge(challenge?.id || '')
      .then(() => {
        getChallenge(challenge.id);
        getChallenges();
      })
      .catch((error) => console.log('Error while taking challenge', error));
  }, [challenge, getChallenge, getChallenges, postTakeChallenge]);

  const handleWorkOnChallenge = useCallback(async () => {
    if (challenge) {
      getChallenge(challenge.id)
        .then(unwrapResult)
        .then((challengeDetails) => {
          onPauseRequest && onPauseRequest();
          setSubmitChallengeDialog(challengeDetails);
          history.push(`/main/submit-challenge/${challengeDetails.id}`);
        })
        .catch((error) => console.log('Error while fetching challenge details', error));
    }
  }, [challenge, getChallenge, history, onPauseRequest, setSubmitChallengeDialog]);

  // const handleEditChallenge = useCallback(() => {
  //   if (challenge) {
  //     history.push(`/main/my-challenges/challenges/${challenge.id}/edit`);
  //   }
  // }, [history, challenge]);

  const continueFromIntroduction = () => {
    setLiveChallengeInstructionModal(false)
    setLiveChallengeMessageBox(false)
    setConsentP2PModal(true)
  }

  const handleTakeChallengeDialogClose = useCallback(
    ({ result }: ConfirmationResult) => {
      if (result) {
        // handleTakeChallenge().then(() => {
          setTakeChallengeModal(false)
          user?.liveChallengeConfirmed ? setConsentP2PModal(true) : setLiveChallengeMessageBox(true);
        // });
      } else {
        // setIgnoreChallenge(true);
        setTakeChallengeModal(false)
        history.push('/main/my-challenges')
      }
    },
    [handleTakeChallenge],
  );

  const handleConsentP2P = useCallback(
    ({ result }: ConfirmationResult) => {
      if (result) {
        handleTakeChallenge().then(() => {
          setConsentP2PModal(false);
          setChallengeTakenConfirmationModal(true);
        });
      } else {
        setIgnoreChallenge(true);
        setTakeChallengeModal(false);
        history.push('/main/my-challenges')
      }
    },
    [handleTakeChallenge],
  );

  //#endregion handlers
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box fontSize={['20px', '20px', '22px']} fontWeight={500} mt={1} ml={2}>
          {name}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          fontSize={['12px', '12px', '16px']}
          textAlign={['left', 'left', 'right']}
          mt={1}
          ml={2}
          mr={2}
        >
          {caption}
        </Box>

        <Box
          fontSize={['12px', '12px', '16px']}
          textAlign={['left', 'left', 'right']}
          mt={1}
          ml={2}
          mr={2}
        ></Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={7}>
        <Box
          fontSize={['12px', '12px', '14px', '16px']}
          fontWeight={300}
          lineHeight={1.34}
          ml={2}
          mr={2}
          mt={1}
          pb={2}
        >
          {description}
        </Box>
      </Grid>
      <Grid item md={2} />
      <Grid item xs={12} md={4} lg={3}>
        <Box display='flex' justifyContent='flex-end'>
          <Box mt={2} display='flex' justifyContent='center' alignItems='center'>
            <DownloadInstructions instructions={challenge?.instructions || null} />
          </Box>
          <Box m={2} display='flex' flexDirection='column' alignItems='flex-end'>
            {challenge?.submission == null && (
              <MediumButton color='secondary' variant='contained' onClick={handleTakeChallengeClick}>
                <FormattedMessage
                  id='VideoPlayer.ChallengeInfoBox.TakeChallenge'
                  defaultMessage='TAKE CHALLENGE'
                />
              </MediumButton>
            )}

            {!!challenge?.submission && challenge?.submission.status === 'TAKEN' && (
              <MediumButton color='secondary' variant='contained' startIcon={<CheckIcon />}>
                <FormattedMessage
                  id='VideoPlayer.ChallengeInfoBox.ChallengeAccepted'
                  defaultMessage='CHALLENGE ACCEPTED'
                />
              </MediumButton>
            )}
            {!!challenge?.submission && challenge?.submission.status === 'COMPLETED' && (
              <MediumButton color='secondary' variant='contained' startIcon={<CheckIcon />}>
                <FormattedMessage
                  id='VideoPlayer.ChallengeInfoBox.ChallengeCompleted'
                  defaultMessage='CHALLENGE COMPLETED'
                />
              </MediumButton>
            )}
            {challenge?.submission && challenge?.submission.status !== 'COMPLETED' && (
              <MediumButton color='primary' variant='outlined' onClick={handleWorkOnChallenge}>
                <FormattedMessage
                  id='VideoPlayer.ChallengeInfoBox.WorkOnChallenge'
                  defaultMessage='WORK ON CHALLENGE'
                />
              </MediumButton>
            )}
          </Box>
        </Box>
      </Grid>
      <Dialog open={liveChallengeInstructionModal} fullScreen>
        <LiveChallengeIntroductionFullscreenDialogPlayer
          shouldPlay={liveChallengeInstructionModal}
          setLiveChallengeInstructionModal={setLiveChallengeInstructionModal}
          continueFromIntroduction={continueFromIntroduction}
        />
      </Dialog>
      <Dialog open={liveChallengeMessageBox}>
        <DialogContent style={{ padding: 0, background: 'none' }}>
          <LiveChallengeMessageBox
            // goToChallenge={goToChallenge}
            setConsentP2PModal={setConsentP2PModal}
            toggleLiveChallengeMessageBox={() => setLiveChallengeMessageBox(!liveChallengeMessageBox)}
            setLiveChallengeInstructionModal={setLiveChallengeInstructionModal}
          />
        </DialogContent>
      </Dialog>
      {consentP2PModal && (
        <SimpleConfirmationDialog
          customOpacity={0.9}
          // fullScreen={!isMedium}
          open={consentP2PModal}
          confirmationLabel={intl.formatMessage({
            id: 'VideoPlayer.LiveChallengeInfoBox.TakeChallengeModal.Confirm.Yes',
            defaultMessage: 'OK',
          })}
          cancellationLabel={intl.formatMessage({
            id: 'VideoPlayer.LiveChallengeInfoBox.ConsentP2PModal.Cancellation.DontWantToParticipate',
            defaultMessage: "No, I don't want to participate",
          })}
          header={intl.formatMessage({
            id: 'VideoPlayer.LiveChallengeInfoBox.ConsentP2PModal.P2PFeedbackConfirmation',
            defaultMessage: 'P2P Feedback Confirmation',
          })}
          text={intl.formatMessage({
            id: 'VideoPlayer.LiveChallengeInfoBox.ConsentP2PModal.Text',
            defaultMessage:
              'To get feedback from your peers, all your uploaded Challenge results will be shared anonymously. Please confirm your consent below.',
          })}
          onClose={handleConsentP2P}
        />
      )}
      {takeChallengeModal && (
        <SimpleConfirmationDialog
          customOpacity={0.9}
          // fullScreen={!isMedium}
          open={takeChallengeModal}
          confirmationLabel={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.TakeChallengeModal.Confirm.Yes',
            defaultMessage: 'Yes',
          })}
          cancellationLabel={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.TakeChallengeModal.Cancellation.No',
            defaultMessage: 'No',
          })}
          header={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.TakeChallengeModal.DoYouWantToJoin',
            defaultMessage: 'Do you want to join the Live Challenge?',
          })}
          text={null}
          // text={intl.formatMessage({
          //   id: 'VideoPlayer.LiveChallengeInfoBox.TakeChallengeModal.Text',
          //   defaultMessage:
          //     'Are you ready to turn the insights of this chapter into impact? To complete this chapter, take on the challenge we prepared for you. You will find further guidance and concrete steps on how to make positive change happen and reach the next level with your team.',
          // })}
          onClose={handleTakeChallengeDialogClose}
        />
      )}
      {challengeTakenConfirmationModal && challenge?.submission?.status === 'TAKEN' && (
        <SimpleConfirmationDialog
          customOpacity={0.9}
          // fullScreen={!isMedium}
          withCloseButton
          open={challengeTakenConfirmationModal}
          confirmationLabel={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.ChallengeTakenInfoModal.Confirm.Caption',
            defaultMessage: 'CHALLENGE ACCEPTED',
          })}
          confirmationIconStart={<CheckIcon style={{ marginTop: '-5px', marginBottom: '-5px' }} />}
          cancellationLabel={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.ChallengeTakenInfoModal.Cancellation.Caption',
            defaultMessage: 'WORK ON CHALLENGE',
          })}
          header={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.ChallengeTakenInfoModal.Header',
            defaultMessage: 'Perfect!',
          })}
          text={null}
          content={
            <Typography variant='body1' style={{ textAlign: 'center' }}>
              <FormattedMessage
                id='VideoPlayer.ChallengeInfoBox.UserDidTakeChallengeRightNowInfoModal.Content'
                defaultMessage='Challenge has been saved to
                <link> „My challenges”
                </link> successfully! You can start working on it now or come back when you’re ready.'
                values={{
                  link: (chunks: any) => (
                    <LinkWithIcon to='/main/my-challenges/challenges'>
                      {chunks}
                      <ArrowForwardIcon />
                    </LinkWithIcon>
                  ),
                }}
              />
            </Typography>
          }
          onClose={({ result }) => {
            setChallengeTakenConfirmationModal(false);
          }}
          onCancel={({result}) => {
            setChallengeTakenConfirmationModal(false);
            if (!result) {
              handleWorkOnChallenge();
            }
          }}
        />
      )}
    </Grid>
  );
};
