import {
  Box,
  Snackbar,
  SnackbarProps,
  Theme,
  useMediaQuery,
  withStyles,
  Portal,
  SwipeableDrawer,
  useTheme,
  SnackbarClassKey,
  SnackbarCloseReason,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { NotificationTile } from 'common/components/Notifications/NotificationTile';
import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';

const SnackbarWithStyles = withStyles<SnackbarClassKey, {}, SnackbarProps & { position: any }>(
  () => ({
    root: (props) => ({
      top: '12px',
      position: props.position,
    }),
    anchorOriginBottomCenter: {},
    anchorOriginBottomLeft: {},
    anchorOriginBottomRight: {},
    anchorOriginTopCenter: {},
    anchorOriginTopLeft: {},
    anchorOriginTopRight: {},
  }),
)(
  React.forwardRef<HTMLElement, SnackbarProps>((props, ref) => (
    <Snackbar ref={ref} {...omit(props, ['position'])} />
  )),
);

const SlideOnLeftTransition = (props: any) => {
  return <Slide {...props} direction='left' mountOnEnter unmountOnExit />;
};

export const KairosPushSnackBar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const isMediumUpScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { notification, navBarMounted } = useSelector(
    (state: RootState) => ({
      notification: state.session.notifications.pushNotifications.item,
      navBarMounted: state.session.dependencies.navBarMounted,
    }),
    shallowEqual,
  );

  useEffect(() => {
    setOpen(!!notification);
  }, [notification]);

  const { pushNotification } = useSessionActions('pushNotification');

  const handleClose = (_: any, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return isMediumUpScreen && navBarMounted ? (
    <Portal container={document.querySelector('.notificationsBar')}>
      <React.Fragment>
        <Box
          overflow='hidden'
          width='max-content'
          position='relative'
          ml='auto'
          pl='9px'
          onClick={() => setOpen(!open)}
        >
          <SnackbarWithStyles
            open={open}
            onClose={handleClose}
            position='relative'
            TransitionComponent={SlideOnLeftTransition}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            autoHideDuration={notification?.timeout || null}
            onExited={() => {
              pushNotification(null);
            }}
          >
            {notification ? (
              <Box width='530px' color='#ffffff'>
                <NotificationTile notification={notification} compact padding={1} />
              </Box>
            ) : (
              <div></div>
            )}
          </SnackbarWithStyles>
        </Box>
      </React.Fragment>
    </Portal>
  ) : (
    <SwipeableDrawer
      anchor='top'
      open={!!notification}
      onOpen={() => console.log('Opening notification push message', notification)}
      onClose={() => pushNotification(null)}
    >
      {notification && (
        <Box
          bgcolor={theme.palette.grey[300]}
          display='flex'
          flexDirection='column'
          alignItems='center'
        >
          <NotificationTile notification={notification} padding={1} />
          <Box
            m={0.5}
            height={8}
            borderRadius={3}
            width='25%'
            bgcolor={theme.palette.grey[800]}
          ></Box>
        </Box>
      )}
    </SwipeableDrawer>
  );
};
