import React, { useMemo, useState } from 'react';
import SlideComponent, { SlideComponentProps } from '../index';
import { joinCourse, getMyCoursesData } from 'state/views/actions';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSessionActions } from 'state/session/useSessionActions';
import {useIntl} from "react-intl";
const actionsToBound = {
  joinCourse,
  getMyCoursesData,
};

const CourseSlideSecondary = ({ ...props }: SlideComponentProps) => {
  const dispatch = useDispatch();
  const bounded = useMemo(() => bindActionCreators(actionsToBound, dispatch), [dispatch]);
  const intl = useIntl()

  const [userJoined, setUserJoined] = useState(props.item.joined);

  const { openSlideNotification } = useSessionActions('openSlideNotification');

  const openNotification = (
    sliderTitle: string | undefined,
    itemId: string,
    message: string,
    actionLabel: string,
  ) => {
    return openSlideNotification({
      id: `${sliderTitle}-${itemId}`,
      message: message,
      actionLabel: actionLabel,
    });
  };

  const joinCourse = () => {
    if (props.item.joined) {
      return openNotification(
        props.sliderTitle,
        props.item.id,
        intl.formatMessage({
          id: 'OpenCourses.Notification.AlreadyJoined',
          defaultMessage: `You have already joined this course.`,
        }),
        intl.formatMessage({
          id: 'Literal.OK',
          defaultMessage: `OK`,
        }),
      );
    }

    bounded.joinCourse(props.item.id).then(() => {
      setUserJoined(true);
      bounded.getMyCoursesData();
      openNotification(
        props.sliderTitle,
        props.item.id,
        intl.formatMessage({
          id: 'SecondaryCourseSlide.Notification.JoinedSuccessfully',
          defaultMessage: `You have joined the course successfully!`,
        }),
        intl.formatMessage({
          id: 'Literal.OK',
          defaultMessage: `OK`,
        }),
      );
    });
  };

  return (
    <SlideComponent
      withDescription
      withVideo
      withTitleOverlay
      withArrow
      withJoinFab
      joinFabProps={{
        onClick: () => joinCourse(),
        userJoined,
      }}
      {...props}
    />
  );
};

export default React.memo(CourseSlideSecondary);
