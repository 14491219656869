import React from 'react';
import SlickCarousel from 'common/components/SlickCarousel';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

const Ongoing = ({ items, executeScroll }: any) => {
  const intl = useIntl();
  return (
    <div>
      <SlickCarousel
        titleBright
        sliderType='courses-with-challenges'
        items={items}
        sliderTitle={intl.formatMessage({
          id: 'MyCourses.Sections.Ongoing.SliderTitle',
          defaultMessage: 'ONGOING COURSES',
        })}
        nothingToShowMessage={
          <div>
            <FormattedMessage
              id='MyCourses.Sections.Ongoing.NothingToShowMessage'
              defaultMessage="<p>It seems like you don't have any ongoing courses at the moment.</p>
            <button>EXPLORE OPEN COURSES</button>"
              values={{
                p: (chunks: any) => <p>{chunks}</p>,
                button: (chunks: any) => (
                  <Button color='secondary' fullWidth variant='contained' onClick={executeScroll}>
                    {chunks}
                  </Button>
                ),
              }}
            />
          </div>
        }
        slidesToShow={3}
        slidesToScroll={3}
        speed={500}
      />
    </div>
  );
};

export default React.memo(Ongoing);
