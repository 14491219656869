import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import VideoPlayer from 'views/VideoPlayer';
import { Typography } from '@material-ui/core';
import { P2PIntroductionInfoBox } from './P2PIntroductionInfoBox';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { LiveChallengeIntroductionFullscreenDialogInfoBox } from './LiveChallengeIntroductionFullscreenDialogInfoBox';

export const LiveChallengeIntroductionFullscreenDialogPlayer = ({shouldPlay, setLiveChallengeInstructionModal, continueFromIntroduction}: any) => {
  const history = useHistory();
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.session.user, shallowEqual);

  const params = useParams<{ new?: string }>();

  const videoId = user?.company?.liveChallengeInstructionsVideoId;

  return (
    <div id='__introductionPlayerContainer'>
      <VideoPlayer
        play={shouldPlay}
        src={videoId}
        type='introduction'
        endedTreshold={0}
        OverlayProps={{
          name: intl.formatMessage({
            id: 'VideoPlayer.LiveChallengeIntroductionPlayer.Name',
            defaultMessage: 'LIVE CHALLENGE: How does it work?',
          }),
          description: intl.formatMessage({
            id: 'VideoPlayer.LiveChallengeIntroductionPlayer.Description',
            defaultMessage: 'Take a minute to discover how live challenges work.',
          }),
          onSkip: setLiveChallengeInstructionModal,
          onContinue: continueFromIntroduction,
          withSplitBar: true,
          InfoBoxComponent: LiveChallengeIntroductionFullscreenDialogInfoBox,
          ExpandedElement: (
            <Box maxWidth={'600px'}>
              {/*<Typography variant='h2'>*/}
              {/*  <FormattedMessage*/}
              {/*    id='VideoPlayer.IntroductionPlayer.GratulationsAfterIntroductionVideo'*/}
              {/*    defaultMessage='Now you are ready to start your personal journey with Curjos.*/}
              {/*  <br></br>*/}
              {/*  <br></br>*/}
              {/*  Good luck!'*/}
              {/*    values={{*/}
              {/*      br: () => <br />,*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Typography>*/}
              <Button
                color='secondary'
                variant='contained'
                style={{ marginTop: '20px', padding: '20px 50px' }}
                onClick={() => continueFromIntroduction()}
              >
                <FormattedMessage
                  id='VideoPlayer.IntroductionPlayer.Caption.GetStarted'
                  defaultMessage='GET STARTED'
                />
              </Button>
            </Box>
          ),
          withBackButton: true,
          backButtonLabel: 'Back',
          onBackClick: () => {
            setLiveChallengeInstructionModal(false)
          },
        }}
      />
    </div>
  );
};
