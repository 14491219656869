import { Box, Fade, Grid } from '@material-ui/core';
import { ArrowForward, Check } from '@material-ui/icons';
import React from 'react';
import { formatDurration } from 'utils';
import { getCourseDuration } from '../../../../../utils/helpers';
import { pad } from '../../../../utils';
import { SlideAdditionalInfo, SlideName, StyledLinkButton } from '../../styledComponents';

interface TitleAndDetailsProps extends Slides.ItemWithTypeProps {
  withTitleOrderNumberPrefix: boolean;
  showDetails: boolean;
  withArrow: boolean;
  withCheck: boolean;
  slideNameWithDetails: boolean;
  transitionTime: number;
  withTitleOverlay: boolean;
  withDuration?: boolean;
  onLinkButtonClick: (item: any) => void;
}

const TitleAndDetails: React.FC<TitleAndDetailsProps> = ({
  withTitleOrderNumberPrefix,
  withTitleOverlay,
  showDetails,
  item,
  withArrow,
  withCheck,
  slideNameWithDetails,
  transitionTime,
  sliderTypeName,
  sliderType,
  withDuration = false,
  onLinkButtonClick,
}) => {
  const getEpisodesAmount = (chapters: any) => {
    let amount = 0;

    chapters.forEach((chapter: any) => {
      return (amount += chapter.episodes.length);
    });

    return amount;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledLinkButton
          fullWidth={withDuration}
          disableRipple
          disableTouchRipple
          onClick={() => {
            onLinkButtonClick(item);
          }}
        >
          <Box width='100%' fontSize={['12px', '12px', '16px']}>
            <SlideName style={{ width: withDuration ? '100%' : 'auto', display: 'flex' }}>
              {item.ordinal
                ? withTitleOrderNumberPrefix && <span>{pad(item.ordinal, 2)}&nbsp;|&nbsp;</span>
                : ''}
              <span
                style={{
                  fontSize: 'inherit',
                  opacity: withTitleOverlay ? (showDetails ? 1 : 0) : 1,
                  transition: `${transitionTime}ms all`,
                }}
              >
              {item.is_p2p ? 'P2P: ' : ''}
                {item.title || item.name}
              </span>
              {withDuration && (
              <span style={{ textTransform: 'none', flex: '1 0 auto', textAlign: 'right' }}>
                {formatDurration(item.video?.duration)}
              </span>
              )}

              {withArrow && !item.comingSoon && (
                <ArrowForward
                  style={{
                    opacity: !slideNameWithDetails ? (showDetails ? 1 : 0) : 1,
                    transition: `${transitionTime}ms all`,
                    marginLeft: '20px',
                  }}
                />
              )}
              {withCheck && (
                <Check
                  style={{
                    opacity: !slideNameWithDetails ? (showDetails ? 1 : 0) : 1,
                    transition: `${transitionTime}ms all`,
                    marginLeft: '20px',
                  }}
                />
              )}
            </SlideName>
          </Box>
        </StyledLinkButton>
      </Grid>
      <Fade in={showDetails}>
        <Box>
          {!item.comingSoon && (
            <SlideAdditionalInfo>
              {(item.itemType || sliderTypeName) && (
                <span>
                  {item.itemType || sliderTypeName} {item.ordinal ? item.ordinal : ''}
                </span>
              )}
              {(item.itemType || sliderTypeName) && item.chapter && ' /'}
              {item.chapter && <span> Chapter {item.chapter.ordinal}</span>}
              {item.chapters && (
                <span>
                  {item.chapters.length} {item.chapters.length === 1 ? 'Chapter' : 'Chapters'}
                </span>
              )}{' '}
              {item.chapters && (
                <span>
                  / {getEpisodesAmount(item.chapters)}{' '}
                  {getEpisodesAmount(item.chapters) === 1 ? 'Episode' : 'Episodes'}
                </span>
              )}
              {item.chapters && ' /'}{' '}
              {item.chapters && <span>{getCourseDuration(item)} {getCourseDuration(item) < 2 ? 'hour' : 'hours'} total time</span>}
            </SlideAdditionalInfo>
          )}
          {item.chapter?.course && (
            <SlideAdditionalInfo>{item.chapter?.course?.name}</SlideAdditionalInfo>
          )}
        </Box>
      </Fade>
    </Grid>
  );
};

export default React.memo(TitleAndDetails);
