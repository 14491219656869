import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Checkbox,
  IconButton,
  createStyles,
  Theme,
  makeStyles,
  Grow,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from 'react-router-dom';
import api from '../../../api/apiHandler';
import { useSessionActions } from '../../../state/session/useSessionActions';
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxRoot: {
      color: '#fff',
    },
  }),
);

const P2PMessageBox = ({ user }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl()

  const [showMessage, setShowMessage] = useState<any>(!user?.can_take_p2p);

  const [disableMessageBoxCheckbox, setDisableMessageBoxCheckbox] = useState(false);

  const { getCurrentProfile } = useSessionActions('getCurrentProfile');

  const handleCheckbox = () => {
    setDisableMessageBoxCheckbox(!disableMessageBoxCheckbox);
  };

  return (
    showMessage && (
      <Grow in={showMessage}>
        <Box style={{ background: '#101010', borderRadius: '5px' }} pb={4}>
          <Grid container>
            <Grid item xs={12}>
              <Box textAlign='right'>
                <IconButton
                  onClick={() => {
                    if (disableMessageBoxCheckbox) {
                      api.setP2PChecked().then((res) => getCurrentProfile());
                    }
                    setShowMessage(!showMessage);
                  }}
                >
                  <Clear color='primary' />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign='center'>
                <h2>{
                  intl.formatMessage({
                    id: 'Literal.Hey',
                    defaultMessage:
                      'Hey',
                  })
                } {user?.firstName}!</h2>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign='center' px={[3, 3, 5]}>
                <p>
                  {
                    intl.formatMessage({
                      id: 'P2PMessageBox.InstructionVideo.Caption',
                      defaultMessage:
                        'Did you watch the video we prepared to help you understand the goal of P2P Feedback? If not we encourage you to do so!',
                    })
                  }
                </p>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='center'>
                <Grid item xs={12} md={8}>
                  <Box mt={2} px={3}>
                    <Button
                      fullWidth
                      color='secondary'
                      variant='contained'
                      onClick={() => history.push('/watch/p2p-introduction')}
                    >
                      {
                        intl.formatMessage({
                          id: 'P2PMessageBox.Literal.WatchVideo',
                          defaultMessage:
                            'Watch video',
                        })
                      }
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='center'>
                <Grid item xs={12} md={8}>
                  <Box mt={1} px={3}>
                    <Button
                      fullWidth
                      color='primary'
                      variant='outlined'
                      onClick={() => {
                        if (!!user.company.p2pInstructionsFile) {
                          window.open(user.company.p2pInstructionsFile);
                        }
                      }}
                    >
                      {
                        intl.formatMessage({
                          id: 'P2PMessageBox.Literal.DownloadPDF',
                          defaultMessage:
                            'Download pdf',
                        })
                      }
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt={3} mx={3} display='flex' justifyContent='center' alignItems='center'>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkboxRoot}
                      checked={disableMessageBoxCheckbox}
                      onChange={handleCheckbox}
                      name='disable'
                    />
                  }
                  label={
                    intl.formatMessage({
                      id: 'P2PMessageBox.Literal.DontWantToSeeThisMessage',
                      defaultMessage:
                        "I don't want to see this message.",
                    })
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grow>
    )
  );
};

export default React.memo(P2PMessageBox);
