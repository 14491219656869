/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useEffect, useState } from 'react';

export interface VisibilityAdornmentProps {
  visible?: boolean;
  onChange: (e: { isVisible: boolean }) => void;
}

export const VisibilityAdornment = ({ visible = false, onChange }: VisibilityAdornmentProps) => {
  const [isVisible, setIsVisible] = useState(visible);

  const handleDown = () => {
    setIsVisible(true);
  };

  const handleUp = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (onChange) onChange({ isVisible });
  }, [isVisible, onChange]);

  return (
    <InputAdornment position='end'>
      <IconButton
        tabIndex={-1}
        onMouseUp={handleUp}
        onMouseDown={handleDown}
        onTouchStart={handleDown}
        onTouchEnd={handleUp}
      >
        {isVisible ? <Visibility color='primary' /> : <VisibilityOff color='primary' />}
      </IconButton>
    </InputAdornment>
  );
};
