import React from 'react';
import { useHistory } from 'react-router-dom';
import {Box, makeStyles, Typography, Grow, useTheme, useMediaQuery} from '@material-ui/core';
import { Courses } from 'state/views';
import { LargeButton } from 'common/components/General/Buttons';
import IconArrowDownLite from 'common/components/Icons/IconArrowDownLite';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  anchor: {
    marginTop: '36px',
    cursor: 'pointer',
    textAlign: 'center',
    textDecoration: 'none',
    color: '#FFFFFF',
  },
});

export const EpisodeExpandedComponent = ({
  nextEpisode,
  fullScreen,
}: {
  nextEpisode: Courses.IEpisodeDTO | null | any;
  fullScreen: boolean;
}) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grow in>
      <Box mt={[1, 1, 2]}>
        <LargeButton
          style={{height: isMedium ? '50px' : '30px', fontSize: isMedium ? '16px' : '12px'}}
          disabled={nextEpisode === null}
          color='secondary'
          variant='contained'
          onClick={() => {
            if (!!nextEpisode) {
              history.push(`/watch/episode/${nextEpisode.id}/autoplay`);
            }
          }}
        >
          <FormattedMessage
            id='VideoPlayer.EpisodeExpandedComponent.PlayNextEpisode.Caption'
            defaultMessage='Play Next Episode'
          />
        </LargeButton>
        {!fullScreen && (
          <a href='#isCurrentEpisode' className={classes.anchor}>
            <Typography style={{fontSize: isMedium ? '16px' : '12px'}}>
              <FormattedMessage
                id='VideoPlayer.EpisodeExpandedComponent.SeeMoreEpisodes.Label'
                defaultMessage='See more episodes'
              />
            </Typography>
            <Box mt={['12px', '12px', '24px']} width='24px' height='24px' display='inline-block'>
              <IconArrowDownLite />
            </Box>
          </a>
        )}
      </Box>
    </Grow>
  );
};
