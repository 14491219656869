import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { range } from 'utils/helpers';
import Slide from './Slide';
import ChallengeSlideCompleted from './Slide/Challenge/Completed';
import ChallengeSlideInProgress from './Slide/Challenge/InProgress';
import ChallengeSlideOpen from './Slide/Challenge/Open';
import CourseSlideCompleted from './Slide/Course/Completed';
import CourseSlideOpen from './Slide/Course/Open';
import CourseSlideRecommended from './Slide/Course/Reommended';
import CourseSlidePrimary from './Slide/Course/Primary';
import CourseSlideSecondary from './Slide/Course/Secondary';
import CourseSlideWithChallenges from './Slide/Course/WithChallenges';
import EpisodeSlide from './Slide/Episode';
import EpisodeToContinue from './Slide/Episode/EpisodeToContinue';
import './slider.scss';
import SliderArrow from './sliderArrow';
import {
  CenteredTitleDecorationLeft,
  CenteredTitleDecorationRight,
  SliderTitle,
  TitleWrapper,
} from './styledComponents';
import SlideHelperVideo from './Slide/HelperVideos/HelperVideos';

// TODO: uncoment and finish slides disapear animiation
// import gdiff from 'generic-diff';
// import styles from './slider.module.scss';

const getSliderComponent = (sliderType: Slides.SliderType, item: any) => {
  switch (sliderType) {
    //Episodes
    case 'episodes':
      return EpisodeSlide;
    case 'episodes-to-continue':
      return EpisodeToContinue;

    //Courses
    case 'courses-primary':
      return CourseSlidePrimary;
    case 'courses-secondary':
      return CourseSlideSecondary;
    case 'courses-with-challenges':
      return CourseSlideWithChallenges;
    case 'courses-completed':
      return CourseSlideCompleted;
    case 'courses-open':
      return CourseSlideOpen;
    case 'courses-recommended':
      return CourseSlideRecommended;

    case 'helper-videos':
      return SlideHelperVideo;

    //Challenges
    case 'challenges-in-progress':
      return ChallengeSlideInProgress;
    case 'challenges-completed':
      return ChallengeSlideCompleted;
    case 'challenges-open':
      return ChallengeSlideOpen;
    case 'challenges-mixed':
      const submission = item.submission?.status;

      switch (submission) {
        case 'TAKEN':
          return ChallengeSlideInProgress;
        case 'COMPLETED':
          return ChallengeSlideCompleted;
        case undefined:
          return ChallengeSlideOpen;
        default:
          return ChallengeSlideOpen;
      }

    default:
      return Slide;
  }
};

const SlickCarousel = ({
  dots = false,
  infinite,
  sliderTitle,
  withIcon,
  Icon,
  titleCentered = false,
  titleBright = false,
  slidesToShow,
  slidesToScroll,
  speed,
  sliderTypeName,
  sliderType,
  nothingToShowMessage = 'Nothing to show',
  isCurrent = false,
  initialSlide = 0,
  shouldShowActionButton = true,
  hiddenTitle = false,
  ...rest
}: Slides.SlickCarouselProps) => {
  const defaultVisible = range(0, slidesToShow - 1, 1);
  const [viewed, setViewed] = useState(0);
  const [isOnTop, setIsOnTop] = useState(false);
  const [highlightedSlides, setHighlightedSlides] = useState<number[]>([]);
  const [visibleSlideIndexes, setVisibleSlidesIndexes] = useState(defaultVisible);
  // const [localItems, setLocalItems] = useState<any[]>([]);

  const localItems = rest.items || []

  // useEffect(() => {
  //   let items = rest.items || [];
  //
  //   // TEMP: REMOVE SHORTCUT
  //   setLocalItems(items);
  //   return;
  //   // end

    // let clean = localItems.filter((i) => !!!i.removed);
    // let changes = gdiff(clean.map((x) => x.id) as string[], items.map((x) => x.id) as String[]);
    // if (
    //   changes.length === 0 ||
    //   !changes.some((c) => {
    //     return !!c.added || !!c.removed;
    //   })
    // ) {
    //   return;
    // }
    // let newLocalItems: any[] = [];
    // changes.forEach(({ items: ids, added, removed }) => {
    //   let sourceCollection = !!removed ? clean : items;
    //   let $_items = ids.map((id) => ({
    //     ...sourceCollection.find((x) => x.id === id),
    //     removed,
    //     added,
    //   }));
    //   newLocalItems.push(...$_items);
    // });
    // setLocalItems(newLocalItems);
  // }, [localItems, rest.items]);

  // useEffect(() => {
  //   const indexes = range(viewed, viewed + slidesToShow - 1, 1);
  //
  //   console.log(indexes)
  //
  //   setVisibleSlidesIndexes(indexes);
  // }, [slidesToShow, viewed]);

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  const settings: Settings = {
    dots: dots,
    infinite: infinite,
    speed: speed,
    // lazyLoad: 'progressive',
    arrows: isLarge ? isOnTop : false,
    className: cx('videos-slider'),
    slidesToShow: isLarge ? slidesToShow : 1,
    slidesToScroll: isLarge ? slidesToScroll : 1,
    initialSlide: isLarge ? (initialSlide <= slidesToShow ? 0 : initialSlide) : initialSlide,
    afterChange: (viewedSlides: any) => {
      const indexes = range(viewedSlides, viewedSlides + slidesToShow - 1, 1);
      setVisibleSlidesIndexes(indexes);
    },
    nextArrow: <SliderArrow to='next' />,
    prevArrow: <SliderArrow to='prev' />,
    adaptiveHeight: false,
  };

  return (
    <div
      onMouseOver={() => setIsOnTop(true)}
      onMouseLeave={() => setIsOnTop(false)}
      style={{
        position: 'relative',
        zIndex: isOnTop ? 2 : 1,
      }}
    >
      {!hiddenTitle && (
        <Box position='relative' mb={2}>
          <SliderTitle
            // centered={titleCentered || localItems.length < slidesToShow}
            centered
            bright={titleBright}
          >
            {withIcon && <div style={{ marginRight: '10px' }}>{Icon}</div>}
            {titleCentered && <CenteredTitleDecorationLeft />}
            <TitleWrapper>{sliderTitle}</TitleWrapper>
            {titleCentered && <CenteredTitleDecorationRight />}
          </SliderTitle>
        </Box>
      )}
      {localItems.length > 0 ? (
        <Slider {...settings}>
          {localItems.map((item: any, index: number) => {
            const Component = getSliderComponent(sliderType, item);

            return (
              <Component
                isCurrent={isCurrent}
                sliderTitle={sliderTitle}
                sliderType={sliderType}
                sliderTypeName={sliderTypeName}
                highlightedSlidesIndexes={rest.items}
                setHighlightedSlidesIndexes={setHighlightedSlides}
                shouldShowActionButton={shouldShowActionButton}
                key={item.id}
                visibleSlideIndexes={visibleSlideIndexes}
                isInfinite={!!infinite}
                noSlidesToSwipe={localItems.length <= slidesToShow}
                item={item}
                index={index}
              />
            );
          })}
        </Slider>
      ) : (
        <Box
          pt={6}
          pb={6}
          display='flex'
          justifyContent='center'
          alignItems='center'
          style={{ backgroundColor: '#101010' }}
        >
          <Grid container justify='center'>
            <Grid item xs={11} md={5} lg={3}>
              <Box
                minHeight='10rem'
                textAlign='center'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                {nothingToShowMessage}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default React.memo(SlickCarousel);
