import React from 'react';
import { Fade } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const MyFavorites = () => {
  return (
    <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Fade in timeout={2000}>
        <h1>
          <FormattedMessage id='MyFavorites.Header' defaultMessage='These are my favorites.' />
        </h1>
      </Fade>
    </div>
  );
};

export default React.memo(MyFavorites);
