import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '@media (hover: none)': {
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& label,label.MuiFormLabel-root.Mui-disabled': {
    color: theme.palette.primary.main,
  },
  '& input': {
    color: theme.palette.primary.main,
  },
  '& input.Mui-disabled': {
    color: theme.palette.primary.light,
  },
  '& .Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiFormHelperText-root': {
    margin: '3px 0px 6px',
  },
  '& .MuiInputAdornment-positionEnd': {
    '& svg': { color: '#5f5f5f' },
  },
}));

export const StyledLabeledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiInputLabel-shrink': {
    top: '-26px',
    fontSize: '14px',
    // left: '-13px',
    color: theme.palette.primary.light,
    transform: 'none',
  },
  '& legend': {
    display: 'none',
  },
  '& label,label.MuiFormLabel-root.Mui-disabled': {
    color: theme.palette.primary.light,
  },
  '& input': {
    color: theme.palette.primary.main,
  },
  '& input.Mui-disabled': {
    color: theme.palette.primary.light,
  },
  '& .Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiFormHelperText-root': {
    margin: '3px 0px 6px',
  },
}));

export const EditorTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderTop: `${theme.palette.grey[800]} 2px solid`,
    borderRadius: '0px',
  },
  '& .MuiInputLabel-shrink': {
    top: '-26px',
    fontSize: '14px',
    // left: '-13px',
    color: theme.palette.primary.light,
    transform: 'none',
  },
  '& legend': {
    display: 'none',
  },
  '& label,label.MuiFormLabel-root.Mui-disabled': {
    color: theme.palette.primary.light,
  },
  '& input,textarea': {
    //padding: '4px 1px 0px 8px',
    padding: '17px 0px 15px 12px',
    margin: '0 0 25px',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.primary.main,
    '&.Mui-disabled': {
      backgroundColor: (props: any) =>
        props.disabledDarkVariant ? theme.palette.grey[900] : theme.palette.grey[600],
      padding: (props: any) => (props.disabledDarkVariant ? '8px 0px 6px' : '17px 0px 15px 12px'),
    },
  },
  '& .MuiOutlinedInput-multiline': {
    padding: '0px',
  },
  '& input.Mui-disabled': {
    // color: theme.palette.primary.light,
  },
  '& .Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderTop: `solid 2px ${theme.palette.grey[600]}`,
  },
  '& .MuiFormHelperText-root': {
    margin: '-22px 0px 6px',
  },
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '0px',
  },
  '& .MuiInputAdornment-positionEnd': {
    marginLeft: '-48px',
    marginTop: '-26px',
    '& svg': { color: '#5f5f5f' },
  },
}));

interface TextFieldAdapterProps {
  input: any;
  meta: any;
  component?: React.ComponentType<TextFieldProps>;
  rest?: any;
}

export const TextFieldAdapter = ({ input, meta, component, ...rest }: TextFieldAdapterProps) => {
  const Compomemt = component ? component : TextField;
  return (
    <Compomemt
      {...rest}
      {...input}
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
    />
  );
};

export const StyledTextFieldAdapter = (props: TextFieldAdapterProps) => {
  return <TextFieldAdapter component={StyledTextField} {...props} />;
};

export const StyledLabeledTextFieldAdapter = (props: TextFieldAdapterProps) => {
  return <TextFieldAdapter component={StyledLabeledTextField} {...props} />;
};

export const EditorTextFieldAdapter = (props: TextFieldAdapterProps) => {
  return <TextFieldAdapter component={EditorTextField} {...props} />;
};
