/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Box, IconButton, useMediaQuery, useTheme } from '@material-ui/core';

import clipIcon from './clip.svg';
import CloseIcon from '@material-ui/icons/Close';
import { FileDescriptor } from 'common/components/Uploader';

interface UploaderFilesListProps {
  filesDescriptors: FileDescriptor[];
  onDelete: (index: number) => void;
  displayOnly?: boolean;
  allowDownload?: boolean;
  disabled?: boolean;
}

export const UploaderFilesList = ({
  filesDescriptors,
  onDelete,
  displayOnly = false,
  allowDownload = false,
  disabled = false,
}: UploaderFilesListProps) => {
  const theme = useTheme();

  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  //const bounded = useSessionActions('downloadFile');

  const handleDownloadFile = (desc: FileDescriptor) => {
    if (desc.fileName && desc.url) {
      // bounded.downloadFile({ url: desc.url, fileName: desc.fileName }).catch((error) => {
      //   console.log('Error while downloading file', error);
      // });
      window.open(desc.url);
    }
  };

  return (
    <React.Fragment>
      {filesDescriptors?.length > 0 && filesDescriptors.map((descriptor, i: number) => {
        const parts = descriptor.fileName.split('.');
        const ext = parts.pop();
        const name = parts.join('.');

        return (
          <React.Fragment key={descriptor.id}>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                borderBottom:
                  descriptor.isOversized && !displayOnly ? 'none' : 'solid 1px #ffffffa6',
                backgroundColor: descriptor.isOversized && !displayOnly ? '#5d181c' : 'transparent',
              }}
            >
              <img
                src={clipIcon}
                css={{
                  height: isMedium ? '40px' : '20px',
                }}
                alt=''
              />
              <Box
                onClick={(displayOnly || allowDownload) ? () => handleDownloadFile(descriptor) : undefined}
                fontSize={['13px', '15px', '16px']}
                css={{
                  color: '#ffffffa6',
                  fontWeight: 300,
                  marginLeft: '16px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  cursor: (displayOnly || allowDownload) && descriptor.url ? 'pointer' : 'auto',
                }}
              >
                {name}
              </Box>
              <Box fontSize={['13px', '15px', '16px']} css={{ color: '#ffffffa6', fontWeight: 300 }}>{`.${ext}`}</Box>
              {!displayOnly && (
                <IconButton
                  disabled={disabled}
                  onClick={() => {
                    onDelete(i);
                  }}
                  css={{ width: '40px', height: '40px' }}
                >
                  <CloseIcon css={{ color: '#FFFFFFa6' }} />
                </IconButton>
              )}
            </div>
            {descriptor.isOversized && !displayOnly && (
              <div
                css={{
                  fontSize: '12px',
                  color: theme.palette.error.dark,
                  margin: '3px 0px 13px',
                }}
              >
                This file is too big. The maximum acceptable file size is 500MB.{' '}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
