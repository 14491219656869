/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Badge, useTheme } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import uploadIcon from './upload.svg';

interface UploaderButtonProps {
  disabled?: boolean;
  more: boolean;
}

export const UploaderButton = ({ more, disabled = false }: UploaderButtonProps) => {
  const theme = useTheme();
  return (
    <div
      css={{
        backgroundColor: 'transparent',
        width: '100%',
        border: 'none',
        textAlign: 'left',
        marginTop: '40px',
        cursor: disabled ? 'auto' : 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Badge
        overlap='circle'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={more ? <AddCircleIcon css={{ height: '18px' }} /> : null}
      >
        <img src={uploadIcon} css={{ height: '40px', verticalAlign: 'middle' }} alt='' />
      </Badge>
      <div
        css={{
          color: theme.palette.primary.light,
          display: 'inline-block',
          verticalAlign: 'middle',
          fontWeight: 300,
          marginLeft: '16px',
        }}
      >
        {more
          ? 'Add more files ( .jpg, .pdf, .doc - max. 10MB; .mp4 max. 500MB)'
          : 'Upload a file ( .jpg, .pdf, .doc - max 10MB; .mp4 max. 500MB)'}
      </div>
    </div>
  );
};
