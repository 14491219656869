/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box } from '@material-ui/core';
import { debounce, flatten } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { Courses } from 'state/views';
import { useViewsActions } from 'state/views/useViewsActions';
import { ChaptersAccordions } from 'views/Components/ChaptersAccordions';
import VideoPlayer from 'views/VideoPlayer';
import { Player } from 'views/VideoPlayer/player';
import { getChallengeById, getEpisodeById } from 'views/VideoPlayer/utils';
import { ChallengeInfoBox } from './ChallengeInfoBox';
import { EpisodeExpandedComponent } from './EpisodeExpandedComponent';
import { EpisodeInfoBox } from './EpisodeInfoBox';

const EpisodePlayer = ({ episodeId, autoplay }: { episodeId: string; autoplay: boolean }) => {
  const intl = useIntl();
  const history = useHistory();
  const [expanded, setExpanded] = useState(localStorage.getItem('expandedChapter') || '');
  const [fullScreen, setFullScreen] = useState(false);

  const { course, chapter, episode } = useSelector((state: RootState) => {
    return getEpisodeById(episodeId, state.views.courses);
  }, shallowEqual);

  const { setEpisodeProgress, getMyCoursesData, getHomeData } = {
    ...useSessionActions('setEpisodeProgress'),
    ...useViewsActions('getMyCoursesData', 'getHomeData'),
  };

  const submitProgressDebounced = useCallback(
    debounce(
      (e: Player.ProgressChangeEvent) => {
        if (course && chapter && episode) {
          setEpisodeProgress({
            course: course.id,
            chapter: chapter.id,
            watchable: episode.id,
            progressPercent: e.progressPercent,
          }).catch((error) => console.warn('Error while updating progress', error));
        }
      },
      15000,
      { maxWait: 15000, leading: false },
    ),
    [episodeId],
  );

  useEffect(() => {
    return () => {
      submitProgressDebounced.flush();
    };
  }, [submitProgressDebounced]);

  const handleProgressChange = useCallback(
    (e: Player.ProgressChangeEvent) => {
      submitProgressDebounced(e);
    },
    [submitProgressDebounced],
  );

  const [initialTime] = useState(getInitialTime(episode));

  let episodeToContinueWithChapterId = {
    chapterId: '',
  };

  if (episodeId && course) {
    course.chapters.forEach((chapter: any) => {
      chapter.challenges.forEach((episode: any) => {
        if (episode.id === episodeId) {
          episodeToContinueWithChapterId = {
            ...episode,
            chapterId: chapter.id,
            chapterOrdinal: chapter.ordinal,
            courseId: course.id,
          };
        }
      });
      chapter.episodes.forEach((episode: any) => {
        if (episode.id === episodeId) {
          episodeToContinueWithChapterId = {
            ...episode,
            chapterId: chapter.id,
            chapterOrdinal: chapter.ordinal,
            courseId: course.id,
          };
        }
      });
    });
  }

  useEffect(() => {
    if (expanded !== episodeToContinueWithChapterId.chapterId) {
      localStorage.setItem('expandedChapter', episodeToContinueWithChapterId.chapterId);
      setExpanded(episodeToContinueWithChapterId.chapterId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  let nextEpisode = useMemo(() => getNextEpisode(course, chapter, episode), [
    chapter,
    course,
    episode,
  ]);

  let challengeDetails: Challenges.IChallengeDTO | Courses.IChallengeDTO | null = useSelector(
    (state: RootState) => (!!episode && episode.hasOwnProperty('submission') ? episode : null),
    shallowEqual,
  );

  if (!!episode && episode.challenge && challengeDetails === null) {
    // debugger;
  }

  if (!(course && chapter && episode)) {
    return <Redirect to='/main/my-courses' />;
  }

  const genericOverlayProps = {
    description: course.description,
    course,
    caption: episode.hasOwnProperty('submission')
      ? `Challenge / ${intl.formatMessage({
          id: 'Literal.Chapter',
          defaultMessage: 'Chapter',
          description: 'Literally Chapter word or its equivalent',
        })} ${String(chapter.ordinal).padStart(2, '0')}`
      : `${intl.formatMessage({
          id: 'Literal.Episode',
          defaultMessage: 'Episode',
          description: 'Literally word Episode or its equivalent',
        })} ${String(episode.ordinal).padStart(2, '0')} / ${intl.formatMessage({
          id: 'Literal.Chapter',
          defaultMessage: 'Chapter',
          description: 'Literally Chapter word or its equivalent',
        })} ${String(chapter.ordinal).padStart(2, '0')}`,
    name: episode.title,
    withShowNextEpisodes: true,
    challenge: challengeDetails,
    withBackButton: true,
    ExpandedElement: <EpisodeExpandedComponent nextEpisode={nextEpisode} fullScreen={fullScreen} />,
    backButtonLabel: intl.formatMessage({
      id: 'EpisodePlayer.BackButton.Caption.CourseOverview',
      defaultMessage: 'COURSE OVERVIEW',
    }),
    onBackClick: () => {
      history.replace(`/main/my-courses/${course.id}`, {});

      getMyCoursesData();
      getHomeData();
    },
  };

  const playerProps = {
    play: autoplay,
    src: course.joined
      ? episode.video?.videoId
      : !!episode.challenge
      ? episode.video?.videoId
      : episode.trailerVideo?.videoId,
    initialProgress: initialTime,
    onProgressChange: handleProgressChange,
  };

  return (
    <div id='__episodePlayerContainer'>
      {episode.hasOwnProperty('submission') ? (
        <VideoPlayer
          {...playerProps}
          coverImage={episode.desktopCoverImage?.url}
          type={'challenge'}
          OverlayProps={{ ...genericOverlayProps, InfoBoxComponent: ChallengeInfoBox }}
        />
      ) : (
        <VideoPlayer
          {...playerProps}
          coverImage={episode.desktopCoverImage?.url}
          type={'episode'}
          OverlayProps={{ ...genericOverlayProps, InfoBoxComponent: EpisodeInfoBox }}
          endedTreshold={2}
          onFullScreenChange={({ fullScreen }) => setFullScreen(fullScreen)}
        />
      )}

      <Box mt={[2, 2, 8]}>
        <ChaptersAccordions
          course={course}
          currentEpisodeId={episodeId}
          defaultExpanded={expanded}
        />
      </Box>
    </div>
  );
};

function getNextEpisode(
  course: Courses.ICourseDTO | null,
  chapter: Courses.IChapterDTO | null,
  episode: Courses.IEpisodeDTO | null,
) {
  if (course && chapter && episode) {
    let flatEpisodes = flatten(
      flatten(course.chapters).map((c) => {
        return [...c.episodes, ...c.challenges];
      }),
    );
    // @ts-ignore
    let index = flatEpisodes.findIndex((e) => e.id === episode.id);
    if (flatEpisodes.length > index + 1) {
      return flatEpisodes[index + 1];
    }
  }
  return null;
}

function getInitialTime(episode: Courses.IEpisodeDTO | null) {
  const progress = episode?.progress && parseFloat(episode.progress);
  const initialTime =
    !isNaN(progress as any) && episode?.video?.duration && !isNaN(episode?.video?.duration as any)
      ? episode?.video?.duration * (progress as number)
      : 0;
  return initialTime;
}

export default React.memo(EpisodePlayer);
