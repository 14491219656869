import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import { scrollToTopSmooth } from 'common/utils';
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { createTabIndicatorStyles, linkTabStyles, StyledTabs } from './styles';
import { tabsConfig } from './tabsConfig';
import {useIntl} from "react-intl";

const NavTabs = () => {
  const {
    setActiveTab,
    setCourseOverview,
    setDependencyNavBarMounted,
  } = {
    ...useSessionActions('setActiveTab', 'setCourseOverview', 'setDependencyNavBarMounted'),
  };
  const history = useHistory();
  const intl = useIntl()
  const location = useLocation();
  const activeTab = useSelector((state: RootState) => state.session.config.activeTab, shallowEqual);

  const getFormattedTabLabel = (name: string) => {
    if (name === 'Home') {
      return intl.formatMessage({
        id: 'NavTabs.Home',
        defaultMessage: `Home`,
      })
    } else if (name === 'MyChallenges') {
      return intl.formatMessage({
        id: 'NavTabs.MyChallenges',
        defaultMessage: `My Challenges`,
      })
    } else if (name === 'MyCourses') {
      return intl.formatMessage({
        id: 'NavTabs.MyCourses',
        defaultMessage: `My Courses`,
      })
    } else if (name === 'MySessions') {
      return intl.formatMessage({
        id: 'NavTabs.MySessions',
        defaultMessage: `My Sessions`,
      })
    }
  }

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setCourseOverview(false);
    if (newValue !== activeTab) {
      scrollToTopSmooth();
      setActiveTab(newValue);
    }
  };

  useEffect(() => {
    setDependencyNavBarMounted(true);
    return () => {
      setDependencyNavBarMounted(false);
    };
  }, [setDependencyNavBarMounted]);

  useEffect(() => {
    const tabId = Object.values(tabsConfig).find((tab) => location.pathname.includes(tab.path))?.id;
    activeTab !== tabId && setActiveTab(tabId || 0);
  }, [activeTab, location, setActiveTab]);

  const tabIndicatorStyles = createTabIndicatorStyles(activeTab);

  return (
    <AppBar position='sticky'>
      <StyledTabs
        className='navBarTabs'
        variant={window.innerWidth > 1000 ? 'fullWidth' : 'scrollable'}
        value={activeTab < 100 ? activeTab : false}
        onChange={handleChange}
        aria-label='nav tabs'
        TabIndicatorProps={{ style: tabIndicatorStyles, className: 'tabIndicator' }}
        scrollButtons='auto'
        selectionFollowsFocus
      >
        {Object.values(tabsConfig)
          .filter((v) => v.id < 100)
          .map((tab) => (
            <Tab
              key={tab.id}
              disableRipple
              className='navBarLink'
              label={tab.name ? getFormattedTabLabel(tab.name) : tab.label}
              disabled={tab.disabled}
              onClick={() => {
                history.push(`/main/${tab.path}`);
              }}
              style={{
                ...linkTabStyles,
                minWidth: '160px',
                userSelect: 'none',
                color: activeTab === tab.id ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)',
              }}
            />
          ))}
      </StyledTabs>
      <Box className='notificationsBar' width='100%' height='0px' position='relative' />
    </AppBar>
  );
};

export default React.memo(NavTabs);
