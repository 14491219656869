import React from 'react';
import SlideComponent, { SlideComponentProps } from '../index';

const CourseSlidePrimary = ({ ...props }: SlideComponentProps) => {
  return <SlideComponent
    withDescription
    withVideo
    withArrow
    withActionFab
    {...props}
  />;
};

export default React.memo(CourseSlidePrimary);
