import React from 'react'
import {
  validatePasswordHasLowerLetter,
  validatePasswordHasSpecialCharacterOrDigit, validatePasswordHasUpperLetter,
  validatePasswordLength
} from "../../../utils/form-validation";
import {jsx} from "@emotion/core";
import {useTheme} from "@material-ui/core/styles";

const PasswordRequirements = ({password}: {password: string}) => {

  const theme = useTheme()

  return (
    <div>
      <p style={{
        fontSize: '12px',
        marginTop: '25px',
        fontWeight: 700,
        color: theme.palette.primary.light
      }}>Password must contain:</p>
      <p
        style={{
          fontSize: '12px',
          margin: '5px 0',
          color: password
            ? validatePasswordLength(password)
              ? '#f44336'
              : '#45C345'
            : theme.palette.primary.light
        }}
      >
        -at least 8 characters
      </p>
      <p
        style={{
          fontSize: '12px',
          margin: '5px 0',
          color: password
            ? validatePasswordHasSpecialCharacterOrDigit(password)
              ? '#f44336'
              : '#45C345'
            : theme.palette.primary.light
        }}
      >
        -at least one special character or digit
      </p>
      <p
        style={{
          fontSize: '12px',
          margin: '5px 0',
          color: password
            ? validatePasswordHasLowerLetter(password)
              ? '#f44336'
              : '#45C345'
            : theme.palette.primary.light
        }}
      >
        -at least one lowercase letter
      </p>
      <p
        style={{
          fontSize: '12px',
          margin: '5px 0',
          color: password
            ? validatePasswordHasUpperLetter(password)
              ? '#f44336'
              : '#45C345'
            : theme.palette.primary.light
        }}
      >
        -at least one uppercase letter
      </p>
    </div>
  )
}

export default PasswordRequirements
