import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import api, { SetProgressPayload } from 'api/apiHandler';

//#region action types
const GET_REGISTRATION_TOKEN = 'GET_REGISTRATION_TOKEN';

const POST_NEW_USER_PASSWORD = 'POST_NEW_USER_PASSWORD';

const POST_USER_IDENTITY = 'POST_USER_IDENTITY';

const SET_USER_CONSENT = 'SET_USER_CONSENT';

const GET_FAQ_DATA = 'GET_FAQ_DATA';

const POST_CONTACT_US_DATA = 'POST_CONTACT_US_DATA';
const GET_CURRENT_PROFILE = 'GET_CURRENT_PROFILE';
const SET_EPISODE_PROGRESS = 'SET_EPISODE_PROGRESS';
const POST_UPDATE_PROFILE = 'POST_UPDATE_PROFILE';
const LOG_OUT = 'LOG_OUT';
const POST_CHANGE_PASSWORD = 'POST_CHANGE_PASSWORD';
const CHECK_RESET_PASSWORD_TOKEN = 'CHECK_RESET_PASSWORD_TOKEN';
const POST_NEW_PASSWORD = 'POST_NEW_PASSWORD';
const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
const SET_DEPENDENCY_NAVBAR_MOUNTED = 'SET_DEPENDENCY_NAVBAR_MOUNTED';

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const GET_UNREADED_NOTIFICATIONS = 'GET_UNREADED_NOTIFICATIONS';
const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
const POST_ALLOW_EMAIL_NOTIFICATION_FLAG = 'POST_ALLOW_EMAIL_NOTIFICATION_FLAG';
const POST_REQUEST_PASSWORD_RESET = 'POST_REQUEST_PASSWORD_RESET';
const INVALIDATE_SESSION = 'INVALIDATE_SESSION';

//#endregion

export type UserConsents = 'hasCookiesConsent';

export const setUserConsent = createAction(
  SET_USER_CONSENT,
  (consent: UserConsents, value: boolean) => {
    return { payload: { consent, value } };
  },
);

export const registerPassword = createAsyncThunk(
  POST_NEW_USER_PASSWORD,
  async (identity: IUserIdentity) => {
    return await api.postNewPassword(identity);
  },
);

export const loginUser = createAsyncThunk(POST_USER_IDENTITY, async (identity: IUserIdentity) => {
  return await api.logInUser(identity);
});

export const getRegistrationToken = createAsyncThunk(
  GET_REGISTRATION_TOKEN,
  async (token: string) => {
    return await api.getRegistrationToken(token);
  },
);

export const setActiveTab = createAction('setActiveTab', (tabId: number) => ({
  payload: tabId,
}));

export const setLoginDialog = createAction('set-flag-openLoginDialog', (flag: boolean) => ({
  payload: flag,
}));

export const setCourseOverview = createAction(
  'extend-flag-openCourseOverview',
  (flag: boolean, avoidReset = false) => ({
    payload: { flag, avoidReset },
  }),
);

export const setViewProfileDialog = createAction(
  'set-flag-openViewProfileDialog',
  (flag: boolean) => ({
    payload: flag,
  }),
);

export const setEditProfileDialog = createAction(
  'set-flag-openEditProfileDialog',
  (flag: boolean) => ({
    payload: flag,
  }),
);

export const setResetPasswordDialog = createAction(
  'set-flag-openResetPasswordDialog',
  (flag: boolean) => ({
    payload: flag,
  }),
);

export const setOpenAvatarMenu = createAction('set-flag-openAvatarMenu', (flag: boolean) => ({
  payload: flag,
}));

export const switchOpenAvatarMenu = createAction('switchOpenAvatarMenu', () => ({ payload: null }));

export const setOpenNotificationsMenu = createAction(
  'set-flag-openNotificationsMenu',
  (flag: boolean) => ({
    payload: flag,
  }),
);

export const switchOpenNotificationMenu = createAction('switchOpenNotificationMenu', () => ({
  payload: null,
}));

export const switchOpenLiveChallengeDialog = createAction('switchOpenLiveChallengeDialog', () => ({
  payload: null,
}));

export const setNewPasswordDialog = createAction(
  'extended-flag-openSetNewPassword',
  (flag: boolean, token: string | null) => ({
    payload: { flag, token },
  }),
);

export const openConfirmationDialog = createAction(
  'openConfirmationDialog',
  (confirmationDialog: ConfirmationDialogProps) => {
    return {
      payload: {
        isVisible: true,
        modalType: confirmationDialog.modalType,
        modalProps: confirmationDialog.modalProps,
      },
    };
  },
);

export const closeConfirmationDialog = createAction('closeConfirmationDialog', () => {
  return {
    payload: {
      isVisible: false,
    },
  };
});

export const openSlideNotification = createAction(
  'openSlideNotification',
  (slideNotification: SlideNotificationProps) => {
    return {
      payload: {
        isVisible: true,
        id: slideNotification.id,
        message: slideNotification.message,
        actionLabel: slideNotification.actionLabel,
        isError: !!slideNotification.isError ? true : false,
        action: slideNotification.action,
      },
    };
  },
);

export const pushSnack = createAction('pushMessageSnack', (snack: SnackNotification) => ({
  payload: { ...snack, timestamp: new Date().toISOString() },
}));

export const pushMessageSnack = (
  snack: Omit<SnackNotification, 'isError' | 'timeout' | 'action' | 'actionLabel' | 'timestamp'> &
    Partial<Pick<SnackNotification, 'timeout'>>,
) =>
  pushSnack({
    ...snack,
    isError: false,
    timeout: snack.timeout !== undefined ? snack.timeout : 3000,
  });

export const pushErrorSnack = (
  snack: Omit<SnackNotification, 'isError' | 'timeout' | 'action' | 'actionLabel' | 'timestamp'> &
    Partial<Pick<SnackNotification, 'timeout'>>,
) =>
  pushSnack({
    ...snack,
    isError: true,
    timeout: snack.timeout !== undefined ? snack.timeout : 3000,
  });

export const clearSnackChannel = createAction('clearSnackChannel', (channel: string) => ({
  payload: channel,
}));

export const closeSlideNotification = createAction('closeSlideNotification', () => ({
  payload: {
    isVisible: false,
  },
}));

export const fetchFaq = createAsyncThunk(GET_FAQ_DATA, async () => await api.getFAQ());
export const postContactUsData = createAsyncThunk(POST_CONTACT_US_DATA, async (data: any) => {
  return await api.postContactUsData(data);
});

export const getCurrentProfile = createAsyncThunk(
  GET_CURRENT_PROFILE,
  async () => await api.getCurrentProfile(),
);

export const setEpisodeProgress = createAsyncThunk(
  SET_EPISODE_PROGRESS,
  async (data: SetProgressPayload) => {
    return await api.setEpisodeProgress(data);
  },
);

export const postUpdateProfile = createAsyncThunk(
  POST_UPDATE_PROFILE,
  async (profile: { identity: IIdentity; avatarForm: FormData | null }, thunkApi) => {
    await api.updateIdentity(profile.identity).catch((error) => {
      console.log('Update profile identity error', error);
      throw new Error('Error while updating profile');
    });

    if (profile.avatarForm) {
      await api.updateAvatar(profile.avatarForm).catch((error) => {
        console.log('Update profile avatar error', error);
        throw new Error('Profile data saved, although error occured when updating avatar');
      });
    }

    await thunkApi.dispatch(getCurrentProfile()).catch((error) => {
      console.log('Error while fetching updated profile', error);
    });

    return true;
  },
);

export const logOut = createAction(LOG_OUT, () => ({ payload: null }));

export const invalidateSession = createAsyncThunk(
  INVALIDATE_SESSION,
  async (refreshToken: string) => await api.invalidateSession(refreshToken)
);

export const postRequestPasswordReset = createAsyncThunk(
  POST_REQUEST_PASSWORD_RESET,
  async (email: string) => await api.postResetPasswordRequest(email),
);

export const postChangePassword = createAsyncThunk(
  POST_CHANGE_PASSWORD,
  async (passwords: IPasswordChangeDTO) =>
    await api.postChangePassword(passwords).catch((error) => {
      if (!!error?.response?.data?.errors?.current) {
        throw new Error('> current');
      } else {
        throw error;
      }
    }),
);

export const resetFlags = createAction('resetFlags', () => ({ payload: null }));

export const checkResetPasswordToken = createAsyncThunk(
  CHECK_RESET_PASSWORD_TOKEN,
  async (token: string) => await api.postResetPasswordTokenCheck(token),
);

export const postNewPassword = createAsyncThunk(
  POST_NEW_PASSWORD,
  async ({ token, password }: INewPassword) =>
    await api.postSetNewPasswordWithToken(token, password),
);

export const downloadFile = createAsyncThunk(
  DOWNLOAD_FILE,
  async ({ url, fileName }: { url: string; fileName: string }) => {
    return await api.downloadFile(url, fileName);
  },
);

export const setDependencyNavBarMounted = createAction(
  SET_DEPENDENCY_NAVBAR_MOUNTED,
  (flag: boolean) => {
    return { payload: { flag } };
  },
);

export const getNotifications = createAsyncThunk(
  GET_NOTIFICATIONS,
  async ({ olderThan }: { olderThan?: Date }) => {
    return await api.getNotifications(olderThan);
  },
);

export const getUnreadedNotifications = createAsyncThunk(
  GET_UNREADED_NOTIFICATIONS,
  async () => await api.getUnreadedNotificationsCount(),
);

export const registerNotification = createAction(
  'registerNotification',
  (notification: INotification) => ({ payload: notification }),
);
export const markNotificationAsRead = createAsyncThunk(
  MARK_NOTIFICATION_AS_READ,
  async ({ notificationId }: { notificationId: string }) => {
    return await api.markNotificationAsRead(notificationId);
  },
);

export const postAllowEmailNotificationFlag = createAsyncThunk(
  POST_ALLOW_EMAIL_NOTIFICATION_FLAG,
  async (flag: boolean) => await api.postAllowEmailNotification(flag),
);

export const getLanguages = createAsyncThunk('GET_LANGUAGES', async () => await api.getLanguages());

export const setLanguage = createAsyncThunk(
  'SET_LANGUAGE',
  async ({ langId }: { langId: string }) => {
    return await api.setLanguage(langId);
  },
);

export const pushNotification = createAction(
  'pushNotification',
  (notification: INotification | null) => ({ payload: { notification } }),
);
