import { createReducer } from '@reduxjs/toolkit';
import {
  clearCurrentLiveChallenge,
  getChallenges,
  getCurrentLiveChallenge,
  getFeedbacksCreatedByMeData,
  setFeedbackChallenge,
  setLocalChallengeSubmissionState,
  setSubmitChallengeDialog,
} from 'state/challenges/actions';
import { getChallengeById } from 'views/VideoPlayer/utils';

const initialState: Challenges.ChallengesState = {
  loading: false,
  items: {
    inProgress: [],
    completed: [],
    open: [],
    live: [],
  },
  currentLiveChallenge: null,
  feedbacksCreatedByMe: [],
  submission: {
    challengeItem: null,
    loadedAt: null,
  },
  p2pFeedback: {
    challengeItem: null,
  },
  feedback: {
    challengeItem: null,
  },
};

const challengesReducer = createReducer(initialState, (builder) => {
  // plain actions
  builder.addCase(setSubmitChallengeDialog, (state, action) => {
    if (
      action.payload.challengeItem === null &&
      action.payload.submitted &&
      state.submission.challengeItem !== null
    ) {
      const index = state.items.inProgress.findIndex(
        (x) => x.id === state.submission.challengeItem?.id,
      );
      const moved = state.items.inProgress.splice(index, 1);
      if (moved.length) state.items.completed.push(moved[0]);
    }
    state.submission.challengeItem = action.payload.challengeItem;
    state.submission.loadedAt = action.payload.challengeItem ? new Date().toISOString() : null;
  });

  builder.addCase(setFeedbackChallenge, (state, action) => {
    state.feedback.challengeItem = action.payload.challengeItem;
  });

  builder.addCase(clearCurrentLiveChallenge, (state, action) => {
    state.currentLiveChallenge = null;
  });

  builder.addCase(setLocalChallengeSubmissionState, (state, action) => {
    const challenge = getChallengeById(
      action.payload.challengeId,
      state.items.open,
      state.items.inProgress,
      state.items.completed,
    );
    if (!!challenge) {
      if (action.payload.state === 'taken') {
        challenge.submission = {
          status: 'TAKEN',
          tekenAt: new Date().toLocaleString(),
          completedAt: '',
          answers: [],
          feedbacks: [],
        };
      } else if (action.payload.state === 'none') {
        challenge.submission = null;
      }
    }
  });

  // async actions
  builder.addCase(getChallenges.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getChallenges.fulfilled, (state, action) => {
    state.loading = false;
    state.items = action.payload;
  });
  builder.addCase(getChallenges.rejected, (state) => {
    state.loading = false;
  });

  builder.addCase(getCurrentLiveChallenge.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getCurrentLiveChallenge.fulfilled, (state, action) => {
    state.loading = false;
    state.currentLiveChallenge = action.payload;
  });
  builder.addCase(getCurrentLiveChallenge.rejected, (state) => {
    state.loading = false;
  });

  builder.addCase(getFeedbacksCreatedByMeData.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getFeedbacksCreatedByMeData.fulfilled, (state, action) => {
    state.loading = false;
    state.feedbacksCreatedByMe = action.payload;
  });
  builder.addCase(getFeedbacksCreatedByMeData.rejected, (state) => {
    state.loading = false;
  });
});

export { challengesReducer };
