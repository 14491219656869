/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Avatar,
  AvatarClassKey,
  AvatarTypeMap,
  createStyles,
  styled,
  withStyles,
} from '@material-ui/core';
import { OverrideProps } from '@material-ui/core/OverridableComponent';

export interface IWithUserProps {
  user: ISessionUser | null;
}

type InternalAvatarProps = IWithUserProps & OverrideProps<AvatarTypeMap, 'div'>;

const InternalAvatar = ({ user, src, ...rest }: InternalAvatarProps) => {
  return (
    <Avatar src={user?.avatar?.sm || user?.avatar?.md || user?.avatar?.lg || src} {...rest}>
      {user?.initials || '??'}
    </Avatar>
  );
};

export const SmallAvatar = styled(InternalAvatar)({ width: '46px', height: '46px' });
export const MediumAvatar = withStyles((theme) =>
  createStyles<AvatarClassKey, InternalAvatarProps>({
    root: () => ({
      width: '97px',
      height: '97px',
      margin: '24px',
      [theme.breakpoints.down('sm')]: {
        width: '56px',
        height: '56px',
        margin: '12px',
      },
    }),
    img: {},
    circle: {},
    colorDefault: {},
    rounded: {},
    square: {},
    fallback: {},
  }),
)(InternalAvatar);
