import React, { useState } from 'react';
import { Box, Fab, Grid, styled, Collapse, Button } from '@material-ui/core';
import { ChapterName, Description } from 'common/components/HomePosterOverlay/styledComponents';

import { KeyboardArrowDownSharp } from '@material-ui/icons';
import { getCourseDuration } from '../../utils/helpers';
import { FormattedMessage } from 'react-intl';

type OverlayProps = {
  episodeTitle: string;
  courseName: string;
  courseDescription: string;
  episodeDescription: string;
  chapterNumber: number;
  episodeNumber: number;
  hero: any;
  withSeeAllLink?: boolean;
  courseToHighlightChallenges: any;
};

export const PosterInfoContainer = styled(Box)({
  background: 'rgba(0, 0, 0, 0.7)',
  width: '100%',
});

export const ShowDetailsButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  background: 'transparent!important',
}));

const StyledFab = styled(Fab)({
  backgroundColor: 'transparent',
  color: '#fff',
  border: '1px solid #fff',
  cursor: 'pointer',
  fontSize: '1.8rem',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const StyledCheckFab = styled(Fab)(({ theme }) => ({
  marginLeft: '2rem',
  color: 'white',
  background: theme.palette.grey[700],
  border: `1px solid grey`,
  cursor: 'default',
  '&:hover': {
    background: theme.palette.grey[800],
  },
}));

const HomePosterOverlay = (props: OverlayProps) => {
  const [showDescription, setShowDescription] = useState(false);

  // const getChallengesAmount = (hero: any) => {
  //   let amount = 0;
  //
  //   hero.chapters.forEach((chapter: any) => {
  //     return chapter.episodes.forEach((episode: any) => {
  //       if (episode.challenge) {
  //         amount++;
  //       }
  //     });
  //   });
  //
  //   return amount;
  // };

  return (
    <PosterInfoContainer
      pl={[2, 2, 8]}
      pr={[2, 2, 8]}
      pt={2}
      pb={2}
      style={{
        position: 'absolute',
        width: '100%',
        top: 0,
        boxSizing: 'border-box',
      }}
    >
      <Grid container justify='space-between'>
        <Box>
          <ChapterName>
            <ShowDetailsButton
              disableRipple
              disableTouchRipple
              fullWidth
              onClick={() => setShowDescription(!showDescription)}
            >
              <h1 style={{ fontWeight: 500, margin: 0, fontSize: '1.5rem', textTransform: 'none' }}>
                {props.courseName}
              </h1>
              <KeyboardArrowDownSharp
                fontSize='small'
                style={{
                  marginLeft: '20px',
                  transform: showDescription ? 'rotate(180deg)' : 'rotate(0)',
                  transition: `${500}ms all`,
                }}
              />
            </ShowDetailsButton>
          </ChapterName>
        </Box>
        <Collapse in={showDescription} style={{ width: '100%' }}>
          <React.Fragment>
            <Grid container justify='space-between'>
              <Box mt={2}>
                <Description>{props.episodeDescription}</Description>
              </Box>
              {props.hero.name && (
                <Box display='flex' justifyContent='right' textAlign='center'>
                  <Box mr={3}>
                    <StyledFab size='medium' style={{ fontSize: '16px', textTransform: 'none' }}>
                      {getCourseDuration(props.hero) || 0}h
                    </StyledFab>
                    <p style={{ fontSize: '12px' }}>
                      <FormattedMessage
                        id='MyCourses.CourseOvervew.CourseOverviewOverlay.StatsLabel.Duration'
                        defaultMessage='Duration'
                      />
                    </p>
                  </Box>
                  <Box mr={3}>
                    <StyledFab size='medium' style={{ fontSize: '16px', textTransform: 'none' }}>
                      {props.courseToHighlightChallenges.length}
                    </StyledFab>
                    <p style={{ fontSize: '12px' }}>
                      <FormattedMessage
                        id='MyCourses.CourseOvervew.CourseOverviewOverlay.StatsLabel.Challenges'
                        defaultMessage='Challenges'
                      />
                    </p>
                  </Box>
                  <Box mr={3}>
                    <StyledFab size='medium' style={{ fontSize: '16px', textTransform: 'none' }}>
                      {props.hero.chapters.length}
                    </StyledFab>
                    <p style={{ fontSize: '12px' }}>
                      <FormattedMessage
                        id='MyCourses.CourseOvervew.CourseOverviewOverlay.StatsLabel.Chapters'
                        defaultMessage='Chapters'
                      />
                    </p>
                  </Box>
                  <Box>
                    <StyledFab size='medium' style={{ fontSize: '16px', textTransform: 'none' }}>
                      {props.hero.chapters.reduce(
                        (prev: any, next: any) => prev + next.episodes.length,
                        0,
                      )}
                    </StyledFab>
                    <p style={{ fontSize: '12px' }}>
                      <FormattedMessage
                        id='MyCourses.CourseOvervew.CourseOverviewOverlay.StatsLabel.Episodes'
                        defaultMessage='Episodes'
                      />
                    </p>
                  </Box>
                </Box>
              )}
            </Grid>
          </React.Fragment>
        </Collapse>
      </Grid>
    </PosterInfoContainer>
  );
};

export default React.memo(HomePosterOverlay);
