/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Fade, useMediaQuery, useTheme } from '@material-ui/core';
import { TabContentProps } from 'common/components/NavBar/tabsConfig';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSessionActions } from 'state/session/useSessionActions';
import { useViewsActions } from 'state/views/useViewsActions';
import { useSelectorWithTabCompare } from 'utils';
import CourseOverview from 'views/MyCourses/CourseOverview';
import { RootState } from '../../reducers';
import { scrollToRef } from '../../utils/helpers';
import Completed from './sections/Completed';
import Ongoing from './sections/Ongoing';
import Open from './sections/Open';

const MyCourses = ({ tabIndex, active }: TabContentProps) => {
  const theme = useTheme()
  const openRef = useRef(null);
  const { courseId, showChapters } = useParams<{ courseId?: string; showChapters?: string }>();

  const [fetched, setFetched] = useState(false)

  const [bounded] = useState({
    ...useViewsActions('getMyCoursesData', 'getCourseToOverview'),
    ...useSessionActions('setCourseOverview'),
  });

  const { myCoursesData, coursesByType, courseToOverview } = useSelectorWithTabCompare(
    (state: RootState) => ({
      myCoursesData: state.views.courses,
      coursesByType: state.views.coursesByType,
      courseToOverview: state.views.courseToOverview,
    }),
    tabIndex,
  );

  useEffect(() => {
    if (active) {
      bounded.getMyCoursesData().then(() => {
        setFetched(true)
      })
    }
  }, [active]);

  useEffect(() => {
    bounded.getCourseToOverview(courseId || null, !!showChapters);
  }, [bounded, courseId, showChapters]);

  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  const courseToHighlight = myCoursesData.find(
    (course) => courseToOverview && course.id === courseToOverview.courseId,
  );

  const showCourseOverview = !!courseToOverview && !!courseToHighlight;

  return (
    <React.Fragment>
      {!showCourseOverview && (
        <Box minHeight='100vh' pb={0.1} boxSizing='border-box'>
          <Fade in={fetched}>
            <div>
              <Box mt={6} ml={2} mr={2} mb={[0, 0, 12]}>
                <Ongoing executeScroll={() => scrollToRef(openRef)} items={coursesByType.ongoing} />
              </Box>
              <Box mt={2} ml={2} mr={2} mb={[0, 0, 12]}>
                <Completed items={coursesByType.completed} />
              </Box>
              {coursesByType.open.length > 0 && (
                <div ref={openRef}>
                  <Box
                    mt={coursesByType.ongoing.length === 0 && coursesByType.completed.length === 0 ? 2 : 6}
                    ml={2}
                    mr={2}
                    mb={[0, 0, 12]}
                  >
                    <Open items={coursesByType.open} />
                  </Box>
                </div>
              )}
            </div>
          </Fade>
        </Box>
      )}
      {courseToOverview && courseToHighlight && (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <CourseOverview
            heroPosterPosition={isMedium ? 'top' : ''}
            showChapters={courseToOverview.showChapters}
            courseToHighlight={courseToHighlight}
            courseOverview={showCourseOverview}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(MyCourses);
