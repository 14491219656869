import { Button, styled, Fade } from '@material-ui/core';
import { TabContentProps } from 'common/components/NavBar/tabsConfig';
import { differenceInHours, isFuture, parseISO, toDate } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useViewsActions } from 'state/views/useViewsActions';
import { useSelectorWithTabCompare } from 'utils';
import { RootState } from '../../reducers';
import { useSessionActions } from '../../state/session/useSessionActions';
import HeroSession from './HeroSession';
import SessionsAccordion from './SessionsAccordion';
import { unwrapResult } from '@reduxjs/toolkit';

export const MediumButton = styled(Button)(({ theme }) => ({
  height: '50px',
  margin: '5px',
  '&.MuiButton-contained.Mui-disabled': {
    color: theme.palette.primary.light,
    backgroundColor: '#9b9b9b',
  },
}));

const MySessions = ({ tabIndex }: TabContentProps) => {
  const intl = useIntl();
  const [actionsBase] = useState({});
  const [signingUp, setSigningUp] = useState(false);

  const bounded = Object.assign(actionsBase, {
    ...useSessionActions('openConfirmationDialog'),
    ...useViewsActions(
      'getSessionsData',
      'joinSession',
      'leaveSession',
      'joinSessionWaitingList',
      'leaveSessionWaitingList',
    ),
  });

  const openLeaveSessionConfirmationDialog = (session: any) =>
    bounded.openConfirmationDialog({
      isVisible: true,
      modalType: 'LEAVE_SESSION',
      modalProps: {
        sessionId: session.id,
        header: `${session.name}`,
        text: `${
          session.isFull
            ? intl.formatMessage({
                id: 'MySessions.LeaveSessionModal.SessionIsFullWarning.Text',
                defaultMessage:
                  'This session is full. After you leave it, your spot may be taken by another user from the waiting list. Are you sure you want to leave this session?',
              })
            : session.slots.length > 1
            ? intl.formatMessage({
                id: 'MySessions.LeaveSessionModal.LeavingNotification.TextMoreThanOneSlot',
                defaultMessage:
                  'You can only sign off from all parts of this session. It will appear in "Open to join" again. Do you want to continue?',
              })
            : intl.formatMessage({
                id: 'MySessions.LeaveSessionModal.LeavingNotification.Text',
                defaultMessage:
                  'Are you sure you want to leave this session? It will appear in "Open to join" again.',
              })
        }`,
        confirmationLabel: intl.formatMessage({
          id: 'MySessions.LeaveSessionModal.Caption.YES',
          defaultMessage: 'YES',
        }),
        cancellationLabel: intl.formatMessage({
          id: 'MySessions.LeaveSessionModal.Caption.CANCEL',
          defaultMessage: 'CANCEL',
        }),
      },
    });

  const openLeaveSessionWaitingListConfirmationDialog = (session: any, afterLeave: any) =>
    bounded.openConfirmationDialog({
      isVisible: true,
      modalType: 'LEAVE_SESSION_WAITING_LIST',
      modalProps: {
        sessionId: session.id,
        header: `${session.name}`,
        afterLeave,
        text: intl.formatMessage({
          id: 'MySessions.OpenLiveSessionWaitingListModal.Text',
          defaultMessage:
            'You are currently on the waiting list. If a spot is available you will be signed up for this session automatically.',
        }),
        confirmationLabel: intl.formatMessage({
          id: 'MySessions.OpenLiveSessionWaitingListModal.Caption.LEAVE',
          defaultMessage: 'LEAVE',
        }),
        cancellationLabel: intl.formatMessage({
          id: 'MySessions.OpenLiveSessionWaitingListModal.Caption.CLOSE',
          defaultMessage: 'CLOSE',
        }),
      },
    });

  const [upcoming, setUpcoming] = useState<any>([]);
  const [open, setOpen] = useState<any>([]);
  const [completed, setCompleted] = useState<any>([]);

  const sessions = useSelectorWithTabCompare((state: RootState) => state.views.sessions, tabIndex);
  const getLiveSessions = () => {
    bounded.getSessionsData();
  };

  useEffect(() => {
    getLiveSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    transformData(sessions);
  }, [sessions]);

  const transformData = (sessions: any) => {
    let futureSessions: Array<any> = [];
    let pastSessions: Array<any> = [];

    sessions.forEach((session: any) => {
      if (isFuture(new Date(parseISO(session.slots[session.slots.length - 1]?.end)))) {
        futureSessions.push(session);
      } else {
        pastSessions.push(session);
      }
    });

    const upcomingSessions = futureSessions.filter((session: any) => session.joined);
    const completedSessions = pastSessions.filter((session: any) => session.joined);
    const openSessions = futureSessions.filter((session: any) => !session.joined);

    setUpcoming(upcomingSessions);
    setCompleted(completedSessions);
    setOpen(openSessions);
  };

  const signUpForSession = (sessionId: string, afterSignUp: any) => {
    bounded
      .joinSession(sessionId)
      .then(unwrapResult)
      .then((res) => {
        afterSignUp ? afterSignUp(res) : bounded.getSessionsData();
      })
      .catch((error) => {
        bounded.getSessionsData();
        bounded.openConfirmationDialog({
          isVisible: true,
          modalType: 'JUST_CONFIRM',
          modalProps: {
            header: intl.formatMessage({
              id: 'MySessions.JoinSession.SessionFullError.Header',
              defaultMessage: `Session full`,
            }),
            text: intl.formatMessage({
              id: 'MySessions.JoinSession.SessionFullError.Description',
              defaultMessage: 'This sessions is full. You can join waiting list to sign up automatically when a spot is available.',
            }),
            confirmationLabel: 'OK',
            onlyConfirm: true,
          },
        });
      });
  };

  const joinWaitingList = (sessionId: string, afterJoin: any) => {
    bounded.joinSessionWaitingList(sessionId).then(() => {
      afterJoin ? afterJoin() : bounded.getSessionsData();
    });
  };

  const leaveWaitingList = (session: any, afterLeave: any) => {
    openLeaveSessionWaitingListConfirmationDialog(session, afterLeave);
  };

  const leaveSession = (session: any) => {
    openLeaveSessionConfirmationDialog(session);
  };

  return (
    <React.Fragment>
      {upcoming.length > 0 && (
        <HeroSession
          hero={upcoming[0]}
          getLiveSessions={getLiveSessions}
          signUpForSession={signUpForSession}
          joinWaitingList={joinWaitingList}
          leaveWaitingList={leaveWaitingList}
          leaveSession={leaveSession}
        />
      )}

      <SessionsAccordion
        title={intl.formatMessage({
          id: 'MySessions.Groups.Upcoming.Header',
          defaultMessage: 'MY UPCOMING SESSIONS',
        })}
        sessions={upcoming.filter((upcomingSession: any, index: number) => {
          const currentSlot = upcomingSession.slots.find((slot: any) => {
            return isFuture(new Date(parseISO(slot.end)));
          });

          const startDate = toDate(parseISO(currentSlot?.start));

          const hoursToStart = differenceInHours(new Date(), startDate);

          return index === 0 && hoursToStart > -24 && upcomingSession.slots.length === 1
            ? false
            : true;
        })}
        leaveSession={leaveSession}
        nothingToShowMessage={intl.formatMessage({
          id: 'MySessions.Groups.Upcoming.NothingToShowMessage',
          defaultMessage: "It seems like you don't have any sessions planned at the moment.",
        })}
      />
      <SessionsAccordion
        title={intl.formatMessage({
          id: 'MySessions.Groups.Open.Header',
          defaultMessage: 'OPEN TO JOIN',
        })}
        sessions={open}
        signingUp={signingUp}
        signUpForSession={signUpForSession}
        leaveSession={leaveSession}
        joinWaitingList={joinWaitingList}
        leaveWaitingList={leaveWaitingList}
        nothingToShowMessage={intl.formatMessage({
          id: 'MySessions.Groups.Open.NothingToShowMessage',
          defaultMessage: 'It seems like there are no open sessions at the moment.',
        })}
      />
      <SessionsAccordion
        isPast
        title={intl.formatMessage({
          id: 'MySessions.Groups.Ended.Header',
          defaultMessage: 'MY ENDED SESSIONS',
        })}
        sessions={completed}
        leaveSession={leaveSession}
        nothingToShowMessage={intl.formatMessage({
          id: 'MySessions.Groups.Ended.NothingToShowMessage',
          defaultMessage: "It seems like you didn't participate in any sessions yet.",
        })}
      />
    </React.Fragment>
  );
};

export default React.memo(MySessions);
