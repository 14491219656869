/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { HTMLStreamElement, Stream } from '@cloudflare/stream-react';
import { Box, Fade, useMediaQuery, useTheme } from '@material-ui/core';
import { ControlBar } from 'views/VideoPlayer/ControlBar';
import PlayButton from 'views/VideoPlayer/PlayButton';
import { debounce } from 'lodash';
import { PlayerOverlay } from 'views/VideoPlayer/PlayerOverlay';
import { Player } from 'views/VideoPlayer/player';
import { usePlayerReducer } from 'views/VideoPlayer/usePlayerReducer';
import screenfull from 'screenfull';
import { scrollToTopSmooth } from 'common/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useViewsActions } from 'state/views/useViewsActions';
import { MuteButton } from 'common/components/SlickCarousel/styledComponents';
import { VolumeOff, VolumeUp } from '@material-ui/icons';

const VideoPlayer = <T extends Player.PlayerType>({
  type,
  src,
  OverlayProps,
  initialProgress = 0,
  endedTreshold = 2,
  play = false,
  coverImage,
  withOverlay = true,
  noControls = false,
  loop = false,
  leftExpandedIfLooping = true,
  withMuteFab = false,
  onProgressChange = () => {},
  onPlayingChange,
  onEndedChange,
  onCanPlayChange,
  onFullScreenChange,
}: Player.VideoPlayerProps<T>) => {
  const theme = useTheme();
  const streamRef = useRef<HTMLStreamElement>() as MutableRefObject<HTMLStreamElement>; // a little bit tricky, but streamRef Type in Stream Component is incorrectly defined

  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));

  const [playing, setPlaying] = useState(false);
  const [ended, setEnded] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);

  const rootRef = useRef<HTMLDivElement | null>(null);
  const { values, bounded } = usePlayerReducer();
  const forcePauseFlag = useSelector(
    (state: RootState) => state.views.watch.forcePause,
    shallowEqual,
  );

  const { forcePause } = useViewsActions('forcePause');

  if (onProgressChange) {
    onProgressChange({ progressPercent: values.currentTime / values.duration });
  }

  useEffect(() => {
    scrollToTopSmooth();
  }, []);

  useEffect(() => {
    if (forcePauseFlag && streamRef.current && playing && streamRef.current.pause) {
      streamRef.current.pause();
      forcePause(false);
    }
  }, [forcePause, forcePauseFlag, playing, streamRef]);

  useEffect(() => {
    if (streamRef.current && canPlay) {
      if (play) {
        streamRef.current.play();
      } else {
        streamRef.current.pause();
      }
    }
    if (onCanPlayChange) {
      onCanPlayChange({ canPlay });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play, canPlay, streamRef.current]);

  useEffect(() => {
    onPlayingChange && onPlayingChange({ playing });
  }, [onPlayingChange, playing]);

  useEffect(() => {
    onEndedChange && onEndedChange({ ended });
  }, [ended, onEndedChange]);

  useEffect(() => {
    streamRef.current.volume = values.volume;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.volume, streamRef.current]);

  useEffect(() => {
    if (!values.currentTimeInferredFromPlayer) {
      streamRef.current.currentTime = values.currentTime;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamRef.current, values.currentTime]);

  useEffect(() => {
    if (canPlay) {
      streamRef.current.currentTime = initialProgress;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canPlay, initialProgress, streamRef.current]);

  useEffect(() => {
    streamRef.current.addEventListener('play', () => {
      setPlaying(true);
    });
    streamRef.current.addEventListener('pause', () => {
      setPlaying(false);
    });
  }, [streamRef]);

  useEffect(() => {
    if (showControls && playing) {
      requestHide();
    } else {
      requestHide.cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showControls, playing]);

  const requestHide = useCallback(
    debounce(() => {
      if (canPlay) {
        if (!ended) {
          setShowControls(false);
        } else {
          setShowControls(true);
        }
      }
    }, 3000),
    [canPlay, ended],
  );

  const handleSpeedChange = useCallback(
    (val: number) => {
      if (streamRef.current) streamRef.current.playbackRate = val;
    },
    [streamRef],
  );

  const handleFullScreenChange = useCallback(() => {
    if (rootRef.current) {
      const element = rootRef.current;
      if (fullScreen) {
        if (screenfull.isEnabled) {
          screenfull
            .exit()
            .then(() => {
              setFullScreen(false);
              onFullScreenChange && onFullScreenChange({ fullScreen: false });
            })
            .catch((err) => console.log('Error leaving fullscreen', err));
        }
      } else {
        if (screenfull.isEnabled) {
          screenfull
            .request(element)
            .then(() => {
              setFullScreen(true);
              onFullScreenChange && onFullScreenChange({ fullScreen: true });
            })
            .catch((err) => {
              console.log('Error while requesting fullscreen', err);
            });
        }
      }
    }
  }, [fullScreen, onFullScreenChange]);

  const getCoverImage = () => {
    let cover = 'none';
    if (!canPlay || (!playing && values.currentTime === 0)) {
      if (playing) {
        // debugger;
      }
      cover = coverImage || 'none';
    }

    return cover;
  };

  const handlePauseRequest = () => {
    if (playing) {
      if (streamRef.current?.pause) {
        streamRef.current.pause();
      }
    }
  };
  const handleAllowPlay = () => {
    if (!playing && canPlay && play) {
      if (streamRef.current?.play) {
        streamRef.current.play();
      }
    }
  };
  return (
    <div
      id='__root'
      ref={rootRef}
      onMouseMove={() => {
        if (!showControls) {
          setShowControls(true)
        }
      }}
      style={{ background: '#000000', cursor: showControls ? 'default' : 'none' }}
    >
      {!isMediumUp && withOverlay && (
        <PlayerOverlay
          show={true}
          expanded={ended}
          type={type}
          customProps={OverlayProps}
          // onMouseMove={() => {
          //   setShowControls(true)
          //   console.log('mouse move player overlay')
          // }}
          playing={playing}
          streamRef={streamRef}
          onPauseRequest={handlePauseRequest}
          onAllowPlay={handleAllowPlay}
          fullScreen={fullScreen}
        />
      )}
      <Fade in timeout={2000}>
        <Box id='__container'
             onMouseMove={() => {
               if (!showControls) {
                 setShowControls(true)
               }
             }}
             position='relative' minHeight={'300px'} height={['60vw', '50vw', '100vh']}>
          <div
            onMouseMove={() => {
              if (!showControls) {
                setShowControls(true)
              }
            }}
            style={{position: 'absolute', top: 0, height: '100%', width: '100%'}}>
            <Stream
              src={src || ''}
              controls={isMediumUp ? false : !ended}
              preload
              height='100%'
              streamRef={streamRef}
              volume={1}
              loop={loop}
              onCanPlayThrough={() => {
                setCanPlay(true);
              }}
              onTimeUpdate={(e: any) => {
                bounded.inferCurrentTime(e.target.currentTime);
                if (values.duration !== e.target.duration) {
                  bounded.setDuration(e.target.duration);
                }

                if (e.target.duration - e.target.currentTime <= endedTreshold) {
                  setEnded(true);
                }
              }}
              onSeeked={(e: any) => {
                const localDuration = e.target.duration;
                const localCurrent = e.target.currentTime;

                bounded.setDuration(localDuration);
                bounded.inferCurrentTime(localCurrent);
                if (ended && localDuration - localCurrent > endedTreshold) {
                  if (loop && leftExpandedIfLooping) {
                    // Yeah - do nothing!
                  } else {
                    setEnded(false);
                  }
                }
              }}
            />
          </div>
          <div
            id='__cover'
            onMouseMove={() => {
              if (!showControls) {
                setShowControls(true)
              }
            }}
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: playing ? 'none' : 'block',
              backgroundImage: `url(${getCoverImage()})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            // onMouseMove={() => {
            //   console.log('mouse move player cover')
            //   setShowControls(true);
            // }}
            onClick={() => {
              if (streamRef.current) {
                if (playing) {
                  streamRef.current.pause();
                } else {
                  streamRef.current.play();
                }
              }
            }}
          />
          <div
            id='__mouseMoveContainer'
            onMouseMove={() => {
              if (!showControls) {
                setShowControls(true)
              }
            }}
            style={{display: isMediumUp ? 'block' : 'none', position: "absolute", top: 0, left: 0, height: '100%', width: '100%'}}
          >

          </div>

          {!ended && canPlay && (!isMediumUp ? values.currentTime === 0 : true) && (
            <PlayButton
              open={showControls && !noControls}
              streamPlayer={streamRef.current}
              playing={playing}
            />
          )}
          {isMediumUp && withOverlay && (
            <PlayerOverlay
              show={!playing}
              expanded={ended}
              type={type}
              customProps={OverlayProps}
              // onMouseMove={() => setShowControls(true)}
              playing={playing}
              streamRef={streamRef}
              noControls={noControls}
              onPauseRequest={handlePauseRequest}
              onAllowPlay={handleAllowPlay}
              fullScreen={fullScreen}
            />
          )}

          {streamRef.current && isMediumUp && (
            <div style={{position: 'absolute', zIndex: 1, bottom: 0, width: '100%'}}>
              <ControlBar
                showControls={showControls && !noControls}
                style={{ position: 'relative', bottom: '15px' }}
                duration={values.duration}
                current={values.currentTime}
                setVolume={bounded.setVolume}
                volume={values.volume}
                fullScreen={fullScreen}
                onChange={(_event, val) => {
                  if (Array.isArray(val)) {
                  } else {
                    bounded.setCurrentTime(val, true);
                  }
                }}
                onChangeCommited={(event, val) => {
                  if (Array.isArray(val)) {
                  } else {
                    bounded.setCurrentTime(val);
                  }
                }}
                onMouseEnter={() => {
                  requestHide.cancel();
                }}
                onMouseLeave={() => {
                  requestHide();
                }}
                onSpeedChange={handleSpeedChange}
                onFullScreenChange={handleFullScreenChange}
              />
            </div>
          )}

          {streamRef.current && withMuteFab && (
            <Box position='absolute' bottom={[2 * 8, 4 * 8, 8 * 8, 12 * 8]} right='120px'>
              <MuteButton onClick={() => bounded.switchMute()} size='small' aria-label='mute'>
                {values.volume === 0 ? <VolumeOff /> : <VolumeUp />}
              </MuteButton>
            </Box>
          )}
        </Box>
      </Fade>
    </div>
  );
};

export default VideoPlayer;
