import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';

import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Fade,
  Grid,
} from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { scrollToTopSmooth } from '../../utils';
import { MediumButton } from '../General/Buttons';

const Accordion = withStyles({
  root: {
    marginBottom: '15px',
    background: '#000',
    overflow: 'hidden',
  },
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    fontSize: '1.5em',
    borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    marginTop: 25,
    marginBottom: 25,
    lineHeight: 1.5,
    fontWeight: 300,
  },
})(MuiAccordionDetails);

export const FAQ = () => {
  const bounded = useSessionActions('fetchFaq');
  const history = useHistory();

  const items = useSelector((state: RootState) => {
    return state.session.faqCollection.items;
  });

  useEffect(() => {
    bounded.fetchFaq();
  }, [bounded]);

  return (
    <Fade in timeout={1000}>
      <Box
        fontSize={['12px', '14px', '16px']}
        mt={[2, 2, 1]}
        p={[2, 4, 10]}
        style={{ backgroundColor: '#000' }}
      >
        <p style={{ fontSize: '2.5em', textAlign: 'center' }}>Frequently Asked Questions</p>
        <Grid container>
          {items.length > 0 &&
            items.map((item) => {
              return (
                <Grid item key={item.ordinal} xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          fontSize='large'
                          style={{
                            color: '#fff',
                          }}
                        />
                      }
                    >
                      {item.question}
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
        </Grid>
        <Box textAlign='center' mt={10} mb={10}>
          <h1 style={{ fontWeight: 400 }}>Have more questions?</h1>
          <MediumButton
            color='secondary'
            variant='contained'
            onClick={() => {
              scrollToTopSmooth();
              history.push('/help/contact');
            }}
          >
            CONTACT US
          </MediumButton>
        </Box>
      </Box>
    </Fade>
  );
};
