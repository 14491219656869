import React from 'react';
import {
  Box,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  useMediaQuery,
  useTheme,
  Grow,
} from '@material-ui/core';
import { TransitionGroup } from 'react-transition-group';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme, withStyles } from '@material-ui/core/styles';
import P2PTile from './P2PTile';
import P2PTileGiveFeedback from './P2PTileGiveFeedback';

const Accordion = withStyles({
  root: {
    marginBottom: '15px',
    background: '#000',
    overflow: 'hidden',
  },
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(24),
    padding: 0,
    borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    fontWeight: 300,
    padding: 0,
    paddingTop: 10,
  },
})(MuiAccordionDetails);

const P2PAccordion = ({
  nothingToShowMessage = 'Nothing to show',
  isPast,
  p2pChallenges,
  title,
  action,
  actionLabel,
  type,
}: any) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div style={{ background: '#000' }}>
      <Box display='flex' justifyContent='center'>
        <Grid item xs={12} md={10} lg={7} xl={5}>
          <Accordion square defaultExpanded={true}>
            <Box mx={[1, 2, 3]}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    fontSize='large'
                    style={{
                      color: '#fff',
                    }}
                  />
                }
              >
                <Box fontSize={['14px', '14px', '18px']}>{title}</Box>
              </AccordionSummary>
            </Box>
            <AccordionDetails>
              <Box mx={[1, 2, 4]} width='100%'>
                <Grid container justify='center'>
                  {p2pChallenges.length === 0 ? (
                    <Grid item xs={12} md={5} lg={3}>
                      <Box
                        minHeight='10rem'
                        textAlign='center'
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        width='100%'
                      >
                        {nothingToShowMessage}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <TransitionGroup>
                        {p2pChallenges.map((p2pChallenge: any) => {
                          const isCompleted = type === 'completed';
                          const isToDo = type === 'to_do';
                          const isMultipleFeedback = type === 'multiple_feedback';
                          const isInProgress = type === 'in_progress';

                          if (isToDo || isMultipleFeedback) {
                            return (
                              <Grow>
                                <P2PTileGiveFeedback
                                  key={p2pChallenge.id}
                                  imageUrl={p2pChallenge.desktopCoverImage?.url}
                                  challengeTitle={p2pChallenge.title}
                                  courseName={p2pChallenge.chapter?.course.name}
                                  challengeCompletedAt={p2pChallenge.submission.completedAt}
                                  feedbacks={p2pChallenge.feedbacksCreatedByMe}
                                  action={(challenge: any, feedback: any) =>
                                    action(challenge, feedback)
                                  }
                                  challengeItem={p2pChallenge}
                                  actionLabel={actionLabel}
                                  type={type}
                                />
                              </Grow>
                            );
                          } else if (isInProgress || isCompleted) {
                            return (
                              <Grow>
                                <P2PTile
                                  key={p2pChallenge.id}
                                  imageUrl={p2pChallenge.desktopCoverImage?.url}
                                  challengeTitle={p2pChallenge.title}
                                  courseName={p2pChallenge.chapter?.course.name}
                                  feedbacks={p2pChallenge.feedbacksCreatedByMe}
                                  action={(challenge: any, feedback: any) =>
                                    action(challenge, feedback)
                                  }
                                  challengeItem={p2pChallenge}
                                  actionLabel={actionLabel}
                                  type={type}
                                />
                              </Grow>
                            );
                          }
                        })}
                      </TransitionGroup>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Box>
    </div>
  );
};

export default React.memo(P2PAccordion);
