import { Button, Fab, styled } from '@material-ui/core';
import { default as styledComponent } from 'styled-components';

interface SliderTitleProps {
  readonly bright: boolean;
  readonly centered: boolean;
}

export const SliderTitle = styledComponent.h1<SliderTitleProps>`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
  color: ${(props) => (props.bright ? '#ffffff' : 'rgba(255, 255, 255, 0.6)')}
`;

const CenteredTitleDecoration = styledComponent('span')({
  height: '1px',
  width: '100%',
  background: 'rgba(255, 255, 255, 0.6)',
});

export const CenteredTitleDecorationLeft = styledComponent(CenteredTitleDecoration)({
  float: 'right',
  maxWidth: '120px',
  marginRight: '1rem',
});

export const CenteredTitleDecorationRight = styledComponent(CenteredTitleDecoration)({
  maxWidth: '120px',
  marginLeft: '1rem',
});

export const TitleWrapper = styled('div')({
  textAlign: 'center',
});

export const StyledLinkButton = styled(Button)({
  color: 'white',
  textDecoration: 'none',
  background: 'transparent!important',
  fontWeight: 400,
  padding: 0,
  borderRadius: 0,
});

export const VideoPlayerButton = styled(Fab)(({ theme }) => ({
  color: 'white',
  background: theme.palette.grey[700],
  opacity: 0.75,
  transition: '300ms opacity',
  '&:hover': {
    opacity: 1,
    background: theme.palette.grey[700],
  },
}));

export const MuteButton = styled(Fab)(({ theme }) => ({
  color: 'white',
  background: theme.palette.grey[700],
  opacity: 0.75,
  transition: '300ms opacity',
  '&:hover': {
    opacity: 1,
    background: theme.palette.grey[700],
  },
}));

export const VideoPlayerPlayButton = styled(Fab)(({ theme }) => ({
  height: '100px',
  width: '100px',
  lineHeight: '100px',
  color: 'white',
  background: theme.palette.grey[700],
  opacity: 0.75,
  transition: '300ms opacity',
  '&:hover': {
    opacity: 1,
    background: theme.palette.grey[700],
  },
}));

export const ChallengeStar = styled(Fab)(({ theme }) => ({
  color: 'white',
  background: theme.palette.grey[700],
  opacity: 0.9,
  cursor: 'default',
  transition: '300ms opacity',
  padding: '2px',
  '&:hover': {
    background: theme.palette.grey[700],
  },
}));

export const StyledFab = styled(Fab)(({ theme }) => ({
  '&.MuiFab-root': {
    color: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[800],
  },
  cursor: 'pointer',
  background: 'transparent',
  border: `1px solid grey`,
  textTransform: 'none',
  '&:hover': {
    background: theme.palette.grey[800],
  },
}));

export const StyledP2PFab = styled(Fab)(({ theme }) => ({
  '&.MuiFab-root': {
    color: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[800],
  },
  cursor: 'pointer',
  background: theme.palette.grey[700],
  border: `1px solid grey`,
  textTransform: 'none',
  '&:hover': {
    background: theme.palette.grey[800],
  },
}));

export const SlideName = styled('h2')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textTransform: 'none',
  textAlign: 'left',
  fontWeight: 400,
  fontSize: 'inherit',
  padding: 0,
}));

export const SlideAdditionalInfo = styled('h5')(() => ({
  fontWeight: 400,
  padding: 0,
  fontSize: '0.7rem',
}));

export const SlideDescription = styled('h5')(() => ({
  fontWeight: 400,
  padding: 0,
  margin: '10px',
}));

export const StreamWrapper = styled('div')(() => ({
  fontWeight: 400,
  padding: 0,
  margin: '10px',
}));

export const ShowDetailsButton = styled(Button)(() => ({
  margin: '5px 0',
  height: '30px',
  borderRadius: '0',
}));

export const SlideActionButton = styled(Button)({
  // marginLeft: '10px',
  fontSize: '0.75rem',
  padding: '1.75em 3.5em',
});
