import React, { useCallback } from 'react';
import SlideComponent from '../index';
import { isAfter, parseISO, toDate } from 'date-fns';
import { unwrapResult } from '@reduxjs/toolkit';
import { useChallengesActions } from '../../../../../state/challenges/useChallengesActions';
import { useSessionActions } from '../../../../../state/session/useSessionActions';
import { useIntl } from 'react-intl';
import { saveRouteToGoBack } from '../../../../../utils/goBack';
import { useHistory } from 'react-router-dom';
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";

const ChallengeSlideCompleted = (props: any) => {
  const intl = useIntl();
  const history = useHistory();

  const { feedbacksCreatedByMe } = useSelector(
    (state: RootState) => ({
      feedbacksCreatedByMe: state.challenges.feedbacksCreatedByMe,
    }),
    shallowEqual,
  );

  const thisChallengeGivenFeedbacks = feedbacksCreatedByMe.filter(
    (feedback: any) => feedback.submission.challenge.id === props.item.id
  );

  const isMissedLiveChallenge =
    props.item.is_live &&
    !props.item.submission?.p2pFeedbackGiven &&
    props.item.hasOwnProperty('availableTo') &&
    isAfter(new Date(), toDate(parseISO(props.item.availableTo || '')));

  const isFirstParticipant = props.item.completedSubmissionsCount <= 1
  const allFeedbacksGiven = thisChallengeGivenFeedbacks >= props.item.completedSubmissionsCount - 1

  const { postRequestSubmissionToFeedback, getFeedbacksCreatedByMeData } = useChallengesActions(
    'postRequestSubmissionToFeedback',
    'getFeedbacksCreatedByMeData',
  );

  const { openConfirmationDialog } = {
    ...useSessionActions('openConfirmationDialog'),
  };

  const giveFeedback = useCallback(async () => {
    postRequestSubmissionToFeedback({ challengeId: props.item.id })
      .then(unwrapResult)
      .then((res) => {
        const feedbackToShow = res;

        if (feedbackToShow) {
          const routeToGoBack = history.location.pathname;
          saveRouteToGoBack(routeToGoBack);
          history.push(`/main/my-challenges/p2p/${feedbackToShow.id}/give`);
        }
      })
      .catch((error) => {
        openConfirmationDialog({
          isVisible: true,
          modalType: 'JUST_CONFIRM',
          modalProps: {
            header: props.item?.title,
            text: intl.formatMessage({
              id: 'P2PFeedback.GiveFeedback.Modal.NoSubmission',
              defaultMessage:
                'There are no available challenge submissions at the moment. Try again later.',
            }),
            confirmationLabel: 'OK',
            onlyConfirm: true,
          },
        });
      });
  }, [history, intl, openConfirmationDialog, postRequestSubmissionToFeedback, props.item]);

  return (
    <SlideComponent
      withP2P={props.item.is_p2p}
      withMissedLiveChallengeIcon={isMissedLiveChallenge}
      withDescription
      withCompletedFab
      actionButtonDisabled={isFirstParticipant || allFeedbacksGiven}
      withActionButton={isMissedLiveChallenge}
      actionButtonLabel='GIVE FEEDBACK'
      onAction={giveFeedback}
      // withCheck
      customSlideStyles={{
        cursor: 'pointer',
      }}
      {...props}
    />
  );
};

export default React.memo(ChallengeSlideCompleted);
