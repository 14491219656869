import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSessionActions } from 'state/session/useSessionActions';

const useStyles = makeStyles((theme) => ({
  tile: {
    backgroundColor: theme.palette.grey[300],
    transition: 'background-color 400ms',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.grey[800],
      transition: 'background-color 400ms',
    },
  },
}));

export const NotificationTile = ({
  notification,
  compact = false,
  padding = 0,
}: {
  notification: INotification;
  compact?: boolean;
  padding?: number;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    markNotificationAsRead,
    getUnreadedNotifications,
    setOpenNotificationsMenu,
  } = useSessionActions(
    'markNotificationAsRead',
    'getUnreadedNotifications',
    'setOpenNotificationsMenu',
  );

  const handleClick = async () => {
    if (notification.actionUrl) {
      try {
        const actionUrl = new URL(notification.actionUrl);
        if (actionUrl.host === window.location.host) {
          let relative = `${actionUrl.pathname}${actionUrl.hash}${actionUrl.search}`;
          history.push(relative);
        } else {
          window.open(notification.actionUrl);
        }
      } catch (ex) {
        console.log('Error while navigating to notification link', ex);
      }
    }
    await markNotificationAsRead({ notificationId: notification.id });
    getUnreadedNotifications();
    setOpenNotificationsMenu(false);
  };

  return (
    <Box display='flex' m={1} mx={2} className={classes.tile} onClick={handleClick} p={padding}>
      {isSmUp && (
        <Box
          width={compact ? [16 * 8, 16 * 10, 16 * 12] : [16 * 10, 16 * 12, 16 * 14]}
          height={compact ? [9 * 8, 9 * 10, 9 * 12] : [9 * 10, 9 * 12, 9 * 14]}
        >
          <img
            src={notification.image?.url || ''}
            alt={notification.title}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      )}
      <Box m={1} flex='1 0 0'>
        <Box
          fontSize={compact ? ['10px', '12px', '14px', '16px'] : ['12px', '14px', '16px', '18px']}
          fontWeight={500}
        >
          <div dangerouslySetInnerHTML={{ __html: notification.title }} />
        </Box>
        <Box
          mt={1}
          fontSize={compact ? ['10px', '12px', '14px', '16px'] : ['12px', '14px', '16px', '18px']}
          fontWeight={300}
        >
          <div dangerouslySetInnerHTML={{ __html: notification.content }} />
        </Box>
      </Box>

      <Box m={1}>
        <Box
          width={[8, 10, 14, 16]}
          height={[8, 10, 14, 16]}
          borderRadius={[4, 5, 7, 8]}
          mt={['6px', '5px', '3px', '2px']}
          bgcolor={notification.readAt || compact ? 'transparent' : theme.palette.secondary.main}
        ></Box>
      </Box>
    </Box>
  );
};
