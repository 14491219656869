import React from 'react';
import { Box } from '@material-ui/core';
import StreamPlayer from '../../../StreamPlayer/StreamPlayer';

interface VideoProps {
  showDetails: boolean;
  videoCanPlay: boolean;
  transitionTime: number;
  isHighlighted: boolean;
  initialHover: boolean;
  index: number;
  // todo: needs typing
  highlightedSlidesIndexes: any;
  // todo: needs typing
  setVideoCanPlay: any;
  item: any;
}

const Video: React.FC<VideoProps> = ({
  showDetails,
  item,
  transitionTime,
  videoCanPlay,
  highlightedSlidesIndexes,
  index,
  isHighlighted,
  setVideoCanPlay,
  initialHover,
}) => {
  return (
    <Box
      position='absolute'
      width='100%'
      style={{
        transition: `${transitionTime}ms opacity`,
        opacity: showDetails && videoCanPlay ? 1 : 0,
        zIndex: showDetails && videoCanPlay ? 1 : -1,
      }}
    >
      {highlightedSlidesIndexes.indexOf(index) !== -1 && (
        <StreamPlayer
          initialHover={initialHover}
          isHighlighted={isHighlighted}
          videoCanPlay={videoCanPlay}
          setVideoCanPlay={setVideoCanPlay}
          item={item}
        />
      )}
    </Box>
  );
};

export default React.memo(Video);
