/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fade, useTheme, Box, Grid } from '@material-ui/core';
import PlayButton from 'common/components/SlickCarousel/Slide/components/PlayButton';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { saveRouteToGoBack } from '../../../../utils/goBack';

export const DescriptionControl = ({ item }: { item: Challenges.IChallengeInProgres }) => {
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();

  return (
    <Box
      bgcolor={theme.palette.grey[900]}
      border={`solid 1px ${theme.palette.primary.light}`}
      borderRadius='5px'
      gridColumn='span 2'
      mt='30px'
      mx={[2, 4, 6, 13]}
    >
      <Grid container>
        <Grid item xs={12} md={7}>
          <Box
            fontSize={['18px', '20px', '20px', '26px']}
            mt={[1, 3, 4, 5]}
            mx={['10px', '20px', '40px', '60px']}
            mb={[1]}
            fontWeight={500}
          >
            {item.title}
          </Box>
          {item.chapter && <Box
            fontSize={['16px', '18px', '18px', '20px']}
            fontWeight={400}
            mx={['10px', '20px', '40px', '60px']}
            mt={[2, 2, 2, 0]}
          >{`${item.chapter?.course.name} / Chapter ${item.chapter?.ordinal}: ${item.chapter?.name}`}</Box>}
          <Box
            fontSize={['12px', '14px', '14px', '14px']}
            mx={['10px', '20px', '40px', '60px']}
            my='20px'
          >
            {item.description}
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            display='flex'
            alignContent='center'
            justifyContent='center'
            position='relative'
            mx={['10px', '20px', '0px', '0px']}
            mb={['10px', '20px', '0px']}
          >
            <Box
              mr={0}
              ml='auto'
              maxWidth='100%'
              position='relative'
              // bgcolor='#ff121255'
              display='flex'
              maxHeight={['auto', 'auto', '40vh']}
            >
              <img
                src={item.desktopCoverImage?.url}
                alt={intl.formatMessage({
                  id: 'ChallengeFeedback.DescriptionControl.Cover.Alt',
                  defaultMessage: 'challenge cover image',
                })}
                css={{
                  maxWidth: '100%',
                  objectFit: 'cover',
                  marginLeft: 'auto',
                  marginRight: '0',
                }}
              />
              <Fade in>
                <PlayButton
                  onClick={() => {
                    const routeToGoBack = history.location.pathname;
                    saveRouteToGoBack(routeToGoBack);
                    history.push(`/watch/challenge/${item.id}`);
                  }}
                />
              </Fade>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
