import { useReducer, useState } from 'react';

import { bindActionCreators } from '@reduxjs/toolkit';

interface ReducerAction {
  type: 'setVolume' | 'setCurrentTime' | 'setDuration' | 'inferCurrentTime' | 'switchMute';
  val: number;
  pauseInfering?: boolean;
}

type ReducerActionCreator = (...args: any[]) => ReducerAction;

const setVolume = (val: number) => {
  if (val < 0 || val > 1) {
    throw new Error('Volume value out of range [0-1]');
  }
  return {
    type: 'setVolume',
    val,
  };
};

const setCurrentTime = (currentTime: number, pauseInfering = false) => {
  return {
    type: 'setCurrentTime',
    val: currentTime,
    pauseInfering,
  };
};

const inferCurrentTime = (currentTime: number) => {
  return {
    type: 'inferCurrentTime',
    val: currentTime,
  };
};

const setDuration = (duration: number) => {
  return {
    type: 'setDuration',
    val: duration,
  };
};

const switchMute = () => {
  return {
    type: 'switchMute',
    val: 0,
  };
};

export const usePlayerReducer = () => {
  const initialState = {
    playing: false,
    wasPaused: false,
    duration: 0,
    currentTime: 0,
    currentTimeInferredFromPlayer: false,
    pauseInfering: false,
    volume: 0.5,
    volumeMemo: 0.5,
  };

  const [values, dispatch] = useReducer(
    (state: typeof initialState, action: ReducerAction) => {
      switch (action.type) {
        case 'setVolume': {
          localStorage.setItem('playerVolume', action.val.toString());
          return { ...state, volume: action.val };
        }
        case 'switchMute': {
          if (state.volume > 0) {
            return { ...state, volumeMemo: state.volume, volume: 0 };
          } else {
            return { ...state, volume: state.volumeMemo };
          }
        }
        case 'setCurrentTime': {
          return {
            ...state,
            currentTime: action.val,
            currentTimeInferredFromPlayer: false,
            pauseInfering: action?.pauseInfering || false,
          };
        }
        case 'inferCurrentTime': {
          if (!state.pauseInfering) {
            return { ...state, currentTime: action.val, currentTimeInferredFromPlayer: true };
          }
          return state;
        }
        case 'setDuration': {
          if (action.val !== state.duration) {
            return { ...state, duration: action.val };
          } else {
            return state;
          }
        }
      }
      return state;
    },
    initialState,
    (initialState) => {
      const localVolume = localStorage.getItem('playerVolume');
      if (localVolume != null) {
        const parsedVolume = parseFloat(localVolume);
        if (!isNaN(parsedVolume) && parsedVolume >= 0 && parsedVolume <= 1) {
          initialState.volume = parsedVolume;
        } else {
          localStorage.setItem('playerVolume', initialState.volume.toString());
        }
      }

      return initialState;
    },
  );

  const [bounded] = useState(
    bindActionCreators(
      { setVolume, setCurrentTime, setDuration, inferCurrentTime, switchMute },
      dispatch as any,
    ),
  );

  return { values, bounded };
};
