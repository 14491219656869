/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import {
  Box,
  AccordionSummary as MuiAccordionSummary,
  Accordion as MuiAccordion,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SlickCarousel from '../../common/components/SlickCarousel';
import { Courses } from 'state/views';
import { useEffect, useState } from 'react';
import { EpisodeTile } from 'common/components/Tiles/EpisodeTile';
import { padStart } from 'lodash';
import { FormattedMessage } from 'react-intl';

const Accordion = withStyles({
  root: {
    marginBottom: '15px',
    background: '#000',
  },
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(24),
    marginLeft: 30,
    marginRight: 30,
    padding: 0,
    borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
  },
}))(MuiAccordionSummary);

interface ChaptersAccordionsProps {
  course: Courses.ICourseDTO;
  currentEpisodeId?: string;
  defaultExpanded: string;
}

export const ChaptersAccordions = ({
  course,
  currentEpisodeId,
  defaultExpanded,
}: ChaptersAccordionsProps) => {
  const [expanded, setExpanded] = useState<string>(defaultExpanded);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const handleAccordionChange = (panel: string) => (
    // eslint-disable-next-line no-unused-vars
    _event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : '');
  };

  useEffect(() => window.scrollTo(0, 0), [course.id]);

  return (
    <React.Fragment>
      {course.chapters.map((chapter: any) => {
        return (
          <Accordion
            key={chapter.id}
            square
            expanded={chapter.id === expanded}
            onChange={handleAccordionChange(chapter.id)}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  fontSize='large'
                  style={{
                    color: '#fff',
                  }}
                />
              }
            >
              <Box fontSize={['14px', '16px', '18px', '20px']}>
                <p style={{ margin: 0, fontSize: '1em' }}>
                  <FormattedMessage
                    id='Literal.Chapter'
                    defaultMessage='Chapter'
                    description='Literally Chapter word or its equivalent'
                  />{' '}
                  {padStart(chapter.ordinal, 2, '0')} /&nbsp;{chapter.name}
                </p>
              </Box>
            </AccordionSummary>
            <Box mt={0} mb={[1, 1, 12]} px='30px'>
              {false ? (
                <SlickCarousel
                  sliderType='episodes'
                  items={chapter.episodes
                    .filter((episode: any) => episode.id)
                    .map((e: any) => ({
                      ...e,
                      courseId: course.id,
                      chapterId: chapter.id,
                      isCurrent: e.id === currentEpisodeId,
                    }))}
                  sliderTitle=''
                  slidesToShow={3}
                  slidesToScroll={3}
                  speed={500}
                />
              ) : (
                [...chapter.episodes, ...chapter.challenges].map((episode: any, i: number) => (
                  <EpisodeTile
                    key={episode.id}
                    index={i}
                    sliderType='episodes'
                    item={episode}
                    withPlayButton
                    withProgress
                    withTitleOrderNumberPrefix
                    isCurrent={episode.id === currentEpisodeId}
                    tabIndex={i}
                  />
                ))
              )}
            </Box>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
};
