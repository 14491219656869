/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback, MouseEventHandler } from 'react';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { merge } from 'lodash';

type LinkItemProps = {
  key?: string | number | null | undefined;
  weight?: 'normal' | 'light';
  label: string;
  color?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  selected?: boolean;
  to?: string;
};

type LinkItemClassKey = 'root' | 'label' | 'pointer';

const defaultMenuItemStyle = (theme: Theme) =>
  createStyles<LinkItemClassKey, LinkItemProps>({
    root: {
      display: 'block',
      cursor: 'pointer',
      backgroundColor: (props) => (props.weight === 'light' ? '' : ''),
      color: (props) =>
        props.weight === 'light' ? theme.palette.primary.main : theme.palette.primary.main,
      '&:hover .kairos-pointer div': {
        backgroundColor: (props) =>
          props.weight !== 'light' ? theme.palette.secondary.main : 'transparent',
        transition: 'background-color .5s ease',
      },
    },
    label: {
      fontSize: '1rem',
      fontWeight: (props) => (props.weight === 'light' ? 300 : 500),
      transition: 'font-weight .1s ease',
      display: 'inline-block',
      verticalAlign: 'middle',
      '&:hover': {
        fontWeight: (props) => (props.weight === 'light' ? 400 : 500),
        transition: 'font-weight .1s ease',
      },
    },
    pointer: (props) => ({
      display: 'inline-block',
      padding: '10px',
      verticalAlign: 'middle',
      '& div': {
        width: '9px',
        height: '9px',
        backgroundColor:
          props.selected && props.weight !== 'light' ? theme.palette.secondary.main : 'transparent',
        transition: 'background-color .5s ease',
        borderRadius: '4.5px',
      },
    }),
  });

const PureMenuItem = ({
  key,
  classes,
  label,
  to,
  onClick,
}: LinkItemProps & WithStyles<typeof defaultMenuItemStyle, false>) => {
  let history = useHistory();
  const interpolated = merge(defaultMenuItemStyle, classes);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();

      if (onClick) {
        onClick(e);
      }
      if (to) {
        history.push(to);
      }
    },
    [onClick, to, history],
  );

  let Component: 'div' | 'a' = 'div';
  let extras = {};
  if (to) {
    Component = 'a';
    extras = { href: to };
  }

  return (
    <Component {...extras} key={key} className={interpolated.root} onClick={handleClick}>
      <div className={interpolated.label}>{label}</div>
      <div className={cx('kairos-pointer', interpolated.pointer)}>
        <div></div>
      </div>
    </Component>
  );
};

export const MenuItem = withStyles(defaultMenuItemStyle)(PureMenuItem);
