import { Box } from '@material-ui/core';
import { SmallButton } from 'common/components/General/Buttons';
import React from 'react';
import {useIntl} from "react-intl";

export const CelebrationExpandedComponent = ({ onClick }: any) => {
  const intl = useIntl()
  return (
    <Box height='100%' display='flex' alignItems='flex-end'>
      <Box mb={[2, 4, 8, 12]}>
        <SmallButton
          variant='contained'
          color='secondary'
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          {
            intl.formatMessage({
              id: 'CelebrationExpandedComponent.FinishCelebration',
              defaultMessage:
                "FinishCelebration",
            })
          }
        </SmallButton>
      </Box>
    </Box>
  );
};
