/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Fade } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { BackButton } from 'common/components/General/Buttons';
import { scrollToTopSmooth } from 'common/utils';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { DescriptionControl } from 'views/MyChallenges/sections/Feedback/DescriptionControl';
import { FeedbackControl } from 'views/MyChallenges/sections/Feedback/FeedbackControl';
import { P2PFeedbackContent } from 'views/MyChallenges/sections/Feedback/P2PFeedbackContent';
import { SubmissionControl } from 'views/MyChallenges/sections/Feedback/SubmissionControl';
import SlickCarousel from '../../../../common/components/SlickCarousel';

export const feedbackChannelName = 'ChallengFeedbackSnackbar';

const ChallengeFeedback = ({
  item,
}: ({ item: Challenges.IChallengeInProgres } & RouteComponentProps) | any) => {
  const theme = useTheme();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.session.user);

  useEffect(() => {
    setTimeout(() => {
      scrollToTopSmooth();
    }, 100);
  }, [item.id]);

  const handleBack = () => {
    history.go(-1);
  };

  const intl = useIntl();

  return (
    <React.Fragment>
      <Fade in>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '0.5fr 0.5fr',
          }}
        >
          <Box mx={[2, 4, 6, 13]}>
            <BackButton
              onClick={handleBack}
              label={intl.formatMessage({
                id: 'Literal.Back',
                defaultMessage: 'Back',
                description:
                  'Literally word Back or it equivalent, used as Literal for back button in overlays',
              })}
            />
          </Box>
          <DescriptionControl item={item} />
          {item?.helperVideos?.length > 0 && (
            <Box mt={[2, 4, 5]} mb={[2, 4, 6]} gridColumn='span 2'>
              <SlickCarousel
                sliderType='helper-videos'
                items={item?.helperVideos.map((video: any) => {
                  return {
                    ...video,
                    challenge: item.id,
                  };
                })}
                sliderTitle='FIND OUT MORE'
                titleCentered
                nothingToShowMessage={
                  <span>
                    <FormattedMessage
                      id='MyChallenges.Sections.Open.NothingToShowMessage'
                      defaultMessage='It seems like there are no open challenges at the moment. <br></br>
            Join new courses to take on new challenges.'
                      values={{
                        br: () => <br />,
                      }}
                    />
                  </span>
                }
                slidesToShow={3}
                slidesToScroll={3}
                speed={500}
              />
            </Box>
          )}
          <SubmissionControl item={item} user={user} theme={theme} />

          {/*{<P2PFeedbackContent item={item} />}*/}
        </div>
      </Fade>
    </React.Fragment>
  );
};

export default ChallengeFeedback;
