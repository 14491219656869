import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { SmallButton } from '../../common/components/General/Buttons';
import { mainLayoutChannel } from '../../layouts/Main';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useSessionActions } from '../../state/session/useSessionActions';

export const IntroductionInfoBox = ({
  name,
  description,
  expanded,
}: {
  name: string;
  description: string;
  expanded: boolean;
}) => {
  const history = useHistory();
  const intl = useIntl();

  const params = useParams<{ new?: string }>();

  const user = useSelector((state: RootState) => state.session.user, shallowEqual);

  const { pushMessageSnack } = {
    ...useSessionActions('pushMessageSnack'),
  };

  const greeting = intl.formatMessage({
    id: 'MainLayout.RegistrationSnack.Greeting',
    defaultMessage: 'Hello',
  });
  const weAreHappy = intl.formatMessage({
    id: 'MainLayout.RegistrationSnack.HappyToHaveYou',
    defaultMessage: 'We are happy to have you here!',
  });

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Box fontSize={['24px', '24px', '26px']} fontWeight={500} mt={2} ml={2} mb={1}>
          {name}
        </Box>
      </Grid>
      {/*<Box justifySelf='end' alignSelf='end'></Box>*/}

      <Grid item xs={12} sm={12} md={6}>
        <Box
          fontSize={['12px', '12px', '16px']}
          fontWeight={300}
          lineHeight={1.34}
          ml={2}
          mr={2}
          mt={1}
          pb={2}
        >
          {description}
        </Box>
      </Grid>

      {!expanded && !!params.new && (
        <Grid item xs={12} sm={12} md={6}>
          <Box
            display='flex'
            justifyContent={['center', 'center', 'flex-end']}
            m={2}
            mr={[0, 0, 6]}
          >
            <SmallButton
              color='secondary'
              variant='contained'
              onClick={() => {
                history.push('/main/home');
                pushMessageSnack({
                  channel: mainLayoutChannel,
                  messageBold: `${greeting} ${user?.firstName}.`,
                  message: `${weAreHappy}!`,
                  timeout: 10000,
                });
              }}
            >
              <FormattedMessage
                id='VideoPlayer.IntroductionInfoBox.Skip.Caption'
                defaultMessage='Skip'
              />
            </SmallButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
