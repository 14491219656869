import { Fade, styled } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { Footer } from 'common/components/Footer/Footer';
import Header from 'common/components/Header/Header';
import NavTabs from 'common/components/NavBar/NavTabs';
import { EditProfileDialog } from 'common/components/Profile/EditProfileDialog';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { RootState } from 'reducers';
import { privateRoutes, publicRoutes } from 'routes';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';
import { getRoutes } from 'routes/utils';
import { useSessionActions } from 'state/session/useSessionActions';
import LoginModal from 'views/LandingPage/Components/LoginModal';
import { ResetPasswordModal } from 'views/LandingPage/Components/ResetPasswordModal';
import * as constants from '../../state/session/constants';

const Wrapper = styled('div')({
  color: '#ffffff',
});
const HelpLayout = ({ ...rest }: any) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [openLoginDialog, openResetPasswordDialog, openEditProfileDialog, user] = useSelector(
    (state: RootState) => [
      state.session.flags.openLoginDialog,
      state.session.flags.openResetPasswordDialog,
      state.session.flags.openEditProfileDialog,
      state.session.user,
    ],
    shallowEqual,
  );

  const bounded = useSessionActions('setActiveTab', 'getCurrentProfile');

  useEffect(() => {
    bounded
      .getCurrentProfile()
      .then(unwrapResult)
      .then((data) => data)
      .catch((error) => console.log('Error while fetching profile', error));
  }, [bounded]);

  useEffect(() => {
    setFadeIn(true);
    bounded.setActiveTab(100);
  }, [bounded]);
  const access = localStorage.getItem(constants.TOKEN_NAME);
  const isAuthenticated = !!user && access;

  return (
    <React.Fragment>
      <Fade in={fadeIn}>
        <Wrapper id='__help_wrapper'>
          <Header
            sticky={!isAuthenticated}
            withPopper={true}
            withBoxShadow={false}
            withBackground={true}
            hasAuthDialogs={true}
          />
          {isAuthenticated && <NavTabs />}
          <Switch>
            {getRoutes(publicRoutes, '/help').map((route: any) => (
              <PublicRoute
                exact={route.exact}
                key={route.path}
                path={route.layout + route.path}
                component={route.Component}
                {...rest}
              />
            ))}
            {getRoutes(privateRoutes, '/help').map((route: any) => (
              <PrivateRoute
                exact={route.exact}
                key={route.path}
                path={route.layout + route.path}
                component={route.Component}
                {...rest}
              />
            ))}
            {/*<Redirect to={} />*/}
          </Switch>
          <Footer />
          {openLoginDialog && <LoginModal />}
          {openResetPasswordDialog && <ResetPasswordModal />}
          {openEditProfileDialog && <EditProfileDialog />}
        </Wrapper>
      </Fade>
    </React.Fragment>
  );
};

export default React.memo(HelpLayout);
