import React from 'react';
import SlickCarousel from 'common/components/SlickCarousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useIntl } from 'react-intl';

const Recommended = ({ items }: any) => {
  const intl = useIntl();
  return (
    <div>
      <SlickCarousel
        sliderType='courses-recommended'
        items={items}
        sliderTitle={intl.formatMessage({
          id: 'Home.Sections.Recommended.SliderTitle',
          defaultMessage: 'RECOMMENDED COURSES',
        })}
        slidesToShow={3}
        slidesToScroll={3}
        speed={500}
      />
    </div>
  );
};

export default React.memo(Recommended);
