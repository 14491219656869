/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Typography, useTheme, useMediaQuery, Theme } from '@material-ui/core';
import {
  StyledContent,
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
  TitleButton,
} from 'common/components/Dialogs/StyledDialogComponents';
import { Form, Field } from 'react-final-form';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import ClearIcon from '@material-ui/icons/Clear';
import { useCallback, useState } from 'react';
import { StyledTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import {
  required,
  validatePasswordLength,
  validatePasswordHasSpecialCharacterOrDigit,
  validatePasswordHasLowerLetter, validatePasswordHasUpperLetter, mandatory, validatePasswordHasWhiteSpace
} from 'utils/form-validation';
import { VisibilityAdornment } from 'common/components/Dialogs/VisibilityAdornment';
import { useSessionActions } from 'state/session/useSessionActions';
import { KairosSnackBar } from 'common/components/General/KairosSnackBar';
import { unwrapResult } from '@reduxjs/toolkit';
import { MediumButton } from 'common/components/General/Buttons';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import PasswordRequirements from "../../../common/components/PasswordRequirements";
const replaceAll = require('string.prototype.replaceall');

interface IValues {
  password: string;
  password2: string;
}

const getChannelName = () => replaceAll(__filename, '.', '_');

export const NewPasswordModal = (props: any) => {
  const theme = useTheme();
  const intl = useIntl();

  const isVisible = useSelector(
    (state: RootState) => state.session.flags.openSetNewPassword,
    shallowEqual,
  );

  const token = useSelector(
    (state: RootState) => state.session.config.tokens.reset,

    shallowEqual,
  );

  const bounded = useSessionActions(
    'setNewPasswordDialog',
    'postNewPassword',
    'pushMessageSnack',
    'pushErrorSnack',
  );
  const onClose = useCallback(() => bounded.setNewPasswordDialog(false, null), [bounded]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const history = useHistory();
  return (
    <StyledDialog
      style={{ zIndex: 2010, width: '100%' }}
      open={isVisible as boolean}
      maxWidth='xs'
      onClose={onClose}
      fullScreen={useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))}
    >
      <StyledDialogTitle>
        <TitleButton onClick={() => onClose()}>
          <ClearIcon color='primary' />
        </TitleButton>
      </StyledDialogTitle>
      <KairosSnackBar channel={getChannelName()} />

      <Form
        onSubmit={async ({ password }: IValues) => {
          if (token !== null) {
            bounded
              .postNewPassword({
                password,
                token: token as string,
              })
              .then(unwrapResult)
              .then(() => {
                bounded.pushMessageSnack({
                  channel: getChannelName(),
                  message: intl.formatMessage({
                    id: 'NewPasswordModal.Snack.Success.PasswordChanged',
                    defaultMessage: 'Password successfully changed',
                  }),
                });
                history.push('/auth');
                setTimeout(() => onClose(), 3000);
              })
              .catch(() => {
                bounded.pushErrorSnack({
                  channel: getChannelName(),
                  message: intl.formatMessage({
                    id: 'NewPasswordModal.Snack.Error.ChangingPasswordFailed',
                    defaultMessage: 'Error while changing password',
                  }),
                });
              });
          }
        }}
        validate={({ password, password2 }: IValues) => {
          const errors: Partial<Record<keyof IValues, string>> = {};
          if (password !== password2) {
            errors.password2 = intl.formatMessage({
              id: 'NewPasswordModal.Validation.PasswordsDontMatch',
              defaultMessage: "The entered passwords are different",
            });
          }

          errors.password =
            (validatePasswordLength(password) ||
              validatePasswordHasSpecialCharacterOrDigit(password) ||
              validatePasswordHasLowerLetter(password) ||
              validatePasswordHasUpperLetter(password)) === undefined
              ? undefined
              : intl.formatMessage({
                id: 'NewPasswordModal.Validation.PasswordDoesntMeetRequirements',
                defaultMessage: "Password does not meet requirements.",
              });

          return errors;
        }}
        render={({ handleSubmit, submitting, hasValidationErrors , values, ...rest}) => (
          <form onSubmit={handleSubmit}>
            <StyledContent>
              <div css={{ textAlign: 'center' }}>
                <Typography variant='h3'>Reset password</Typography>
              </div>

              <Field
                name='password'
                component={StyledTextFieldAdapter}
                color='primary'
                validate={value => {
                  return mandatory(value, intl.formatMessage({
                    id: 'FormValidation.MandatoryField',
                    defaultMessage:
                      'This field is required.',
                  })) || validatePasswordHasWhiteSpace(value)
                }}
                label={intl.formatMessage({
                  id: 'NewPasswordModal.Field.Password.Label',
                  defaultMessage: 'New password',
                })}
                placeholder={intl.formatMessage({
                  id: 'NewPasswordModal.Field.Password.Placeholder',
                  defaultMessage: 'Write your new password here',
                })}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                variant='outlined'
                css={{ marginTop: theme.spacing(8) }}
                InputProps={{
                  endAdornment: (
                    <VisibilityAdornment
                      onChange={({ isVisible }) => {
                        setShowPassword(isVisible);
                      }}
                    />
                  ),
                }}
              />

              <PasswordRequirements password={values.password} />

              <Field
                name='password2'
                component={StyledTextFieldAdapter}
                color='primary'
                label={intl.formatMessage({
                  id: 'NewPasswordModal.Field.ConfirmPassword.Label',
                  defaultMessage: 'Confirm password',
                })}
                placeholder={intl.formatMessage({
                  id: 'NewPasswordModal.Field.ConfirmPassword.Placeholder',
                  defaultMessage: 'Write your new password here',
                })}
                type={showPassword2 ? 'text' : 'password'}
                fullWidth
                variant='outlined'
                validate={value => {
                  return mandatory(value, intl.formatMessage({
                    id: 'FormValidation.MandatoryField',
                    defaultMessage:
                      'This field is required.',
                  })) || validatePasswordHasWhiteSpace(value)
                }}
                css={{
                  marginTop: theme.spacing(3),
                }}
                InputProps={{
                  endAdornment: (
                    <VisibilityAdornment
                      onChange={({ isVisible }) => setShowPassword2(isVisible)}
                    />
                  ),
                }}
              />
            </StyledContent>

            <StyledDialogActions>
              <MediumButton
                type='submit'
                color='secondary'
                variant='contained'
                disabled={submitting || hasValidationErrors}
                fullWidth
              >
                <FormattedMessage
                  id='NewPasswordModal.Caption.ChangePassword'
                  defaultMessage='Change password'
                />
              </MediumButton>
            </StyledDialogActions>
          </form>
        )}
      />
    </StyledDialog>
  );
};
