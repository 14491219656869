import { ContactUs } from 'common/components/Help/ContactUs';
import { FAQ } from 'common/components/Help/FAQ';
import { TermsOfService } from 'common/components/Help/TermsOfService';
import Home from 'views/Home';
import MyChallenges from 'views/MyChallenges';
import MyFavorites from 'views/MyFavorites';
import MySessions from 'views/MySessions';
import {
  ChallengePlayerRoute,
  EpisodePlayerRoute,
  LiveChallengeHelperPlayerRoute,
  TrailerPlayerRoute,
} from 'views/VideoPlayer/PlayerRoutes';
import WallOfIdeas from 'views/WallOfIdeas';
import { Legal } from '../common/components/Help/Legal';
import { Privacy } from '../common/components/Help/Privacy';
import { LandingPage } from '../views/LandingPage/LandingPage';
import { IntroductionPlayer } from '../views/VideoPlayer/IntroductionPlayer';
import { P2PIntroductionPlayer } from '../views/VideoPlayer/P2PIntroductionPlayer';
import { LiveChallengeHelperPlayer } from '../views/VideoPlayer/LiveChallengeHelperPlayer';
import { LiveChallengeIntroductionPlayer } from '../views/VideoPlayer/LiveChallengeIntroductionPlayer';

export const publicRoutes = [
  {
    exact: true,
    path: '',
    layout: '/auth',
    Component: LandingPage,
  },
  {
    exact: false,
    path: '/:tokenType/:token',
    layout: '/auth',
    Component: LandingPage,
  },
  {
    exact: true,
    path: '/contact',
    layout: '/help',
    Component: ContactUs,
  },
  // {
  //   exact: true,
  //   path: '/terms-of-service',
  //   layout: '/help',
  //   Component: TermsOfService,
  // },
  {
    exact: true,
    path: '/legal',
    layout: '/help',
    Component: Legal,
  },
  {
    exact: true,
    path: '/privacy',
    layout: '/help',
    Component: Privacy,
  },
];
export const privateRoutes = [
  {
    exact: false,
    path: '/home',
    layout: '/main',
    Component: Home,
  },
  {
    exact: true,
    path: '/faq',
    layout: '/help',
    Component: FAQ,
  },
  {
    exact: false,
    path: '/courses/:courseId?/:showChapters?',
    layout: '/main',
    Component: () => null,
  },
  {
    exact: false,
    path: '/my-challenges/challenges/:challengeId?/:edit?',
    layout: '/main',
    Component: MyChallenges,
  },
  {
    exact: false,
    path: '/my-sessions',
    layout: '/main',
    Component: MySessions,
  },
  {
    exact: false,
    path: '/wall-of-ideas',
    layout: '/main',
    Component: WallOfIdeas,
  },
  {
    exact: false,
    path: '/my-favorites',
    layout: '/main',
    Component: MyFavorites,
  },
  // {
  //   exact: false,
  //   path: '/video/:src',
  //   layout: '/watch',
  //   Component: VideoPlayer,
  // },
  {
    exact: true,
    path: '/introduction/:new?',
    layout: '/watch',
    Component: IntroductionPlayer,
  },
  {
    exact: false,
    path: '/p2p-introduction/:new?',
    layout: '/watch',
    Component: P2PIntroductionPlayer,
  },
  {
    exact: false,
    path: '/live-challenge-introduction/:new?',
    layout: '/watch',
    Component: LiveChallengeIntroductionPlayer,
  },
  {
    exact: false,
    path: '/live-challenge/:videoId/:id?',
    layout: '/watch',
    Component: LiveChallengeHelperPlayerRoute,
  },
  {
    exact: false,
    path: '/trailer/:courseId',
    layout: '/watch',
    Component: TrailerPlayerRoute,
  },
  {
    exact: false,
    path: '/episode/:episodeId/:autoplay?',
    layout: '/watch',
    Component: EpisodePlayerRoute,
  },
  {
    exact: false,
    path: '/challenge/:id/:autoplay?',
    layout: '/watch',
    Component: ChallengePlayerRoute,
  },
];
