/** @jsx jsx */
import { jsx } from '@emotion/core';

import { FileLink } from 'common/components/Dialogs/StyledDialogComponents';
import { FileDescriptor } from 'common/components/Uploader';
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { Avatar, Badge, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import editAcatarSvg from './edit-avatar.svg';

interface EditorAvatarProps<T extends boolean = boolean> {
  user: ISessionUser;
  showBadge?: T;
  onPictureChoosed?: (desc: FileDescriptor | null) => void;
}

export const EditorAvatar = ({ user, showBadge = false, onPictureChoosed }: EditorAvatarProps) => {
  const [file, setFile] = useState<FileDescriptor | null>(null);

  useEffect(() => onPictureChoosed && onPictureChoosed(file), [file, onPictureChoosed]);

  const handleFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length === 1) {
      let file = e.target.files[0];
      let url = URL.createObjectURL(file);

      setFile({
        id: Date.now().toString(),
        file,
        fileName: file.name,
        isOversized: false,
        objectUrl: url,
      });

      e.target.value = '';
    }
  }, []);

  const handleClear = useCallback(() => setFile(null), []);

  return (
    <React.Fragment>
      <Badge
        id='avatar-badge'
        style={{ margin: 'auto' }}
        overlap='circle'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          showBadge ? (
            <div>
              <label htmlFor='__file_avatar'>
                <IconButton component='span'>
                  <img src={editAcatarSvg} css={{ height: '36px' }} alt='' />
                </IconButton>
              </label>
              {file && false && (
                <IconButton onClick={handleClear}>
                  <CloseIcon color='secondary' />
                </IconButton>
              )}
              <input
                id='__file_avatar'
                type='file'
                accept='image/jpeg,image/png'
                multiple={false}
                css={{ display: 'none' }}
                onChange={handleFile}
              />
            </div>
          ) : null
        }
      >
        <Avatar
          src={file?.objectUrl || user.avatar?.lg}
          css={{ width: '150px', height: '150px', fontSize: '59px', fontWeight: 700 }}
          alt={user.nickname || `${user.firstName}`}
        >
          {user?.initials}
        </Avatar>
      </Badge>
      {showBadge && (
        <Box
          style={{
            fontSize: '16px',
            fontWeight: 'normal',
            textAlign: 'center',
            // display: 'flex',
            // flexDirection: 'column',
          }}
        >
          <div>
            <label htmlFor='__file_avatar'>
              <FileLink>Upload new photo</FileLink>
            </label>
          </div>
          <div>{file && <FileLink onClick={() => setFile(null)}>Cancel</FileLink>}</div>
        </Box>
      )}
    </React.Fragment>
  );
};
