import React, { useMemo } from 'react';
import SlideComponent, { SlideComponentProps } from '../index';
import { openConfirmationDialog } from 'state/session/actions';
import { getMyCoursesData, leaveCourse, getHomeData } from 'state/views/actions';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const actionsToBound = {
  openConfirmationDialog,
  leaveCourse,
  getMyCoursesData,
  getHomeData,
};

const CourseSlideWithChallenges = ({ ...props }: SlideComponentProps) => {
  const dispatch = useDispatch();
  const bounded = useMemo(() => bindActionCreators(actionsToBound, dispatch), [dispatch]);

  const openConfirmationDialog = () => {
    bounded.openConfirmationDialog({
      isVisible: true,
      modalType: 'LEAVE_COURSE',
      modalProps: {
        courseId: props.item.id,
        header: `${props.item.name}`,
        text: 'Are you sure you want to leave this course?',
        confirmationLabel: 'YES',
        cancellationLabel: 'NO',
      },
    });
  };

  return (
    <React.Fragment>
      <SlideComponent
        withDescription
        withLeaveFab
        leaveFabProps={{
          onClick: () => openConfirmationDialog(),
        }}
        withStars
        withArrow
        {...props}
      />
    </React.Fragment>
  );
};

export default React.memo(CourseSlideWithChallenges);
