export const TOKEN_NAME = 'access';
export const USER = 'user';
export const REFRESH_TOKEN_NAME = 'refresh_token';
export const SESSION_TIMSPAN_DAYS = 14;
export const REQUEST_URL_BACKUP_NAME = 'REQUEST_URL_BACKUP';

export const devSwitches = {
  // TEMP: Celebrations - dev switch
  __celebrationsEnabled: process.env.REACT_APP_ENABLE_CELEBRATIONS === '1',
  __devHelperEnabled: process.env.REACT_APP_USE_DEV_HELPER === '1',
};
