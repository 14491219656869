/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Portal, Fade } from '@material-ui/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { KairosSnackBar } from 'common/components/General/KairosSnackBar';
import { TabContentProps } from 'common/components/NavBar/tabsConfig';
import { scrollToTopSmooth } from 'common/utils';
import React, {useEffect, useRef, useState} from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'reducers';
import { useChallengesActions } from 'state/challenges/useChallengesActions';
import { useSelectorWithTabCompare } from 'utils';
import ChallengeFeedback, {
  feedbackChannelName,
} from 'views/MyChallenges/sections/Feedback/ChallengeFeedback';
import { SubmissionNotification } from 'views/MyChallenges/sections/Feedback/SubmissionNotification';
import { scrollToRef } from '../../utils/helpers';
import Completed from './sections/Completed';
import InProgress from './sections/InProgress';
import Open from './sections/Open';
import P2PFeedback from './P2P/P2PFeedback';
import LiveChallenge from './Live/LiveChallenge';
import LiveChallengePage from './Live/LiveChallengePage';
import { ModeSwitchSection } from './ModeSwitchSection';
import { shallowEqual, useSelector } from 'react-redux';

const challengesPathname = '/main/my-challenges/challenges';
const liveChallengePathname = '/main/my-challenges/live';
const p2pPathname = '/main/my-challenges/p2p';

const MyChallenges = ({ tabIndex, active }: TabContentProps) => {
  const intl = useIntl();
  const params = useParams<{ challengeId?: string; mode?: string }>();
  const { challengeId, mode } = params;
  const openRef = useRef(null);
  const challengesMode = mode === 'challenges';
  const p2pMode = mode === 'p2p';
  const history = useHistory();
  const [fetched, setFetched] = useState(false)

  const {
    getChallenge,
    getChallenges,
    setFeedbackChallenge,
    getCurrentLiveChallenge,
    getFeedbacksCreatedByMeData,
  } = {
    ...useChallengesActions(
      'getChallenges',
      'getChallenge',
      'getCurrentLiveChallenge',
      'setFeedbackChallenge',
      'getFeedbacksCreatedByMeData',
    ),
  };

  let {
    challengeInFeedback,
    navBarMounted,
    feedbacksCreatedByMe,
    user,
  } = useSelectorWithTabCompare(
    (state: RootState) => ({
      challengeInFeedback: state.challenges.feedback.challengeItem,
      navBarMounted: state.session.dependencies.navBarMounted,
      feedbacksCreatedByMe: state.challenges.feedbacksCreatedByMe,
      user: state.session.user,
    }),
    tabIndex,
  );

  const { currentLiveChallenge } = useSelector(
    (state: RootState) => ({
      currentLiveChallenge: state.challenges.currentLiveChallenge,
    }),
    shallowEqual,
  );

  useEffect(() => {
    (async () => {
      if (challengeId && challengesMode) {
        const thunkResult = await getChallenge(challengeId);
        const challengeDetails = unwrapResult(thunkResult);
        if (challengeDetails.submission?.status === 'COMPLETED') {
          setFeedbackChallenge(challengeDetails);
        } else {
          setFeedbackChallenge(null);
          history.push('/main/my-challenges');
        }
      } else {
        setFeedbackChallenge(null);
      }
    })().catch((error) => {
      history.push('/main/my-challenges');
    });
  }, [challengeId, challengesMode, getChallenge, history, setFeedbackChallenge]);

  useEffect(() => {
    getCurrentLiveChallenge();
    getFeedbacksCreatedByMeData();
  }, [getCurrentLiveChallenge, getFeedbacksCreatedByMeData, history]);

  useEffect(() => {
    if (active) {
      scrollToTopSmooth();
      getChallenges().then(() => {
        setFetched(true)
      });
    }
  }, [active]);

  const updateCurrentLiveChallenge = () => {
    getCurrentLiveChallenge();
  };

  const showFeedback = !!challengeId && challengeInFeedback;

  return (
    <Fade in={fetched}>
      <div>
        {navBarMounted && active && (
          <Portal container={document.querySelector('.notificationsBar')}>
            <KairosSnackBar channel={feedbackChannelName} />
          </Portal>
        )}
        <Switch>
          <Route
            path={challengesPathname}
            render={() => {
              return (
                <Fade in>
                  <Box>
                    {!showFeedback && user?.company.p2pEnabled && (
                      <ModeSwitchSection
                        p2pMode={p2pMode}
                        p2pPathname={p2pPathname}
                        challengesMode={challengesMode}
                        challengesPathname={challengesPathname}
                      />
                    )}
                    {showFeedback && challengeInFeedback != null && (
                      <Box mt={4} ml={2} mr={2} mb={[3, 4, 12]}>
                        <ChallengeFeedback item={challengeInFeedback} />
                      </Box>
                    )}
                    {user?.company.p2pEnabled && !showFeedback && (
                      <LiveChallenge
                        heroChallenge={currentLiveChallenge}
                        updateCurrentLiveChallenge={updateCurrentLiveChallenge}
                        feedbacksCreatedByMe={feedbacksCreatedByMe}
                      />
                    )}
                    {!showFeedback && (
                      <Box mt={6} ml={2} mr={2} mb={[0, 0, 12]}>
                        <InProgress executeScroll={() => scrollToRef(openRef)} />
                      </Box>
                    )}
                    <Box mt={2} ml={2} mr={2} mb={[0, 0, 12]}>
                      <Completed
                        titleCentered={!!showFeedback}
                        title={
                          showFeedback
                            ? intl.formatMessage({
                              id: 'MyChallenges.CompletedSliderTitle.MoreCompleted',
                              defaultMessage: 'MORE COMPLETED',
                            })
                            : undefined
                        }
                        filterOut={[challengeInFeedback].filter(
                          (x): x is Challenges.IChallengeDTO => !!x,
                        )}
                      />
                    </Box>
                    {!showFeedback && (
                      <div ref={openRef}>
                        <Box mt={2} ml={2} mr={2} mb={[0, 0, 14]}>
                          <Open />
                        </Box>
                      </div>
                    )}
                  </Box>
                </Fade>
              );
            }}
          />
          {user?.company.p2pEnabled && (
            <Route
              path={p2pPathname + '/:challengeId?/:mode?'}
              render={() => (
                <P2PFeedback
                  feedbacksCreatedByMe={feedbacksCreatedByMe}
                  p2pMode={p2pMode}
                  p2pPathname={p2pPathname}
                  challengesMode={challengesMode}
                  challengesPathname={challengesPathname}
                />
              )}
            />
          )}
          <Route
            path={liveChallengePathname + '/:challengeId?'}
            render={() => (
              <LiveChallengePage
                liveChallenge={currentLiveChallenge}
                feedbacksCreatedByMe={feedbacksCreatedByMe}
                updateCurrentLiveChallenge={updateCurrentLiveChallenge}
              />
            )}
          />
          <Redirect exact from={'/main/my-challenges'} to={'/main/my-challenges/challenges'} />
        </Switch>
      </div>
    </Fade>
  );
};

export default React.memo(MyChallenges);
