import React, { useCallback, useMemo } from 'react';
import { Box, Button, Dialog, DialogContent, Grid, styled } from '@material-ui/core';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { openConfirmationDialog } from '../../../state/session/actions';
import { getHomeData, getMyCoursesData, getSessionsData, leaveCourse, leaveSession, leaveSessionWaitingList } from '../../../state/views/actions';
import { bindActionCreators } from 'redux';

const StyledDialog = styled(Dialog)({
  background: 'transparent',
});

const StyledContent = styled(DialogContent)({
  background: 'black',
});

const DialogHeader = styled('h2')({
  fontWeight: 400,
  textAlign: 'center',
});

const DialogText = styled('p')({
  fontSize: '0.9rem',
  textAlign: 'center',
});

const actionsToBound = {
  openConfirmationDialog,
  leaveCourse,
  leaveSession,
  getMyCoursesData,
  getHomeData,
  getSessionsData,
  leaveSessionWaitingList
};

const ConfirmationDialog = () => {
  const dispatch = useDispatch();
  const { isVisible, user } = useSelector(
    (state: RootState) => ({
      isVisible: state.session.config.confirmationDialog.isVisible,
      user: state.session.user,
    }),
    shallowEqual,
  );

  const { openConfirmationDialog } = {
    ...useSessionActions('openConfirmationDialog'),
  };

  const bounded = useSessionActions('closeConfirmationDialog');
  const viewsActionsToBind = useMemo(() => bindActionCreators(actionsToBound, dispatch), [
    dispatch,
  ]);
  const isMobile = false;

  const [confirmationDialog] = useSelector(
    (state: RootState) => [state.session.config.confirmationDialog],
    shallowEqual,
  );

  const onClose = useCallback(() => bounded.closeConfirmationDialog(), [bounded]);

  const onDialogConfirm = (modalType: string) => {
    switch (modalType) {
      case 'LEAVE_COURSE':
        return viewsActionsToBind.leaveCourse(confirmationDialog.modalProps.courseId).then(() => {
          viewsActionsToBind.getMyCoursesData();
          viewsActionsToBind.getHomeData();
        });
      case 'LEAVE_CHALLENGE':
        return window.alert(
          `leave challenge with id: ${confirmationDialog.modalProps.challengeId}`,
        );
      case 'LEAVE_SESSION':
        return viewsActionsToBind.leaveSession(confirmationDialog.modalProps.sessionId).then((res) => {
          if (res?.payload?.status === 400) {
            openConfirmationDialog({
              isVisible: true,
              modalType: 'JUST_CONFIRM',
              modalProps: {
                header: '',
                text: user?.language?.locale === 'en' ? 'Unfortunately it is too late to resign from this session. You can do so only up until one week before the start of a Live Session.' : 'Leider ist es zum jetzigen Zeitpunkt nicht mehr möglich, die Registrierung zu ändern. Änderungen sind lediglich bis eine Woche vor Beginn der Session möglich.',
                confirmationLabel: 'OK',
                onlyConfirm: true,
              },
            });
          }
          viewsActionsToBind.getSessionsData();
        });
      case 'LEAVE_SESSION_WAITING_LIST':
        return viewsActionsToBind.leaveSessionWaitingList(confirmationDialog.modalProps.sessionId).then(() => {
          confirmationDialog.modalProps.afterLeave
            ? confirmationDialog.modalProps.afterLeave()
            : viewsActionsToBind.getSessionsData()
        });
      case 'JUST_CONFIRM':
        return () => null
      default:
        return;
    }
  };

  const onConfirm = () => {
    onDialogConfirm(confirmationDialog.modalType);
    onClose();
  };

  return (
    <StyledDialog style={{zIndex: 2010}} PaperProps={{ style: { background: 'transparent' } }} fullScreen={isMobile} maxWidth='xs' open={isVisible} onClose={onClose}>
      <div style={{ background: '#101010', opacity: 0.8, height: '3rem', width: '100%' }} />
      <StyledContent>
        <Grid container justify='center'>
          <Grid item md={10}>
            <DialogHeader>{confirmationDialog.modalProps.header}</DialogHeader>
          </Grid>
          <Grid item md={10}>
            <Box mb={3}>
              <DialogText>{confirmationDialog.modalProps.text}</DialogText>
            </Box>
          </Grid>
          <Grid item md={8}>
            <Box mb={1}>
              <Button onClick={onConfirm} fullWidth color='secondary' variant='contained'>
                {confirmationDialog.modalProps.confirmationLabel}
              </Button>
            </Box>
            {!confirmationDialog.modalProps.onlyConfirm && <Box mb={3}>
              <Button onClick={onClose} fullWidth color='primary' variant='outlined'>
                {confirmationDialog.modalProps.cancellationLabel}
              </Button>
            </Box>}
          </Grid>
        </Grid>
      </StyledContent>
    </StyledDialog>
  );
};

export default React.memo(ConfirmationDialog);
