import React, { useEffect, useState } from 'react';
import { Footer } from 'common/components/Footer/Footer';
import { styled } from '@material-ui/core';
import { Fade } from '@material-ui/core';
import {Route, Switch} from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import { privateRoutes } from 'routes';
import { getRoutes } from 'routes/utils';
import { SubmitChallengeDialog } from 'views/MyChallenges/SubmitChallengeDialog';
import { useViewsActions } from 'state/views/useViewsActions';
import { CelebrationModal } from 'common/components/Celebrations/CelebrationModal';
import { devSwitches } from 'state/session/constants';
import { useSessionActions } from '../../state/session/useSessionActions';
import { unwrapResult } from '@reduxjs/toolkit';

const Wrapper = styled('div')({
  background: '#000000',
  color: '#ffffff',
  fontFamily: 'Montserrat',
});

const WatchLayout = ({ render, ...rest }: any) => {
  const [fadeIn, setFadeIn] = useState(false);

  const { forcePause } = useViewsActions('forcePause');

  const bounded = useSessionActions('getCurrentProfile');

  useEffect(() => {
    bounded
      .getCurrentProfile()
      .then(unwrapResult)
      .then((data) => data)
      .catch((error) => console.log('Error while fetching profile', error));
  }, [bounded]);

  useEffect(() => {
    setFadeIn(true);
    forcePause(false);
  }, [forcePause]);

  return (
    <Wrapper>
      <Fade in={fadeIn}>
        <div>
          <Switch>
            {getRoutes(privateRoutes, '/watch').map((route: any) => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.layout + route.path}
                component={route.Component}
                {...rest}
              />
            ))}
          </Switch>
          <Footer />
        </div>
      </Fade>
      <SubmitChallengeDialog />
    </Wrapper>
  );
};

export default React.memo(WatchLayout);
