/** @jsx jsx */
import React, { MouseEventHandler, useCallback, useContext, useRef, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import {
  Box,
  Button,
  Collapse,
  Fab,
  Fade,
  Grid,
  Grow,
  makeStyles,
  Snackbar,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import cx from 'classnames';
import { SlideActionsContext } from 'common/components/SlickCarousel/SlideActionsContext';
import { shallowEqual, useSelector } from 'react-redux';
import { useSessionActions } from 'state/session/useSessionActions';
import { Courses } from 'state/views';
import { BorderLinearProgress } from 'views/Home/styledComponents';
import { RootState } from '../../../../reducers';
import {ChallengeStar, SlideActionButton, SlideDescription, StyledFab, StyledP2PFab} from '../styledComponents';
import Description from './components/Description';
import Fabs from './components/Fabs';
import PlayButton from './components/PlayButton';
import Stars from './components/Stars';
import TitleAndDetails from './components/TitleAndDetails';
//components
import TitleOverlay from './components/TitleOverlay';
import Video from './components/Video';
import { useDidUpdateEffect } from './hooks';
import mountainMissed from '../../../../assets/mountainMissed.svg';
import mountainFull from "../../../../assets/mountainFull.svg";
import mountain from "../../../../assets/mountain.svg";

// FIXME:
// import styles from '../slider.module.scss';

const createVisibleClass = (
  // slidesIndexes: any,
  itemIndex: number,
  slidesIndexes: any,
  isInfinite: boolean,
  noSlidesToSwipe: boolean,
  highlightedSlidesIndexesLength: number,
) => {
  let className;

  const isLastSlide = itemIndex === highlightedSlidesIndexesLength - 1
  const isLastCurrent = slidesIndexes.indexOf(itemIndex) === slidesIndexes.length - 1
  const isFirstCurrent = slidesIndexes.indexOf(itemIndex) === 0

  if (itemIndex === 0) {
    if (highlightedSlidesIndexesLength === 1) {
      className = 'visible'
    } else if (!isInfinite) {
      className = 'visible-first'
    } else {
      className = 'visible-first withArrow'
    }
  } else if (isLastSlide) {
    if (!isInfinite) {
      className = 'visible-last'
    } else {
      className = 'visible-last withArrow'
    }
  } else if (isLastCurrent) {
    className = 'visible-last withArrow'
  } else if (isFirstCurrent) {
    className = 'visible-first withArrow'
  } else {
    className = 'visible'
  }

  return className;
};

const useSnackbarStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSnackbarContent-root': {
      flexWrap: 'nowrap',
    },
    '& .MuiSnackbarContent-action': {
      paddingLeft: '0px',
      '& .MuiButtonBase-root': {
        [theme.breakpoints.down('xs')]: {
          minWidth: '54px',
        },
      },
      '& .MuiButton-text': {
        [theme.breakpoints.down('xs')]: {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      },
    },
  },
}));
export type SlideComponentProps = Slides.SlideComponentPartialProps;

const SlideComponent = ({
  style,
  tabIndex,
  sliderTitle,
  index,
  noSlidesToSwipe,
  visibleSlideIndexes,
  item,
  isInfinite,
  highlightedSlidesIndexes,
  setHighlightedSlidesIndexes,
  withDescription = false,
  descriptionOnHover = false,
  withVideo = false,
  withProgress = false,
  slideNameWithDetails = false,
  withArrow = false,
  withCheck = false,
  withActionFab = false,
  withTitleOverlay = false,
  withActionButton = false,
  shouldShowActionButton = true,
  actionButtonLabel = 'Take the action',
  actionButtonStartIcon,
  withPlayButton = false,
  withStars = false,
  withJoinFab = false,
  joinFabProps = {},
  withLeaveFab = false,
  leaveFabProps = {},
  withDownloadFab = false,
  downloadFabProps = {},
  withCompletedFab = false,
  withTitleOrderNumberPrefix = false,
  onAction = () => {},
  sliderTypeName,
  sliderType,
  customSlideStyles = {},
  withP2P = false,
  withMissedLiveChallengeIcon = false,
  actionButtonDisabled = false,
}: SlideComponentProps) => {
  const TRANSITION_TIME = 500;
  const theme = useTheme();
  const isLarge = window.innerWidth > theme.breakpoints.values.md;

  const [initialHover, setInitialHover] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(!isLarge);
  const [showDescription, setShowDescription] = useState<boolean>(!isLarge);
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
  const [showActionButton, setShowActionButton] = useState<any>(!isLarge);
  const [videoCanPlay, setVideoCanPlay] = useState<boolean>(false);
  const slideRootRef = useRef<HTMLDivElement>(null);
  const [slideClassName, setSlideClassName] = useState<any>('')
  const [highlightedSlideIndex, setHighlightedSlideIndex] = useState(-1)

  const [slideNotification] = useSelector(
    (state: RootState) => [state.session.config.slideNotification],
    shallowEqual,
  );
  const { closeSlideNotification } = useSessionActions('closeSlideNotification');

  const emphasizeTimerRef = useRef<any>(null);
  const deEmphasizeTimerRef = useRef<any>(null);

  const slideActions = useContext(SlideActionsContext)?.getActions(sliderType);

  useEffect(() => {

    if (highlightedSlideIndex !== -1) {
      const className = createVisibleClass(
        highlightedSlideIndex,
        visibleSlideIndexes,
        isInfinite,
        noSlidesToSwipe,
        highlightedSlidesIndexes.length
      )

      setSlideClassName(className)
    }

  }, [highlightedSlideIndex, visibleSlideIndexes])

  // useEffect(() => {
  //   if (item.added) {
  //     console.log(`Slide-MOUNT sliderType:${sliderType}:`, item);
  //   } else if (item.removed || item.added) {
  //     const c = slideRootRef.current;
  //     const pp = c?.parentElement?.parentElement;
  //     if (!!pp) {
  //       !!c && (c.style.width = pp.style.width);
  //       pp.classList.add(styles.isRemoved);
  //     }
  //   } else {
  //     const c = slideRootRef.current;
  //     const pp = c?.parentElement?.parentElement;
  //     if (!!pp && pp.classList.contains(styles.isRemoved)) {
  //       pp.classList.remove(styles.isRemoved);
  //     }
  //   }
  //   return () => {
  //     if (item.added) {
  //       console.log(`Slide-UNMOUNT: sliderType: ${sliderType}`, item);
  //     }
  //   };
  // }, [item, sliderType]);

  const emphasize = () => {
    setHighlightedSlideIndex(index)
    clearTimeout(deEmphasizeTimerRef.current);
    emphasizeTimerRef.current = setTimeout(() => {
      setShowActionButton(true);
      setIsHighlighted(true);
    }, TRANSITION_TIME);
  };
  const deEmphasize = () => {
    setHighlightedSlideIndex(-1)
    clearTimeout(emphasizeTimerRef.current);
    deEmphasizeTimerRef.current = setTimeout(() => setIsHighlighted(false), TRANSITION_TIME);
  };

  const focusBox = () => {
    if (!isLarge) {
      return;
    }

    !initialHover && setInitialHover(true);
    setShowDetails(true);
    return emphasize();
  };
  const unFocusBox = () => {
    if (!isLarge) {
      return;
    }

    setShowActionButton(false);
    setShowDetails(false);
    return deEmphasize();
  };

  const handlePlayClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (slideActions?.onPlayClick) {
        slideActions.onPlayClick(e, item);
      }
    },
    [item, slideActions],
  );

  const handleTileClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (slideActions?.onTileClick) {
        slideActions.onTileClick(e, item);
      }
    },
    [item, slideActions],
  );

  const handleTileLinkButtonClick = useCallback(
    (item: any) => {
      if (slideActions?.onLinkButtonClick) {
        slideActions.onLinkButtonClick(item);
      }
    },
    [slideActions],
  );

  const getBackground = () => {
    return item.isCurrent ? '#2F2F2F' : showDetails ? 'black' : 'transparent';
  };

  let showNotification = false;
  if (slideNotification.id === `${sliderTitle}-${item.id}`) {
    showNotification = true;
  }

  const snackbarClassess = useSnackbarStyles();

  const withFabs = withCompletedFab ||
  withDownloadFab ||
  withJoinFab ||
  withLeaveFab

  return (
    <div
      ref={slideRootRef}
      style={{
        position: 'relative',
        backgroundColor: getBackground(),
      }}
      className={cx(
        snackbarClassess.root,
        slideClassName,
        isHighlighted ? 'hovered' : '',
        '--fullHeight',
      )}
      onMouseLeave={() => {
        // const visibleClass = createVisibleClass(
        //   visibleSlideIndexes,
        //   index,
        //   noSlidesToSwipe,
        //   isInfinite,
        //   highlightedSlidesIndexes.length,
        // )
        // setSlideClassName(visibleClass)
        unFocusBox()
      }}
    >
      <Snackbar
        style={{ width: '100%', position: 'absolute', top: 0, left: 0 }}
        ContentProps={{
          style: {
            fontSize: '14px',
            width: '100%',
            borderRadius: 0,
            background: '#ffffff',
            color: '#000000',
            display: 'flex',
            justifyContent: 'space-between',
          },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={showNotification}
        autoHideDuration={4000}
        onClose={() => {
          closeSlideNotification();
        }}
        action={
          <Button
            style={{ fontSize: '14px' }}
            onClick={() => closeSlideNotification()}
            color='inherit'
          >
            {slideNotification.actionLabel}
          </Button>
        }
        message={slideNotification.message}
        key={'topleft'}
      />
      <Grow in>
        <React.Fragment>
          <Box
            id='__tile'
            css={customSlideStyles}
            className={isLarge ? cx('--fullHeight') : ''}
            onMouseEnter={() => {
              // const visibleClass = createVisibleClass(
              //   visibleSlideIndexes,
              //   index,
              //   noSlidesToSwipe,
              //   isInfinite,
              //   highlightedSlidesIndexes.length,
              // )
              // setSlideClassName(visibleClass)
              focusBox()
            }}
            style={{ cursor: slideActions?.onTileClick ? 'pointer' : 'default' }}
            onClick={handleTileClick}
            position='relative'
          >
            {withTitleOverlay && (
              <Fade in={!showDetails}>
                <TitleOverlay item={item} />
              </Fade>
            )}
            {withPlayButton && item.showPlayButton && (
              <Fade in={showDetails}>
                <PlayButton onClick={handlePlayClick} />
              </Fade>
            )}
            {withStars && (
              <Fade in={showDetails}>
                <Stars item={item} />
              </Fade>
            )}
            {withVideo && item.trailerVideo && (
              <Video
                item={item}
                index={index}
                initialHover={initialHover}
                showDetails={showDetails}
                videoCanPlay={videoCanPlay}
                transitionTime={TRANSITION_TIME}
                setVideoCanPlay={setVideoCanPlay}
                isHighlighted={isHighlighted}
                highlightedSlidesIndexes={highlightedSlidesIndexes}
              />
            )}
            <div
              style={{
                background: '#000',
                height: '100%',
              }}
            >
              <img
                id='__imgTile'
                style={{
                  ...style,
                  opacity: withVideo && videoCanPlay && showDetails ? 0 : 1,
                }}
                key={index}
                src={
                  item.desktopCoverImageWithTitle
                    ? item.desktopCoverImageWithTitle.url
                    : item.desktopCoverImage
                    ? item.desktopCoverImage.url
                    : null
                }
                alt={item.title || item.name}
                {...tabIndex}
              />
              {withP2P && !withMissedLiveChallengeIcon && (
                <Box position='absolute' top={0} right={0} mt={1} mr={1}>
                  <StyledP2PFab size='medium' aria-label='p2p feedback'>
                    <span style={{ lineHeight: 1, fontSize: '8px' }}>
                      <b style={{ fontSize: '12px' }}>P2P</b>
                      <br /> feedback
                    </span>
                  </StyledP2PFab>
                </Box>
              )}
              {item.hasOwnProperty('submission') && !item.is_live && !item.is_p2p && (
                <Box position='absolute' top={0} right={0} mt={1} mr={1}>
                  <ChallengeStar
                    disableRipple
                    disableTouchRipple
                    size='medium'
                    aria-label='challenge'
                  >
                    {item.submission?.status === 'COMPLETED' ? (
                      <img
                        style={{ height: '20px' }}
                        src={mountainFull}
                        alt={'completed challenge'}
                      />
                    ) : (
                      <img
                        style={{ height: '20px' }}
                        src={mountain}
                        alt={'incomplete challenge'}
                      />
                    )}
                  </ChallengeStar>
                </Box>
              )}
              {withMissedLiveChallengeIcon && (
                <Box position='absolute' top={0} right={0} mt={1} mr={1}>
                  <Tooltip
                    key={item.id}
                    title='Missed live challenge'
                    arrow
                    placement='top'
                    aria-label='missed live challenge'
                  >
                    <img
                      style={{ height: '48px' }}
                      src={mountainMissed}
                      alt={'incomplete challenge'}
                    />
                  </Tooltip>
                </Box>
              )}
            </div>
          </Box>
          <Box
            id='--secondBox'
            position={isLarge ? 'absolute' : 'relative'}
            width='100%'
            style={{
              transition: `${TRANSITION_TIME}ms all`,
              borderRadius: '0 0 5px 5px',
              background: getBackground(),
            }}
          >
            {withProgress && item.progress > 0 && (
              <BorderLinearProgress
                style={{
                  top: '-1px',
                  height: '6px',
                  width: '100%',
                }}
                variant='determinate'
                value={(item as any).progress * 100}
              />
            )}
            <Grid container justify='center'>
              <Grid item xs={withFabs ? 8 : 12} md={withFabs ? 9 : 12}>
                <Box ml={'10px'}>
                  <TitleAndDetails
                    withTitleOverlay={withTitleOverlay}
                    showDetails={showDetails}
                    item={item}
                    withArrow={withArrow}
                    withCheck={withCheck}
                    slideNameWithDetails={slideNameWithDetails}
                    transitionTime={TRANSITION_TIME}
                    sliderTypeName={sliderTypeName}
                    sliderType={sliderType}
                    withTitleOrderNumberPrefix={withTitleOrderNumberPrefix}
                    onLinkButtonClick={handleTileLinkButtonClick}
                  />
                </Box>
              </Grid>
              <Grid item xs={withFabs ? 4 : undefined} md={withFabs ? 3 : undefined}>
                {!item.comingSoon && (
                  <Fabs
                    showDetails={showDetails}
                    shouldShowDownloadFab={shouldShowActionButton}
                    withCompletedFab={withCompletedFab}
                    withDownloadFab={withDownloadFab}
                    downloadFabProps={downloadFabProps}
                    withJoinFab={withJoinFab}
                    joinFabProps={joinFabProps}
                    withLeaveFab={withLeaveFab}
                    leaveFabProps={leaveFabProps}
                  />
                )}
              </Grid>
              {withActionButton && shouldShowActionButton && (
                <Grid item xs={12} sm={12} md={8}>
                  <Fade in={showActionButton}>
                    <Box ml={1} mr={1} mt={1} mb={1}>
                      <SlideActionButton
                        disabled={actionButtonDisabled}
                        fullWidth
                        size='small'
                        style={{minWidth: '180px'}}
                        color='secondary'
                        variant='contained'
                        startIcon={actionButtonStartIcon}
                        onClick={onAction}
                      >
                        {actionButtonLabel}
                      </SlideActionButton>
                    </Box>
                  </Fade>
                </Grid>
              )}
              {withDescription && !descriptionOnHover && item.description && isLarge && (
                <Grid item xs={12}>
                  <Fade in={showDetails}>
                    <div>
                      <Description
                        setShowDescription={setShowDescription}
                        showDescription={showDescription}
                        transitionTime={TRANSITION_TIME}
                      />
                    </div>
                  </Fade>
                </Grid>
              )}
            </Grid>
            <Collapse in={descriptionOnHover ? showDetails : showDetails && showDescription}>
              <SlideDescription>{item.description}</SlideDescription>
            </Collapse>
          </Box>
        </React.Fragment>
      </Grow>
    </div>
  );
};

export default SlideComponent;
