import { createStyles, Switch, SwitchClassKey, SwitchProps, withStyles } from '@material-ui/core';

export const KairosSwitch = withStyles((theme) =>
  createStyles<SwitchClassKey, SwitchProps>({
    thumb: {},
    track: {
      border: 'solid 2px #ffffff',
      boxSizing: 'border-box',
      borderRadius: '11px',
    },
    root: {
      width: '62px',
      height: '42px',
      padding: '10px',
      marginRight: '1px'
    },
    sizeSmall: {
      width: '55px',
      height: '38px',
      padding: '12px',
      '& .MuiSwitch-switchBase': {
        padding: '12px',
      },
      '& .MuiSwitch-thumb': {
        width: '14px',
        height: '14px',
      },
    },
    checked: {},
    colorPrimary: {},
    colorSecondary: {},
    disabled: {},
    input: {},

    switchBase: {
      padding: '11px',
      '&$checked': {
        // transform: 'translateX(15px)',
        '& + .MuiSwitch-track': {
          borderColor: theme.palette.secondary.light,
        },
      },
    },
  }),
)(Switch);
