/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, IconButton, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DehazeIcon from '@material-ui/icons/Dehaze';
import curjosLogo from 'assets/curjosLogo.png';
import { NotificationsMenu } from 'common/components/Notifications/NotificationsMenu';
import AvatarMenu from 'common/components/Profile/AvatarMenu';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import * as constants from '../../../state/session/constants';
import { MainMenuPopper } from './MainMenuPopper';
import { LanguageChooser } from 'common/components/Header/LanguageChooser';

type HeaderProps = {
  sticky?: boolean;
  withBackground?: boolean;
  withBoxShadow?: boolean;
  withPopper?: boolean;
  negativeMargin?: boolean;
  hasAuthDialogs?: boolean;
  withNotifications?: boolean;
  withLanguageSelector?: boolean;
};

type HeaderClassKey =
  | 'root'
  | 'marginBox'
  | 'centralContainer'
  | 'contentContainer'
  | 'button'
  | 'productBox'
  | 'productName';

const useStyles = makeStyles<Theme, HeaderProps, HeaderClassKey>((theme) => ({
  root: (props) => ({
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 'none',
    alignItems: 'center',
    height: '70px',
    position: props.sticky ? 'sticky' : 'relative',
    padding: '0px',
    marginBottom: props.sticky ? '-70px' : 0,
    zIndex: 1101,

    backgroundColor: props.withBackground ? theme.palette.grey[900] : 'transparent',
    // TEMP, to be used from theme
    boxShadow: props.withBoxShadow ? '0 4px 6px 0 rgba(0, 0, 0, 0.84)' : 'none',
  }),

  marginBox: {
    // TODO: remove if styled component will do the job - mirror is in CornerBoxContainer
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '126px',
    flex: '0 1 auto',
  },

  centralContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    maxWidth: 'none',
    width: 'auto',
    height: '100%',
    padding: '0px',
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: '1 0 auto',
    margin: '0px',
    padding: '0px',
    maxWidth: 'none',
  },

  button: {
    marginLeft: theme.spacing(5),
    textTransform: 'uppercase',
    paddingBottom: '4px',
    marginBottom: '-4',
    borderBottom: 'solid 1px transparent',
    transition: 'border-bottom .5s ease',
    cursor: 'pointer',

    '&:hover': {
      borderBottom: 'solid 1px #fff',
      transition: 'border-bottom .5s ease',
    },
  },
  productBox: {
    flex: '0 1 auto',
    alignItems: 'center',
    display: 'flex',
    padding: '0px',

    '& img': {
      width: '60px',
      height: '60px',
    },
  },

  productName: {
    marginLeft: '30px',
  },
}));

export const Header = ({
  hasAuthDialogs = false,
  withNotifications = true,
  withLanguageSelector = true,
  ...props
}: HeaderProps) => {
  const classes = useStyles(props);
  const history = useHistory();

  const bounded = useSessionActions('setLoginDialog');

  const user = useSelector((state: RootState) => state.session.user, shallowEqual);

  const onLoginClick = useCallback(() => bounded.setLoginDialog(true), [bounded]);
  const [openMenu, setOpenMenu] = useState(false);
  const onMenu = useCallback(() => setOpenMenu(!openMenu), [openMenu]);

  const access = localStorage.getItem(constants.TOKEN_NAME);
  const isAuthenticated = !!access;
  const hasUser = !!user && access;

  return (
    <React.Fragment>
      <Box component='header' className={classes.root}>
        <Box
          display='flex'
          alignItems='center'
          ml={[1, 1, 4]}
          mt={-2}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/main/home')}
        >
          <img src={curjosLogo} alt='logo' style={{ height: '56px' }} />
        </Box>

        <Box className={classes.centralContainer}>
          <Box className={classes.contentContainer}>
            <Box display='flex' flex='1 1 auto' justifyContent='flex-end' p={0} alignItems='center'>
              {user && withLanguageSelector && user.company.allowChangeLanguage && (
                <LanguageChooser />
              )}

              {hasUser && withNotifications && (
                <Box ml={[1, 2, 4, 5]}>
                  <NotificationsMenu />
                </Box>
              )}

              {!hasUser && hasAuthDialogs && (
                <div className={classes.button} onClick={onLoginClick}>
                  Log in
                </div>
              )}
            </Box>
          </Box>
        </Box>

        {!hasUser && hasAuthDialogs && (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            width='126px'
            flex='0 1 auto'
          >
            <IconButton onClick={onMenu}>
              <DehazeIcon color='primary' fontSize='large' />
            </IconButton>
          </Box>
        )}

        {isAuthenticated && (
          <div>
            <AvatarMenu user={user} />
          </div>
        )}
      </Box>
      <Box position='relative'>
        {!hasUser && props.withPopper && <MainMenuPopper open={openMenu} />}
      </Box>
    </React.Fragment>
  );
};

export default React.memo(Header);
