/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useParams } from 'react-router-dom';
import { ChallengePlayer } from 'views/VideoPlayer/ChallengePlayer';
import EpisodePlayer from 'views/VideoPlayer/EpisodePlayer';
import { TrailerPlayer } from 'views/VideoPlayer/TrailerPlayer';
import { LiveChallengeHelperPlayer } from './LiveChallengeHelperPlayer';

export const EpisodePlayerRoute = () => {
  const { episodeId, autoplay } = useParams<{
    episodeId: string;
    autoplay?: string;
  }>();

  return <EpisodePlayer key={episodeId} episodeId={episodeId} autoplay={!!autoplay} />;
};

export const TrailerPlayerRoute = () => {
  const { courseId } = useParams<{
    courseId: string;
  }>();

  return <TrailerPlayer key={courseId} courseId={courseId} />;
};

export const ChallengePlayerRoute = () => {
  const { id, autoplay } = useParams<{
    id: string;
    autoplay?: string;
  }>();

  return <ChallengePlayer key={id} id={id} autoplay={!!autoplay} />;
};

export const LiveChallengeHelperPlayerRoute = () => {
  const { id, videoId, autoplay } = useParams<{
    id: string;
    videoId: string;
    autoplay?: string;
  }>();

  return <LiveChallengeHelperPlayer key={id} id={id} videoId={videoId} autoplay={!!autoplay} />;
};
