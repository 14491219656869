export const GO_BACK_TO_LS_KEY = 'goBackTo'

export const saveRouteToGoBack = (route) => {
  localStorage.setItem(GO_BACK_TO_LS_KEY, route)
}

// export const goBack = (history) => {
//   const destination = localStorage.getItem(GO_BACK_TO_LS_KEY)
//
//   console.log(destination)
//
//   if (destination) {
//     history.push(destination)
//   } else {
//     history.push('/main/my-courses')
//   }
//
//   clearGoBack()
// }

export const clearGoBack = () => localStorage.removeItem(GO_BACK_TO_LS_KEY)
