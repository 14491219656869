import { unwrapResult } from '@reduxjs/toolkit';
import {
  ConfirmationResult,
  SimpleConfirmationDialog,
} from 'common/components/Dialogs/SimpleConfirmationDialog';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useChallengesActions } from 'state/challenges/useChallengesActions';
import SlideComponent, { SlideComponentProps } from '../index';
import { isAfter, parseISO, toDate } from 'date-fns';
import { useViewsActions } from '../../../../../state/views/useViewsActions';

interface ChallengeSlideInProgressProps extends Omit<SlideComponentProps, 'item'> {
  item: Challenges.IChallengeInProgres;
}

const ChallengeSlideInProgress = ({ ...props }: ChallengeSlideInProgressProps) => {
  const history = useHistory();
  const intl = useIntl();
  const [leaveConfirm, setLeaveConfirm] = useState(false);

  const boundedChallenges = useChallengesActions(
    'setSubmitChallengeDialog',
    'removeSubmission',
    'getChallenge',
  );

  const [bounded] = useState({
    ...useViewsActions('getMyCoursesData'),
  });

  const handleSubmission = useCallback(() => {
    boundedChallenges
      .getChallenge(props.item.id)
      .then(unwrapResult)
      .then((challengeDetails) => {
        boundedChallenges.setSubmitChallengeDialog(challengeDetails);
        history.push(`/main/submit-challenge/${props.item.id}`);
      })
      .catch((error) => {
        boundedChallenges.setSubmitChallengeDialog(null, false);
        console.log('Error while fetching challenge details for Submission', error);
      });
  }, [boundedChallenges, history, props.item.id]);

  const handleLeaveDialogClose = useCallback(
    ({ result }: ConfirmationResult) => {
      if (result) {
        boundedChallenges
          .removeSubmission(props.item.id)
          .then(unwrapResult)
          .then(() => bounded.getMyCoursesData());
      } else {
      }
      setLeaveConfirm(false);
    },
    [bounded, boundedChallenges, props.item.id],
  );

  const isMissedLiveChallenge =
    props.item.is_live &&
    props.item.hasOwnProperty('availableTo') &&
    isAfter(new Date(), toDate(parseISO(props.item.availableTo || '')));

  const instructions = props.item.instructions;

  return (
    <React.Fragment>
      <SlideComponent
        withP2P={props.item.is_p2p}
        withMissedLiveChallengeIcon={isMissedLiveChallenge}
        withDescription
        withLeaveFab
        leaveFabProps={{
          onClick: () => setLeaveConfirm(true),
        }}
        withDownloadFab
        downloadFabProps={{
          onClick: () => {
            if (instructions != null) {
              window.open(instructions.url);
            }
          },

          disabled: instructions === null,
        }}
        withActionButton
        actionButtonLabel='WORK ON CHALLENGE'
        onAction={handleSubmission}
        withStars={true}
        {...props}
      />
      {leaveConfirm && (
        <SimpleConfirmationDialog
          open={leaveConfirm}
          confirmationLabel='Yes'
          cancellationLabel='No'
          header={`${props.item.title}`}
          text='Are you sure you want to leave this challenge?'
          onClose={handleLeaveDialogClose}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(ChallengeSlideInProgress);
