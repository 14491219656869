import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useState } from 'react';
import { useChallengesActions } from 'state/challenges/useChallengesActions';
import { useSessionActions } from 'state/session/useSessionActions';
import SlideComponent, { SlideComponentProps } from '../index';
import { deferAsyncAction } from 'utils';
import CheckIcon from '@material-ui/icons/Check';
import { CircularProgress } from '@material-ui/core';
import { useViewsActions } from '../../../../../state/views/useViewsActions';
import {useIntl} from "react-intl";

const ChallengeSlideOpen = ({ item, sliderTitle, ...rest }: SlideComponentProps) => {
  const intl = useIntl()
  const { openSlideNotification } = useSessionActions('openSlideNotification');
  const boundChallenges = useChallengesActions(
    'postTakeChallenge',
    'getChallenges',
    'removeSubmission',
    'setLocalChallengeSubmissionState',
  );
  const [bounded] = useState({
    ...useViewsActions('getMyCoursesData', 'getCourseToOverview'),
  });
  const [waiting, setWaiting] = useState(false);

  const openNotification = useCallback(
    (message: string, actionLabel: string, isError?: boolean, action?: () => void) => {
      return openSlideNotification({
        id: `${sliderTitle}-${item.id}`,
        message: message,
        actionLabel: actionLabel,
      });
    },
    [item.id, openSlideNotification, sliderTitle],
  );

  const cancelJoin = useCallback(() => {
    boundChallenges
      .removeSubmission(item.id)
      .catch((error) => console.log('Error when canceling joining the challenge', error));
  }, [boundChallenges, item.id]);

  const challengeId = item.id;
  const handleTakeChallenge = useCallback(() => {
    setWaiting(true);

    boundChallenges
      .postTakeChallenge(challengeId)
      .then(unwrapResult)
      .then(async (res) => {
        boundChallenges.setLocalChallengeSubmissionState(challengeId, 'taken');
        openNotification(
          intl.formatMessage({
            id: 'OpenChallenges.Notification.Joined',
            defaultMessage: `You have joined the challenge successfully!`,
          }),
          intl.formatMessage({
            id: 'Literal.Cancel',
            defaultMessage: 'Cancel',
          }),
          false,
          cancelJoin,
        );
        setWaiting(false);
        bounded.getMyCoursesData();
        await deferAsyncAction(3000, boundChallenges.getChallenges);
      })
      .catch((error) => {
        openNotification(intl.formatMessage({
          id: 'OpenChallenges.Notification.ErrorJoiningChallenge',
          defaultMessage: 'Error while joining challenge',
        }), intl.formatMessage({
          id: 'Literal.OK',
          defaultMessage: `OK`,
        }), true);
      })
      .finally(() => {
        setWaiting(false);
      });
  }, [boundChallenges, bounded, cancelJoin, challengeId, openNotification]);

  const instructions = item.instructions;

  return (
    <SlideComponent
      withP2P={item.is_p2p}
      withDescription
      withDownloadFab
      downloadFabProps={{
        onClick: () => {
          if (instructions != null) {
            window.open(instructions.url);
          }
        },

        disabled: instructions === null,
      }}
      withActionButton
      actionButtonLabel={item.submission === null
        ? intl.formatMessage({
          id: 'OpenChallenges.ActionButtonLabel.Join',
          defaultMessage: `Join challenge`,
        })
        : intl.formatMessage({
          id: 'OpenChallenges.ActionButtonLabel.Accepted',
          defaultMessage: `Challenge accepted`,
        })}
      actionButtonStartIcon={
        waiting ? (
          <CircularProgress
            size='20px'
            style={{
              position: 'relative',
              marginTop: '-12px',
              marginBottom: '-12px',
              marginLeft: '-5px',
            }}
          />
        ) : (
          item.submission && <CheckIcon style={{ marginTop: '-5px', marginBottom: '-5px' }} />
        )
      }
      onAction={item.submission === null ? handleTakeChallenge : undefined}
      item={item}
      sliderTitle={sliderTitle}
      {...rest}
    />
  );
};

export default React.memo(ChallengeSlideOpen);
