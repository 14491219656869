/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Grid, Grow, Typography, useTheme } from '@material-ui/core';
import { MediumAvatar } from 'common/components/Profile/Components/Avatars';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { FileDescriptor, Uploader } from 'common/components/Uploader';
import styled from 'styled-components';
import { ReplyFeedbackModal } from 'views/MyChallenges/sections/Feedback/ReplyFeedbackModal';
import { MediumButton } from 'common/components/General/Buttons';
import { cloneDeep } from 'lodash';
import RatingSection from "./RatingSection";
import {format, parseISO, toDate} from "date-fns";

const Arrow = styled.div`
  width: 60px;
  height: 60px;
  border: solid 1px #ffffffa6;
  position: absolute;
  top: -31px;
  right: 75px;
  @media (max-width: 960px) {
    width: 30px;
    height: 30px;
    right: 50px;
    top: -16px;
  }
  @media (max-width: 680px) {
    width: 30px;
    height: 30px;
    right: 20px;
    top: -16px;
  }
  background-color: #101010;

  transform: rotate(45deg) skew(18deg, 18deg);
  border-right: 0;
  border-bottom: 0;
  border-radius: 5px 0;
`;

const ArrowLeft = styled.div`
  width: 60px;
  height: 60px;
  border: solid 1px #ffffffa6;
  position: absolute;
  top: -31px;
  left: 75px;
  @media (max-width: 960px) {
    width: 30px;
    height: 30px;
    left: 50px;
    top: -16px;
  }
  @media (max-width: 680px) {
    width: 30px;
    height: 30px;
    left: 20px;
    top: -16px;
  }
  background-color: #101010;

  transform: rotate(45deg) skew(18deg, 18deg);
  border-right: 0;
  border-bottom: 0;
  border-radius: 5px 0;
`;

export const P2PFeedbackContent = ({
  challengeItem,
  item,
  initialMedia = [
    { name: '', id: '', url: '' },
  ],
}: {
  challengeItem: any;
  item: any;
  initialMedia?: Challenges.IMedia[];
}) => {
  const theme = useTheme();
  const params = useParams<{ challengeId?: string; edit?: string }>();
  const intl = useIntl();
  const [showReplyModal, setShowReplyModal] = useState(false);

  const [initialFeedbackMedia, setInitialFeedbacklMedia] = useState<Challenges.IMedia[]>([]);

  useEffect(() => {
    setInitialFeedbacklMedia(item.media || []);
  }, [item]);

  const showFileUploader = challengeItem.submission?.p2pFeedbackGiven && item.media.length > 0;

  // let temp: Challenges.IChallengeDTO = cloneDeep(item);

  return (
    <Grow in>
      <Box
        key={`container-${item.id}-${showFileUploader}`}
        mt={['45px', '45px', '100px']}
        mb={['15px', '15px', '15px']}
        // mr={[2, 4, 7]}
        // pr={[2, 4, 6]}
        gridColumn='span 2'
        boxSizing='border-box'
        justifySelf='right'
      >
        <Grid container justify='flex-end'>
          <Grid item xs={12} md={showFileUploader ? 12 : 8}>
            <div
              css={{
                padding: '1px 0px',
                position: 'relative',
              }}
            >
              <Box
                fontSize={['14px', '14px', '16px', '18px']}
                color={theme.palette.primary.light}
                position='absolute'
                left={0}
                bottom={5}
              >
                {challengeItem.is_p2p ? (
                  <FormattedMessage
                    id='MyChallenges.Sections.Feedback.P2P.Label.Feedback'
                    defaultMessage='PEER-TO-PEER FEEDBACK'
                  />
                ) : (
                  <FormattedMessage
                    id='MyChallenges.Sections.Feedback.Coach.Label.Feedback'
                    defaultMessage='FEEDBACK'
                  />
                )}
              </Box>
            </div>
            <Box
              border={`solid 1px ${theme.palette.primary.light}`}
              borderRadius='4px'
              pt='0px'
              pb={['10px', '15px', '40px', '60px']}
              px={['10px', '20px', '40px', '60px']}
              bgcolor={theme.palette.grey[900]}
              position='relative'
            >
              <Box
                width='100%'
                my={[1, 2, 3, 4, 5]}
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap'
              >
                <Arrow />

                <Grid container>
                  <Grid item xs={12} md={showFileUploader ? 6 : 12}>
                    <Box fontSize={['12px', '13px', '18px']}>
                      <Box zIndex={1} color={theme.palette.primary.light}>
                        <Box fontWeight={600}>
                          {intl.formatDate(item.completedAt, {
                            weekday: 'long',
                          })}
                        </Box>
                        <Box>{`${format(toDate(parseISO(item.completedAt)), 'dd.MM.yyyy')} at ${format(toDate(parseISO(item.completedAt)), 'HH:mm')}`}</Box>
                      </Box>
                      <Box fontSize={['13px', '15px', '16px']} my={[1, 2, 3]} pr={4}>
                        <Typography
                          component='div'
                          variant='body2'
                          css={{ gridColumn: '1', fontSize: 'inherit' }}
                        >
                          {challengeItem.submission?.p2pFeedbackGiven
                            ? item.content
                            : intl.formatMessage({
                              id: 'P2PFeedbackContent.HiddenFeedbackMessage',
                              defaultMessage:
                                "This feedback is hidden because you didn't give feedback to any peer.",
                            })
                          }
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {showFileUploader && (
                      <Box
                        borderLeft={{ lg: 'solid 1px grey' }}
                        height='100%'
                        mt={[0, 0, 3]}
                        ml={-2}
                        pl={2}
                      >
                        <Uploader media={initialFeedbackMedia} displayOnly onChange={() => {}} />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {item.evaluation
          ? <Grow in>
            <Grid container>
              <Grid item xs={12} md={8} lg={5} xl={6}>
                <Box
                  mt={['45px', '45px', '100px']}
                  mb={['15px', '15px', '40px']}
                  boxSizing='border-box'
                >
                  <div
                    css={{
                      padding: '1px 0px',
                      position: 'relative',
                    }}
                  >
                    <Box
                      fontSize={['14px', '14px', '16px', '18px']}
                      color={theme.palette.primary.light}
                      position='absolute'
                      right={0}
                      bottom={5}
                    >
                      <FormattedMessage
                        id='MyChallenges.Sections.Feedback.MyReply'
                        defaultMessage='MY REPLY'
                      />
                    </Box>
                  </div>
                  <Box
                    border={`solid 1px ${theme.palette.primary.light}`}
                    borderRadius='4px'
                    pt='0px'
                    pb={['10px', '15px', '40px', '60px']}
                    px={['10px', '20px', '40px', '60px']}
                    bgcolor={theme.palette.grey[900]}
                    position='relative'
                  >
                    <Box
                      width='100%'
                      my={[1, 2, 3, 4, 5]}
                      display='flex'
                      justifyContent='space-between'
                      flexWrap='wrap'
                    >
                      <ArrowLeft />

                      <Grid container style={{zIndex: 2}}>
                        <Grid item xs={12}>
                          <Box fontSize={['12px', '13px', '18px']}>
                            <Box display='flex' flexWrap='wrap' justifyContent='space-between' alignItems='center'>
                              <Box>
                                <Box zIndex={1} color={theme.palette.primary.light}>
                                  <Box fontWeight={600}>
                                    {intl.formatDate(item.evaluation.createdAt, {
                                      weekday: 'long',
                                    })}
                                  </Box>
                                  <Box>
                                    {`${format(toDate(parseISO(item.evaluation.createdAt)), 'dd.MM.yyyy')} at ${format(toDate(parseISO(item.evaluation.createdAt)), 'HH:mm')}`}
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <RatingSection
                                  size='small'
                                  disabled
                                  align='right'
                                  maxRate={5}
                                  stars={item.evaluation.rate}
                                  setStars={() => {}}
                                />
                              </Box>
                            </Box>
                            <Box fontSize={['13px', '15px', '16px']} my={[1, 2, 3]} pr={4}>
                              <Typography
                                component='div'
                                variant='body2'
                                css={{ gridColumn: '1', fontSize: 'inherit' }}
                              >
                                {item.evaluation.content}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grow>
          : challengeItem.submission?.p2pFeedbackGiven &&
            <Grid container justify='flex-end'>
              <Grid item xs={12} md={showFileUploader ? 12 : 8}>
                <Box my={4} textAlign='center'>
                <MediumButton onClick={() => setShowReplyModal(true)} variant='contained' color='secondary'>
                  {intl.formatMessage({
                    id: 'FeedbackContent.ReplyToFeedbackButton',
                    defaultMessage: `REPLY TO FEEDBACK`,
                  })}
                </MediumButton>
                </Box>
              </Grid>
            </Grid>
        }

        <ReplyFeedbackModal challengeItem={challengeItem} feedbackItem={item} open={showReplyModal} handleClose={() => setShowReplyModal(false)} />
      </Box>
    </Grow>
  );
};
