export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const scrollToRef = (ref) => {
  if (ref && ref.current) {
    window.scrollTo(0, ref.current.offsetTop);
  }
};

export const getCourseDuration = (course) => {
  let duration = 0;

  course.chapters.forEach((chapter) => {
    chapter.episodes.forEach((episode) => {
      if (episode.video?.duration) {
        duration += episode.video.duration;
      }
    });
    chapter.challenges.forEach((challenge) => {
      if (challenge.video?.duration) {
        duration += challenge.video.duration;
      }
    })
  });

  const durationInHours = duration / 3600;

  return getRoundedDurationInHours(durationInHours);
};

export const getRoundedDurationInHours = (duration) => {
  const durationDecimalPart = duration % 1;

  if (duration < 1) {
    switch (true) {
      case durationDecimalPart < 0.5:
        return 0.5;
      case durationDecimalPart >= 0.5:
        return 1;
      default:
        return 0;
    }
  } else {
    switch (true) {
      case durationDecimalPart < 0.25:
        return parseInt(duration);
      case durationDecimalPart >= 0.25 && durationDecimalPart < 0.75:
        return parseInt(duration) + 0.5;
      case durationDecimalPart >= 0.75:
        return Math.ceil(duration);
      default:
        return 0;
    }
  }
};
