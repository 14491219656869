import React from 'react';
import SlickCarousel from 'common/components/SlickCarousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import mountainFull from 'assets/mountainFull.svg';
import { useIntl } from 'react-intl';

const Completed = ({
  titleCentered = false,
  title,
  filterOut = [],
}: {
  titleCentered?: boolean;
  title?: string;
  filterOut?: Challenges.IChallengeDTO[];
}) => {
  const intl = useIntl();
  const sliderTitle =
    title ||
    intl.formatMessage({
      id: 'MyChallenges.Sections.Completed.DefaultSliderTitle',
      defaultMessage: 'COMPLETED CHALLENGES',
    });
  const items = useSelector((state: RootState) => state.challenges.items.completed, shallowEqual);
  const idsToFilterOut = filterOut.map((c) => c.id);
  const filteredItems = items.filter((i) => !idsToFilterOut.includes(i.id));

  return (
    <div>
      {/*{filteredItems.length > 0 && (*/}
        <SlickCarousel
          withIcon
          Icon={
            titleCentered ? null : (
              <img style={{ height: '25px' }} src={mountainFull} alt={'incomplete challenge'} />
            )
          }
          nothingToShowMessage={intl.formatMessage({
            id: 'MyChallenges.Sections.Completed.NothingToShowMessage',
            defaultMessage:
              "It seems like you don't have any completed challenges at the moment.",
          })}
          titleBright
          titleCentered={titleCentered}
          sliderType='challenges-completed'
          items={filteredItems}
          sliderTitle={sliderTitle}
          slidesToShow={3}
          slidesToScroll={3}
          speed={500}
        />
      {/*)}*/}
    </div>
  );
};

export default React.memo(Completed);
