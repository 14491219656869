import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Checkbox,
  IconButton,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from 'react-router-dom';
import api from '../../../api/apiHandler';
import { useSessionActions } from '../../../state/session/useSessionActions';
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxRoot: {
      color: '#fff',
    },
  }),
);

const P2PMessageBox = ({ toggleLiveChallengeMessageBox, goToChallenge, setLiveChallengeInstructionModal, setConsentP2PModal }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl()

  const [disableMessageBoxCheckbox, setDisableMessageBoxCheckbox] = useState(false);

  const { getCurrentProfile } = useSessionActions('getCurrentProfile');

  const handleCheckbox = () => {
    setDisableMessageBoxCheckbox(!disableMessageBoxCheckbox);
  };

  return (
    <div>
      <Box style={{ background: '#101010', borderRadius: '5px' }} pb={4}>
        <Grid container>
          <Grid item xs={12}>
            <Box textAlign='right'>
              <IconButton
                onClick={() => {
                  disableMessageBoxCheckbox &&
                    api.setLiveChecked().then((res) => getCurrentProfile());
                  toggleLiveChallengeMessageBox();
                }}
              >
                <Clear color='primary' />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <h2 style={{ textAlign: 'center' }}>Live Challenge Instructions</h2>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign='center' px={[3, 3, 5]}>
              <p>
                {
                  intl.formatMessage({
                    id: 'LiveChallengeMessageBox.InstructionVideo.Description',
                    defaultMessage:
                      'Do you know how Live Challenges and the P2P Feedback process work?',
                  })
                }
              </p>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item xs={12} md={8}>
                <Box mt={2} px={3}>
                  <Button
                    fullWidth
                    color='secondary'
                    variant='contained'
                    onClick={() => {
                      // history.push('/watch/live-challenge-introduction')
                      // toggleLiveChallengeMessageBox();
                      setLiveChallengeInstructionModal(true)
                    }}
                  >
                    {
                      intl.formatMessage({
                        id: 'LiveChallengeMessageBox.Literal.GoToInstructions',
                        defaultMessage:
                          'Go to instructions',
                      })
                    }
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {/*<Grid container justify='center'>*/}
            {/*  <Grid item xs={12} md={8}>*/}
            {/*    <Box mt={1} px={3}>*/}
            {/*      <Button*/}
            {/*        fullWidth*/}
            {/*        color='primary'*/}
            {/*        variant='outlined'*/}
            {/*        onClick={() => {*/}
            {/*          if (!!user.company.liveChallengeInstructionsFile) {*/}
            {/*            window.open(user.company.liveChallengeInstructionsFile);*/}
            {/*          }*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        Download pdf*/}
            {/*      </Button>*/}
            {/*    </Box>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <Grid container justify='center'>
              <Grid item xs={12} md={8}>
                <Box mt={1} px={3}>
                  <Button
                    fullWidth
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                      disableMessageBoxCheckbox && api.setLiveChecked().then((res) => getCurrentProfile());
                      toggleLiveChallengeMessageBox();
                      setConsentP2PModal(true)
                    }}
                  >
                    {
                      intl.formatMessage({
                        id: 'LiveChallengeMessageBox.Literal.GoToChallenge',
                        defaultMessage:
                          'YES, CONTINUE',
                      })
                    }
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt={3} mx={3} display='flex' justifyContent='center' alignItems='center'>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkboxRoot}
                    checked={disableMessageBoxCheckbox}
                    onChange={handleCheckbox}
                    name='disable'
                  />
                }
                label={
                  intl.formatMessage({
                    id: 'LiveChallengeMessageBox.Literal.DoNotShowThisMessage',
                    defaultMessage:
                      "Do not show this message again.",
                  })
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default React.memo(P2PMessageBox);
