import { IconButton, Snackbar, SnackbarProps, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { omit } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { scrollToRef } from 'utils/helpers';

const SnackbarContentProps = {
  style: {
    width: '100%',
    borderRadius: 0,
    background: '#ffffff',
    color: '#000000',
    display: 'flex',
    justifyContent: 'center',
  },
};

const SnackbarEmptyProps = {
  style: {
    width: '100%',
    borderRadius: 0,
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
  },
};

const SnackbarErrorProps = {
  style: {
    width: '100%',
    borderRadius: 0,
    background: '#5d181c',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
  },
};

interface KairosSnackBarProps {
  channel: string;
  scrollOnMessage?: boolean;
  position?: 'sticky' | 'relative' | 'absolute';
  children?: (snack: SnackNotification) => React.ReactNode;
}

const SnackbarWithStyles = withStyles(() => ({
  root: (props: any) => ({
    width: '100%',
    position: props.position,
    top: 0,
    zIndex: 10,
    backgroundColor: 'transparent',
    '& .MuiSnackbarContent-message': {
      flex: '1 0 0',
      textAlign: 'center',
    },
  }),
}))(
  React.forwardRef<HTMLElement, SnackbarProps>((props, ref) => (
    <Snackbar ref={ref} {...omit(props, ['position'])} />
  )),
);

export const KairosSnackBar = ({
  channel,
  children,
  scrollOnMessage = false,
  position = 'absolute',
}: KairosSnackBarProps) => {
  const [key] = useState(`kairos-snackbar-${Date.now().toString()}`);
  let snack = useSelector((state: RootState) => state.session.config.snacks[channel], shallowEqual);

  const bounded = useSessionActions('clearSnackChannel');
  const snackbarRef = useRef<HTMLElement>();

  const messageBold: React.ReactNode =
    (!!snack && (snack?.messageBold || (children && children(snack)))) || null;

  const message: React.ReactNode =
    (!!snack && (snack?.message || (children && children(snack)))) || null;

  useEffect(() => {
    if (scrollOnMessage && !!message) {
      scrollToRef(snackbarRef);
    }
  }, [message, scrollOnMessage]);

  let now = Date.now();
  let ts = new Date(snack?.timestamp || '1990-11-11T10:12:05.000Z').valueOf();

  if (snack && snack.timestamp && now - ts > 10000) {
    bounded.clearSnackChannel(channel);
    snack = undefined;
  }

  return (
    <SnackbarWithStyles
      key={key}
      ref={snackbarRef}
      position={position}
      autoHideDuration={snack?.timeout || null}
      ContentProps={
        snack ? (snack.isError ? SnackbarErrorProps : SnackbarContentProps) : SnackbarEmptyProps
      }
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snack && snack.channel === channel}
      onClose={() => bounded.clearSnackChannel(channel)}
      action={
        <React.Fragment>
          {snack?.actionLabel ? (
            <Link to={snack.actionHref || '#snack'} onClick={snack?.action && snack.action()}>
              {snack?.actionLabel}
            </Link>
          ) : null}
          <IconButton
            onClick={() => {
              bounded.clearSnackChannel(channel);
              if (snack && snack.action) {
                snack.action();
              }
            }}
            color='inherit'
            size='small'
          >
            <Close />
          </IconButton>
        </React.Fragment>
      }
      message={
        <>
          <b>{messageBold}</b> {message}
        </>
      }
    />
  );
};
