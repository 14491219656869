import React from 'react';
import SlickCarousel from 'common/components/SlickCarousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useIntl } from 'react-intl';

const Completed = ({ items }: any) => {
  const intl = useIntl();
  return (
    <div>
      <SlickCarousel
        titleBright
        sliderType='courses-completed'
        items={items}
        sliderTitle={intl.formatMessage({
          id: 'MyCourses.Sections.Completed.SliderTitle',
          defaultMessage: 'COMPLETED COURSES',
        })}
        nothingToShowMessage={intl.formatMessage({
          id: 'MyCourses.Sections.Completed.NothingToShowMessage',
          defaultMessage:
            "It seems like you don't have any completed courses at the moment. To complete courses take on challenges and upload your results.",
        })}
        slidesToShow={3}
        slidesToScroll={3}
        speed={500}
      />
    </div>
  );
};

export default React.memo(Completed);
