import React from 'react';
import {
  Box,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Theme, withStyles } from '@material-ui/core/styles';
import SessionTile from './SessionTile';

const Accordion = withStyles({
  root: {
    marginBottom: '15px',
    background: '#000',
    overflow: 'hidden',
  },
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(24),
    marginLeft: 30,
    marginRight: 30,
    padding: 0,
    borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    fontWeight: 300,
    marginLeft: 30,
    marginRight: 30,
    padding: 0,
    paddingTop: 30,
  },
})(MuiAccordionDetails);

const SessionsAccordion = ({
  nothingToShowMessage = 'Nothing to show',
  isPast,
  sessions,
  title,
  leaveSession,
  signUpForSession,
  joinWaitingList,
  leaveWaitingList,
}: any) => {
  return (
    <div style={{ background: '#000' }}>
      <Accordion square defaultExpanded={true}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              fontSize='large'
              style={{
                color: '#fff',
              }}
            />
          }
        >
          <Box fontSize={['14px', '14px', '18px']}>{title}</Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justify='center'>
            {sessions.length === 0 ? (
              <Grid item xs={12} md={5} lg={3}>
                <Box
                  minHeight='10rem'
                  textAlign='center'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  {nothingToShowMessage}
                </Box>
              </Grid>
            ) : (
              sessions.map((session: any) => {
                return (
                  <SessionTile
                    key={session.id}
                    session={session}
                    isPast={isPast}
                    leaveSession={leaveSession}
                    signUpForSession={signUpForSession}
                    joinWaitingList={joinWaitingList}
                    leaveWaitingList={leaveWaitingList}
                  />
                );
              })
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default React.memo(SessionsAccordion);
