import { Fab, styled } from '@material-ui/core';

export const StyledFab = styled(Fab)(({ theme }) => ({
  '&.MuiFab-root': {
    color: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[800],
  },
  cursor: 'pointer',
  background: 'transparent',
  border: `1px solid grey`,
  textTransform: 'none',
  '&:hover': {
    background: theme.palette.grey[800],
  },
}));
