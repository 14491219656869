import { unwrapResult } from '@reduxjs/toolkit';
import { TabContentProps } from 'common/components/NavBar/tabsConfig';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'reducers';
import { useChallengesActions } from 'state/challenges/useChallengesActions';

export const SubmitChallengeLoader = ({ active }: TabContentProps) => {
  const history = useHistory();
  const { challengeId } = useParams<{ challengeId: string }>();
  const [actionsBase] = useState({});
  const bounded = Object.assign(actionsBase, {
    ...useChallengesActions('getChallenge', 'setSubmitChallengeDialog'),
  });
  const submission = useSelector((state: RootState) => state.challenges.submission);

  const hasFreshModel = useCallback(() => {
    return (
      !!challengeId &&
      !!submission.challengeItem &&
      submission.challengeItem.id === challengeId &&
      !!submission.loadedAt &&
      Date.now() - new Date(submission.loadedAt).valueOf() < 1000
    );
  }, [challengeId, submission.challengeItem, submission.loadedAt]);

  useEffect(() => {
    if (!active) {
      return;
    }
    if (!challengeId) {
      return history.go(-1);
    }
    if (hasFreshModel()) {
      return;
    }
    (async () => {
      const thunkResult = await bounded.getChallenge(challengeId);
      const challengeDetails = unwrapResult(thunkResult);
      bounded.setSubmitChallengeDialog(challengeDetails);
      history.push(`/main/submit-challenge/${challengeDetails.id}`);
    })().catch(() => {});
  }, [active, bounded, challengeId, hasFreshModel, history]);

  return <div></div>;
};
