import 'App.css';
import { HistoryHook } from 'HistoryHook';
import HelpLayout from 'layouts/Help';
import React, { useEffect } from 'react';
import {BrowserRouter, Redirect, Route, Switch, useHistory, withRouter} from 'react-router-dom';
import styles from './App.module.scss';
import AuthLayout from './layouts/Auth';
import MainLayout from './layouts/Main';
import WatchLayout from './layouts/WatchLayout';
import PrivateRoute from './routes/PrivateRoute';
import * as constants from './state/session/constants';
import { CookiesPolicyPopover } from './views/LandingPage/Components/CookiesPolicyPopover';

import { en, de } from 'assets/locale/data.json';
import { IntlProvider } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { CelebrationModal } from 'common/components/Celebrations/CelebrationModal';
import { devSwitches } from './state/session/constants';
import { clearGoBack } from './utils/goBack';
import {useChallengesActions} from "./state/challenges/useChallengesActions";

const supportedLocales = ['en', 'de'];

function App() {

  const {
    getChallenges,
  } = {
    ...useChallengesActions(
      'getChallenges'
    ),
  };

  useEffect(() => {
    clearGoBack();
    getChallenges()
  }, []);

  const isAuthenticated = localStorage.getItem(constants.TOKEN_NAME);
  const localesWithIndices = supportedLocales
    .map(
      (() => {
        let navigatorLanguages = navigator.languages.map((lang) =>
          lang.split('-')[0].toLowerCase(),
        );
        return (locale: string) => ({
          locale,
          index: navigatorLanguages.findIndex((x) => x === locale),
        });
      })(),
    )
    .filter((pair) => pair.index !== -1)
    .sort((a, b) => a.index - b.index);

  const defaultLanguage = localesWithIndices[0]?.locale || 'en';
  const userLanguage = useSelector(
    (state: RootState) => state.session.user?.language?.locale?.toLowerCase(),
    shallowEqual,
  );
  const language = userLanguage || defaultLanguage;

  const { getLanguages } = useSessionActions('getLanguages');
  useEffect(() => {
    getLanguages();
  }, [getLanguages]);

  return (
    // <div className={styles.App}>
    <IntlProvider key={language} locale={language} messages={language === 'de' ? de : en}>
      <BrowserRouter>
        <HistoryHook>
          <Switch>
            <Redirect from='/main' to='/main/home' exact />
            <PrivateRoute path='/main' render={(...props: any) => <MainLayout {...props} />} />
            <Route path='/auth' render={(...props: any) => <AuthLayout {...props} />} />
            <Route path='/watch' render={(...props: any) => <WatchLayout {...props} />} />
            <Route path='/help' render={(...props: any) => <HelpLayout {...props} />} />

            <Redirect from='/x' to='/auth/register/6bbbdcff-2728-4666-959d-c84ec8922ff4' />
            {/*<Redirect from='/y' to='/auth/reset/bdd354274cc684b0afc24a65821cd9d6' />*/}

            {isAuthenticated ? <Redirect from='/' to='/main' /> : <Redirect to='/auth' />}
          </Switch>
        </HistoryHook>
      </BrowserRouter>
      <CookiesPolicyPopover />
      {devSwitches.__celebrationsEnabled && <CelebrationModal />}
    </IntlProvider>
    // </div>
  );
}

export default React.memo(App);
