import {
  Badge,
  ClickAwayListener,
  Dialog,
  Fab,
  Fade,
  IconButton,
  makeStyles,
  Paper,
  Popper,
  styled,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import React, { useCallback, useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { NotificationsList } from './NotificationsList';
import PopperJs from 'popper.js';
import { palette, spacing, sizing, compose } from '@material-ui/system';
import ResizeObserver from 'resize-observer-polyfill';
import { debounce } from 'lodash';
import { KairosPushSnackBar } from 'common/components/General/KairosPushSnackBar';

const StyledFab = styled(Fab)(compose(palette, spacing, sizing));

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    backgroundColor: theme.palette.grey[300],
    maxHeight: '80vh',
    height: '80vh',
    minWidth: '720px',
  },
  menuPaperMobile: {
    backgroundColor: theme.palette.grey[300],
    maxHeight: '100vh',
    height: '100vh',
  },
  notificationBellRoot: {
    '& .MuiSvgIcon-root': {
      width: '1.3em',
      height: '1.3em',
    },
  },
  fabRoot: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

export const NotificationsMenu = ({
  withPushNotifications = process.env.REACT_APP_DEV_FEATURES === '1', // TEMP: feature disabled on staging until finished
}: {
  withPushNotifications?: boolean;
}) => {
  const menuAnchorRef = useRef<HTMLDivElement | null>(null);

  const eventSourceRef = useRef<EventSource>();
  const popperRef = useRef<PopperJs>(null);
  const paperRef = useRef<HTMLElement>();
  const resizeObserver = useRef(
    new ResizeObserver(() => {
      popperRef.current?.update();
    }),
  );

  const { menuOpen, user, unreaded, hasNotificationsinStore } = useSelector(
    (state: RootState) => ({
      menuOpen: state.session.flags.openNotificationsMenu,
      user: state.session.user,
      unreaded: state.session.notifications.unreaded,
      hasNotificationsinStore: state.session.notifications.items.length > 0,
    }),
    shallowEqual,
  );

  const isWideScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const bounded = useSessionActions(
    'getNotifications',
    'getUnreadedNotifications',
    'setOpenNotificationsMenu',
    'setOpenAvatarMenu',
    'registerNotification',
    'getUnreadedNotifications',
    'switchOpenNotificationMenu',
    'pushNotification',
  );
  const classess = useStyles();

  const userId = user?.id;
  useEffect(() => {
    bounded.getNotifications({});
    bounded.getUnreadedNotifications();
    if (!!userId) {
      const topic = `notifications/${userId}`;
      const es = new EventSource(
        `${process.env.REACT_APP_MERCURE_URL}?topic=${decodeURIComponent(topic)}`,
      );
      es.onopen = (e) => {};
      es.onerror = (e) => {};
      es.onmessage = (e) => {
        const notification = JSON.parse(e.data);
        bounded.registerNotification(notification);
        bounded.pushNotification({ ...notification, timeout: 3000 });
        bounded.getUnreadedNotifications();
      };
      eventSourceRef.current = es;
    }
    return () => {
      if (
        eventSourceRef.current?.readyState === EventSource.OPEN ||
        eventSourceRef.current?.readyState === EventSource.CONNECTING
      )
        eventSourceRef.current.close();
    };
  }, [bounded, userId]);

  useEffect(() => {
    if (paperRef.current) {
      resizeObserver.current.observe(paperRef.current);
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      let observer = resizeObserver.current;
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperRef.current]);

  const debounceSwitchOpenState = useCallback(
    debounce(
      (): void => {
        bounded.switchOpenNotificationMenu();
      },
      500,
      { leading: true, trailing: false },
    ),
    [bounded],
  );

  const handleClickAway = () => {
    if (menuOpen) {
      // console.log('handleClickAway', new Date().toISOString());
      debounceSwitchOpenState();
    }
  };

  const handleBellClick = useCallback((): void => {
    // console.log('handleBellClick', new Date().toISOString());
    debounceSwitchOpenState();
  }, [debounceSwitchOpenState]);

  return (
    <React.Fragment>
      {withPushNotifications && <KairosPushSnackBar />}

      <Badge
        overlap='circle'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          unreaded > 0 && (
            <StyledFab
              disableRipple
              disableFocusRipple
              bgcolor='secondary.dark'
              className={classess.fabRoot}
              minWidth={32}
              width='auto'
              height={32}
              minHeight={32}
              ml={-1}
              mb={-1}
              onClick={handleBellClick}
            >
              {unreaded}
            </StyledFab>
          )
        }
      >
        <IconButton
          color='primary'
          className={classess.notificationBellRoot}
          size='medium'
          onClick={handleBellClick}
          // disabled={!hasNotificationsinStore && unreaded === 0}
        >
          <NotificationsNoneIcon />
        </IconButton>
      </Badge>
      <div
        id='__notification-anchor'
        ref={menuAnchorRef}
        style={{ position: 'relative', bottom: '-16px' }}
      ></div>
      {menuAnchorRef.current &&
        user &&
        (isWideScreen ? (
          <Popper
            open={menuOpen}
            container={menuAnchorRef.current}
            anchorEl={menuAnchorRef.current}
            placement='bottom-end'
            keepMounted
            popperOptions={{
              resize: false,
              eventsEnabled: false,
            }}
            popperRef={popperRef}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Fade in={menuOpen}>
                <Paper className={classess.menuPaper} ref={paperRef}>
                  <NotificationsList emailNotifications={user.allowMailNotifications} />
                </Paper>
              </Fade>
            </ClickAwayListener>
          </Popper>
        ) : (
          <Dialog open={menuOpen} fullScreen={true} style={{ zIndex: 3000 }}>
            <Paper className={classess.menuPaperMobile} ref={paperRef}>
              <NotificationsList emailNotifications={user.allowMailNotifications} mobileVersion />
            </Paper>
          </Dialog>
        ))}
    </React.Fragment>
  );
};
