import React, { useState } from 'react';
import { StyledButton, VideoPoster } from '../../styledComponents';
import { useHistory } from 'react-router-dom';
import './snackbar.css';
import HomePosterOverlay from 'common/components/HomePosterOverlay/HomePosterOverlay';
import { Box, IconButton, Snackbar, Fade, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { saveRouteToGoBack } from '../../../../utils/goBack';
import { Courses } from 'state/views';
import { FormattedMessage } from 'react-intl';
import { useViewsActions } from 'state/views/useViewsActions';
import { useCelebrationsActions } from 'state/celebrations/useCelebrationsActions';

const SnackbarContentProps = {
  style: {
    width: '100%',
    borderRadius: 0,
    background: '#ffffff',
    color: '#000000',
    display: 'flex',
    justifyContent: 'center',
  },
};

const HeroPoster = ({
  hero,
  hasEpisodesToContinue = false,
  position = { bottom: 0 },
  withSnackbar = false,
  withSeeAllLink = false,
  handleSeeAll = () => null,
  seeAllLabel = '',
}: {
  hero: Courses.IEpisodeEssentials | Courses.ICourseDTO;
  hasEpisodesToContinue?: boolean;
  position?: any;
  withSnackbar?: boolean;
  withSeeAllLink?: boolean;
  handleSeeAll?: any;
  seeAllLabel?: string;
}) => {
  const heroCourse: Courses.ICourseDTO | null = (hero as any).chapters
    ? (hero as Courses.ICourseDTO)
    : (hero as Courses.IEpisodeEssentials).course;
  const heroEpisode: Courses.IEpisodeEssentials | null = (hero as any).video
    ? (hero as Courses.IEpisodeEssentials)
    : null;

  const heroChapter = heroCourse.chapters.find(
    (c) =>
      c.episodes.find((e) => e.id === heroCourse.joined?.episodeToContinue?.episode.id) !==
      undefined,
  );

  const theme = useTheme();
  const history = useHistory();
  const [snackBar, setSnackBar] = useState(false);
  const [showJoined, setShowJoined] = useState(false);

  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  const [actionsBase] = useState({});
  const bounded = Object.assign(actionsBase, {
    ...useViewsActions('joinCourse', 'getHomeData', 'getMyCoursesData'),
    ...useCelebrationsActions('randomCelebration'),
  });

  const { user } = useSelector(
    (state: RootState) => ({
      user: state.session.user,
    }),
    shallowEqual,
  );

  const joinCourse = () => {
    if (showJoined) {
      return;
    }

    joinedNotification();
    bounded.joinCourse(hero.id).then(() => {
      bounded.getHomeData();
      bounded.getMyCoursesData().then(() => {
        setTimeout(() => {
          setShowJoined(false);
        }, 5000);
        bounded.randomCelebration(0.5);
      });
    });
  };

  const joinedNotification = () => {
    setShowJoined(true);
  };

  const startWatching = () => {
    const routeToGoBack = history.location.pathname;
    saveRouteToGoBack(routeToGoBack);
    history.push(`/watch/episode/${heroCourse.chapters[0].episodes[0].id}/autoplay`);
  };

  const continueEpisode = () => {
    const routeToGoBack = history.location.pathname;
    saveRouteToGoBack(routeToGoBack);
    hero.id && history.push(`/watch/episode/${hero.id}/autoplay`);
  };

  const watchTrailer = () => {
    const routeToGoBack = history.location.pathname;
    saveRouteToGoBack(routeToGoBack);
    heroCourse.trailerVideo.videoId && history.push(`/watch/trailer/${hero.id}`);
  };

  const heroPartial = hero as Partial<Courses.ICourseDTO & Courses.IEpisodeEssentials>;

  return (
    <Fade in>
      <VideoPoster
        style={{
          minHeight: '100vh',
          height: '100%',
          backgroundImage: `url(${
            !isMedium && heroPartial.mobileCoverImage?.url
              ? heroPartial.mobileCoverImage?.url
              : heroPartial.desktopCoverImage?.url
          })`,
          overflow: 'hidden',
          display: 'flex',
          justifyContent: position === 'top' ? 'flex-start' : 'flex-end',
          position: 'relative',
        }}
      >
        <Box display='flex' flexDirection={position === 'top' ? 'column-reverse' : 'column'}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
              <Box
                width='100%'
                boxSizing='border-box'
                pb={[5, 5, 5, 7]}
                pl={[2, 8, 10]}
                pr={[2, 8, 10]}
                mt={position === 'top' ? [10, 10, 5, 20, 30] : 0}
                pt={isMedium ? '150px' : '50vh'}
              >
                <div
                  style={{
                    position: 'relative',
                    padding: '18px 0',
                    width: '100%',
                    bottom: '-10px',
                    textAlign: 'center',
                    background: 'rgba(0, 0, 0, 0.7)',
                    zIndex: 0,
                    borderRadius: '4px 4px 0 0',
                    opacity: showJoined ? 1 : 0,
                    transition: '0.3s opacity',
                  }}
                >
                  <h2
                    style={{
                      textTransform: 'none',
                      margin: '25px 0',
                      fontWeight: 400,
                    }}
                  >
                    <FormattedMessage
                      id='HeroPoster.JoinedCourse.Gratulation.Title'
                      defaultMessage='Good choice!'
                    />
                  </h2>
                  <p style={{ lineHeight: '1.4rem' }}>
                    <FormattedMessage
                      id='HeroPoster.JoindeCourse.Gratulation.Information'
                      defaultMessage="You are now a participant of the course
                    <br></br>''{name}''.
                    <br></br>You can find course in 'My courses'."
                      values={{
                        br: () => <br />,
                        name: heroCourse.name,
                      }}
                    />
                  </p>
                </div>
                {heroCourse &&
                  heroCourse.joined &&
                  !heroCourse.joined.toContinue &&
                  !hasEpisodesToContinue && (
                    <Box mb={1}>
                      <StyledButton
                        fullWidth
                        onClick={startWatching}
                        type='submit'
                        color='primary'
                        variant='contained'
                        style={{ zIndex: 1 }}
                        css={{
                          marginTop: 50,
                        }}
                      >
                        <FormattedMessage
                          id='HeroPoster.Caption.StartWatching'
                          defaultMessage='Start watching'
                        />
                      </StyledButton>
                    </Box>
                  )}
                <Box mb={1}>
                  {!heroCourse.joined && heroEpisode === null && (
                    <StyledButton
                      fullWidth
                      onClick={joinCourse}
                      type='submit'
                      color='primary'
                      variant='contained'
                      css={{
                        marginTop: 0,
                      }}
                    >
                      {showJoined ? (
                        <Box display='flex' alignItems='center'>
                          <Check style={{ marginRight: '10px' }} />
                          <FormattedMessage
                            id='HeroPoster.Caption.CourseJoined'
                            defaultMessage='Joined'
                          />
                        </Box>
                      ) : (
                        <FormattedMessage
                          id='HeroPoster.Caption.JoinCourse'
                          defaultMessage='Join course'
                        />
                      )}
                    </StyledButton>
                  )}
                </Box>
                {heroEpisode && (
                  <Box mb={1}>
                    <StyledButton
                      fullWidth
                      onClick={continueEpisode}
                      type='submit'
                      color='primary'
                      variant='contained'
                      css={{
                        marginTop: 0,
                      }}
                    >
                      <FormattedMessage
                        id='HeroPoster.Caption.ContinueWatching'
                        defaultMessage='Continue watching'
                      />
                    </StyledButton>
                  </Box>
                )}
                {heroCourse.trailerVideo.videoId && !heroCourse.joined && (
                  <StyledButton
                    fullWidth
                    onClick={watchTrailer}
                    style={{ backgroundColor: `rgba(0, 0, 0, 0.7)` }}
                    variant='outlined'
                    color='primary'
                  >
                    <FormattedMessage
                      id='HeroPoster.Caption.WatchTrailer'
                      defaultMessage='Watch trailer'
                    />
                  </StyledButton>
                )}
              </Box>
            </Grid>
          </Grid>
          <HomePosterOverlay
            withSeeAllLink={withSeeAllLink}
            handleSeeAll={handleSeeAll}
            seeAllLabel={seeAllLabel}
            hero={hero}
            episodeTitle={!!heroEpisode ? heroEpisode.title : heroCourse.name}
            episodeDescription={heroEpisode?.description || heroCourse.description}
            episodeNumber={
              heroEpisode?.ordinal || heroCourse.joined?.episodeToContinue?.episode.ordinal
            }
            courseName={heroEpisode?.course.name}
            chapterNumber={heroEpisode?.chapter.ordinal || heroChapter?.ordinal}
          />
        </Box>
      </VideoPoster>
    </Fade>
  );
};

export default React.memo(HeroPoster);
