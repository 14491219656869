import { Box, Button, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { Score } from 'common/components/General/Score';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCelebrationsActions } from 'state/celebrations/useCelebrationsActions';
import { Courses } from 'state/views';
import { useViewsActions } from 'state/views/useViewsActions';
import { useNavigation } from 'utils/useNavigation';
import { MediumButton } from 'common/components/General/Buttons';

export const TrailerInfoBox = ({
  name,
  description,
  duration,
  challenges,
  chapters,
  episodes,
  course,
}: {
  name: string;
  description: string;
  duration: number;
  challenges: number;
  chapters: number;
  episodes: number;
  course: Courses.ICourseDTO;
}) => {
  const nav = useNavigation();
  const { joinCourse, getMyCoursesData, randomCelebration } = {
    ...useViewsActions('joinCourse', 'getMyCoursesData'),
    ...useCelebrationsActions('randomCelebration'),
  };

  const handleJoinCourse = useCallback(() => {
    joinCourse(course.id).then(() => {
      getMyCoursesData();
      randomCelebration(0.5);
    });
  }, [course.id, getMyCoursesData, joinCourse, randomCelebration]);

  const handleShowCourseOverview = useCallback(() => {
    nav.goToCourseverview(course.id);
  }, [course.id, nav]);

  const intl = useIntl();

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Box fontSize={['24px', '24px', '26px']} fontWeight={500} mt={1} ml={2}>
          {course.name}
        </Box>
        <Box
          fontSize={['14px', '14px', '16px']}
          fontWeight={300}
          lineHeight={1.34}
          ml={2}
          mr={2}
          mt={1}
          pb={2}
        >
          {description}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        {!course.comingSoon && (
          <Box display='flex' justifyContent='flex-end' m={2}>
            <Box>
              <Score
                size='medium'
                label={intl.formatMessage({
                  id: 'Score.Duration',
                  defaultMessage: 'Duration',
                })}
                value={Math.round(duration / 3600) + 'h'}
              />
            </Box>
            <Box ml={1}>
              <Score
                size='medium'
                label={intl.formatMessage({
                  id: 'Score.Challenges',
                  defaultMessage: 'Challenges',
                })}
                value={challenges}
              />
            </Box>
            <Box ml={1}>
              <Score
                size='medium'
                label={intl.formatMessage({
                  id: 'Score.Chapters',
                  defaultMessage: 'Chapters',
                })}
                value={chapters}
              />
            </Box>
            <Box ml={1}>
              <Score
                size='medium'
                label={intl.formatMessage({
                  id: 'Score.Episodes',
                  defaultMessage: 'Episodes',
                })}
                value={episodes}
              />
            </Box>
          </Box>
        )}
        {!course.comingSoon && (
          <Box display='flex' flexDirection='column' m={2}>
            {!course.joined && (
              <Box mb={1} textAlign='right'>
                <MediumButton
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={handleJoinCourse}
                >
                  <FormattedMessage
                    id='VideoPlayer.TrailerInfoBox.JoinCourse.Caption'
                    defaultMessage='JOIN COURSE'
                  />
                </MediumButton>
              </Box>
            )}
            {course.joined && (
              <Box mb={1} textAlign='right'>
                <MediumButton
                  fullWidth
                  color='secondary'
                  variant='contained'
                  startIcon={<CheckIcon />}
                >
                  <FormattedMessage
                    id='VideoPlayer.TrailerInfoBox.Joined.Caption'
                    defaultMessage='JOINED'
                  />
                </MediumButton>
              </Box>
            )}
            {true && (
              <Box mb={1} textAlign='right'>
                <MediumButton
                  fullWidth
                  color='primary'
                  variant='outlined'
                  onClick={handleShowCourseOverview}
                >
                  <FormattedMessage
                    id='VideoPlayer.TrailerInfoBox.ShowCourseOverview.Caption'
                    defaultMessage='SHOW COURSE OVERVIEW'
                  />
                </MediumButton>
              </Box>
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9} xl={10} />
      <Grid item xs={12} sm={12} md={4} lg={3} xl={2}></Grid>
    </Grid>
  );
};
