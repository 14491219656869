import { styled } from '@material-ui/core';

export const PosterInfoContainer = styled('div')({
  background: 'rgba(0, 0, 0, 0.7)',
  width: '100%',
  paddingBottom: 10,
  paddingTop: 10
});

export const StyledLink = styled('span')({
  cursor: 'pointer',
  color: '#ffffff',
  textDecoration: 'none',
  '&:hover': {
    borderBottom: '1px solid #fff',
  },
});

export const ChapterName = styled('h1')({
  fontWeight: 500,
  fontSize: '1.5rem',
  marginBottom: 0,
  marginTop: 30,
});

export const CourseName = styled('h2')({
  fontWeight: 400,
  marginTop: 5,
  marginBottom: 20,
  lineHeight: '1.23',
  fontSize: '1.25rem',
});

export const ChapterEpisodeInformation = styled('h2')({
  fontWeight: 500,
  fontSize: 20,
  marginBottom: 5,
});

export const SeeAllEpisodes = styled('h3')({
  marginTop: 5,
  fontWeight: 400,
  lineHeight: '1.44',
  fontSize: 16,
});

export const Description = styled('p')({
  fontSize: '14px',
  fontWeight: 300,
  maxWidth: 640,
  marginTop: 0,
  lineHeight: 1.33,
});
