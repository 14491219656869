import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {differenceInMilliseconds, toDate, parseISO, differenceInDays} from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import Countdown from 'react-countdown';
import { pad, scrollToTopSmooth } from '../../../common/utils';
import { MediumButton } from '../../MySessions/HeroSession';
import { useChallengesActions } from '../../../state/challenges/useChallengesActions';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSessionActions } from '../../../state/session/useSessionActions';
import { saveRouteToGoBack } from '../../../utils/goBack';
import P2PMessageBox from '../P2P/P2PMessageBox';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import LiveChallengeMessageBox from './LiveChallengeMessageBox';
import { switchOpenLiveChallengeDialog } from '../../../state/session/actions';

const ResponsiveGridContainer = styled(Grid)(() => ({
  flexDirection: 'row-reverse',
}));

export const StyledLink = styled('h3')({
  display: 'inline',
  cursor: 'pointer',
  color: '#ffffff',
  textDecoration: 'none',
  '&:hover': {
    borderBottom: '1px solid #fff',
  },
});

const LiveChallenge = ({
  heroChallenge,
  updateCurrentLiveChallenge,
  feedbacksCreatedByMe,
}: any) => {
  const [showJoinedButtonText, setShowJoinedButtonText] = useState(false);
  const [showLiveChallengeMessageBox, setShowLiveChallengeMessageBox] = useState(true);
  const [ended, setEnded] = useState(false);

  const thisChallengeGivenFeedbacks = feedbacksCreatedByMe.filter(
    (feedback: any) => feedback.submission.challenge?.id === heroChallenge?.id
  );

  const bounded = useSessionActions('switchOpenLiveChallengeDialog');

  const { openLiveChallengeDialog } = useSelector(
    (state: RootState) => ({
      openLiveChallengeDialog: state.session.flags.openLiveChallengeDialog,
    }),
    shallowEqual,
  );

  const theme = useTheme();

  const history = useHistory();
  const intl = useIntl();

  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    setTimeout(() => {
      setShowJoinedButtonText(false);
    }, 5000);
  }, [showJoinedButtonText]);

  const {
    postTakeChallenge,
    postRequestSubmissionToFeedback,
    openConfirmationDialog,
    clearCurrentLiveChallenge,
  } = {
    ...useChallengesActions(
      'postTakeChallenge',
      'postRequestSubmissionToFeedback',
      'clearCurrentLiveChallenge',
    ),
    ...useSessionActions('openConfirmationDialog'),
  };

  const { user } = useSelector(
    (state: RootState) => ({
      user: state.session.user,
    }),
    shallowEqual,
  );

  // const toggleLiveChallengeMessageBox = () => {
  //   bounded.switchOpenLiveChallengeDialog();
  // };

  const goToChallenge = () => {
    if (heroChallenge?.id) {
      history.push(`/watch/challenge/${heroChallenge?.id}/autoplay`);
    }
  }

  const handleTakeChallenge = useCallback(() => {
    // if (!user?.liveChallengeConfirmed) {
    //   return bounded.switchOpenLiveChallengeDialog();
    // }

    // if (!user?.liveChallengeConfirmed && !openLiveChallengeDialog) {
    //   return bounded.switchOpenLiveChallengeDialog();
    // } else {
    //   return postTakeChallenge(heroChallenge?.id || '')
    //     .then(() => {
    //       updateCurrentLiveChallenge()
    //       setShowJoinedButtonText(true);
    //     })
    //     .catch((error) => console.log('Error while taking challenge', error));
    // }
  }, [
    user,
    openLiveChallengeDialog,
    bounded,
    postTakeChallenge,
    heroChallenge,
    updateCurrentLiveChallenge,
  ]);

  const workOnFeedback = (feedback: any) => {
    const routeToGoBack = history.location.pathname;
    saveRouteToGoBack(routeToGoBack);
    history.push(`/main/my-challenges/p2p/${feedback.id}/give`);
  };

  const giveFeedback = useCallback(
    async (challenge: any) => {
      postRequestSubmissionToFeedback({ challengeId: challenge })
        .then(unwrapResult)
        .then((res) => {
          const feedbackToShow = res;

          if (feedbackToShow) {
            const routeToGoBack = history.location.pathname;
            saveRouteToGoBack(routeToGoBack);
            history.push(`/main/my-challenges/p2p/${feedbackToShow.id}/give`);
          }
        })
        .catch((error) => {
          openConfirmationDialog({
            isVisible: true,
            modalType: 'JUST_CONFIRM',
            modalProps: {
              header: heroChallenge?.title,
              text: intl.formatMessage({
                id: 'P2PFeedback.GiveFeedback.Modal.NoSubmission',
                defaultMessage:
                  'There are no available challenge submissions at the moment. Try again later.',
              }),
              confirmationLabel: 'OK',
              onlyConfirm: true,
            },
          });
        });
    },
    [heroChallenge, history, intl, openConfirmationDialog, postRequestSubmissionToFeedback],
  );

  if (!heroChallenge) {
    return null;
  }

  const unfinishedFeedback = feedbacksCreatedByMe.find(
    (feedback: any) =>
      feedback.submission.challenge.id === heroChallenge.id &&
      !feedback.completedAt,
  );

  const heroStartDate = toDate(parseISO(heroChallenge.availableFrom || ''));
  const heroEndDate = toDate(parseISO(heroChallenge.availableTo || ''));

  const millisecondsToEnd = differenceInMilliseconds(heroEndDate, new Date());
  const fullTimeInSeconds = differenceInMilliseconds(heroEndDate, heroStartDate) / 1000;

  return heroChallenge ? (
    <div style={{ minHeight: 'calc(100vh)', background: '#000' }}>
      <Box
        // pt={[2, 2, 20]}
        // pb={[2, 2, 20]}
        style={{
          backgroundColor: 'black',
          backgroundImage: `url(${
            isMediumUp ? heroChallenge.desktopCoverImage?.url : heroChallenge.mobileCoverImage?.url
          })`,
          minHeight: 'calc(100vh)',
          width: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box mt={[3, 3, 0]} mb={[3, 3, 0]} width='100%'>
          <Grid container justify={'center'}>
            <Grid
              item
              xs={11}
              md={9}
              lg={8}
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                borderRadius: '5px',
                overflow: 'hidden',
                minWidth: '75%'
              }}
            >
              <Box mt={[3, 3, 5]} mb={[3, 3, 5]}>
                <ResponsiveGridContainer container>
                  <Grid item xs={12} md={4}>
                    <Countdown
                      onComplete={() => {
                        setEnded(true);
                        clearCurrentLiveChallenge();
                      }}
                      date={Date.now() + millisecondsToEnd}
                      renderer={({ days, hours, minutes, seconds, completed }) => {
                        const timeLeftInSeconds =
                          seconds + minutes * 60 + hours * 60 * 60 + days * 60 * 60 * 24;

                        return (
                          <Box
                            display='flex'
                            height='100%'
                            alignItems='center'
                            justifyContent='center'
                            flexDirection='column'
                            borderLeft={[0, 0, '1px solid grey']}
                          >
                            {!completed && (
                              <h3 style={{ marginBottom: 5 }}>
                                <FormattedMessage
                                  id='LiveP2PChallenge.Caption'
                                  defaultMessage='LIVE P2P CHALLENGE'
                                />
                              </h3>
                            )}
                            {!completed && (
                              <h5 style={{ fontWeight: 300, marginTop: 0 }}>
                                <FormattedMessage
                                  id='LiveP2PChallenge.OpenUntil'
                                  defaultMessage='OPEN UNTIL'
                                />
                              </h5>
                            )}
                            <Box position='relative' ml={[2, 2, 5]} mr={[2, 2, 5]}>
                              <CircularProgress
                                variant='determinate'
                                style={{ color: '#2f2f2fa6' }}
                                size='12rem'
                                thickness={1}
                                value={100}
                              />
                              <CircularProgress
                                style={{ position: 'absolute', left: 0 }}
                                variant='static'
                                size='12rem'
                                thickness={1}
                                value={
                                  ((fullTimeInSeconds - timeLeftInSeconds) / fullTimeInSeconds) *
                                  100
                                }
                              />
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  height: '100%',
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {completed ? (
                                  <h1 style={{ fontWeight: 400, fontSize: '20px' }}>Ended</h1>
                                ) : hours === 0 && minutes < 1 ? (
                                  <h1 style={{ fontWeight: 400, fontSize: '20px' }}>
                                    {pad(seconds, 2)} s
                                  </h1>
                                ) : (
                                  <h1 style={{ fontWeight: 400, fontSize: '20px' }}>
                                    {days > 0 && `${pad(days, 2)} d : `}
                                    {pad(hours, 2)} h : {pad(minutes, 2)} m
                                  </h1>
                                )}
                              </div>
                            </Box>
                          </Box>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box
                      mt={[2, 2, 0]}
                      ml={[2, 2, 5]}
                      mr={[2, 2, 5]}
                      height='100%'
                      display='flex'
                      flexDirection='column'
                      justifyContent='space-between'
                    >
                      <Box>
                        <StyledLink
                          onClick={() => {
                            scrollToTopSmooth();
                            heroChallenge.submission?.completedAt
                              ? history.push(`/main/my-challenges/challenges/${heroChallenge.id}`)
                              : history.push(`/main/my-challenges/live/${heroChallenge.id}`);
                          }}
                        >
                          {heroChallenge.title}
                        </StyledLink>
                        <p>{heroChallenge.description}</p>
                      </Box>
                      <Box>
                        <Box mt={[1]}>
                          <MediumButton
                            style={{ margin: 0 }}
                            fullWidth
                            color='secondary'
                            variant='contained'
                            disabled={
                              heroChallenge.submission?.completedAt &&
                              (heroChallenge.completedSubmissionsCount <= 1 ||
                              (!unfinishedFeedback && (thisChallengeGivenFeedbacks.length >= heroChallenge.completedSubmissionsCount - 1)) ||
                              heroChallenge.submission?.p2pFeedbackGiven && differenceInDays(new Date(), new Date(heroChallenge.submission?.completedAt)) >= 30)
                            }
                            onClick={() => {
                              const challengeHasFeedbackInProgress = feedbacksCreatedByMe.some(
                                (feedback: any) =>
                                  feedback.submission.challenge.id === heroChallenge.id &&
                                  !feedback.completedAt,
                              );

                              // const unfinishedFeedback = feedbacksCreatedByMe.find(
                              //   (feedback: any) =>
                              //     feedback.submission.challenge.id === heroChallenge.id &&
                              //     !feedback.completedAt,
                              // );

                              return heroChallenge.submission
                                ? heroChallenge.submission.completedAt
                                  ? challengeHasFeedbackInProgress
                                    ? unfinishedFeedback && workOnFeedback(unfinishedFeedback)
                                    : giveFeedback(heroChallenge.id)
                                  : history.push(`/main/submit-challenge/${heroChallenge.id}`)
                                : goToChallenge();
                            }}
                            startIcon={
                              heroChallenge.submission && showJoinedButtonText ? (
                                <CheckIcon />
                              ) : null
                            }
                          >
                            {heroChallenge.submission
                              ? showJoinedButtonText
                                ? intl.formatMessage({
                                  id: 'LiveChallenge.SubmissionButton.ChallengeAccepted',
                                  defaultMessage:
                                    'CHALLENGE ACCEPTED',
                                })
                                : heroChallenge.submission.completedAt
                                  ? thisChallengeGivenFeedbacks.some((feedback: any) => !feedback.completedAt)
                                    ? intl.formatMessage({
                                      id: 'LiveChallenge.SubmissionButton.WorkOnFeedback',
                                      defaultMessage:
                                        'WORK ON FEEDBACK',
                                    })
                                    : heroChallenge.submission.p2pFeedbackGiven
                                      ? intl.formatMessage({
                                        id: 'LiveChallenge.SubmissionButton.GiveAnotherFeedback',
                                        defaultMessage:
                                          'GIVE ANOTHER FEEDBACK',
                                      })
                                      : intl.formatMessage({
                                        id: 'LiveChallenge.SubmissionButton.GiveFeedback',
                                        defaultMessage:
                                          'GIVE FEEDBACK',
                                      })
                                  : intl.formatMessage({
                                    id: 'LiveChallenge.SubmissionButton.WorkOnChallenge',
                                    defaultMessage:
                                      'WORK ON CHALLENGE',
                                  })
                              : intl.formatMessage({
                                id: 'LiveChallenge.SubmissionButton.GoToChallenge',
                                defaultMessage:
                                  'GO TO CHALLENGE',
                              })}
                          </MediumButton>
                          {heroChallenge.submission?.completedAt && heroChallenge.completedSubmissionsCount <= 1 &&
                            <p style={{color: theme.palette.primary.light}}>
                              {intl.formatMessage({
                                id: 'P2PFeedback.CompletedChallenge.DidNotGiveFeedbackMessage',
                                defaultMessage: `It seems like you are the first person to complete this challenge. You will be able to give feedback as soon as more people join.`,
                              })}
                            </p>
                          }
                          {heroChallenge.completedSubmissionsCount > 1 && heroChallenge.submission?.completedAt && !unfinishedFeedback && thisChallengeGivenFeedbacks.length >= heroChallenge.completedSubmissionsCount - 1 &&
                            <p style={{color: theme.palette.primary.light}}>
                              {intl.formatMessage({
                                id: 'P2PFeedback.CompletedChallenge.AllFeedbacksGivenMessage',
                                defaultMessage: `Well done, you gave feedback to all peers available at the moment. You will be able to give another feedback as more people complete this challenge.`,
                              })}
                            </p>
                          }
                        </Box>
                        <Box mt={[1]}>
                          <MediumButton
                            style={{ margin: 0 }}
                            fullWidth
                            color='primary'
                            variant='outlined'
                            onClick={() => {
                              scrollToTopSmooth();
                              heroChallenge.submission?.completedAt
                                ? history.push(`/main/my-challenges/challenges/${heroChallenge.id}`)
                                : history.push(`/main/my-challenges/live/${heroChallenge.id}`);
                            }}
                          >
                            MORE INFO
                          </MediumButton>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </ResponsiveGridContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/*<Dialog open={openLiveChallengeDialog}>*/}
      {/*  <DialogContent style={{ padding: 0, background: 'none' }}>*/}
      {/*    <LiveChallengeMessageBox*/}
      {/*      goToChallenge={goToChallenge}*/}
      {/*      toggleLiveChallengeMessageBox={toggleLiveChallengeMessageBox}*/}
      {/*    />*/}
      {/*  </DialogContent>*/}
      {/*</Dialog>*/}
    </div>
  ) : null;
};

export default LiveChallenge;
