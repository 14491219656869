/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Stream } from '@cloudflare/stream-react';
import { Container, Portal, Typography, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { MediumButton } from 'common/components/General/Buttons';
import { KairosSnackBar } from 'common/components/General/KairosSnackBar';
import React, {useCallback, useEffect, useState} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {useHistory, useParams} from 'react-router-dom';
import { useSessionActions } from 'state/session/useSessionActions';
import RegisterModal from './Components/RegisterModal';
import * as constants from "../../state/session/constants";
import api from "../../api/apiHandler";

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '100vh',
    textAlign: 'center',
  },
  content: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export type LandingPageProps = {};
export const mainMenuChannel = 'mainMenuChannel';

export const LandingPage = () => {
  const intl = useIntl();
  const { token, tokenType } = useParams<{ token?: string; tokenType?: 'register' | 'reset' }>();
  const theme = useTheme();
  const classes = useStyles();
  const [openRegisterDialog, setOpenRegisterDialog] = useState<{
    isOpen: boolean;
    email: string | null;
  }>({
    isOpen: false,
    email: null,
  });
  const [requestSent, setRequestSent] = React.useState(false);
  const { logOut } = useSessionActions('logOut');
  const history = useHistory();

  const access = localStorage.getItem(constants.TOKEN_NAME);
  const rt = localStorage.getItem(constants.REFRESH_TOKEN_NAME)

  useEffect(() => {
    if (access && !tokenType) {
      history.push('/main/home')
    } else {
      if (access) {
        return history.push('/main/home')
      } else {
        if (rt) {
          api.invalidateSession(rt).then(() => {
            logOut()
            history.push('/auth');
          })
        } else {
          logOut()
        }
      }
    }
  }, [history]);

  const onCloseRegisterUserDialog = useCallback(() => {
    setOpenRegisterDialog({ ...openRegisterDialog, isOpen: false });
  }, [openRegisterDialog]);

  const bounded = useSessionActions(
    'getRegistrationToken',
    'setLoginDialog',
    'setNewPasswordDialog',
    'checkResetPasswordToken',
    'setNewPasswordDialog',
    'pushErrorSnack',
    'pushMessageSnack',
  );

  React.useEffect(() => {
    if (token && tokenType && !requestSent) {
      if (tokenType === 'register' && !access) {
        logOut()
        setRequestSent(true);
        bounded
          .getRegistrationToken(token)
          .then(unwrapResult)
          .then((payload: any) => {
            setOpenRegisterDialog({ email: payload.email, isOpen: true });
          })
          .catch(() => {
            bounded.pushErrorSnack({
              channel: mainMenuChannel,
              message: intl.formatMessage({
                id: 'LandingPage.Snack.Error.InvalidRegistrationToken',
                defaultMessage: 'Registration token is not valid',
              }),
            });
            setOpenRegisterDialog({ email: null, isOpen: false });
          });
      } else if (tokenType === 'reset') {
        setRequestSent(true);
        bounded
          .checkResetPasswordToken(token)
          .then(unwrapResult)
          .then(() => {
            bounded.setNewPasswordDialog(true, token);
          })
          .catch(() => {
            bounded.pushErrorSnack({
              channel: mainMenuChannel,
              message: intl.formatMessage({
                id: 'LandingPage.Snack.Error.InvalidResetPasswordToken',
                defaultMessage:
                  'Reset password link is inactive - try to LOG IN or request new link',
              }),
              timeout: 10000,
            });
            setTimeout(() => bounded.setLoginDialog(true), 2000);
          });
      }
    }
  }, [token, bounded, tokenType, requestSent, intl]);

  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSmallUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <React.Fragment>
      <Portal container={document.querySelector('#main-menu-anchor')}>
        <KairosSnackBar channel={mainMenuChannel} />
      </Portal>
      <div
        css={{
          maxWidth: 'none',
          backgroundColor: theme.palette.grey[700],
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          css={{
            position: 'absolute',
            left: '0px',
            top: '0px',
            width: '100%',
            height: '100%',
            zIndex: -1,
            objectFit: 'cover',
            overflow: 'hidden',
          }}
        >
          <Stream
            src='7ea8ca4857aeaffd5d75aece499a1fd7'
            height='100vh'
            width={isMediumUp ? '100vw' : isSmallUp ? '200vw' : '350vw'}
            autoplay
            muted
            loop
            controls={false}
          />
        </div>
        <Container className={classes.container}>
          <Box className={classes.content}>
            <Typography variant='h1'>
              <FormattedMessage id='LandingPage.Titile' defaultMessage='Stay Curious.' />
            </Typography>
            <Typography variant='subtitle1' css={{ maxWidth: '33.625rem' }}>
              <FormattedMessage
                id='LandingPage.WelcomeMessage'
                defaultMessage='We help leaders turn their work environments from places to work into places to grow. Join our community and make curiosity a key driver of your personal leadership journey.'
              />
            </Typography>

            <MediumButton
              color='secondary'
              variant='contained'
              css={{ marginTop: theme.spacing(5) }}
              onClick={() => bounded.setLoginDialog(true)}
            >
              <FormattedMessage
                id='LandingPage.Caption.LogIn'
                defaultMessage='Log In'
                description='Log In - caption'
              />
            </MediumButton>
          </Box>
        </Container>
        <RegisterModal
          open={openRegisterDialog.isOpen}
          email={openRegisterDialog.email}
          onClose={onCloseRegisterUserDialog}
        />
      </div>
    </React.Fragment>
  );
};
