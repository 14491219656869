/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box } from '@material-ui/core';
import { MediumButton } from 'common/components/General/Buttons';

interface VerticalButtonsProps {
  confirmationLabel: string;
  cancellationLabel: string;
  confirmationIconStart?: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  disabled?: boolean;
}

export const VerticalButtons = ({
  confirmationLabel,
  cancellationLabel,
  confirmationIconStart,
  onCancel,
  onConfirm,
  disabled = false,
}: VerticalButtonsProps) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <MediumButton
        color='secondary'
        variant='contained'
        type='submit'
        startIcon={confirmationIconStart}
        onClick={onConfirm}
        disabled={disabled}
        style={{ width: '100%', maxWidth: '350px', marginTop: '10px' }}
      >
        {confirmationLabel}
      </MediumButton>

      <MediumButton
        color='primary'
        variant='outlined'
        style={{ width: '100%', maxWidth: '350px', marginTop: '10px' }}
        onClick={onCancel}
      >
        {cancellationLabel}
      </MediumButton>
    </Box>
  );
};
