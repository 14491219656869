import {Box, Button, DialogActions, Typography, useMediaQuery} from '@material-ui/core';
import {
  StyledContentMedium,
  StyledDialog,
  StyledDialogTitle,
} from 'common/components/Dialogs/StyledDialogComponents';
import { MediumButton } from 'common/components/General/Buttons';
import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import {useTheme} from "@material-ui/core/styles";

interface SimpleDialogWithFormProps<V> {
  open: boolean;
  header: string;
  children: React.ReactChild;
  onSubmit: (values: V) => void;
  handleClose: () => void;
  submitting?: boolean
}

export const SimpleDialogWithForm = <V extends {}>({
  open,
  header,
  children,
  onSubmit,
  handleClose,
  submitting
}: SimpleDialogWithFormProps<V>) => {

  const theme = useTheme()

  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  const handleSubmit = useCallback(
    (values: V) => {
      onSubmit(values);
    },
    [onSubmit],
  );

  return (
    <StyledDialog fullScreen={!isMedium} open={open} maxWidth='md'>
      <StyledDialogTitle style={{opacity: isMedium ? 0.8 : 1}}>
        <Box mt={1} textAlign='center'>
          <h3 style={{fontWeight: 'normal'}}>
            {header}
          </h3>
        </Box>
      </StyledDialogTitle>
      <StyledContentMedium>

        <Box>
          <Form onSubmit={handleSubmit}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {children}

                  <Box display='flex' flexDirection='row' justifyContent='center'>
                    <MediumButton
                      style={{ marginRight: '10px' }}
                      variant='outlined'
                      color='primary'
                      onClick={handleClose}
                    >
                      Cancel
                    </MediumButton>
                    <MediumButton
                      disabled={submitting}
                      type='submit'
                      style={{ marginLeft: '10px' }}
                      variant='contained'
                      color='secondary'
                    >
                      Send
                    </MediumButton>
                  </Box>
                </form>
              );
            }}
          </Form>
        </Box>
      </StyledContentMedium>
    </StyledDialog>
  );
};
