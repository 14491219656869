/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { Courses } from 'state/views';
import { useViewsActions } from 'state/views/useViewsActions';
import VideoPlayer from 'views/VideoPlayer';
import { getCourseById, getCourseStats } from 'views/VideoPlayer/utils';
import { TrailerInfoBox } from './TrailerInfoBox';

export const TrailerPlayer = ({ courseId }: { courseId: string }) => {
  const history = useHistory();
  const intl = useIntl();
  const course: Courses.ICourseDTO | undefined = useSelector((state: RootState) => {
    return getCourseById(courseId, state.views.courses);
  }, shallowEqual);

  const [bounded] = useState({
    ...useSessionActions('setEpisodeProgress'),
    ...useViewsActions('getMyCoursesData', 'getHomeData'),
  });

  return (
    <div id='__trailrPlayerContainer'>
      {course ? (
        <VideoPlayer
          play
          src={course?.trailerVideo?.videoId}
          type='trailer'
          OverlayProps={{
            courseName: course.name,
            description: course.description,
            withBackButton: true,
            ...getCourseStats(course),
            InfoBoxComponent: TrailerInfoBox,
            course,
            backButtonLabel: intl.formatMessage({
              id: 'Literal.Back',
              defaultMessage: 'Back',
              description:
                'Literally word Back or it equivalent, used as Literal for back button in overlays',
            }),
            onBackClick: () => {
              history.go(-1);

              bounded.getMyCoursesData();
              bounded.getHomeData();
            },
          }}
        />
      ) : (
        <div>
          <FormattedMessage
            id='VideoPlayer.TrailerPlayer.NothingToDisplayMessage'
            defaultMessage='Nothing to display'
          />
        </div>
      )}

      {/*{course && (*/}
      {/*  <Box mt={8} mb={20}>*/}
      {/*    <ChaptersAccordions course={course} defaultExpanded={''} />*/}
      {/*  </Box>*/}
      {/*)}*/}
    </div>
  );
};
