import { ActionCreatorsMapObject, ThunkAction } from '@reduxjs/toolkit';
import { scrollToTopSmooth } from 'common/utils';
import { History } from 'history';
import React from 'react';
import { ChallengesActionsType } from 'state/challenges/useChallengesActions';
import { SessionActionsType } from 'state/session/useSessionActions';
import { ViewsActionsType } from 'state/views/useViewsActions';
import { saveRouteToGoBack } from 'utils/goBack';
import { Navigation, useNavigation } from 'utils/useNavigation';

export interface SlideActions {
  onTileClick: ((e: React.MouseEvent<HTMLElement>, item: any) => void) | null;
  onAction: any;
  onPlayClick: ((e: React.MouseEvent<HTMLButtonElement>, item: any) => void) | null;
  onLinkButtonClick: ((item: any) => void) | null;
}

type BoundedActionsGeneric<
  K extends keyof P,
  P extends ActionCreatorsMapObject<any> = SessionActionsType &
    ChallengesActionsType &
    ViewsActionsType
> = {
  [Key in K]: ReturnType<P[Key]> extends ThunkAction<any, any, any, any>
    ? (...args: Parameters<P[Key]>) => ReturnType<ReturnType<P[Key]>>
    : P[Key];
};
type BoundedActionsType = BoundedActionsGeneric<'getChallenge' | 'setActiveTab' | 'getMyCoursesData'>;

export class SlideActionsFactory {
  /**
   *
   */
  constructor(history: History<any>, bounded: BoundedActionsType) {
    this._history = history;
    this._bounded = bounded;
    this._nav = useNavigation();
  }

  private _history: History<any>;
  private _bounded: BoundedActionsType;
  private _nav: Navigation;
  private _getRouteToGoBack = () => this._history.location.pathname;
  private _actions: Record<Slides.SliderType, SlideActions> = {
    episodes: {
      onTileClick: async (e: any, item) => {
        if (
          e.target.id === '__playerTile' ||
          e.target.id === '__imgTile' ||
          e.target.id === '__tile' ||
          e.target.id === '__playButtonWrapper'
        ) {
          if (!this._getRouteToGoBack().includes('watch')) {
            saveRouteToGoBack(this._getRouteToGoBack());
          }
          scrollToTopSmooth();
          this._history.push(`/watch/episode/${item.id}/autoplay`);
        }
      },
      onAction: () => {},
      onPlayClick: (e: React.MouseEvent<HTMLButtonElement>, item: any) => {
        e.stopPropagation();
        scrollToTopSmooth();
        saveRouteToGoBack(this._getRouteToGoBack());
        console.log('navigating to: ', `/watch/episode/${item.id}/autoplay`);
        this._history.push(`/watch/episode/${item.id}/autoplay`);
      },
      onLinkButtonClick: (item) => {
        const video = item.video?.videoId;
        scrollToTopSmooth();
        if (video) {
          saveRouteToGoBack(this._getRouteToGoBack());
          this._history.push(`/watch/episode/${item.id}/autoplay`);
        } else {
          this._nav.goToCourseverview(item.id);
        }
      },
    },
    'episodes-to-continue': {
      onTileClick: async (e: any, item) => {
        if (
          e.target.id === '__playerTile' ||
          e.target.id === '__imgTile' ||
          e.target.id === '__tile' ||
          e.target.id === '__playButtonWrapper'
        ) {
          saveRouteToGoBack(this._getRouteToGoBack());
          scrollToTopSmooth();
          this._history.push(`/watch/episode/${item.id}/autoplay`);
        }
      },
      onAction: () => {},
      onPlayClick: (e: React.MouseEvent<HTMLButtonElement>, item: any) => {
        e.stopPropagation();
        scrollToTopSmooth();
        saveRouteToGoBack(this._getRouteToGoBack());
        this._history.push(`/watch/episode/${item.id}/autoplay`);
      },
      onLinkButtonClick: (item) => {
        const video = item.video?.videoId;
        scrollToTopSmooth();
        if (video) {
          saveRouteToGoBack(this._getRouteToGoBack());
          this._history.push(`/watch/episode/${item.id}/autoplay`);
        } else {
          this._nav.goToCourseverview(item.id);
        }
      },
    },
    'courses-primary': {
      onTileClick: null,
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        scrollToTopSmooth();
        this._nav.goToCourseverview(item.id);
      },
    },
    'courses-secondary': {
      onTileClick: async (e: any, item) => {
        if (e.target.id === '__playerTile') {
          saveRouteToGoBack(this._getRouteToGoBack());
          scrollToTopSmooth();
          this._history.push(`/watch/trailer/${item.id}`);
        }
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        scrollToTopSmooth();
        this._nav.goToCourseverview(item.id);
      },
    },
    'courses-with-challenges': {
      onTileClick: async (_e, item) => {
        this._nav.goToCourseverview(item.id);
        scrollToTopSmooth();
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        scrollToTopSmooth();
        this._nav.goToCourseverview(item.id);
      },
    },
    'courses-completed': {
      onTileClick: async (_e, item) => {
        this._nav.goToCourseverview(item.id);
        scrollToTopSmooth();
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        scrollToTopSmooth();
        this._nav.goToCourseverview(item.id);
      },
    },
    'helper-videos': {
      onTileClick: async (_e, item) => {
        scrollToTopSmooth();
        this._history.push(`/watch/live-challenge/${item.video.videoId}/${item.challenge}`);
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        scrollToTopSmooth();
        this._history.push(`/watch/live-challenge/${item.video.videoId}/${item.challenge}`);
      },
    },
    'courses-open': {
      onTileClick: async (e: any, item) => {
        this._bounded.getMyCoursesData()
        if (e.target.id === '__playerTile') {
          saveRouteToGoBack(this._getRouteToGoBack());
          this._history.push(`/watch/trailer/${item.id}`);
          scrollToTopSmooth();
        } else if (e.target.id === '__imgTile') {
          this._nav.goToCourseverview(item.id);
        }
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        if (item.comingSoon) {
          this._history.push(`/watch/trailer/${item.id}`);
          return;
        }
        this._bounded.getMyCoursesData()
        this._nav.goToCourseverview(item.id);
        scrollToTopSmooth();

      },
    },
    'courses-recommended': {
      onTileClick: async (e: any, item) => {
        if (e.target.id === '__playerTile') {
          saveRouteToGoBack(this._getRouteToGoBack());
          scrollToTopSmooth();
          this._history.push(`/watch/trailer/${item.id}`);
        } else if (e.target.id === '__imgTile') {
          this._nav.goToCourseverview(item.id);
          scrollToTopSmooth();
        }
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        if (item.comingSoon) {
          this._history.push(`/watch/trailer/${item.id}`);
          return;
        }
        scrollToTopSmooth();
        this._nav.goToCourseverview(item.id);
      },
    },
    'challenges-in-progress': {
      onTileClick: async (_e, item) => {
        saveRouteToGoBack(this._getRouteToGoBack());
        scrollToTopSmooth();
        this._history.push(`/watch/challenge/${item.id}/autoplay`);
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        const video = item.video?.videoId;
        scrollToTopSmooth();
        if (video) {
          saveRouteToGoBack(this._getRouteToGoBack());
          this._history.push(`/watch/challenge/${item.id}/autoplay`);
        } else {
          this._nav.goToCourseverview(item.id);
        }
      },
    },
    'challenges-completed': {
      onTileClick: async (_e, item) => {
        this._history.push(`/main/my-challenges/challenges/${item.id}`);
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        const video = item.video?.videoId;
        if (video) {
          saveRouteToGoBack(this._getRouteToGoBack());
          this._history.push(`/watch/challenge/${item.id}/autoplay`);
        } else {
          this._nav.goToCourseverview(item.id);
        }
      },
    },
    'challenges-open': {
      onTileClick: async (_e, item) => {
        saveRouteToGoBack(this._getRouteToGoBack());
        scrollToTopSmooth();
        this._history.push(`/watch/challenge/${item.id}/autoplay`);
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        const video = item.video?.videoId;
        scrollToTopSmooth();
        if (video) {
          saveRouteToGoBack(this._getRouteToGoBack());

          this._history.push(`/watch/challenge/${item.id}/autoplay`);
        } else {
          this._nav.goToCourseverview(item.id);
        }
      },
    },
    'challenges-mixed': {
      onTileClick: async (_e, item) => {
        saveRouteToGoBack(this._getRouteToGoBack());
        scrollToTopSmooth();
        this._history.push(`/watch/episode/${item.id}/autoplay`);
      },
      onAction: () => {},
      onPlayClick: null,
      onLinkButtonClick: (item) => {
        const video = item.video?.videoId;
        scrollToTopSmooth();
        if (video) {
          saveRouteToGoBack(this._getRouteToGoBack());

          this._history.push(`/watch/episode/${item.id}/autoplay`);
        } else {
          this._nav.goToCourseverview(item.id);
        }
      },
    },
  };

  public getActions(sliderType: Slides.SliderType): SlideActions {
    return this._actions[sliderType];
  }
}

export const SlideActionsContext = React.createContext<SlideActionsFactory | null>(null);
