/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useCallback } from 'react';
import { Popper, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { RootState } from '../../../reducers';
import { shallowEqual, useSelector } from 'react-redux';
import { useSessionActions } from 'state/session/useSessionActions';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[700],
    padding: '.875rem 0',
  },
}));

export const CookiesPolicyPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const settingAnchor = useCallback((node) => {
    setAnchorEl(node);
  }, []);
  const hasCookiesConsent = useSelector(
    (state: RootState) => !!state.session.config.hasCookiesConsent,
    shallowEqual,
  );

  const bounded = useSessionActions('setUserConsent');
  const onConsent = useCallback(() => bounded.setUserConsent('hasCookiesConsent', true), [bounded]);

  const theme = useTheme();
  const classes = useStyles();

  return hasCookiesConsent ? null : (
    <React.Fragment>
      <div
        id='cookies-policy-anchor'
        ref={settingAnchor}
        style={{ position: 'fixed', bottom: '0px', left: '0px', right: '0px', height: '0px' }}
      >
        {anchorEl && (
          <Popper
            id='cookies-popper'
            className={classes.root}
            open={true}
            container={anchorEl}
            anchorEl={anchorEl}
            placement='top-start'
          >
            <Box className={classes.contentContainer}>
              <Typography variant='subtitle1'>
                <FormattedMessage
                  id='CookiesPolicyPopover.Consent.Text'
                  defaultMessage='We use cookies to ensure you get the best experience on our website. If you agree to our use of cookies, please continue to use our site. Form more information, see our privacy'
                />
              </Typography>
              <Button css={{ marginLeft: theme.spacing(2) }} variant='outlined' color='primary'>
                <FormattedMessage
                  id='CookiesPolicyPopover.Caption.LearnMore'
                  defaultMessage='Learn'
                />
              </Button>
              <Button
                css={{ marginLeft: theme.spacing(2) }}
                variant='contained'
                color='primary'
                onClick={onConsent}
              >
                <FormattedMessage
                  id='CookiesPolicyPopover.Caption.Continue'
                  defaultMessage='Continue'
                />
              </Button>
            </Box>
          </Popper>
        )}
      </div>
    </React.Fragment>
  );
};
