import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import VideoPlayer from 'views/VideoPlayer';
import { Typography } from '@material-ui/core';
import { IntroductionInfoBox } from './IntroductionInfoBox';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { mainLayoutChannel } from '../../layouts/Main';
import { useSessionActions } from '../../state/session/useSessionActions';

export const IntroductionPlayer = () => {
  const history = useHistory();
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.session.user, shallowEqual);

  const params = useParams<{ new?: string }>();

  const videoId = user?.company?.welcomeVideoId;

  const { pushMessageSnack } = {
    ...useSessionActions('pushMessageSnack'),
  };

  const greeting = intl.formatMessage({
    id: 'MainLayout.RegistrationSnack.Greeting',
    defaultMessage: 'Hello',
  });
  const weAreHappy = intl.formatMessage({
    id: 'MainLayout.RegistrationSnack.HappyToHaveYou',
    defaultMessage: 'We are happy to have you here!',
  });

  return (
    <div id='__introductionPlayerContainer'>
      <VideoPlayer
        play={true}
        src={videoId}
        type='introduction'
        endedTreshold={0}
        OverlayProps={{
          name: intl.formatMessage({
            id: 'VideoPlayer.IntroductionPlayer.Name',
            defaultMessage: 'Welcome to Curjos!',
          }),
          description: intl.formatMessage({
            id: 'VideoPlayer.IntroductionPlayer.Desctiption',
            defaultMessage:
              'We are excited to have you here as an active member of our new learning platform. Before you start exploring Curjos, let Prof. Dr. Wolfgang Jenewein briefly introduce you to the main ideas behind the platform and how you can make most out of your online leadership journey.',
          }),
          withSplitBar: true,
          InfoBoxComponent: IntroductionInfoBox,
          ExpandedElement: !!params.new ? (
            <Box maxWidth={'600px'}>
              <Typography variant='h2'>
                <FormattedMessage
                  id='VideoPlayer.IntroductionPlayer.GratulationsAfterIntroductionVideo'
                  defaultMessage='Thank you. You are now ready to start your personal journey with CURJOS.
                <br></br>
                <br></br>
                Have fun!'
                  values={{
                    br: () => <br />,
                  }}
                />
              </Typography>
              <Button
                color='secondary'
                variant='contained'
                style={{ marginTop: '20px', padding: '20px 50px' }}
                onClick={() => {
                  history.push('/main/home');
                  pushMessageSnack({
                    channel: mainLayoutChannel,
                    messageBold: `${greeting} ${user?.firstName}.`,
                    message: `${weAreHappy}!`,
                    timeout: 10000,
                  });
                }}
              >
                <FormattedMessage
                  id='VideoPlayer.IntroductionPlayer.Caption.GetStarted'
                  defaultMessage='GET STARTED'
                />
              </Button>
            </Box>
          ) : null,
          withBackButton: !params.new,
          backButtonLabel: 'Back',
          onBackClick: () => {
            history.go(-1);
          },
        }}
      />
    </div>
  );
};
