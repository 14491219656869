import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ArrangedLink,
  StyledAgreements,
  StyledContent,
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
  StyledLink,
  TitleButton,
} from 'common/components/Dialogs/StyledDialogComponents';
import { StyledTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import { VisibilityAdornment } from 'common/components/Dialogs/VisibilityAdornment';
import { KairosSnackBar } from 'common/components/General/KairosSnackBar';
import { FORM_ERROR } from 'final-form';
import React, { MouseEventHandler, useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import { mandatory, validateEmail, validatePasswordLength } from 'utils/form-validation';
import './LoginModal.css';
import * as constants from 'state/session/constants';
import { jsx } from '@emotion/core';

interface IValues {
  email: string;
  password: string;
}

const LoginModal = () => {
  const initialValues = { email: null };

  const bounded = useSessionActions(
    'loginUser',
    'setLoginDialog',
    'setResetPasswordDialog',
    'pushErrorSnack',
  );

  const [showPassword, setShowPassword] = useState(false);
  const [isVisible] = useSelector(
    (state: RootState) => [state.session.flags.openLoginDialog],
    shallowEqual,
  );

  const onClose = useCallback(() => bounded.setLoginDialog(false), [bounded]);
  const handlePasswordRevoveryRequest: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      bounded.setLoginDialog(false);
      bounded.setResetPasswordDialog(true);
    },
    [bounded],
  );
  const history = useHistory();
  const intl = useIntl();

  const restoreUrl = (defaultUrl: string) => {
    const memo = localStorage.getItem(constants.REQUEST_URL_BACKUP_NAME);
    if (memo === null) {
      setTimeout(() => {
        history.push(defaultUrl);
      }, 500);
      return;
    }
    try {
      const memoUrl = new URL(memo);
      if (memoUrl.host === new URL(window.location.href).host) {
        const restoring = `${memoUrl.pathname}${memoUrl.hash}${memoUrl.search}`;
        history.push(restoring);
      }
    } catch (ex) {
      setTimeout(() => {
        history.push(defaultUrl);
      }, 500);
      console.log('Error while restoring url memo', ex);
    } finally {
      localStorage.removeItem(constants.REQUEST_URL_BACKUP_NAME);
    }
  };

  return (
    <StyledDialog
      style={{ zIndex: 2010 }}
      open={isVisible}
      maxWidth='xs'
      fullScreen={useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))}
    >
      <KairosSnackBar channel='login' />
      <Form
        onSubmit={async (values: IValues) => {
          bounded
            .loginUser({ username: values.email.toLowerCase(), password: values.password })
            .then(unwrapResult)
            .then((res) => {
              onClose();
              restoreUrl('/main');
            })
            .catch((error) => {
              bounded.pushErrorSnack({
                channel: 'login',
                message: error.message
              });
              return { [FORM_ERROR]: 'error' };
            });
        }}
        initialValues={initialValues}
        validate={({ email, password }: IValues) => {
          const errors: Partial<Record<keyof IValues, string>> = {};
          errors.email = validateEmail(email, intl.formatMessage({
            id: 'FormValidation.InCorrectEmailFormat',
            defaultMessage: 'Incorrect e-mail format',
          }));
          // errors.password = validatePasswordLength(password);
          return errors;
        }}
        render={({ handleSubmit, submitting, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <StyledDialogTitle style={{opacity: 0.6, background: '#101010'}}>
              <TitleButton onClick={() => onClose()}>
                <ClearIcon color='primary' />
              </TitleButton>
            </StyledDialogTitle>
            <StyledContent>
              <Box textAlign='center'>
                <Typography variant='h3'>
                  <FormattedMessage id='LoginModal.Title' defaultMessage='Log In' />
                </Typography>
              </Box>
              <Box mt={8}>
                <Field
                  name='email'
                  component={StyledTextFieldAdapter}
                  color='primary'
                  validate={value => {
                    mandatory(value, intl.formatMessage({
                      id: 'FormValidation.MandatoryField',
                      defaultMessage:
                        'This field is required.',
                    }))
                  }}
                  label={intl.formatMessage({
                    id: 'LoginModal.Field.Email.Label',
                    defaultMessage: 'E-mail',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'LoginModal.Field.Email.Placeholder',
                    defaultMessage: 'Your e-mail here',
                  })}
                  fullWidth
                  variant='outlined'
                  disabled={!!initialValues.email}
                />
              </Box>

              <Box mt={3}>
                <Field
                  name='password'
                  component={StyledTextFieldAdapter}
                  color='primary'
                  label={intl.formatMessage({
                    id: 'LoginModal.Field.Password.Label',
                    defaultMessage: 'Password',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'LoginModal.Field.Password.Placeholder',
                    defaultMessage: 'Your password here',
                  })}
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  variant='outlined'
                  validate={value => {
                    mandatory(value, intl.formatMessage({
                      id: 'FormValidation.MandatoryField',
                      defaultMessage:
                        'This field is required.',
                    }))
                  }}
                  InputProps={{
                    endAdornment: (
                      <VisibilityAdornment
                        onChange={({ isVisible }) => setShowPassword(isVisible)}
                      />
                    ),
                  }}
                />
              </Box>

              <Box mt={1} textAlign='right'>
                <Typography variant='body2'>
                  <StyledLink onClick={handlePasswordRevoveryRequest} to='#password-recovery'>
                    <FormattedMessage
                      id='LoginModal.Link.ForgotPassword'
                      defaultMessage='Forgot password?'
                    />
                  </StyledLink>
                </Typography>
              </Box>
            </StyledContent>

            <StyledDialogActions stickyBottom>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                disabled={hasValidationErrors || submitting}
                fullWidth
              >
                <FormattedMessage id='LoginModal.Caption.LogIn' defaultMessage='Log In' />
              </Button>
            </StyledDialogActions>

            <StyledAgreements>
              <Typography variant='body2'>
                <FormattedMessage
                  id='LoginModal.Footer'
                  defaultMessage='By loggin in, you agree to our <terms>Terms of Service</terms>, and <privacy>Privacy Policy</privacy>.'
                  values={{
                    terms: (chunks: any) => (
                      <ArrangedLink to='/help/terms-of-service'>{chunks}</ArrangedLink>
                    ),
                    privacy: (chunks: any) => (
                      <ArrangedLink to='/help/privacy'>{chunks}</ArrangedLink>
                    ),
                  }}
                />
              </Typography>
            </StyledAgreements>
          </form>
        )}
      />
    </StyledDialog>
  );
};

export default LoginModal;
