import React, { useEffect, useState } from 'react';
import { Box, Grid, Grow } from '@material-ui/core';
import { MediumAvatar } from '../../../../common/components/Profile/Components/Avatars';
import { ArrowRight } from './SubmissionControl';
import { Uploader } from '../../../../common/components/Uploader';
import { useTheme } from '@material-ui/core/styles';

const CoachFeedbackContent = ({ item, challengeItem, inEditMode = false }: any) => {
  const theme = useTheme();

  const [initialFeedbackMedia, setInitialFeedbacklMedia] = useState<Challenges.IMedia[]>([]);

  useEffect(() => {
    setInitialFeedbacklMedia(item.media || []);
  }, [item]);

  return (
    <Grow in>
      <Grid container justify='flex-start' direction='row-reverse'>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end'>
            <MediumAvatar user={null} src={item.author?.avatar?.url} />
          </Box>
        </Grid>
        <Grid item xs={12} md={10} lg={8}>
          <Box
            border={`solid 1px ${theme.palette.primary.light}`}
            borderRadius='4px'
            bgcolor={theme.palette.grey[900]}
            position='relative'
            zIndex={inEditMode ? theme.zIndex.drawer + 2 : 'auto'}
          >
            <Box width='100%' display='flex' justifyContent='space-between' flexWrap='wrap'>
              <Box
                fontSize={['14px', '14px', '16px', '18px']}
                position='absolute'
                top={[-40, -40, -45, -50]}
              >
                <h4 style={{ color: '#ffffffa6', fontWeight: 400, fontSize: 'inherit' }}>
                  FEEDBACK
                </h4>
              </Box>
              <ArrowRight />
              <Box width='100%' display='flex' mx={[3, 3, 8]} my={[2, 2, 4]}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box>
                      <Box
                        fontSize={['13px', '15px', '16px']}
                        lineHeight={['21px', '23px', '24px']}
                      >
                        <p>{item.content}</p>
                      </Box>
                      {initialFeedbackMedia.length > 0 && (
                        <Grid item xs={12}>
                          <Box height='100%'>
                            <Uploader
                              disabled={false}
                              media={initialFeedbackMedia}
                              displayOnly={true}
                            />
                          </Box>
                        </Grid>
                      )}
                      {item.author?.displayName && (
                        <Box fontSize={['13px', '15px', '16px']}>
                          <h4 style={{ textAlign: 'right', fontSize: 'inherit', fontWeight: 400 }}>
                            by {item.author.displayName}
                          </h4>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grow>
  );
};

export default CoachFeedbackContent;
