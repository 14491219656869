/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { UploaderFilesList } from 'common/components/Uploader/Components/UploaderFilesList';
import { UploaderInput } from 'common/components/Uploader/Components/UploaderInpuy';
import { UploaderButton } from 'common/components/Uploader/Components/UploaderButton';

type UploaderProps = {
  children?: ReactElement;
  onChange?: (files: FileDescriptor[]) => void;
  media?: Challenges.IMedia[];
  displayOnly?: boolean;
  allowDownload?: boolean;
  disabled?: boolean;
};

const MB_SIZE = 1024 * 1024;
const MAX_DOC_SIZE_B = 10 * MB_SIZE;
const MAX_VIDEO_SIZE_B = 500 * MB_SIZE;

export const calcMediaSize = (desc: FileDescriptor[]) => {
  return desc.reduce((acc, item) => {
    return (acc += item.file?.size || 0);
  }, 0);
};
export const hasObversizedFile = (desc: FileDescriptor[]) => {
  return desc.findIndex((x) => x.isOversized) !== -1;
};

export const areFilesOverszed = (desc: FileDescriptor[], maxMediaSize: number) => {
  return hasObversizedFile(desc) || calcMediaSize(desc) > maxMediaSize;
};

export interface FileDescriptor {
  id: string;
  file: File | null;
  fileName: string;
  isOversized: boolean;
  objectUrl?: string;
  url?: string;
}

// FIXME: Add drop zone to file uploader

export const Uploader = ({
  children,
  onChange,
  media,
  disabled = false,
  displayOnly = false,
  allowDownload = false,
}: UploaderProps) => {
  const [filesDescriptors, setFilesDescriptors] = useState<FileDescriptor[]>([]);

  useEffect(() => {
    if (media) {
      let result: FileDescriptor[] = [];

      media.forEach((m) =>
        result.push({
          id: m.id,
          file: null,
          fileName: m.name,
          url: m.url,
          isOversized: false,
        }),
      );
      setFilesDescriptors(result);
    }
  }, [media]);

  useEffect(() => {
    if (onChange) {
      onChange(filesDescriptors);
    }
  }, [filesDescriptors, onChange]);

  const handleDelete = useCallback(
    (index: number) => {
      filesDescriptors.splice(index, 1);
      setFilesDescriptors([...filesDescriptors]);
    },
    [filesDescriptors],
  );

  let more = filesDescriptors.length > 0;
  return (
    <React.Fragment>
      <UploaderFilesList
        filesDescriptors={filesDescriptors}
        onDelete={handleDelete}
        displayOnly={displayOnly}
        allowDownload={allowDownload}
        disabled={disabled}
      />

      <UploaderInput
        disabled={disabled}
        onChange={(event) => {
          const target = event.target as HTMLInputElement;
          if (!!target) {
            let filesArray: FileDescriptor[] = [...filesDescriptors];
            let files = target.files;
            if (files == null) return;
            let idBase = Date.now();
            for (let i = 0; i < files.length; i++) {
              let file = files.item(i);
              if (file) {
                filesArray.push({
                  id: (idBase + i).toString(),
                  file: file,
                  fileName: file.name,
                  isOversized: isOversized(file),
                });
              }
            }
            setFilesDescriptors(filesArray);
            (target.value as string | null) = null;
          }
        }}
      >
        {displayOnly ? null : children ? (
          children
        ) : (
          <UploaderButton disabled={disabled} more={more} />
        )}
      </UploaderInput>
    </React.Fragment>
  );
};

export function isOversized(f: File) {
  if (f.type.toLowerCase().includes('video')) {
    return f.size >= MAX_VIDEO_SIZE_B;
  } else {
    return f.size >= MAX_DOC_SIZE_B;
  }
}
