/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

export const UploaderInput = ({
  children,
  disabled = false,
  onChange,
}: {
  children: JSX.Element | null;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <label htmlFor={disabled ? '__' : '__files'} css={{ pointerEvents: 'auto' }}>
      {children}
      <input
        id='__files'
        multiple
        type='file'
        accept='image/*, video/*, .doc, .docx, .pdf'
        css={{ display: 'none' }}
        onChange={onChange}
      />
    </label>
  );
};
