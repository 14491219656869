import * as actions from './actions';
import _ from 'lodash';
import { bindActionCreators } from '@reduxjs/toolkit';
import { shallowEqual, useDispatch } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { forcePause } from 'state/views/actions';

export const useCelebrationsActions = <K extends keyof typeof actions | 'randomCelebration'>(
  ...keys: K[]
) => {
  const dispatch = useDispatch();
  let [localKeys, setKeys] = useState(keys);
  const intl = useIntl();

  if (!shallowEqual(localKeys, keys)) {
    setKeys(keys);
  }

  const randomCelebration = useCallback(
    (chance: number, callback?: () => void, pausePlayer: boolean = false) => {
      if (chance < 0 || chance > 1) {
        throw new Error('chance value must be in scalar scale');
      }

      if (false && Date.now() % 10 > chance * 10) {
        return actions.setupCelebration(null);
      }

      if (pausePlayer) {
        dispatch(forcePause(true));
      }

      return actions.setupCelebration({
        title: intl.formatMessage({
          id: 'Celebrations.Random.Title',
          defaultMessage: "Let's celebrate!",
        }),
        content: intl.formatMessage({
          id: 'Celebration.Random.Content',
          defaultMessage:
            'We like to celebrate little things. Don’t hesitate and click below to start celebrating!',
        }),
        video: intl.formatMessage({
          id: 'Celebrations.Random.VideoId',
          defaultMessage: '1e0c52a1356502550ce253e73808e8bc',
        }),
        callback:
          callback ||
          (() => {
            console.log('Default celebration callback');
          }),
      });
    },
    [intl],
  );

  let pick = useMemo(() => _.pick({ ...actions, randomCelebration }, localKeys), [
    localKeys,
    randomCelebration,
  ]);

  return useMemo(() => bindActionCreators(pick, dispatch), [dispatch, pick]);
};

export type CelebrationsActionsType = typeof actions;
