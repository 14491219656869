/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Fade, Typography } from '@material-ui/core';

export const Legal = (props: any) => {
  return (
    <Fade in timeout={1000}>
      <Box
        fontSize={['12px', '14px', '16px']}
        pt={5}
        pb={10}
        pl={[2, 2, 5]}
        pr={[2, 2, 5]}
        style={{ backgroundColor: '#000' }}
      >
        <p style={{ fontSize: '2.5em', textAlign: 'center' }}>Legal</p>
        <Box mt={[1, 1, 2]}>
          <Typography variant='body1' style={{ textAlign: 'center' }}>
            Management:
            <br />
            Oliver Böhm, Jonas Halder
            <br />
            <br />
            Curjos GmbH:
            <br />
            Seeweg 16
            <br />
            CH – 8593 Kesswil
            <br />
            T +41 (0) 71 224 28 90
            <br />
            info@curjos
            <br />
            www.web.curjos.com
            <br />
            <br />
            Registered office of the company: Kesswil
            <br />
            Registered court: tbd.
            <br />
            Registered number: tbd.
            <br />
            {/*<br/>*/}
            {/*St.-Nr.: tbd.<br/>*/}
            {/*UID: tbd.<br/>*/}
            <br />
            <br />
            <br />
            Extrajudical dispute resolution
            <br />
            <br />
            We do not participate in a dispute resolution procedure before a consumer arbitration
            board. According to EU Regulation No. 524/2013, however, we are obliged to point out
            that the European Commission operates a platform for out-of-court online settlement of
            disputes (the so-called OS platform) at http://ec.europa.eu/consumers/odr/
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};
