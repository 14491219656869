import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useChallengesActions } from '../../../state/challenges/useChallengesActions';
import { Clear } from '@material-ui/icons';
import { styled as styledMui, withStyles } from '@material-ui/core/styles';
import { mandatory } from '../../../utils/form-validation';
import { EditorTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import { areFilesOverszed, FileDescriptor, Uploader } from '../../../common/components/Uploader';
import {FormattedMessage, useIntl} from 'react-intl';
import arrayMutators from 'final-form-arrays';
import { setFieldValue } from '../../../utils/setFieldValue';
import { UploaderFilesList } from '../../../common/components/Uploader/Components/UploaderFilesList';
import { feedbackChannelName } from '../sections/Feedback/ChallengeFeedback';
import { useSessionActions } from '../../../state/session/useSessionActions';
import { unwrapResult } from '@reduxjs/toolkit';
import { GO_BACK_TO_LS_KEY } from '../../../utils/goBack';

interface IBackground {
  backgroundImage?: string;
  customOpacity?: number;
}

const DialogWithStyles = ({ ...rest }: IBackground & DialogProps) => <Dialog {...rest} />;

export const StyledDialog = styledMui(DialogWithStyles)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundImage: (props: IBackground) =>
      props.backgroundImage ? `url(${props.backgroundImage})` : 'none',
    backgroundSize: (props: IBackground) => (props.backgroundImage ? 'cover' : ''),
    backgroundPosition: 'center',
  },
  '& .MuiPaper-root': {
    opacity: (props: IBackground) => props.customOpacity || 1,
    backgroundColor: (props: IBackground) =>
      props.backgroundImage ? theme.palette.grey[700] : 'transparent',
  },
}));

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    padding: '0rem',
    borderRadius: 0,
    backgroundColor: theme.palette.grey[900],
    textAlign: 'end',
  },
}))(({ transparent, ...rest }: any) => <DialogTitle {...rest} />);

const StyledContent = styled(DialogContent)({
  padding: 0,
  background: 'black',
});

//TODO just for development
const MAX_MEDIA_SIZE_B = 500 * 1024 * 1024;

const P2PGiveFeedback = ({
  challengeItem,
  feedbackItem,
  showDialog = false,
  setShowDialog,
}: any) => {
  const history = useHistory();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const intl = useIntl()

  const [feedbackFiles, setFeedbackFiles] = useState<FileDescriptor[]>([]);
  const [oversized, setOversized] = useState(false);
  const [challengeFilesDesriptors, setChallengeFilesDescriptors] = useState<FileDescriptor[]>([]);
  const [completeWithEmptyAnswersModal, setCompleteWithEmptyAnswersModal] = useState(false);
  const [initialMedia, setInitialMedia] = useState<Challenges.IMedia[]>([]);

  const { setP2PFeedback, getCurrentLiveChallenge } = { ...useChallengesActions('setP2PFeedback', 'getCurrentLiveChallenge')};
  const { pushMessageSnack } = {
    ...useSessionActions('pushMessageSnack'),
  };


  const handleClose = () => {
    const destinationToGoBack = localStorage.getItem(GO_BACK_TO_LS_KEY);

    history.push(destinationToGoBack || '/main/my-challenges/p2p');
    setShowDialog(false);
  };

  const initialValues = {
    content: feedbackItem?.content,
  };

  const handleUploaderChange = useCallback((files: FileDescriptor[]) => {
    setFeedbackFiles(files);
    setOversized(areFilesOverszed(files, MAX_MEDIA_SIZE_B));
  }, []);

  const onSubmit = (values: any) => {
    let data = new FormData();

    data.append('content', values.content);

    let mediaIndex = 0,
      existIndex = 0;

    feedbackFiles.forEach((item, index) => {
      item.file && data.append(`media[${mediaIndex++}]`, item.file);
      !item.file && data.append(`existsMedia[${existIndex++}]`, item.id);
    });
    if (!values.draft) {
      data.append('completed', 'true');
    }

    setP2PFeedback({ feedbackId: feedbackItem.id, data: data, challengeId: challengeItem.id })
      .then(unwrapResult)
      .then((res) => {
        if (res.completedAt) {
          handleClose();
          if (challengeItem.is_live) {
            getCurrentLiveChallenge()
          }
          console.log(challengeItem)
          challengeItem?.submission?.p2pFeedbackGiven
            ? pushMessageSnack({
              channel: feedbackChannelName,
              timeout: 5000,
              message: 'Thank you! Your feedback has been sent to your peer!',
            })
            : pushMessageSnack({
            channel: feedbackChannelName,
            timeout: 10000,
            message: 'Thank you! You have successfully completed the Live Challenge. As soon as your feedback is ready, we will notify you by email. In the meantime, you can support your peers by providing additional feedback below!',
          })
        } else {
          handleClose();
          pushMessageSnack({
            channel: feedbackChannelName,
            timeout: 5000,
            message: 'Your changes have been saved!',
          });
        }
      });
  };

  useEffect(() => {
    let result: FileDescriptor[] = [];

    const challengeMedia = feedbackItem?.submission?.media || [];

    challengeMedia.forEach((m: any) =>
      result.push({
        id: m.id,
        file: null,
        fileName: m.name,
        url: m.url,
        isOversized: false,
      }),
    );

    setChallengeFilesDescriptors(result);
    setInitialMedia(feedbackItem?.media || []);
  }, [feedbackItem]);

  useEffect(() => {
    if (feedbackItem?.completedAt) {
      history.push('/main/my-challenges/challenges');
    }
  }, [feedbackItem, history]);

  const { openConfirmationDialog } = {
    ...useSessionActions('openConfirmationDialog'),
  };

  return (
    <StyledDialog
      onClose={handleClose}
      style={{ zIndex: 2010 }}
      PaperProps={{ style: { backgroundColor: 'transparent' } }}
      fullScreen={!isMedium}
      maxWidth='lg'
      fullWidth
      backgroundImage={challengeItem?.desktopCoverImage?.url}
      customOpacity={0.95}
      open={showDialog}
    >
      <StyledDialogTitle>
        <Grid container>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='flex-end'>
              <IconButton onClick={handleClose}>
                <Clear color='primary' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </StyledDialogTitle>
      <StyledContent>
        <Box mx={[2, 3, 10]}>
          <Grid container>
            <Grid item xs={12}>
              <Fade in>
                <h2 style={{ textAlign: 'center', fontWeight: 500 }}>
                  {
                    intl.formatMessage({
                      id: 'GiveFeedback.DialogTitle',
                      defaultMessage:
                        'Give feedback',
                    })
                  }
                </h2>
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <h4
                style={{
                  textAlign: 'right',
                  color: 'rgba(255, 255, 255, 0.6)',
                  fontWeight: 400,
                  margin: 0,
                }}
              >
                {
                  intl.formatMessage({
                    id: 'GiveFeedback.ChallengePreview',
                    defaultMessage:
                      'CHALLENGE PREVIEW',
                  })
                }
              </h4>
            </Grid>
            <Grid item xs={12}>
              <Box
                mt={1}
                mb={5}
                px={[2, 2, 5]}
                style={{ background: '#101010', maxHeight: '450px', overflowY: 'auto' }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <h2 style={{ fontWeight: 500, marginBottom: 8 }}>{challengeItem?.title}</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <h3 style={{ fontWeight: 400, margin: 0 }}>
                      {challengeItem?.chapter?.course?.name} {challengeItem?.chapter && '/'}{' '}
                      {challengeItem?.chapter?.name} {challengeItem?.chapter?.ordinal}
                    </h3>
                  </Grid>
                  {challengeItem?.questions.map((question: any, index: number) => {
                    return (
                      <Grid item key={question.id} xs={12}>
                        <Box
                          my={[2, 2, 3]}
                          px={[2, 2, 5]}
                          py={'3px'}
                          style={{ background: 'rgb(47, 47, 47)' }}
                        >
                          <h4 style={{}}>
                            {index + 1}/{challengeItem?.questions?.length} {question.text}
                          </h4>
                        </Box>
                        <Box>
                          <p>
                            {
                              feedbackItem?.submission?.answers?.find((answer: any) => {
                                return answer.question.id === question.id;
                              })?.content
                            }
                          </p>
                        </Box>
                      </Grid>
                    );
                  })}
                  <Box mt={2} mb={5}>
                    <UploaderFilesList
                      displayOnly
                      filesDescriptors={challengeFilesDesriptors}
                      onDelete={() => null}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <h4>
                  {
                    intl.formatMessage({
                      id: 'GiveFeedback.ShareYourThoughts',
                      defaultMessage:
                        'Share your thoughts',
                    })
                  }
                </h4>
                <p style={{margin: 0, fontSize: '14px', marginBottom: '10px', color: theme.palette.primary.light}}>
                  {
                    intl.formatMessage({
                      id: 'GiveFeedback.PositiveFeedbackReminder',
                      defaultMessage:
                        'Let your colleague know what positive aspects you have noticed in their work. Remember that the idea behind the P2P feedback is to motivate and support each other.',
                    })
                  }
                </p>
                <Form
                  onSubmit={(values) => onSubmit(values)}
                  validate={(values) => {
                    let errors: Partial<Record<string, string>> = {};

                    return errors;
                  }}
                  mutators={{ ...arrayMutators, setFieldValue }}
                  initialValues={initialValues}
                >
                  {({ handleSubmit, submitting, hasValidationErrors, form }) => {
                    return (
                      <React.Fragment>
                        <Field
                          name='content'
                          component={EditorTextFieldAdapter}
                          color='primary'
                          placeholder='Write something...'
                          validate={(value) => {
                            return mandatory(value, intl.formatMessage({
                              id: 'FormValidation.MandatoryField',
                              defaultMessage:
                                'This field is required.',
                            }))
                          }}
                          fullWidth
                          multiline
                          rows={5}
                          rowsMax={20}
                          variant='outlined'
                        />
                        <Box mt={-2} mb={5}>
                          <Uploader
                            allowDownload={true}
                            disabled={submitting}
                            onChange={handleUploaderChange}
                            media={initialMedia}
                          />
                        </Box>

                        {oversized && (
                          <div
                            css={{ color: theme.palette.error.dark, marginTop: theme.spacing(1) }}
                          >
                            <FormattedMessage
                              id='MyChallenges.SubmitChallengeDialog.Validation.OveralFilesSizeExceeded'
                              defaultMessage='Overall files size exceeded or one of files is too big'
                            />
                          </div>
                        )}

                        <Field name='draft'>
                          {({ input }) => <input {...input} type='hidden' />}
                        </Field>
                        <Box bgcolor={theme.palette.grey[700]} pb='1px'>
                          <Box display='flex' mx='auto' mb={5} flexWrap='wrap' width='min-content'>
                            <Box minWidth={{ xs: '300px', sm: '516px', md: '716px' }} m={1}>
                              <Button
                                color='secondary'
                                variant='outlined'
                                fullWidth
                                disabled={submitting}
                                startIcon={
                                  submitting &&
                                  form.getFieldState('draft')?.value === true && (
                                    <CircularProgress />
                                  )
                                }
                                onClick={() => {
                                  form.mutators.setFieldValue('draft', true);
                                  form.submit();
                                }}
                              >
                                <FormattedMessage
                                  id='MyChallenges.SubmitChallengeDialog.Caption.SaveChanges'
                                  defaultMessage='Save changes'
                                />
                              </Button>
                            </Box>
                            <Box
                              flex='0 0 0'
                              m={1}
                              minWidth={{ xs: '300px', sm: '250px', md: '350px' }}
                            >
                              <Button
                                color='primary'
                                variant='outlined'
                                fullWidth
                                onClick={handleClose}
                              >
                                <FormattedMessage
                                  id='MyChallenges.SubmitChallengeDialog.Caption.Cancel'
                                  defaultMessage='Cancel'
                                />
                              </Button>
                            </Box>
                            <Box
                              flex='0 0 0'
                              m={1}
                              minWidth={{ xs: '300px', sm: '250px', md: '350px' }}
                            >
                              <Button
                                color='secondary'
                                variant='contained'
                                fullWidth
                                disabled={hasValidationErrors || submitting}
                                startIcon={
                                  submitting &&
                                  form.getFieldState('draft')?.value === false && (
                                    <CircularProgress />
                                  )
                                }
                                onClick={() => {
                                  form.submit();
                                  // const submissionDoesNotHaveAllTheAnswers = validateSubmissionHasAllAnswers(
                                  //   values.answers,
                                  // );
                                  // if (submissionDoesNotHaveAllTheAnswers) {
                                  //   setCompleteWithEmptyAnswersModal(true);
                                  // } else {
                                  //   form.mutators.setFieldValue('draft', false);
                                  //   form.submit();
                                  // }
                                }}
                              >
                                <FormattedMessage
                                  id='MyChallenges.SubmitChallengeDialog.Caption.CompleteFeedback'
                                  defaultMessage='Complete feedback'
                                />
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  }}
                </Form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </StyledContent>
    </StyledDialog>
  );
};

export default React.memo(P2PGiveFeedback);
