import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/apiHandler';

const GET_HOME_VIEW_DATA = 'GET_HOME_VIEW_DATA';
const GET_MY_COURSES_VIEW_DATA = 'GET_MY_COURSES_VIEW_DATA';
const JOIN_COURSE = 'JOIN_COURSE';
const LEAVE_COURSE = 'LEAVE_COURSE';
const GET_SESSIONS = 'GET_SESSIONS';
const JOIN_SESSION = 'JOIN_SESSION';
const JOIN_SESSION_WAITING_LIST = 'JOIN_SESSION_WAITING_LIST';
const LEAVE_SESSION = 'LEAVE_SESSION';
const LEAVE_SESSION_WAITING_LIST = 'LEAVE_SESSION_WAITING_LIST';

export const getHomeData = createAsyncThunk(GET_HOME_VIEW_DATA, async () => {
  return await api.getHomeView();
});

export const getSessionsData = createAsyncThunk(GET_SESSIONS, async () => {
  return await api.getSessionsView();
});

export const getMyCoursesData = createAsyncThunk(GET_MY_COURSES_VIEW_DATA, async () => {
  return await api.getMyCoursesView();
});

export const joinCourse = createAsyncThunk(JOIN_COURSE, async (courseId: string) => {
  return await api.postJoinCourse(courseId);
});

export const leaveCourse = createAsyncThunk(LEAVE_COURSE, async (courseId: string) => {
  return await api.postLeaveCourse(courseId);
});

export const joinSession = createAsyncThunk(JOIN_SESSION, async (sessionId: string) => {
  return await api.postJoinSession(sessionId);
});

export const leaveSession = createAsyncThunk(LEAVE_SESSION, async (sessionId: string) => {
  return await api.postLeaveSession(sessionId);
});

export const joinSessionWaitingList = createAsyncThunk(
  JOIN_SESSION_WAITING_LIST,
  async (sessionId: string) => {
    return await api.postJoinSessionWaitingList(sessionId);
  },
);

export const leaveSessionWaitingList = createAsyncThunk(
  LEAVE_SESSION_WAITING_LIST,
  async (sessionId: string) => {
    return await api.postLeaveSessionWaitingList(sessionId);
  },
);

export const getCourseToOverview = createAction(
  'getCourseToOverview',
  (courseId: string | null, showChapters: boolean = false) => ({
    payload: {
      courseId,
      showChapters,
    },
  }),
);

export const forcePause = createAction('FORCE_PAUSE', (flag: boolean) => ({ payload: { flag } }));
