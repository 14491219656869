import React from 'react';
import SlickCarousel from 'common/components/SlickCarousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Button } from '@material-ui/core';
import mountain from 'assets/mountain.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { isAfter, parseISO, toDate } from 'date-fns';

const InProgress = ({ executeScroll }: any) => {
  const items = useSelector((state: RootState) => state.challenges.items.inProgress);
  const intl = useIntl();
  const itemsWithoutLive = items.filter(
    (item: any) =>
      !item.is_live || (item.is_live && isAfter(new Date(), toDate(parseISO(item.availableTo)))),
  );

  return (
    <div>
      <SlickCarousel
        withIcon
        Icon={<img style={{ height: '25px' }} src={mountain} alt={'incomplete challenge'} />}
        titleBright
        sliderType='challenges-in-progress'
        items={itemsWithoutLive}
        sliderTitle={intl.formatMessage({
          id: 'MyChallenges.Sections.InProgres.SliderTitle',
          defaultMessage: 'CHALLENGES IN PROGRESS',
        })}
        nothingToShowMessage={
          <div>
            <FormattedMessage
              id='MyChallenges.Sections.InProgres.NothingToShowMessage'
              defaultMessage="<p>It seems like you don't have any challenges in progress at the moment.</p>
              <button>EXPLORE OPEN CHALLENGES</button>"
              values={{
                p: (chunk: any) => <p>{chunk}</p>,
                button: (chunks: any) => (
                  <Button color='secondary' fullWidth variant='contained' onClick={executeScroll}>
                    {chunks}
                  </Button>
                ),
              }}
            />
          </div>
        }
        slidesToShow={3}
        slidesToScroll={3}
        speed={500}
      />
    </div>
  );
};

export default React.memo(InProgress);
