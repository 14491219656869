/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ConfirmationResult,
  SimpleConfirmationDialog,
} from 'common/components/Dialogs/SimpleConfirmationDialog';
import { StyledLabeledTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import { MediumButton } from 'common/components/General/Buttons';
import { PointedHintBox } from 'common/components/General/PointedHintBox';
import { MediumAvatar } from 'common/components/Profile/Components/Avatars';
import { areFilesOverszed, FileDescriptor, Uploader } from 'common/components/Uploader';
import arrayMutators from 'final-form-arrays';
import { useCallback, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useChallengesActions } from 'state/challenges/useChallengesActions';
import { useSessionActions } from 'state/session/useSessionActions';
import styled from 'styled-components';
import { feedbackChannelName } from 'views/MyChallenges/sections/Feedback/ChallengeFeedback';
import { sizing, spacing, compose } from '@material-ui/system';
import { styled as muiStyled } from '@material-ui/core/styles';
import { FeedbackControl } from './FeedbackControl';

const MAX_MEDIA_SIZE_B = 500 * 1024 * 1024;

const Arrow = styled.div`
  width: 60px;
  height: 60px;
  border: solid 1px #ffffffa6;
  position: absolute;
  top: -31px;
  left: 150px;
  @media (max-width: 960px) {
    width: 30px;
    height: 30px;
    left: 80px;
    top: -16px;
  }
  @media (max-width: 680px) {
    width: 30px;
    height: 30px;
    left: 60px;
    top: -16px;
  }
  background-color: #101010;

  transform: rotate(45deg) skew(18deg, 18deg);
  border-right: 0;
  border-bottom: 0;
  border-radius: 5px 0;
`;

export const ArrowRight = styled.div`
  width: 30px;
  height: 30px;
  border: solid 1px #ffffffa6;
  position: absolute;
  top: -16px;
  right: 120px;
  @media (max-width: 960px) {
    width: 30px;
    height: 30px;
    right: 80px;
    top: -16px;
  }
  @media (max-width: 680px) {
    width: 30px;
    height: 30px;
    right: 70px;
    top: -16px;
  }
  background-color: #101010;

  transform: rotate(45deg) skew(18deg, 18deg);
  border-right: 0;
  border-bottom: 0;
  border-radius: 5px 0;
`;

const SystemFab = muiStyled(Fab)(compose(sizing, spacing));

export const SubmissionControl = ({
  item,
  user,
  theme,
}: {
  item: Challenges.IChallengeInProgres;
  user: ISessionUser | null;
  theme: Theme;
}) => {
  const intl = useIntl();
  const history = useHistory();
  const params = useParams<{ challengeId?: string; edit?: string }>();

  const [inEditMode, setInEditMode] = useState(!!params.edit);
  const [leaveConfirm, setLeaveConfirm] = useState(false);

  const [initialMedia, setInitialMedia] = useState<Challenges.IMedia[]>([]);
  const [files, setFiles] = useState<FileDescriptor[]>([]);
  const [oversized, setOversized] = useState(false);

  useEffect(() => {
    setInitialMedia(item.submission?.media || []);
  }, [item]);

  const image = item.submission?.media?.find(
    (x) => x.name.toLocaleLowerCase().includes('png') || x.name.toLocaleLowerCase().includes('jpg'),
  );

  const initialValues = {
    answers: item.submission?.answers.map((a, i) => ({
      ...a,
      content: a.content || '',
      text: (item.questions.find((q) => q.id === a.questionId) || item.questions[i])?.text || '',
    })),
  };
  const {
    postChallengeSubmission,
    removeSubmission,
    getChallenge,
    setFeedbackChallenge,
    pushErrorSnack,
    pushMessageSnack,
  } = {
    ...useChallengesActions(
      'postChallengeSubmission',
      'removeSubmission',
      'getChallenge',
      'setFeedbackChallenge',
    ),
    ...useSessionActions('pushErrorSnack', 'pushMessageSnack'),
  };

  const handleLeaveDialogClose = useCallback(
    ({ result }: ConfirmationResult) => {
      if (result) {
        removeSubmission(item.id)
          .then(unwrapResult)
          .then(() => history.push('/main/my-challenges/challenges'));
      } else {
      }
      setLeaveConfirm(false);
    },
    [history, item.id, removeSubmission],
  );

  const showFileUploader = initialMedia.length > 0 || files.length > 0 || inEditMode;

  const maxWidth = showFileUploader ? '100%' : ['100%', '100%', '60%'];

  return (
    <Box
      key={`container-${item.id}-${inEditMode}-${showFileUploader}`}
      mt={['15px', '15px', '71px']}
      mb={['15px', '15px', '40px']}
      mx={[2, 4, 6, 13]}
      px={[2, 4, 6, 13]}
      gridColumn='span 2'
      maxWidth={maxWidth}
      minWidth='100%'
      boxSizing='border-box'
      justifySelf='center'
    >
      {leaveConfirm && (
        <SimpleConfirmationDialog
          open={leaveConfirm}
          confirmationLabel={intl.formatMessage({
            id: 'Literal.Yes',
            defaultMessage: 'Yes',
            description:
              "Literally Yes word or its equivalent",
          })}
          cancellationLabel='No'
          header={`${item.title}`}
          text={intl.formatMessage({
            id: 'MyChallenges.Sections.Feedback.SubmissionControl.RemoveConfirmationDialog.Text',
            defaultMessage:
              'After deleting your results this challenge will appear in "Open challenges". Do you want to continue?',
          })}
          onClose={handleLeaveDialogClose}
        />
      )}
      <Backdrop open={inEditMode} css={{ zIndex: theme.zIndex.drawer + 1 }}></Backdrop>
      <div
        css={{
          padding: '1px 0px',
          position: 'relative',
          zIndex: inEditMode ? theme.zIndex.drawer + 2 : 'auto',
        }}
      >
        {user && <MediumAvatar user={user} />}
        <Box
          fontSize={['14px', '14px', '16px', '18px']}
          color={theme.palette.primary.light}
          position='absolute'
          right={0}
          bottom={5}
        >
          <FormattedMessage
            id='MyChallenges.Sections.Feedback.SubmissionControl.Label.MyResults'
            defaultMessage='MY RESULTS'
          />
        </Box>
      </div>
      <Box
        border={`solid 1px ${theme.palette.primary.light}`}
        borderRadius='4px'
        pt='0px'
        pb={['20px', '60px']}
        px={['10px', '20px', '40px', '60px']}
        bgcolor={theme.palette.grey[900]}
        position='relative'
        zIndex={inEditMode ? theme.zIndex.drawer + 2 : 'auto'}
      >
        <Box
          width='100%'
          my={[1, 2, 3, 4, 5]}
          display='flex'
          justifyContent='space-between'
          flexWrap='wrap'
        >
          <Arrow />
          {/* <HugeHeader style={{ margin: '10px 0', minWidth: '150px', position: 'relative' }}>
            {item.name}
          </HugeHeader> */}

          <Box
            fontSize={['18px', '20px', '22px', '26px']}
            mt={[1]}
            mb={[1]}
            fontWeight={500}
            position='relative'
            order={1}
          >
            {item.name}
          </Box>

          <Box
            display='flex'
            justifyContent='flex-end'
            order={[0, 0, 2, 2, 2]}
            width={{ xs: '100%', sm: '100%', md: 'max-content' }}
          >
            {!inEditMode && !item.is_p2p && (
              <Tooltip title='Edit result' aria-label='edit result' arrow placement='top'>
                <SystemFab
                  m='10px 10px 10px 0px'
                  width={[46, 46, 56, 56]}
                  height={[46, 46, 56, 56]}
                  style={{ border: 'solid 1px' }}
                  onClick={() => {
                    setInEditMode(true);
                  }}
                >
                  <EditIcon />
                </SystemFab>
              </Tooltip>
            )}
            {!inEditMode && !item.is_p2p && (
              <Tooltip title='Delete result' aria-label='delete result' arrow placement='top'>
                <SystemFab
                  onClick={() => {
                    setLeaveConfirm(true);
                  }}
                  m='10px 10px 10px 0px'
                  width={[46, 46, 56, 56]}
                  height={[46, 46, 56, 56]}
                  style={{ border: 'solid 1px' }}
                >
                  <CloseIcon />
                </SystemFab>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Form
          key={item.id}
          onSubmit={async (values: any) => {
            let data = new FormData();

            data.append('status', 'COMPLETED');
            values.answers.forEach((answer: any, i: number) => {
              data.append(`answers[${i}][question]`, answer.questionId);
              data.append(`answers[${i}][content]`, answer.content);
            });
            let mediaIndex = 0,
              existIndex = 0;
            files.forEach((item) => {
              item.file && data.append(`media[${mediaIndex++}]`, item.file);
              !item.file && data.append(`existsMedia[${existIndex++}]`, item.id);
            });

            if (item?.id) {
              try {
                unwrapResult(await postChallengeSubmission({ challengeId: item.id, data }));
                const challengeDetais = unwrapResult(await getChallenge(item.id));
                setFeedbackChallenge(challengeDetais);
                setInEditMode(false);
                pushMessageSnack({
                  channel: feedbackChannelName,
                  message: null,
                });
              } catch (ex) {
                console.log('Error while editing challenge reply', ex);
                pushErrorSnack({
                  channel: feedbackChannelName,
                  message: 'Your work on challenge was not saved due to an error!',
                  timeout: null,
                });
              }
            }
          }}
          mutators={{ ...arrayMutators }}
          validate={() => {
            return {};
          }}
          initialValues={initialValues}
          keepDirtyOnReinitialize
          render={({ handleSubmit, hasValidationErrors, submitting }) => {
            const getLeftColumnSpan = true || !!image ? '1' : 'span 2';
            return (
              <form onSubmit={handleSubmit}>
                <Box
                  key={`fgrid-${item.id}-${inEditMode}-${showFileUploader}`}
                  css={{
                    gap: '20px 90px',
                  }}
                  display='grid'
                  gridTemplateColumns={
                    showFileUploader
                      ? ['100%', '100%', '100%', 'minmax(0, 0.6fr) minmax(0, 0.4fr)']
                      : ['100%', '100%', '100%', '100%']
                  }
                  gridTemplateRows='max-content'
                >
                  <FieldArray name='answers'>
                    {({ fields }) => {
                      return fields.map((name, index) => {
                        return (
                          <Box
                            key={index}
                            fontSize={['12px', '13px', '18px']}
                            gridColumn={getLeftColumnSpan}
                          >
                            <PointedHintBox pointer={`${index + 1}/${item.questions.length}`}>
                              {fields.value[index].text}
                            </PointedHintBox>
                            {inEditMode ? (
                              <Box
                                css={{ gridColumn: getLeftColumnSpan }}
                                fontSize={['12px', '13px', '18px']}
                              >
                                <Field
                                  name={`${name}.content`}
                                  component={StyledLabeledTextFieldAdapter}
                                  color='primary'
                                  label='Edit description'
                                  variant='outlined'
                                  multiline
                                  rows={9}
                                  fullWidth
                                  css={{ marginTop: theme.spacing(1.5) }}
                                  InputLabelProps={{ shrink: true }}
                                  disabled={submitting}
                                />
                              </Box>
                            ) : (
                              <Box fontSize={['12px', '13px', '18px']}>
                                {/* <Typography
                                  component='div'
                                  variant='body1'
                                  css={{ gridColumn: getLeftColumnSpan }}
                                >
                                  {fields.value[index].text}
                                </Typography> */}
                                <Typography
                                  component='div'
                                  variant='body2'
                                  css={{ gridColumn: getLeftColumnSpan, fontSize: 'inherit' }}
                                >
                                  {fields.value[index].content}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        );
                      });
                    }}
                  </FieldArray>

                  {false && image && (
                    <div
                      css={{
                        display: 'flex',
                        alignContent: 'center',
                        alignSelf: 'start',
                        justifyContent: 'center',
                        gridRow: 'span 3',
                      }}
                    >
                      <img
                        src={image?.url || ''}
                        alt=''
                        css={{
                          width: '100%',
                          maxWidth: '100%',
                          maxHeight: '550px',
                          objectFit: 'contain',
                        }}
                      />
                    </div>
                  )}

                  {showFileUploader && (
                    <Box
                      gridColumn={{ lg: 2 }}
                      gridRow={{
                        lg: `1/span ${
                          inEditMode ? item.questions.length : item.submission?.answers.length || 1
                        }`,
                      }}
                      borderLeft={{ lg: 'solid 1px grey' }}
                      mt={[0, 0, 3]}
                      pl={[0, 0, 2]}
                    >
                      <Uploader
                        disabled={submitting}
                        media={initialMedia}
                        displayOnly={!inEditMode}
                        onChange={(files) => {
                          setFiles(files);
                          setOversized(areFilesOverszed(files, MAX_MEDIA_SIZE_B));
                        }}
                      />
                      {oversized && (
                        <div css={{ color: theme.palette.error.dark, marginTop: theme.spacing(1) }}>
                          <FormattedMessage
                            id='MyChallenges.Sections.Feedback.SubmissionControl.Validation.OveralFilesSizeExceeded'
                            defaultMessage='Overall files size exceeded or one of files is too big'
                          />
                        </div>
                      )}
                    </Box>
                  )}

                  {false && (
                    <div css={{ textAlign: 'center', gridColumn: getLeftColumnSpan }}>
                      <MediumButton variant='contained' color='secondary'>
                        <FormattedMessage
                          id='MyChallenges.Sections.Feedback.SubmissionControl.Caption.ShareOnWall'
                          defaultMessage='Share on Wall'
                        />
                      </MediumButton>
                    </div>
                  )}
                  {inEditMode && (
                    <Box gridColumn={{ lg: '1/span 2' }} textAlign='center'>
                      <MediumButton
                        style={{ marginRight: '10px' }}
                        color='primary'
                        variant='outlined'
                        onClick={() => setInEditMode(false)}
                      >
                        <FormattedMessage
                          id='MyChallenges.Sections.Feedback.SubmissionControl.Caption.Cancel'
                          defaultMessage='Cancel'
                        />
                      </MediumButton>
                      <MediumButton
                        style={{ marginLeft: '10px' }}
                        color='secondary'
                        variant='contained'
                        type='submit'
                        startIcon={submitting && <CircularProgress />}
                        disabled={submitting || hasValidationErrors}
                      >
                        <FormattedMessage
                          id='MyChallenges.Sections.Feedback.SubmissionControl.Caption.SaveChanges'
                          defaultMessage='Save changes'
                        />
                      </MediumButton>
                    </Box>
                  )}
                </Box>
              </form>
            );
          }}
        />
      </Box>

      {/*//FEEDBACK*/}
      {/*<Box mt={5}>*/}
      {/*  {item.submission?.feedbacks.length === 0 && (*/}
      {/*    <Grid container justify='flex-start' direction='row-reverse'>*/}
      {/*      <Grid item xs={12}>*/}
      {/*        <Box display='flex' justifyContent='flex-end'>*/}
      {/*          <MediumAvatar user={null} src={''} />*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={6} sm={4} md={3} lg={2}>*/}
      {/*        <Box*/}
      {/*          border={`solid 1px ${theme.palette.primary.light}`}*/}
      {/*          borderRadius='4px'*/}
      {/*          bgcolor={theme.palette.grey[900]}*/}
      {/*          position='relative'*/}
      {/*          zIndex={inEditMode ? theme.zIndex.drawer + 2 : 'auto'}*/}
      {/*        >*/}
      {/*          <Box width='100%' display='flex' justifyContent='space-between' flexWrap='wrap'>*/}
      {/*            <ArrowRight />*/}
      {/*            <Box width='100%' display='flex' justifyContent='center'>*/}
      {/*              <p style={{ fontSize: '50px', lineHeight: '25px', margin: '0 0 30px' }}>...</p>*/}
      {/*            </Box>*/}
      {/*          </Box>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={12} sm={6} md={5}>*/}
      {/*        <Box mr={4} mt={[2, 0]}>*/}
      {/*          <h4 style={{ margin: 0, fontWeight: 400 }}>*/}
      {/*            {item.is_p2p ? 'PEER-TO-PEER ' : ''}FEEDBACK*/}
      {/*          </h4>*/}
      {/*          <p>*/}
      {/*            {item.is_p2p*/}
      {/*              ? 'There are no available peers for this challenge at the moment. Please be patient and make sure you email notifications are turned on, so you would be notified as soon as there is feedback to your work.'*/}
      {/*              : 'Your coach is preparing feedback for your work. Make sure your feedback notifications are turned on, so you would be notified as soon as there is feedback available.'}*/}
      {/*          </p>*/}
      {/*        </Box>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  )}*/}
      {/*</Box>*/}
      <FeedbackControl item={item} inEditMode={inEditMode} />
    </Box>
  );
};
