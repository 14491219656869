import { Box, Grid, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ConfirmationResult,
  SimpleConfirmationDialog,
} from 'common/components/Dialogs/SimpleConfirmationDialog';
import { MediumButton } from 'common/components/General/Buttons';
import { DownloadInstructions } from 'common/components/General/DownloadInstructions';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { useCelebrationsActions } from 'state/celebrations/useCelebrationsActions';
import { useChallengesActions } from 'state/challenges/useChallengesActions';
import { Courses } from 'state/views';
import { useViewsActions } from 'state/views/useViewsActions';
import styled from 'styled-components';
import { useNavigation } from 'utils/useNavigation';

interface ChallengeInfoBoxProps {
  name: string;
  caption: string;
  course: Courses.ICourseDTO;
  challenge: Challenges.IChallengeDTO;
  description: string;
  expanded: boolean;
  onDialogShow: (flab: boolean) => void;
  onPauseRequest: () => void;
  onAllowPlay: () => void;
}

const LinkWithIcon = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  & svg {
    vertical-align: middle;
  }
  &:hover {
    border-bottom: solid 1px #ffffff;
    transition: border-bottom 1s ease;
  }
`;

export const ChallengeInfoBox = ({
  name,
  caption,
  course,
  challenge,
  description,
  expanded,
  onDialogShow,
  onPauseRequest,
  onAllowPlay,
}: any) => {
  const intl = useIntl();
  const history = useHistory();
  const nav = useNavigation();
  const [takeChallengeModal, setTakeChallengeModal] = useState(false);
  const [ignoreChallenge, setIgnoreChallenge] = useState(false);
  const [challengeTakenConfirmationModal, setChallengeTakenConfirmationModal] = useState(false);
  const [unjoinedCourseNoteModeal, setUnjoinedCourseNoteModal] = useState(false);
  const [unjoinedCourseConfirmed, setUnjoinetCourseConirmed] = useState(false);

  const {
    postTakeChallenge,
    setSubmitChallengeDialog,
    getChallenge,
    getChallenges,
    joinCourse,
    getHomeData,
    getMyCoursesData,
    randomCelebration,
  } = {
    ...useChallengesActions(
      'postTakeChallenge',
      'setSubmitChallengeDialog',
      'getChallenge',
      'getChallenges',
    ),
    ...useViewsActions('joinCourse', 'getHomeData', 'getMyCoursesData'),
    ...useCelebrationsActions('randomCelebration'),
  };

  //#region effects
  useEffect(() => {
    if (!!challenge && course?.joined && challenge.submission === null && !ignoreChallenge) {
      if (expanded) {
        setTakeChallengeModal(true);
      }
    }
  }, [expanded, ignoreChallenge, challenge]);

  useEffect(() => {
    onDialogShow &&
      onDialogShow(
        takeChallengeModal || challengeTakenConfirmationModal || unjoinedCourseNoteModeal,
      );
  }, [challengeTakenConfirmationModal, onDialogShow, takeChallengeModal, unjoinedCourseNoteModeal]);

  useEffect(() => {
    if (!unjoinedCourseConfirmed && !!!course?.joined) {
      onPauseRequest && onPauseRequest();
      setUnjoinedCourseNoteModal(true);
    }
  }, [course, onPauseRequest, unjoinedCourseConfirmed]);
  //#endregion effects
  //#region handlers
  const handleTakeChallenge = useCallback(() => {
    return postTakeChallenge(challenge?.id || '')
      .then(() => {
        getMyCoursesData();
        getChallenges();
        getChallenge(challenge.id);
      })
      .catch((error) => console.log('Error while taking challenge', error));
  }, [challenge, getChallenge, getChallenges, getMyCoursesData, postTakeChallenge]);

  const handleWorkOnChallenge = useCallback(async () => {
    if (challenge) {
      getChallenge(challenge.id)
        .then(unwrapResult)
        .then((challengeDetails) => {
          onPauseRequest && onPauseRequest();
          setSubmitChallengeDialog(challengeDetails);
          history.push(`/main/submit-challenge/${challengeDetails.id}`);
        })
        .catch((error) => console.log('Error while fetching challenge details', error));
    }
  }, [challenge, getChallenge, history, onPauseRequest, setSubmitChallengeDialog]);

  const handleEditChallenge = useCallback(() => {
    if (challenge) {
      history.push(`/main/my-challenges/challenges/${challenge.id}/edit`);
    }
  }, [history, challenge]);

  const handleTakeChallengeDialogClose = useCallback(
    ({ result }: ConfirmationResult) => {
      if (result) {
        handleTakeChallenge().then(() => {
          setTakeChallengeModal(false);
          setChallengeTakenConfirmationModal(true);
        });
      } else {
        setIgnoreChallenge(true);
        setTakeChallengeModal(false);
      }
    },
    [handleTakeChallenge],
  );

  const handleJoinCourse = useCallback(() => {
    joinCourse(course.id).then(() => {
      getHomeData();
      getMyCoursesData();
      randomCelebration(0.5, () => {}, true);
    });
  }, [course.id, getHomeData, getMyCoursesData, joinCourse, randomCelebration]);

  const hanndleShowCourseOverview = useCallback(() => {
    nav.goToCourseverview(course.id);
  }, [course.id, nav]);

  //#endregion handlers
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box fontSize={['20px', '20px', '22px']} fontWeight={500} mt={1} ml={2}>
          {name}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          fontSize={['12px', '12px', '16px']}
          textAlign={['left', 'left', 'right']}
          mt={1}
          ml={2}
          mr={2}
        >
          {caption}
        </Box>

        <Box
          fontSize={['12px', '12px', '16px']}
          textAlign={['left', 'left', 'right']}
          mt={1}
          ml={2}
          mr={2}
        >
          {course.name}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={7}>
        <Box
          fontSize={['12px', '12px', '14px', '16px']}
          fontWeight={300}
          lineHeight={1.34}
          ml={2}
          mr={2}
          mt={1}
          pb={2}
        >
          {description}
        </Box>
      </Grid>
      <Grid item md={2} />
      <Grid item xs={12} md={4} lg={3}>
        <Box display='flex' justifyContent='flex-end'>
          {(challenge.is_p2p ? true : course?.joined) && <Box mt={2} display='flex' justifyContent='center' alignItems='center'>
            <DownloadInstructions instructions={challenge?.instructions || null}/>
          </Box>}
          <Box m={2} display='flex' flexDirection='column' alignItems='flex-end'>
            {!course.joined && (
              <MediumButton color='secondary' variant='contained' onClick={handleJoinCourse}>
                <FormattedMessage
                  id='VideoPlayer.ChallengeInfoBox.JoinCourse'
                  defaultMessage='JOIN COURSE'
                />
              </MediumButton>
            )}

            {course.joined && challenge?.submission == null && (
              <MediumButton color='secondary' variant='contained' onClick={handleTakeChallenge}>
                <FormattedMessage
                  id='VideoPlayer.ChallengeInfoBox.TakeChallenge'
                  defaultMessage='TAKE CHALLENGE'
                />
              </MediumButton>
            )}

            {course.joined && !!challenge?.submission && challenge?.submission.status === 'TAKEN' && (
              <MediumButton color='secondary' variant='contained' startIcon={<CheckIcon />}>
                <FormattedMessage
                  id='VideoPlayer.ChallengeInfoBox.ChallengeAccepted'
                  defaultMessage='CHALLENGE ACCEPTED'
                />
              </MediumButton>
            )}
            {course.joined &&
              !!challenge?.submission &&
              challenge?.submission.status === 'COMPLETED' && (
                <MediumButton color='secondary' variant='contained' startIcon={<CheckIcon />}>
                  <FormattedMessage
                    id='VideoPlayer.ChallengeInfoBox.ChallengeCompleted'
                    defaultMessage='CHALLENGE COMPLETED'
                  />
                </MediumButton>
              )}

            {!course.joined && (
              <MediumButton color='primary' variant='outlined' onClick={hanndleShowCourseOverview}>
                <FormattedMessage
                  id='VideoPlayer.ChallengeInfoBox.ShowCourseOverview'
                  defaultMessage='SHOW COURSE OVERVEW'
                />
              </MediumButton>
            )}
            {course.joined &&
              challenge?.submission &&
              challenge?.submission.status !== 'COMPLETED' && (
                <MediumButton color='primary' variant='outlined' onClick={handleWorkOnChallenge}>
                  <FormattedMessage
                    id='VideoPlayer.ChallengeInfoBox.WorkOnChallenge'
                    defaultMessage='WORK ON CHALLENGE'
                  />
                </MediumButton>
              )}
            {course.joined &&
              !challenge?.is_p2p &&
              challenge?.submission &&
              challenge?.submission.status === 'COMPLETED' && (
                <MediumButton color='primary' variant='outlined' onClick={handleEditChallenge}>
                  <FormattedMessage
                    id='VideoPlayer.ChallengeInfoBox.EditMyResults'
                    defaultMessage={'EDIT MY RESULTS'}
                  />
                </MediumButton>
              )}
            {course.joined &&
              challenge?.is_p2p &&
              challenge?.submission &&
              challenge?.submission.status === 'COMPLETED' && (
                <MediumButton color='primary' variant='outlined' onClick={handleEditChallenge}>
                  <FormattedMessage
                    id='VideoPlayer.ChallengeInfoBox.SeeMyResults'
                    defaultMessage={'SEE MY RESULTS'}
                  />
                </MediumButton>
              )}
          </Box>
        </Box>
      </Grid>
      {takeChallengeModal && (
        <SimpleConfirmationDialog
          customOpacity={0.9}
          // fullScreen={!isMedium}
          open={takeChallengeModal}
          confirmationLabel={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.TakeChallengeModal.Confirm.Caption',
            defaultMessage: 'Take on challenge',
          })}
          cancellationLabel={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.TakeChallengeModal.Cancellation.Caption',
            defaultMessage: 'Not now',
          })}
          header={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.TakeChallengeModal.Header',
            defaultMessage: 'Challenge yourself!',
          })}
          text={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.TakeChallengeModal.Text',
            defaultMessage:
              'Are you ready to turn the insights of this chapter into impact? To complete this chapter, take on the challenge we prepared for you. You will find further guidance and concrete steps on how to make positive change happen and reach the next level with your team.',
          })}
          onClose={handleTakeChallengeDialogClose}
        />
      )}
      {challengeTakenConfirmationModal && challenge?.submission?.status === 'TAKEN' && (
        <SimpleConfirmationDialog
          customOpacity={0.9}
          // fullScreen={!isMedium}
          withCloseButton
          open={challengeTakenConfirmationModal}
          confirmationLabel={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.ChallengeTakenInfoModal.Confirm.Caption',
            defaultMessage: 'CHALLENGE ACCEPTED',
          })}
          confirmationIconStart={<CheckIcon style={{ marginTop: '-5px', marginBottom: '-5px' }} />}
          cancellationLabel={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.ChallengeTakenInfoModal.Cancellation.Caption',
            defaultMessage: 'WORK ON CHALLENGE',
          })}
          header={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.ChallengeTakenInfoModal.Header',
            defaultMessage: 'Perfect!',
          })}
          text={null}
          content={
            <Typography variant='body1' style={{ textAlign: 'center' }}>
              <FormattedMessage
                id='VideoPlayer.ChallengeInfoBox.UserDidTakeChallengeRightNowInfoModal.Content'
                defaultMessage='Challenge has been saved to
                <link> „My challenges”
                </link> successfully! You can start working on it now or come back when you’re ready.'
                values={{
                  link: (chunks: any) => (
                    <LinkWithIcon to='/main/my-challenges/challenges'>
                      {chunks}
                      <ArrowForwardIcon />
                    </LinkWithIcon>
                  ),
                }}
              />
            </Typography>
          }
          onClose={({ result }) => {
            setChallengeTakenConfirmationModal(false);
            if (!result) {
              handleWorkOnChallenge();
            }
          }}
        />
      )}
      {unjoinedCourseNoteModeal && (
        <SimpleConfirmationDialog
          // fullScreen={!isMedium}
          customOpacity={0.9}
          open={unjoinedCourseNoteModeal}
          header={challenge.name}
          text={intl.formatMessage({
            id: 'VideoPlayer.ChallengeInfoBox.JoinCourseBeforeTakeChallengeInfoModal.Content',
            defaultMessage:
              "You can watch this challenge episode but you won't be able to take this challenge. First you need to join the course to do that.",
          })}
          confirmationLabel={intl.formatMessage({
            id:
              'VideoPlayer.ChallengeInfoBox.JoinCourseBeforeTakeChallengeInfoModal.Confirmation.Caption',
            defaultMessage: 'OK',
          })}
          cancellationLabel={intl.formatMessage({
            id:
              'VideoPlayer.ChallengeInfoBox.JoinCourseBeforeTakeChallengeInfoModal.Cancellation.Caption',
            defaultMessage: 'CANCEL',
          })}
          onClose={({ result }) => {
            if (result) {
              setUnjoinetCourseConirmed(true);
              onAllowPlay && onAllowPlay();
            } else {
              history.go(-1);
            }
            setUnjoinedCourseNoteModal(false);
          }}
        />
      )}
    </Grid>
  );
};
