export const scrollToTopSmooth = () => {
  if (window.scrollY > 0) {
    return window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

export function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
