/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fade, Box, Typography } from '@material-ui/core';

import { PaddedViewport } from 'common/components/Help/PaddedViewport';

export const Privacy = (props: any) => {
  return (
    <Fade in timeout={1000}>
      <Box
        fontSize={['12px', '14px', '16px']}
        pt={5}
        pb={10}
        pl={[2, 2, 5]}
        pr={[2, 2, 5]}
        style={{ backgroundColor: '#000' }}
      >
        <p style={{ fontSize: '2.5em', textAlign: 'center' }}>Data Privacy</p>
        <Typography variant='h4'>1. Introduction</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            With the following information, we would like to give you an overview of how your
            personal data is processed by Curjos GmbH and the rights you hold in accordance with the
            data protection law. Use of our website is, in general, possible without entering
            personal data. However, for particular services provided by our company via our website,
            it may be necessary to process personal data in order to use them.
            <br />
            <br />
            The processing of personal data, such as your name, address, or e-mail address, is
            always carried out in accordance with the current data protection law, specifically the
            General Data Protection Regulation (GDPR). With this data protection policy, we would
            like to provide you with transparency and inform you about both the extent and purpose
            of personal data processed by us, as well as the rights you are entitled to.
          </Typography>
        </Box>

        <Typography variant='h4'>2. Person Responsible</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            The following is responsible in matters concerning the GDPR:
            <br />
            Curjos com GmbH
            <br />
            Seeweg 16,
            <br />
            8593 Kesswil, Schweiz
            <br />
            <br />
            E-Mail: info@curjos.com
          </Typography>
        </Box>

        <Typography variant='h4'>3. Data Protection Officer</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            You can contact the data protection officer through the following means:
            <br />
            E-Mail: ap@curjos.com
            <br />
            <br />
            You can contact our data protection officer directly at any time with any questions or
            comments regarding data protection.
          </Typography>
        </Box>

        <Typography variant='h4'>4. Definitions</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            This data protection declaration uses the terminology used in the General Data
            Protection Regulation (GDPR). Our data protection policy should be easy to read and
            understand for the public, as well as for our customers and business partners. To ensure
            this, we would like to explain the terms used in advance.
            <br />
            The terms we use in this data protection policy include:
          </Typography>
          <p>
            <b>a. Personal Data</b>
          </p>
          <p>
            Personal data is any information relating to an identified or identifiable natural
            person. An identifiable natural person is someone who can be identified, directly or
            indirectly, in particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier, or one or more factors
            specific to the physical, physiological, genetic, mental, economic, cultural, or social
            identity of that natural person.
          </p>
          <p>
            <b>b. Affected Person</b>
          </p>
          <p>
            An affected person is any identified or identifiable natural person whose personal data
            is processed by those responsible for processing.
          </p>
          <p>
            <b>c. Processing</b>
          </p>
          <p>
            Processing is any operation or set of operations, carried out with or without automated
            assistance, relating to personal data such as collection, recording, organization,
            filing, storage, adaptation or alteration, reading, consultation, use, disclosure by
            transmission, dissemination or any other form of provision, comparison or linking,
            restriction, deletion, or destruction.
          </p>
          <p>
            <b>d. Restriction of Processing</b>
          </p>
          <p>
            Restriction of processing refers to the marking of stored personal data with the aim of
            restricting their future processing.
          </p>
          <p>
            <b>e. Pseudonymization</b>
          </p>
          <p>
            Pseudonymization is the processing of personal data in such a way that the personal data
            can no longer be assigned to a specific data subject without the use of additional
            information, provided that this additional information is kept separately and is subject
            to technical and organizational measures that ensure the personal data is not assigned
            to an identified or identifiable natural person.
          </p>
          <p>
            <b>f. Processor</b>
          </p>
          <p>
            Processor is a natural or legal person, public authority, agency or other body that
            processes personal data on behalf of the controller.
          </p>
          <p>
            <b>g. Recipient</b>
          </p>
          <p>
            The recipient is a natural or legal person, authority, institution, or other body to
            whom personal data is disclosed, regardless of whether it is a third party. However,
            public authorities that may receive personal data in the course of a specific
            investigation mandate under EU or national law shall not be considered recipients.
          </p>
          <p>
            <b>h. Third Party</b>
          </p>
          <p>
            Third party means any natural or legal person, public authority, agency, or body other
            than the data subject, the controller, the processor, and the persons who, under the
            direct authority of the controller or the processor, are authorized to process the
            personal data.
          </p>
          <p>
            <b>i. Consent</b>
          </p>
          <p>
            Consent means any freely given, informed, and unambiguous expression of the data
            subject’s wishes, in the form of a statement or other unequivocal affirmative act by
            which the data subject signifies his or her consent to the processing of personal data
            relating to him or her.
          </p>
        </Box>

        <Typography variant='h4'>5. Lawfulness of Processing</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            According to the principle of legality of the data protection law, personal data may
            only be processed if an appropriate legal basis allows it. Curjos GmbH processes
            personal data in virtue of the following legal bases:
            <br />
            <br />
            Article 6, paragraph 1, item (a) of the GDPR serves our company as the legal basis for
            processing operations in which we obtain consent for a specific processing purpose.
            <br />
            <br />
            If the processing of personal data is necessary to fulfill a contract to which you are a
            party, the processing is based on article 6, paragraph 1, item (b) of the GDPR. The same
            applies to such processing operations that are required to carry out pre-contractual
            measures, for example in cases of inquiries about our products or services.
            <br />
            <br />
            If our company is subject to a legal obligation which requires the processing of
            personal data, such as certain retention requirements, the processing is based on
            article 6, paragraph 1, item (c) of the GDPR.
            <br />
            <br />
            Furthermore, processing operations could be based on article 6, paragraph 1, item (f) of
            the GDPR. Processing operations are based on this legal foundation if the processing is
            necessary to safeguard a legitimate interest of our company or a third party, and do not
            outweigh the interests, fundamental rights, and freedoms of the data subject. Typically,
            such legitimate interests include cases where processing operations have been mentioned
            by the European legislator. According to this, a legitimate interest for data processing
            can be assumed if you are a customer of our company (recital 47, sentence 2 of the
            GDPR).
          </Typography>
        </Box>

        <Typography variant='h4'>6. Transfer of Data to Third Parties</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            The transfer of your personal data to third parties also constitutes a processing
            operation for which one of the above-mentioned legal bases is required. We only pass on
            your personal data to third parties if:
          </Typography>
          <ul>
            <li>
              According to article 6, paragraph 1, page 1, item (a) of the GDPR, you have expressed
              your consent to this,
            </li>
            <li>
              the transfer is permitted according to article 6, paragraph 1, page 1, item (f) of the
              GDPR to protect our legitimate interests and there is no reason to assume that you
              have an overriding interest worthy of protection in not disclosing your data,
            </li>
            <li>
              there is a legal obligation for disclosure according to article 6, paragraph 1, page
              1, item (c) of the GDPR, and
            </li>
            <li>
              this is legally permissible and, according to article 6, paragraph 1, S. 1, item (b)
              of the GDPR, necessary for the processing of contractual relationships with you.
            </li>
          </ul>
        </Box>

        <Typography variant='h4'>7. Data Collected during Visits to the Website</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            When using our website for informational purposes only (i.e. if you do not register or
            otherwise provide us with information), we only collect the data that your browser sends
            to our server (in so-called “server log files”). Our server collects a range of general
            data and information each time a page is accessed. This general data and information is
            stored in the server’s log files. Data that can be collected includes
          </Typography>
          <ul>
            <li>types and versions of browsers used,</li>
            <li>the operating system used by the accessing computer,</li>
            <li>
              the website from which an accessing system reaches our website (so-called referrer),
            </li>
            <li>the sub-sites that are accessed via an accessing system on our website,</li>
            <li>the date and time of access to the website,</li>
            <li>a shortened internet protocol address (anonymized IP address),</li>
            <li>the internet service provider of the accessing system.</li>
          </ul>
          <Typography variant='body1'>
            When using this general data and information, we do not draw any conclusions about you
            as an individual. Rather, this information is needed to
          </Typography>
          <ul>
            <li>deliver the content of our website correctly,</li>
            <li>optimize the content of our website, as well as the advertising for it,</li>
            <li>
              to ensure the long-term functionality of our IT systems and the technology of our
              website, and
            </li>
            <li>
              to provide law enforcement authorities with the information necessary for prosecution
              in the event of a cyber attack.
            </li>
          </ul>
          <Typography variant='body1'>
            The data and information collected is therefore evaluated by us statistically, while
            also with the aim of increasing data protection and data security in our company in
            order to ultimately ensure an optimum level of protection for the personal data
            processed by us. The anonymous data of the server log files are stored separately from
            all personal data provided by a data subject.
            <br />
            <br />
            The legal basis for data processing is the legitimate interest according to article 6,
            paragraph 1, S. 1, item (f) of the GDPR. We have an overriding legitimate interest in
            being able to offer our services in a technically accurate manner.
          </Typography>
        </Box>

        <Typography variant='h4'>8. Processing of Data That You Transfer to Us</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='h5'>8.1 Data Processing When Registering an Account</Typography>
          <Typography variant='body1'>
            You can create a test account or a business test account with a registration. Within
            this account, you have access and control over the personal data you have provided at
            any time. Which data is collected can be seen from the respective input forms. We store
            and use the data provided by you for the purpose of processing the contract in
            accordance with article 6, paragraph 1, item (b) of the GDPR. You may delete your
            account at any time and can do so by sending a message to the address mentioned above of
            the person responsible. After complete processing of the contract or deletion of your
            account, your data will be blocked with regard to tax and commercial law retention
            periods and deleted after these periods have expired.
          </Typography>
          <Typography variant='h5'>8.2 Establishing Contact/Contact Form</Typography>
          <Typography variant='body1'>
            Personal data is collected when contacting us (e.g. via submitting a contact form or
            sending an e-mail). The type of data collected in the case of a contact form can be seen
            from the respective contact form. This data is stored and used exclusively for the
            purpose of answering your request, or for contacting you and the associated technical
            administration. The legal basis for the processing of the data is our legitimate
            interest in answering your request in accordance with article 6, paragraph 1, item (f)
            of the GDPR. If your contact is aimed at the conclusion of a contract, an additional
            legal basis for the processing is article 6, paragraph 1, item (b) of the GDPR. Your
            data will be deleted after the final processing of your request. This is the case if.
            from the circumstances, it can be concluded that the matter in question has been finally
            clarified and there are no legal obligations to retain data.
          </Typography>
        </Box>

        <Typography variant='h4'>9. Your Rights as a Data Subject</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='h5'>9.1 Right to Confirmation</Typography>
          <Typography variant='body1'>
            You have the right to ask us to confirm whether personal data concerning you is being
            processed.
          </Typography>
          <Typography variant='h5'>9.2 Right to Information Article 15 of the GDPR</Typography>
          <Typography variant='body1'>
            You have the right to receive, at any time, free information from us about the personal
            data stored about you, as well as a copy of this data in accordance with the statutory
            provisions.
          </Typography>
          <Typography variant='h5'>9.3 Right to Rectification Article 16 of the GDPR</Typography>
          <Typography variant='body1'>
            You have the right to request the correction of incorrect personal data concerning you.
            Furthermore, you have the right to request the completion of incomplete personal data,
            taking into account the purposes of the processing.
          </Typography>
          <Typography variant='h5'>9.4 Deletion Article 17 of the GDPR</Typography>
          <Typography variant='body1'>
            You have the right to demand from us that the personal data concerning you be deleted
            immediately if one of the reasons provided by law applies and if the processing or
            storage of this data is not necessary.
          </Typography>
          <Typography variant='h5'>9.5 Restriction of Processing Article 18 of the GDPR</Typography>
          <Typography variant='body1'>
            You have the right to demand that we restrict processing if one of the legal
            requirements is met.
          </Typography>
          <Typography variant='h5'>9.6 Data Transferability Article 20 of the GDPR</Typography>
          <Typography variant='body1'>
            You have the right to receive the personal data concerning you which you have provided
            to us in a structured, common, and machine-readable format. You also have the right to
            transfer this data to another person responsible without hindrance from us, to whom the
            personal data was provided, provided that the processing is based on consent in
            accordance with article 6, paragraph 1, item (a) of the GDPR or article 9, paragraph 2,
            item (a) of the GDPR, or on a contract pursuant to article 6, paragraph 1, item (b) of
            the GDPR, and provided that the processing is carried out with the aid of automated
            procedures, unless the processing is necessary for the performance of a task carried out
            in the public interest or in the exercise of official authority delegated to us.
            <br />
            <br />
            Furthermore, when exercising your right to data portability in accordance with article
            20, paragraph 1 of the GDPR, you have the right to have the personal data transmitted
            directly from one person responsible to another, insofar as this is technically feasible
            and insofar as this does not give affect the rights and freedoms of other people.
          </Typography>
          <Typography variant='h5'>9.7 Objection Article 21 of the GDPR</Typography>
          <Typography variant='body1'>
            You have the right, for reasons that arise from your particular situation, to object at
            any time to the processing of personal data relating to you, which is based on article
            6, paragraph 1, item (e) (data processing in the public interest) or item (f) (data
            processing based on a weighing of interests) of the GDPR.
            <br />
            This also applies to profiling based on these provisions within the meaning of article
            4, paragraph 4 of the GDPR.
            <br />
            If you file an objection, we will no longer process your personal data unless we can
            prove compelling legitimate reasons for the processing that outweigh your interests,
            rights, and freedoms, or the processing serves to assert, exercise, or defend legal
            claims.
            <br />
            In individual cases, we process personal data in order to operate direct mail. You can
            object to the processing of personal data for the purpose of such advertising at any
            time. This also applies to profiling insofar as it is related to such direct mail. If
            you object to processing for direct marketing purposes, we will no longer process the
            personal data for these purposes.
            <br />
            In addition, you have the right, for reasons that arise from your particular situation,
            to object to the processing of personal data concerning you that we use for scientific
            or historical research purposes or for statistical purposes in accordance with article
            89, paragraph 1 of the GDPR, unless such processing is necessary to fulfill a task in
            the public interest.
            <br />
            In connection with the use of information society services, regardless of Directive
            2002/58/EC, you are free to exercise your right of objection by means of automated
            procedures in which technical specifications are used.
          </Typography>
          <Typography variant='h5'>9.8 Revocation of Data Protection Consent</Typography>
          <Typography variant='body1'>
            You have the right to revoke your consent to the processing of personal data at any time
            with effect for the future.
          </Typography>
          <Typography variant='h5'>9.9 Complaint to a Supervisory Authority</Typography>
          <Typography variant='body1'>
            You have the right to complain about our processing of personal data to a supervisory
            authority responsible for data protection.
          </Typography>
        </Box>

        <Typography variant='h4'>
          10. Routine Storage, Deletion, and Blocking of Personal Data
        </Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            We process and store your personal data only for the period of time required to achieve
            the purpose of storage or if this is provided for by the legal provisions to which our
            company is subject.
            <br />
            If the purpose of storage no longer applies or if a prescribed storage period expires,
            the personal data is routinely blocked or deleted in accordance with legal requirements.
          </Typography>
        </Box>

        <Typography variant='h4'>11. Duration of Storage of Personal Data</Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            The criterion for the duration of storage of personal data is the respective legal
            retention period. After this period has expired, the corresponding data is routinely
            deleted if it is no longer required for the fulfillment or initiation of a contract.
          </Typography>
        </Box>

        <Typography variant='h4'>
          12. Currentness and Changes to the Data Protection Policy
        </Typography>
        <Box ml={[1, 2, 2]} mt={2}>
          <Typography variant='body1'>
            This data protection policy is currently valid and was last updated in September 2020.
            <br />
            It may become necessary to amend this data protection declaration as a result of further
            development on our websites and offers, or due to changes in legal or official
            requirements. You can access and print out the current data protection policy at any
            time on the website: „https://web.curjos.com/dataprivacy“
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};
