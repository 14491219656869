/** @jsx jsx */
import { jsx } from '@emotion/core';
import {Fade, IconButton, useMediaQuery} from '@material-ui/core';
import { Pause, PlayArrow, Replay } from '@material-ui/icons';
import { memo } from 'react';
import { HTMLStreamElement } from '@cloudflare/stream-react';
import { usePlayerReducer } from './usePlayerReducer';
import { useTheme } from "@material-ui/core";

interface PlayButtonProps {
  streamPlayer: HTMLStreamElement;
  playing: boolean;
  open: boolean;
  position?: 'relative' | 'absolute';
}

const ReplayButton = ({ streamPlayer, playing, open, position = 'absolute' }: PlayButtonProps) => {
  const { values, bounded } = usePlayerReducer();
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Fade in={open}>
      <div
        css={
          position === 'absolute'
            ? { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }
            : { position, alignSelf: 'center', marginTop: '-20%' }
        }
      >
        <IconButton
          color='primary'
          onClick={() => {
            bounded.setDuration(0);
            bounded.setCurrentTime(0);
            bounded.inferCurrentTime(0);
            streamPlayer.currentTime = 0;
            streamPlayer.play();
          }}
        >
          <Replay css={{
            width: isMedium ? '84px' : '42px',
            height: isMedium ? '84px' : '42px'
          }} />
        </IconButton>
      </div>
    </Fade>
  );
};

export default memo(ReplayButton);
