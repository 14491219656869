import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  createStyles,
  Fade,
  IconButton,
  List,
  ListItem,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import {useIntl} from "react-intl";

const StyledModeButton = styled(Button)({
  textTransform: 'none',
  height: '30px',
  fontSize: '18px',
  transition: '0.3s all',
  '&:hover': {
    color: '#fff!important',
  },
});

const StyledSecondaryListItem = styled(ListItem)({
  transition: '0.3s all',
  border: '1px solid transparent',
  '&:hover': {
    border: '1px solid #FF7722',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 300,
      position: 'absolute',
      top: 50,
      right: 15,
      backgroundColor: theme.palette.grey[800],
    },
    secondaryRoot: {
      width: '100%',
      maxWidth: 320,
      position: 'absolute',
      right: 15,
      backgroundColor: theme.palette.grey[300],
    },
  }),
);

export const ModeSwitchSection = ({
  challengesMode,
  challengesPathname,
  p2pPathname,
  p2pMode,
}: any) => {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl()

  const [P2PShowMore, setP2PShowMore] = useState(false);
  const [P2PShowMoreSecondary, setP2PShowMoreSecondary] = useState(false);

  useEffect(() => {
    setP2PShowMoreSecondary(false);
  }, [P2PShowMore]);

  const { user } = useSelector(
    (state: RootState) => ({
      user: state.session.user,
    }),
    shallowEqual,
  );

  return (
    <Box
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='flex-end'
      mt={2}
      mb={2}
      position='relative'
      zIndex={100}
    >
      <Box mr={1}>
        <StyledModeButton
          onClick={() => history.push(challengesPathname)}
          style={{ color: challengesMode ? '#fff' : 'rgba(255,255,255, 0.6)' }}
        >
          Challenges
        </StyledModeButton>
      </Box>
      <Box borderRight='1px solid rgba(255,255,255, 0.6)' />
      <Box ml={1}>
        <StyledModeButton
          onClick={() => history.push(p2pPathname)}
          style={{
            color: p2pMode ? '#fff' : 'rgba(255,255,255, 0.6)',
          }}
        >
          P2P Feedback
        </StyledModeButton>
      </Box>
      <ClickAwayListener onClickAway={() => setP2PShowMore(false)}>
        <div>
          <Fade in={p2pMode}>
            <IconButton disabled={!p2pMode} onClick={() => setP2PShowMore(!P2PShowMore)}>
              <MoreVert
                color='primary'
                // color={p2pMode ? 'primary' : undefined}
              />
            </IconButton>
          </Fade>
          <Fade in={P2PShowMore}>
            <div className={classes.root}>
              <List component='nav' aria-label='p2p more' style={{ padding: 0 }}>
                <ListItem>How does it work?</ListItem>
              </List>
              <Fade in={P2PShowMore}>
                <div className={classes.secondaryRoot} style={{ position: 'absolute', left: 0 }}>
                  <List component='nav' aria-label='p2p more' style={{ padding: 0 }}>
                    <StyledSecondaryListItem
                      button
                      onClick={() => {
                        setP2PShowMore(false);
                        history.push('/watch/p2p-introduction');
                      }}
                    >
                      {
                        intl.formatMessage({
                          id: 'ModeSwitchSection.Literal.WatchVideo',
                          defaultMessage:
                            'Watch video',
                        })
                      }
                    </StyledSecondaryListItem>
                    <StyledSecondaryListItem
                      button
                      onClick={() => {
                        setP2PShowMore(false);
                        if (!!user?.company.p2pInstructionsFile) {
                          window.open(user?.company.p2pInstructionsFile);
                        }
                      }}
                    >
                      {
                        intl.formatMessage({
                          id: 'P2PMessageBox.Literal.DownloadInstruction',
                          defaultMessage:
                            'Download instruction',
                        })
                      }
                    </StyledSecondaryListItem>
                  </List>
                </div>
              </Fade>
            </div>
          </Fade>
        </div>
      </ClickAwayListener>
    </Box>
  );
};
