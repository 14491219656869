/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Fade, Grid, Grow, Tooltip } from '@material-ui/core';
import cx from 'classnames';
//components
import PlayButton from 'common/components/SlickCarousel/Slide/components/PlayButton';
import TitleAndDetails from 'common/components/SlickCarousel/Slide/components/TitleAndDetails';
import { SlideActionsContext } from 'common/components/SlickCarousel/SlideActionsContext';
import { ChallengeStar } from 'common/components/SlickCarousel/styledComponents';
import { scrollToTopSmooth } from 'common/utils';
import React, { useCallback, useContext, useState } from 'react';
import { BorderLinearProgress } from 'views/Home/styledComponents';
import mountain from 'assets/mountain.svg';
import mountainFull from 'assets/mountainFull.svg';

interface EpisodeTileProps extends Slides.ItemWithTypeProps {
  index: number;
  item: any;
  withProgress?: boolean;
  slideNameWithDetails?: boolean;
  withArrow?: boolean;
  withCheck?: boolean;
  withTitleOverlay?: boolean;
  withPlayButton?: boolean;
  withTitleOrderNumberPrefix?: boolean;
  isCurrent?: boolean;
  withDuration?: boolean;
  tabIndex: number;
}

export const EpisodeTile = ({
  index,
  item,
  withProgress = false,
  slideNameWithDetails = false,
  withArrow = false,
  withCheck = false,
  withTitleOverlay = false,
  withPlayButton = false,
  withTitleOrderNumberPrefix = false,
  sliderTypeName,
  sliderType,
  isCurrent = false,
  withDuration = true,
  ...props
}: EpisodeTileProps) => {
  const TRANSITION_TIME = 500;

  const [showDetails] = useState<boolean>(true);
  const [buttonHovered, setButtonHovered] = useState<boolean>(false);
  const slideActions = useContext(SlideActionsContext)?.getActions(sliderType);

  const getBackground = () => {
    return isCurrent ? '#2F2F2F' : '#1F1F1Fa6';
  };

  const onPlayClick = useCallback(
    (e: any) => {
      if (slideActions?.onPlayClick) {
        slideActions.onPlayClick(e, item);
      }
      scrollToTopSmooth();
    },
    [item, slideActions],
  );

  const handleTileLinkButtonClick = useCallback(
    (item: any) => {
      if (slideActions?.onLinkButtonClick) {
        slideActions.onLinkButtonClick(item);
      }
    },
    [slideActions],
  );

  return (
    <Box
      id={isCurrent ? 'isCurrentEpisode' : undefined}
      position='relative'
      mt='27px'
      style={{
        backgroundColor: getBackground(),
      }}
      className={cx('--fullHeight')}
    >
      <Grow in>
        <React.Fragment>
          <Box
            // css={customSlideStyles}
            className={cx('--fullHeight')}
            onClick={onPlayClick}
            position='relative'
          >
            <Grid container>
              <Grid item xs={12} md={6} lg={4}>
                <div
                  onMouseOut={() => setButtonHovered(false)}
                  onMouseOver={() => setButtonHovered(true)}
                  style={{ position: 'relative' }}
                >
                  <img
                    css={{ width: '100%', cursor: 'pointer' }}
                    key={index}
                    src={(item as any).desktopCoverImage?.url}
                    alt={item.title || item.name}
                    {...props.tabIndex}
                    style={{ objectFit: 'contain', height: 'intrinsic' }}
                  />
                  {item.hasOwnProperty('submission') && (
                    <Box flex='0 1 0' ml={2} position='absolute' top={0} right={0}>
                      <Tooltip
                        key={item.id}
                        title={
                          item.submission?.status === 'COMPLETED'
                            ? 'View completed challenge'
                            : 'Work on challenge'
                        }
                        arrow
                        placement='top'
                        aria-label={
                          item.submission?.status === 'COMPLETED'
                            ? 'view completed challenge'
                            : 'work on challenge in progress'
                        }
                      >
                        <Box mt={1} mr={1}>
                          <ChallengeStar
                            disableRipple
                            disableTouchRipple
                            size='small'
                            aria-label='play'
                          >
                            {item.submission?.status === 'COMPLETED' ? (
                              <img
                                style={{ height: '20px' }}
                                src={mountainFull}
                                alt={'completed challenge'}
                              />
                            ) : (
                              <img
                                style={{ height: '20px' }}
                                src={mountain}
                                alt={'incomplete challenge'}
                              />
                            )}
                          </ChallengeStar>
                        </Box>
                      </Tooltip>
                    </Box>
                  )}
                  {withPlayButton && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 'calc(50% - 50px)',
                        left: 'calc(50% - 50px)',
                        height: '100px',
                        width: '100px',
                        opacity: isCurrent || buttonHovered ? 1 : 0,
                        transition: '500ms all',
                      }}
                    >
                      <Fade in={showDetails}>
                        <PlayButton onClick={onPlayClick} />
                      </Fade>
                    </div>
                  )}
                  {withProgress && item.progress > 0 && (
                    <BorderLinearProgress
                      style={{
                        height: '6px',
                        width: '100%',
                        bottom: 0,
                        position: 'absolute',
                      }}
                      variant='determinate'
                      value={(item as any).progress * 100}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <Box
                  id='--secondBox-xx'
                  position='relative'
                  borderRadius='0 0 5px 5px'
                  p={[1, 1, '60px 40px 20px']}
                >
                  <TitleAndDetails
                    withTitleOverlay={withTitleOverlay}
                    showDetails={showDetails}
                    item={item}
                    withArrow={withArrow}
                    withCheck={withCheck}
                    slideNameWithDetails={slideNameWithDetails}
                    transitionTime={TRANSITION_TIME}
                    sliderTypeName={sliderTypeName}
                    sliderType={sliderType}
                    withTitleOrderNumberPrefix={withTitleOrderNumberPrefix}
                    withDuration={withDuration}
                    onLinkButtonClick={handleTileLinkButtonClick}
                  />
                  <Box display='flex' flexDirection='row'>
                    <Box fontSize={['12px', '14px']} flex='1 0 0'>
                      {item.description}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      </Grow>
    </Box>
  );
};
