import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';

export const formatDurration = (durration: number | null) => {
  if (durration === null) return '';

  let minutes = Math.floor((durration % 3600) / 60);
  let hours = Math.floor(durration / 3600);

  if (hours > 0) {
    return `${hours}h ${minutes} min`;
  } else {
    return `${minutes} min`;
  }
};

export const deferAsyncAction = <A extends (...args: any) => Promise<R>, R>(
  delay: number,
  action: A,
  ...args: Parameters<A>
): Promise<R> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      action(...args)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    }, delay);
  });
};

export const shallowEqualWithSkipOption = <T>(prev: T, next: any, skip: boolean) => {
  return skip ? true : shallowEqual(prev, next);
};

export const useSelectorWithTabCompare = <R>(
  select: (state: RootState) => R,
  tabIndex: number,
): R => {
  const wrappedResult = useSelector(
    (state: RootState) => ({
      result: select(state),
      activeTab: state.session.config.activeTab,
    }),
    (next, prev) => {
      if (next.activeTab !== tabIndex) {
        return true;
      } else {
        return shallowEqual(prev.result, next.result);
      }
    },
  );
  return wrappedResult.result;
};

export const useTabWillShow = (tabIndex: number) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = useSelector(
    (state: RootState) => state.session.config.activeTab,
    (next, prev) => !(next === tabIndex && !shallowEqual(next, prev)),
  );

  return true;
};
