/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Grid, IconButton, Theme, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { EditOutlined, LockOutlined } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  ArrangedBoldLink, StyledContent,
  StyledContentMedium,
  StyledDialog,
  StyledDialogTitle,
  StyledLink,
  TitleButton,
} from 'common/components/Dialogs/StyledDialogComponents';
import { EditorTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import { VisibilityAdornment } from 'common/components/Dialogs/VisibilityAdornment';
import { MediumButton } from 'common/components/General/Buttons';
import { KairosSnackBar } from 'common/components/General/KairosSnackBar';
import { Score } from 'common/components/General/Score';
import { EditorAvatar } from 'common/components/Profile/Components/EditorAvatar';
import { FileDescriptor } from 'common/components/Uploader';
import { pick } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSessionActions } from 'state/session/useSessionActions';
import {
  mandatory,
  validatePasswordHasLowerLetter,
  validatePasswordHasSpecialCharacterOrDigit, validatePasswordHasUpperLetter, validatePasswordHasWhiteSpace,
  validatePasswordLength
} from 'utils/form-validation';
import {FormattedMessage, useIntl} from "react-intl";
import PasswordRequirements from "../PasswordRequirements";
import * as constants from "../../../state/session/constants";
import api from "../../../api/apiHandler";
import {scrollToTopSmooth} from "../../utils";
const replaceAll = require('string.prototype.replaceall');

const IconLink = ({ label, children, onClick = () => {} }: any) => {
  const theme = useTheme();
  return (
    <div
      onClick={onClick}
      css={{
        textAlign: 'right',
        cursor: 'pointer',
        '& *': { display: 'inline-block' },
        '& div:first-of-type': {
          paddingBottom: '4px',
          borderBottom: 'solid 1px transparent',
          transition: 'border-bottom 1s ease',
          '&:hover': {
            borderBottom: `solid 1px ${theme.palette.primary.light}`,
            transition: 'border-bottom 1s ease',
          },
        },
      }}
    >
      <div>{label}</div>
      <IconButton css={{ padding: '4px' }}>{children}</IconButton>
    </div>
  );
};

const NoteBox = ({ children }: any) => {
  const theme = useTheme();
  return (
    <Box maxWidth='446px'>
      <div
        style={{
          fontSize: '14px',
          fontWeight: 300,
          color: theme.palette.primary.light,
        }}
      >
        {children}
      </div>
    </Box>
  );
};

const ControlsBox = ({ onEdit = () => {}, onChangePassword = () => {} }: any) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        textDecoration: 'none',
        color: theme.palette.primary.light,
        position: 'relative',
        right: '-20px'
        // marginRight: '-28px',
      }}
    >
      <IconLink label='Edit profile' onClick={onEdit}>
        <EditOutlined htmlColor={theme.palette.primary.light} />
      </IconLink>
      <IconLink label='Change password' onClick={onChangePassword}>
        <LockOutlined htmlColor={theme.palette.primary.light} />
      </IconLink>
    </Box>
  );
};

type Mode = 'view' | 'edit' | 'password';

const getChannelName = () => replaceAll(__filename, '.', '_');

export const EditProfileDialog = () => {
  const theme = useTheme();
  const history = useHistory();
  const [avatarFile, setAvatarFile] = useState<FileDescriptor | null>(null);
  const [mode, setMode] = useState<Mode>('view');
  const resetFormRef = useRef(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNew2Password, setShowNew2Password] = useState(false);
  const [showResetNote, setShowResetNote] = useState(false);
  const [invalidPasswordsCollection, setInvalidPasswordsCollection] = useState<Array<string>>([]);
  const intl = useIntl()

  useEffect(() => {
    setShowResetNote(false);
  }, [mode]);

  useEffect(() => {
    if (mode !== 'view') {
      resetFormRef.current = true;
    }
  }, [mode]);

  const bounded = useSessionActions(
    'setEditProfileDialog',
    'postUpdateProfile',
    'logOut',
    'postChangePassword',
    'getCurrentProfile',
    'pushMessageSnack',
    'pushErrorSnack',
  );
  const { isVisible, user } = useSelector(
    (state: RootState) => ({
      isVisible: state.session.flags.openEditProfileDialog,
      user: state.session.user,
    }),
    shallowEqual,
  );

  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const onClose = useCallback(() => {
    bounded.setEditProfileDialog(false);
  }, [bounded]);

  const initEtitProfile = useCallback(() => {
    setMode('edit');
  }, []);

  const initChangePassword = useCallback(() => {
    setMode('password');
  }, []);

  if (!user) return null;

  const initialValues = {
    fullName: `${user.firstName || ''} ${user.lastName || ''}`,
    ...pick(user, 'firstName', 'lastName', 'nickname', 'email'),
    company: user.company.name,
  };

  const getDialogTittle = (mode: string) => {
    if (mode === 'view') {
      return intl.formatMessage({
        id: 'EditProfileDialog.Title.MyProfile',
        defaultMessage: "My profile",
      })
    } else if (mode === 'edit') {
      return intl.formatMessage({
        id: 'EditProfileDialog.Title.EditProfile',
        defaultMessage: "Edit profile",
      })
    } else if (mode === 'password') {
      return intl.formatMessage({
        id: 'EditProfileDialog.Title.ChangePassword',
        defaultMessage: "Change password",
      })
    }
  }

  const onSubmit = async (values: any) => {
    if (mode === 'edit') {
      let formData: FormData | null = null;
      if (avatarFile) {
        formData = new FormData();
        avatarFile.file && formData.append('avatar', avatarFile.file);
      }
      bounded
        .postUpdateProfile({
          identity: pick(values, 'firstName', 'lastName', 'nickname'),
          avatarForm: formData,
        })
        .then(unwrapResult)
        .then(() => {
          bounded.getCurrentProfile();
          bounded.pushMessageSnack({
            channel: getChannelName(),
            message: intl.formatMessage({
              id: 'EditProfileDialog.ChangesSaved',
              defaultMessage: 'Your changes have been saved!',
            })
          });
          scrollToTopSmooth()
          setMode('view');
        })
        .catch((error) => {
          bounded.pushErrorSnack({
            channel: getChannelName(),
            message: (error as Error).message,
          });
        });
    } else if (mode === 'password') {
      bounded
        .postChangePassword(pick(values, 'current', 'new'))
        .then(unwrapResult)
        .then((res: any) => {
          if (res?.errors?.current) {
            return bounded.pushErrorSnack({
              channel: getChannelName(),
              message: res.errors.current
            });
          }
          bounded.pushMessageSnack({
            channel: getChannelName(),
            message: intl.formatMessage({
              id: 'NewPasswordDialog.Validation.Success',
              defaultMessage: 'Your password has been changed successfully!',
            }),
          });
          scrollToTopSmooth()
          setMode('view');
        })
    }
  };

  return (
    <StyledDialog
      style={{ zIndex: 2010 }}
      open={isVisible}
      onClose={onClose}
      maxWidth='sm'
      fullScreen={!isMedium}
    >
      <StyledDialogTitle style={{ opacity: 1, background: '#3C3C3C' }}>
        {getDialogTittle(mode)}
        <TitleButton onClick={() => onClose()}>
          <ClearIcon color='primary' />
        </TitleButton>
      </StyledDialogTitle>
      <KairosSnackBar channel={getChannelName()} />
      <StyledContentMedium reduceHeight paddingType={mode === 'view' ? 'small' : 'wide'}>
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          let errors: Partial<Record<string, string>> = {};

          if (mode === 'password') {
            errors.new = (validatePasswordLength(values.new) ||
              validatePasswordHasSpecialCharacterOrDigit(values.new) ||
              validatePasswordHasLowerLetter(values.new) ||
              validatePasswordHasUpperLetter(values.new)) === undefined
              ? undefined
              : intl.formatMessage({
                id: 'NewPasswordDialog.Validation.PasswordDoesntMeetRequirements',
                defaultMessage: "Password does not meet requirements.",
              });
            if (values.new !== values.new2) {
              errors.new2 = errors.password2 = intl.formatMessage({
                id: 'EditProfileDialog.Validation.PasswordsDontMatch',
                defaultMessage: "The entered passwords are different",
              });
            }

            if (invalidPasswordsCollection.includes(values.current)) {
              errors.current = errors.new2 = errors.password2 = intl.formatMessage({
                id: 'EditProfileDialog.Validation.IncorrectCurrentPassword',
                defaultMessage: "The entered password is incorrect.",
              });
            }
          }
          return errors;
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, submitting, hasValidationErrors, form , values}) => {
          if (resetFormRef.current) {
            form.reset();
            resetFormRef.current = false;
          }

          return (
            <form onSubmit={handleSubmit}>
              <Grid container justify='flex-end' style={{position: 'relative'}}>
                <Box position='absolute' top={0}>
                  <Grid item xs={12} md={12}>
                    {mode === 'view' && (
                      <ControlsBox onEdit={initEtitProfile} onChangePassword={initChangePassword} />
                    )}
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <Box
                    mt={[10, 8, 2]}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <EditorAvatar
                      user={user}
                      showBadge={mode === 'edit'}
                      onPictureChoosed={(desc) => {
                        setAvatarFile(desc);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    mt={2}
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    {mode === 'view' && (
                      <React.Fragment>
                        <Box
                          style={{
                            fontSize: '24px',
                            textAlign: 'center',
                            fontWeight: 600,
                          }}
                        >
                          {user.nickname || user.firstName}
                        </Box>

                        <Box css={{}}>
                          <div
                            css={{
                              padding: '16px 0px',
                              textAlign: 'center',
                              color: theme.palette.primary.light,
                              maxWidth: '426px',
                              fontSize: '14px',
                            }}
                          >
                            <FormattedMessage
                              id='EditProfileDialog.CreateNicknameCaption'
                              defaultMessage='(your name <b>will appear public</b> while your activity on the platform, to <b>remain anonymous</b> go to „edit profile” and <b>create a nickname</b>)'
                              values={{
                                b: (chunk: any) => <b>{chunk}</b>,
                              }}
                            />
                          </div>
                        </Box>
                      </React.Fragment>
                    )}
                    {mode === 'view' && (
                      <Field
                        name='fullName'
                        component={EditorTextFieldAdapter}
                        color='primary'
                        validate={value => {
                          return mandatory(value, intl.formatMessage({
                            id: 'FormValidation.MandatoryField',
                            defaultMessage:
                              'This field is required.',
                          }))
                        }}
                        label={intl.formatMessage({
                          id: 'EditProfileDialog.InputLabel.FullName',
                          defaultMessage: 'Full Name',
                        })}
                        variant='outlined'
                        css={{ marginTop: theme.spacing(4), width: '100%', maxWidth: '446px' }}
                        InputLabelProps={{ shrink: true }}
                        disabled={mode === 'view'}
                        disabledDarkVariant
                      />
                    )}
                    {mode === 'view' && (
                      <Field
                        name='email'
                        component={EditorTextFieldAdapter}
                        color='primary'
                        validate={value => {
                          return mandatory(value, intl.formatMessage({
                            id: 'FormValidation.MandatoryField',
                            defaultMessage:
                              'This field is required.',
                          }))
                        }}
                        label={
                          intl.formatMessage({
                            id: 'EditProfileDialog.InputLabel.EMail',
                            defaultMessage: 'E-mail',
                          })
                        }
                        variant='outlined'
                        css={{ marginTop: theme.spacing(1), width: '100%', maxWidth: '446px' }}
                        InputLabelProps={{ shrink: true }}
                        disabled={mode === 'view'}
                        disabledDarkVariant
                      />
                    )}

                    {mode === 'view' && (
                      <Field
                        name='company'
                        component={EditorTextFieldAdapter}
                        color='primary'
                        validate={value => {
                          return mandatory(value, intl.formatMessage({
                            id: 'FormValidation.MandatoryField',
                            defaultMessage:
                              'This field is required.',
                          }))
                        }}
                        label={
                          intl.formatMessage({
                            id: 'EditProfileDialog.InputLabel.Company',
                            defaultMessage: 'Company',
                          })
                        }
                        variant='outlined'
                        css={{ marginTop: theme.spacing(1), width: '100%', maxWidth: '446px' }}
                        InputLabelProps={{ shrink: true }}
                        disabled={mode === 'view'}
                        disabledDarkVariant
                      />
                    )}

                    {mode === 'edit' && (
                      <Field
                        name='firstName'
                        component={EditorTextFieldAdapter}
                        color='primary'
                        validate={value => {
                          return mandatory(value, intl.formatMessage({
                            id: 'FormValidation.MandatoryField',
                            defaultMessage:
                              'This field is required.',
                          }))
                        }}
                        label={
                          intl.formatMessage({
                            id: 'EditProfileDialog.InputLabel.FirstName',
                            defaultMessage: 'First name',
                          })
                        }
                        variant='outlined'
                        css={{ marginTop: '30px', width: '100%', maxWidth: '446px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}

                    {mode === 'edit' && (
                      <Field
                        name='lastName'
                        component={EditorTextFieldAdapter}
                        color='primary'
                        validate={value => {
                          return mandatory(value, intl.formatMessage({
                            id: 'FormValidation.MandatoryField',
                            defaultMessage:
                              'This field is required.',
                          }))
                        }}
                        label={
                          intl.formatMessage({
                            id: 'EditProfileDialog.InputLabel.LastName',
                            defaultMessage: 'Last name (visible only to you)',
                          })
                        }
                        variant='outlined'
                        css={{ marginTop: '30px', width: '100%', maxWidth: '446px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}

                    {mode === 'edit' && (
                      <Field
                        name='nickname'
                        component={EditorTextFieldAdapter}
                        color='primary'
                        label={
                          `${
                            intl.formatMessage({
                            id: 'EditProfileDialog.InputLabel.Nickname',
                            defaultMessage: 'Nickname',
                          })
                          } ${mode === 'edit' 
                            ? ` * ${intl.formatMessage({
                              id: 'EditProfileDialog.Literal.Optional',
                              defaultMessage: 'optional',
                            })}` 
                            : ''}`
                        }
                        fullWidth
                        variant='outlined'
                        css={{ marginTop: '30px', width: '100%', maxWidth: '446px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}

                    {mode === 'password' && (
                      <Field
                        name='current'
                        component={EditorTextFieldAdapter}
                        color='primary'
                        validate={value => {
                          return mandatory(value) || validatePasswordHasWhiteSpace(value)
                        }}
                        label={
                          intl.formatMessage({
                            id: 'NewPasswordDialog.InputLabels.CurrentPassword',
                            defaultMessage: "Current password",
                          })
                        }
                        variant='outlined'
                        css={{ marginTop: theme.spacing(4), width: '100%', maxWidth: '446px' }}
                        InputLabelProps={{ shrink: true }}
                        type={showCurrentPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <VisibilityAdornment
                              onChange={({ isVisible }) => setShowCurrentPassword(isVisible)}
                            />
                          ),
                        }}
                      />
                    )}
                    {mode === 'password' && (
                      <Field
                        name='new'
                        component={EditorTextFieldAdapter}
                        color='primary'
                        validate={value => {
                          return mandatory(value) || validatePasswordHasWhiteSpace(value)
                        }}
                        label={
                          intl.formatMessage({
                            id: 'NewPasswordDialog.InputLabels.NewPassword',
                            defaultMessage: "New password",
                          })
                        }
                        variant='outlined'
                        css={{ marginTop: theme.spacing(4), width: '100%', maxWidth: '446px' }}
                        InputLabelProps={{ shrink: true }}
                        type={showNewPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <VisibilityAdornment
                              onChange={({ isVisible }) => setShowNewPassword(isVisible)}
                            />
                          ),
                        }}
                      />
                    )}
                    {mode === 'password' && (
                      <Box maxWidth='446px' width='100%' mt={-2} mb={3}>
                        <PasswordRequirements password={values.new} />
                      </Box>
                    )}
                    {mode === 'password' && (
                      <React.Fragment>
                        <Field
                          name='new2'
                          component={EditorTextFieldAdapter}
                          color='primary'
                          validate={value => {
                            return mandatory(value) || validatePasswordHasWhiteSpace(value)
                          }}
                          label={
                            intl.formatMessage({
                              id: 'NewPasswordDialog.InputLabels.ConfirmNewPassword',
                              defaultMessage: "Confirm new password",
                            })
                          }
                          variant='outlined'
                          css={{ marginTop: theme.spacing(4), width: '100%', maxWidth: '446px' }}
                          InputLabelProps={{ shrink: true }}
                          type={showNew2Password ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <VisibilityAdornment
                                onChange={({ isVisible }) => setShowNew2Password(isVisible)}
                              />
                            ),
                          }}
                        />
                        <Box maxWidth={'446px'} mb={2}>
                          <StyledLink
                            style={{ marginBottom: '20px' }}
                            to='#reset-note'
                            onClick={() => {
                              setShowResetNote(!showResetNote);
                            }}
                          >
                            {
                              intl.formatMessage({
                                id: 'NewPasswordDialog.ResetLink.DontRememberPassword',
                                defaultMessage: "Forgot password?",
                              })
                            }
                          </StyledLink>
                        </Box>
                        {showResetNote && (
                          <NoteBox>
                            {
                              intl.formatMessage({
                                id: 'NewPasswordDialog.ResetPasswordNote1',
                                defaultMessage: "If you want to change your password but cannot remember your current one, please",
                              })
                            }
                            {' '}
                            <ArrangedBoldLink
                              to='/auth'
                              onClick={() => {
                                const rt = localStorage.getItem(constants.REFRESH_TOKEN_NAME)
                                if (rt) {
                                  api.invalidateSession(rt).then(() => {
                                    bounded.logOut()
                                    history.push('/auth');
                                  })
                                } else {
                                  bounded.logOut()
                                  history.push('/auth');
                                }
                              }}
                            >
                              {
                                intl.formatMessage({
                                  id: 'NewPasswordDialog.Literal.LogOut',
                                  defaultMessage: "Log out",
                                })
                              }
                            </ArrangedBoldLink>{' '}
                            {
                              intl.formatMessage({
                                id: 'NewPasswordDialog.ResetPasswordNote2',
                                defaultMessage: 'and request a new password on the "Log In" page.',
                              })
                            }
                          </NoteBox>
                        )}
                      </React.Fragment>
                    )}
                  </Box>

                  {mode === 'edit' && (
                    <Box>
                      <div
                        style={{
                          width: '100%',
                          maxWidth: '336px',
                          margin: '10px auto 15px',
                          fontSize: '14px',
                          fontWeight: 300,
                          color: theme.palette.primary.light,
                        }}
                      >
                        {
                          intl.formatMessage({
                            id: 'EditProfileDialog.CreateNicknameCaption2',
                            defaultMessage: "* (You can create a personal nickname to remain anonymous. If you don't, your first name will be visible to other users by default.)",
                          })
                        }
                      </div>
                    </Box>
                  )}

                  {(mode === 'edit' || mode === 'password') && (
                    <Box display='flex' flexDirection='column' alignItems='center'>
                      <MediumButton
                        color='secondary'
                        variant='contained'
                        type='submit'
                        disabled={submitting || hasValidationErrors}
                        style={{ width: '100%', maxWidth: '350px', marginTop: '10px' }}
                      >
                        {
                          intl.formatMessage({
                            id: 'EditProfileDialog.Literal.Save',
                            defaultMessage: "Save",
                          })
                        }
                      </MediumButton>

                      <MediumButton
                        color='primary'
                        variant='outlined'
                        style={{ width: '100%', maxWidth: '350px', marginTop: '10px' }}
                        onClick={() => setMode('view')}
                      >
                        {
                          intl.formatMessage({
                            id: 'EditProfileDialog.Literal.Cancel',
                            defaultMessage: "Cancel",
                          })
                        }
                      </MediumButton>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='center'></Box>
                </Grid>
              </Grid>

              {mode === 'view' && (
                <Box
                  display='flex'
                  justifyContent='center'
                  css={{ marginTop: '9px', marginBottom: '9px' }}
                >
                  <Box maxWidth='100px'>
                    <Score
                      label={
                        intl.formatMessage({
                          id: 'EditProfileDialog.ScoreLabel.CompletedCourses',
                          defaultMessage: 'Completed Courses',
                        })
                      }
                      value={user.completedCourses}
                    />
                  </Box>
                  <Box maxWidth='100px' ml={2}>
                    <Score
                      label={
                        intl.formatMessage({
                          id: 'EditProfileDialog.ScoreLabel.CompletedChallenges',
                          defaultMessage: 'Completed Challenges',
                        })
                      }
                      value={user.completedChallenges}
                    />
                  </Box>
                  <Box maxWidth='100px' ml={2}>
                    <Score
                      label={
                        intl.formatMessage({
                          id: 'EditProfileDialog.ScoreLabel.AttendedLiveSessions',
                          defaultMessage: 'Attended Live Sessions',
                        })
                      }
                      value={user.liveSessionsAttended}
                    />
                  </Box>
                </Box>
              )}
            </form>
          );
        }}
      </Form>
      </StyledContentMedium>
    </StyledDialog>
  );
};
