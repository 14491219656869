/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Grid, Container, Portal, Box, Fade } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';

import { Field, Form } from 'react-final-form';
import { EditorTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import { mandatory } from 'utils/form-validation';
import { MediumButton } from 'common/components/General/Buttons';
import { FileDescriptor } from 'common/components/Uploader';
import { useSessionActions } from 'state/session/useSessionActions';
import { useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { RootState } from 'reducers';
import { useSelector } from 'react-redux';
import { KairosSnackBar } from 'common/components/General/KairosSnackBar';
import {useIntl} from "react-intl";
import {pushMessageSnack} from "../../../state/session/actions";
import {mainLayoutChannel} from "../../../layouts/Main";

interface IContactUsData {
  email: string;
  subject: string;
  message: string;
}

const channelName = 'mainMenuChannel';

export const ContactUs = (props: any) => {
  const theme = useTheme();
  const intl = useIntl()
  const filesRef = useRef<FileDescriptor[]>([]);

  const bounded = useSessionActions(
    'postContactUsData',
    'pushErrorSnack',
    'pushMessageSnack',
    'openConfirmationDialog',
  );
  const history = useHistory();

  const user = useSelector((state: RootState) => state.session.user);

  const openConfirmationDialog = () =>
    bounded.pushMessageSnack({
      channel: !user ? channelName : mainLayoutChannel,
      message: intl.formatMessage({
        id: 'ContactUs.HelpRequestSent',
        defaultMessage:
          'Your help request has been sent successfully!',
      }),
      timeout: 5000,
    });
    // bounded.openConfirmationDialog({
    //   isVisible: true,
    //   modalType: 'JUST_CONFIRM',
    //   modalProps: {
    //     header: `Success!`,
    //     text: 'Your message has been sent successfully.',
    //     confirmationLabel: 'OK',
    //     onlyConfirm: true,
    //   },
    // });

  return (
    <Fade in timeout={1000}>
      <Box>
        <Portal container={document.querySelector('#main-menu-anchor')}>
          <KairosSnackBar channel={channelName} />
        </Portal>
        <Form
          onSubmit={async (values: IContactUsData) => {
            let formData = new FormData();
            formData.append('email', values.email);
            formData.append('subject', values.subject);
            formData.append('message', values.message);
            filesRef.current.forEach((descriptor) => {
              descriptor.file && formData.append('media', descriptor.file);
            });
            let result: any;
            try {
              result = await bounded.postContactUsData(formData);
              unwrapResult(result);
              // bounded.pushMessageSnack({
              //   channel: channelName,
              //   message: 'Contact message successfully sent.',
              // });
              // ((history as unknown) as IRHistory).goBack();
              history.push('/main/home');
              openConfirmationDialog();
            } catch (ex) {
              bounded.pushErrorSnack({
                channel: channelName,
                message: 'Contact message sending error',
              });
            }
          }}
          initialValues={{ email: user?.email || '' }}
          render={({ handleSubmit, hasValidationErrors, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Box
                fontSize={['12px', '14px', '16px']}
                p={[2, 4, 7]}
                style={{ backgroundColor: '#000' }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      fontSize={['12px', '14px', '16px']}
                      mt={[5, 5, 1]}
                      style={{ backgroundColor: '#000' }}
                    >
                      <p style={{ fontSize: '2.5em', textAlign: 'center' }}>Contact Us</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      name='email'
                      component={EditorTextFieldAdapter}
                      color='primary'
                      validate={value => {
                        return mandatory(value, intl.formatMessage({
                          id: 'FormValidation.MandatoryField',
                          defaultMessage:
                            'This field is required.',
                        }))
                      }}
                      label='E-mail address *'
                      fullWidth
                      variant='outlined'
                      css={{ marginTop: theme.spacing(4) }}
                      InputLabelProps={{ shrink: true }}
                      disabled={!!user?.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} />
                  <Grid item xs={12} md={6}>
                    <Field
                      name='subject'
                      component={EditorTextFieldAdapter}
                      color='primary'
                      validate={value => {
                        return mandatory(value, intl.formatMessage({
                          id: 'FormValidation.MandatoryField',
                          defaultMessage:
                            'This field is required.',
                        }))
                      }}
                      label='Subject *'
                      fullWidth
                      variant='outlined'
                      css={{ marginTop: theme.spacing(4) }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} />
                  <Grid item xs={12}>
                    <Field
                      name='message'
                      component={EditorTextFieldAdapter}
                      color='primary'
                      validate={value => {
                        return mandatory(value, intl.formatMessage({
                          id: 'FormValidation.MandatoryField',
                          defaultMessage:
                            'This field is required.',
                        }))
                      }}
                      label='Your message *'
                      fullWidth
                      variant='outlined'
                      multiline
                      rows={9}
                      css={{ marginTop: theme.spacing(4) }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} />
                </Grid>
                <Container css={{ textAlign: 'center', marginTop: theme.spacing(5) }}>
                  <Box display='flex' justifyContent='center' flexWrap='wrap'>
                    <Box mr={[0, 1, 1]} ml={[0, 1, 1]} mt={1} width='100%' maxWidth='350px'>
                      <MediumButton
                        fullWidth
                        color='secondary'
                        variant='contained'
                        type='submit'
                        disabled={hasValidationErrors || submitting}
                      >
                        Send
                      </MediumButton>
                    </Box>
                    <Box ml={[0, 1, 1]} mr={[0, 1, 1]} mt={1} width='100%' maxWidth='350px'>
                      <MediumButton
                        fullWidth
                        color='primary'
                        variant='outlined'
                        onClick={() => history.go(-1)}
                      >
                        Cancel
                      </MediumButton>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </form>
          )}
        />
      </Box>
    </Fade>
  );
};
