import { Box, Dialog, Fade, Typography, withStyles } from '@material-ui/core';
import { SmallButton } from 'common/components/General/Buttons';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useCelebrationsActions } from 'state/celebrations/useCelebrationsActions';
import VideoPlayer from 'views/VideoPlayer';
import { CelebrationExpandedComponent } from 'views/VideoPlayer/CelecrationExpanddComponent';
import styles from './Celebrations.module.scss';
import cx from 'classnames';

const CelebrationDialog = withStyles({
  root: {
    '& .MuiBackdrop-root': {
      animation: `${styles.initialFadeIn} 1.5s ease 0s`,
      backgroundColor: '#101010e5',
    },
  },
  container: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      animation: `${styles.containerOpacity} 1.5s ease`,
    },
  },
})(Dialog);

export const CelebrationModal = () => {
  const celebration = useSelector(
    (state: RootState) => state.celebrations.definition,
    shallowEqual,
  );
  const [play, setPlay] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const { setupCelebration } = useCelebrationsActions('setupCelebration');
  const [activated, setActivated] = useState(false);

  useEffect(() => {
    if (celebration === null) {
      setActivated(false);
    } else {
      setTimeout(() => setActivated(true), 500);
    }
  }, [celebration]);

  const [leaving, setLeaving] = useState(false);

  const handleCelebrationFinish = useCallback(() => {
    setPlay(false);
    setLeaving(true);
    setTimeout(() => {
      setupCelebration(null);
      setLeaving(false);
    }, 500);

    if (celebration?.callback) {
      celebration.callback();
    }
  }, [celebration, setupCelebration]);

  return (
    <CelebrationDialog open={activated} fullScreen={true} className={cx({})}>
      <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
        <Fade in={!play && !leaving}>
          <Box position='relative' maxWidth='500px'>
            <Box m={2}>
              <Typography variant='h3' component='div' style={{ textAlign: 'center' }}>
                {celebration?.title}
              </Typography>
            </Box>
            <Box m={2} mb={4}>
              <Typography variant='body1' style={{ textAlign: 'center' }}>
                {celebration?.content}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column' mx={[1, 2, 4, 6]}>
              <SmallButton variant='contained' color='secondary' onClick={() => setPlay(true)}>
                <FormattedMessage
                  id='CelebrationModal.Celebrate.Caption'
                  defaultMessage='Celebrate'
                />
              </SmallButton>
              <SmallButton variant='outlined' color='primary' onClick={handleCelebrationFinish}>
                <FormattedMessage
                  id='CelebrationModal.NotInterested.Caption'
                  defaultMessage='Not today'
                />
              </SmallButton>
            </Box>
          </Box>
        </Fade>
      </Box>

      <Fade in={play && canPlay}>
        <Box position='absolute' width='100%'>
          <VideoPlayer
            play={play}
            src={celebration?.video}
            noControls
            type='celebration'
            loop
            endedTreshold={40000000}
            OverlayProps={{
              transparentBackground: true,
              alwaysShow: true,
              ExpandedElement: <CelebrationExpandedComponent onClick={handleCelebrationFinish} />,
              InfoBoxComponent: () => <Box height='227px'></Box>,
            }}
            onCanPlayChange={(e) => setCanPlay(e.canPlay)}
            withMuteFab
          />
        </Box>
      </Fade>
    </CelebrationDialog>
  );
};
