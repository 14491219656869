import React, { useEffect, useState } from 'react';
import SlideComponent, { SlideComponentProps } from '../index';
import { useSessionActions } from 'state/session/useSessionActions';
import { useViewsActions } from 'state/views/useViewsActions';
import { useCelebrationsActions } from 'state/celebrations/useCelebrationsActions';
import {useIntl} from "react-intl";

const CourseSlideOpen = ({ ...props }: SlideComponentProps) => {
  const [actionsBase] = useState({});
  const intl = useIntl()

  const bounded = Object.assign(actionsBase, {
    ...useViewsActions('joinCourse', 'getHomeData'),
    ...useCelebrationsActions('randomCelebration'),
  });

  const [userJoined, setUserJoined] = useState(props.item.joined);

  useEffect(() => {
    setUserJoined(props.item.joined);
  }, [props.item.joined]);

  useEffect(() => {
    setUserJoined(false)
  }, [props.item])

  const { openSlideNotification } = useSessionActions('openSlideNotification');

  const openNotification = (
    sliderTitle: string | undefined,
    itemId: string,
    message: string,
    actionLabel: string,
  ) => {
    return openSlideNotification({
      id: `${sliderTitle}-${itemId}`,
      message: message,
      actionLabel: actionLabel,
    });
  };

  const joinCourse = () => {
    if (props.item.joined) {
      return openNotification(
        props.sliderTitle,
        props.item.id,
        intl.formatMessage({
          id: 'OpenCourses.Notification.AlreadyJoined',
          defaultMessage: `You have already joined this course.`,
        }),
        intl.formatMessage({
          id: 'Literal.OK',
          defaultMessage: `OK`,
        }),
      );
    }

    bounded.joinCourse(props.item.id).then(() => {
      setUserJoined(true);
      bounded.getHomeData();
      openNotification(
        props.sliderTitle,
        props.item.id,
        intl.formatMessage({
          id: 'OpenCourses.Notification.JoinedSuccessfully',
          defaultMessage: `You have joined the course successfully!`,
        }),
        intl.formatMessage({
          id: 'Literal.OK',
          defaultMessage: `OK`,
        }),
      );
      setTimeout(() => {
        bounded.randomCelebration(0.5);
      }, 500);
    });
  };

  return (
    <SlideComponent
      withVideo
      withDescription
      withArrow
      withJoinFab
      joinFabProps={{
        onClick: () => joinCourse(),
        userJoined,
      }}
      {...props}
    />
  );
};

export default React.memo(CourseSlideOpen);
