import { Box, Button, Grid } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { SmallButton } from '../../common/components/General/Buttons';

export const P2PIntroductionInfoBox = ({
  name,
  description,
  expanded,
}: {
  name: string;
  description: string;
  expanded: boolean;
}) => {
  const history = useHistory();
  const params = useParams<{ new?: string }>();
  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Box fontSize={['24px', '24px', '26px']} fontWeight={500} mt={2} ml={2} mb={1}>
          {name}
        </Box>
      </Grid>
      {/*<Box justifySelf='end' alignSelf='end'></Box>*/}

      <Grid item xs={12} sm={12} md={6}>
        <Box
          fontSize={['12px', '12px', '16px']}
          fontWeight={300}
          lineHeight={1.34}
          ml={2}
          mr={2}
          mt={1}
          pb={2}
        >
          {description}
        </Box>
      </Grid>
    </Grid>
  );
};
