import { Box, Tooltip } from '@material-ui/core';
import { CreateOutlined } from '@material-ui/icons';
import mountain from 'assets/mountain.svg';
import mountainFull from 'assets/mountainFull.svg';
import React from 'react';
import { useIntl } from 'react-intl';
import { ChallengeStar } from '../../styledComponents';

interface StarsProps {
  item: any;
}

const Stars: React.FC<StarsProps> = ({ item }) => {
  // FIXME: apply translation messages for tooltips and labels
  /**
   *
   * @intl intl
   */
  const intl = useIntl();

  const itemChallenges: Array<any> = [];

  item.chapters?.forEach((chapter: any) =>
    chapter.challenges?.forEach((challenge: any) => itemChallenges.push(challenge)),
  );

  return (
    <Box
      // height='100%'
      width='100%'
      position='absolute'
      display='flex'
      justifyContent='flex-end'
      alignItems='flex-start'
    >
      {itemChallenges.length > 0 &&
        itemChallenges.map((challenge: any) => {
          return (
            <Tooltip
              key={challenge.id}
              title={
                challenge.submission?.status === 'COMPLETED'
                  ? 'Completed challenge'
                  : 'Incomplete challenge'
              }
              arrow
              placement='top'
              aria-label={
                challenge.submission?.status === 'COMPLETED'
                  ? 'completed challenge'
                  : 'incomplete challenge'
              }
            >
              <Box mt={1} mr={1}>
                <ChallengeStar disableRipple disableTouchRipple size='small' aria-label='play'>
                  {challenge.submission?.status === 'COMPLETED' ? (
                    <img
                      style={{ height: '20px' }}
                      src={mountainFull}
                      alt={'completed challenge'}
                    />
                  ) : (
                    <img style={{ height: '20px' }} src={mountain} alt={'incomplete challenge'} />
                  )}
                </ChallengeStar>
              </Box>
            </Tooltip>
          );
        })}
      {item.challenge && (
        <Tooltip
          key={item.id}
          title={
            item.challenge.submission?.status === 'COMPLETED'
              ? 'View completed challenge'
              : 'Work on challenge'
          }
          arrow
          placement='top'
          aria-label={
            item.challenge.submission?.status === 'COMPLETED'
              ? 'view completed challenge'
              : 'work on challenge in progress'
          }
        >
          <Box mt={1} mr={1}>
            <ChallengeStar disableRipple disableTouchRipple size='small' aria-label='play'>
              {item.challenge.submission?.status === 'COMPLETED' ? (
                <img style={{ height: '20px' }} src={mountainFull} alt={'completed challenge'} />
              ) : (
                <img style={{ height: '20px' }} src={mountain} alt={'incomplete challenge'} />
              )}
            </ChallengeStar>
          </Box>
        </Tooltip>
      )}
      {item.submission?.status === 'DRAFT' && (
        <Tooltip
          key={item.id}
          title='Work on challenge in progress'
          arrow
          placement='top'
          aria-label='work on challenge in progress'
        >
          <Box mt={1} mr={1}>
            <ChallengeStar disableRipple disableTouchRipple size='small' aria-label='edit result'>
              <CreateOutlined />
            </ChallengeStar>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default React.memo(Stars);
