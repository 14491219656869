import React from 'react';
import SlickCarousel from 'common/components/SlickCarousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useIntl } from 'react-intl';

const Open = ({ items }: any) => {
  const intl = useIntl();
  return (
    <div>
      <SlickCarousel
        titleCentered
        titleBright
        sliderType='courses-open'
        items={items}
        sliderTitle={intl.formatMessage({
          id: 'MyCourses.Sections.Open.SliderTitle',
          defaultMessage: 'OPEN COURSES',
        })}
        slidesToShow={3}
        slidesToScroll={3}
        speed={500}
      />
    </div>
  );
};

export default React.memo(Open);
