import React from 'react';
import SlideComponent, { SlideComponentProps } from '../index';

const EpisodeSlide = ({ ...props }: SlideComponentProps) => {
  return (
    <SlideComponent
      withVideo
      withDescription
      descriptionOnHover
      withPlayButton
      withProgress
      withTitleOrderNumberPrefix
      withStars
      {...props}
    />
  );
};

export default React.memo(EpisodeSlide);
