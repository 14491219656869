import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cx from 'classnames';
import React from 'react';

type PointedHintBoxProps = {
  pointer: string;
  children: string;
  className?: string;
  mt?: string[];
  mb?: string[];
};

export const PointedHintBox = ({
  pointer,
  children,
  className,
  mt = ['20px'],
  mb = ['20px'],
}: PointedHintBoxProps) => {
  const theme = useTheme();
  return (
    <Box
      display='grid'
      gridTemplateColumns='max-content 1fr'
      mt={mt}
      mb={mb}
      className={cx(className)}
      bgcolor={theme.palette.grey[800]}
      p='16px'
    >
      <Box display='inline-block' padding='0px 8px'>
        {pointer}
      </Box>
      <Box display='inline-block' css={{wordBreak: 'break-word'}} padding='0px 8px'>
        {children}
      </Box>
    </Box>
  );
};
