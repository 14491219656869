import {Box, Fade} from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { TabContentProps } from 'common/components/NavBar/tabsConfig';
import React, {useEffect, useState} from 'react';
import { RootState } from 'reducers';
import { Courses } from 'state/views';
import { useViewsActions } from 'state/views/useViewsActions';
import { useSelectorWithTabCompare } from 'utils';
import { useNavigation } from 'utils/useNavigation';
import HeroPoster from './sections/HeroPoster';
import MoreToContinue from './sections/MoreToContinue';
import Recommended from './sections/Recommended';

const Home = ({ tabIndex }: TabContentProps) => {
  const nav = useNavigation();
  const bounded = useViewsActions('getHomeData', 'getMyCoursesData');

  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    bounded
      .getMyCoursesData()
      .then(unwrapResult)
      .catch((error) => console.warn('Error in effect', error));
    bounded
      .getHomeData()
      .then(unwrapResult)
      .then(() => {
        setFetched(true)
      })
      .catch((error) => console.warn('Error in effect', error));
  }, [bounded]);

  const { myCoursesData, homeViewData } = useSelectorWithTabCompare(
    (state: RootState) => ({
      myCoursesData: state.views.courses,
      homeViewData: state.views.home
    }),
    tabIndex,
  );

  const findEpisodesObjectsToContinue = (
    episodeToContinue: Courses.IEpisodePivotDTO,
  ): Courses.IEpisodeEssentials | null => {
    if (myCoursesData.length) {
      const course = myCoursesData.find((course) => course.id === episodeToContinue.course_id);
      const chapter = course?.chapters.find(
        (chapter) => chapter.id === episodeToContinue.chapter_id,
      );
      const episode = chapter?.episodes.find(
        (episode) => episode.id === episodeToContinue.resource_id,
      );
      // const challenge = chapter?.challenges.find(
      //   (challenge) => challenge.id === episodeToContinue.resource_id,
      // );

      return !episode || !course || !chapter
        ? null
        : {
            ...episode,
            course,
            chapter,
          };
    } else {
      return null;
    }
  };

  const episodesToContinue = homeViewData.episodesToContinue
    .map((episodeToContinue: any) => findEpisodesObjectsToContinue(episodeToContinue))
    .filter((x): x is Courses.IEpisodeEssentials => x !== null);

  const [, ...episodesToContinueWithoutHero] = episodesToContinue;

  const hasEpisodesToContinue = episodesToContinue.length > 0;
  const hasCourses = myCoursesData.length > 0;
  const recommendedCourses = myCoursesData;

  const seeAllEpisodes = 'episodes';

  const handleSeeAllEpisodes = () => {
    nav.goToCourseverview(myCoursesData[0].id, true);
  };

  return (
    <Fade in={fetched}>
      <Box>
        {hasEpisodesToContinue ? (
          <HeroPoster
            hero={episodesToContinue[0]}
            withSeeAllLink={true}
            handleSeeAll={handleSeeAllEpisodes}
            seeAllLabel={seeAllEpisodes}
            hasEpisodesToContinue={true}
          />
        ) : (
          hasCourses && (
            <HeroPoster
              hero={myCoursesData[0]}
              withSeeAllLink={true}
              handleSeeAll={handleSeeAllEpisodes}
              seeAllLabel={seeAllEpisodes}
              withSnackbar
              hasEpisodesToContinue={false}
            />
          )
        )}
        {episodesToContinueWithoutHero.length > 0 && (
          <Box ml={2} mr={2} mb={12}>
            <MoreToContinue items={episodesToContinueWithoutHero} />
          </Box>
        )}
        <Box mt={2} ml={2} mr={2} mb={[0, 0, 12]}>
          <Recommended items={recommendedCourses} />
        </Box>
        {/*<Box mt={2} ml={2} mr={2} mb={12}>*/}
        {/*  <MostPopular items={mostPopularCourses} />*/}
        {/*</Box>*/}
      </Box>
    </Fade>
  );
};

export default React.memo(Home);
