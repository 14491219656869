import React from 'react';
import SlickCarousel from 'common/components/SlickCarousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { FormattedMessage, useIntl } from 'react-intl';

const Open = () => {
  const items = useSelector((state: RootState) => state.challenges.items.open);
  const intl = useIntl();
  // const itemsWithoutLive = items.filter((item: any) => !item.is_live);

  return (
    <div>
      <SlickCarousel
        titleBright
        titleCentered
        sliderType='challenges-open'
        items={items}
        sliderTitle={intl.formatMessage({
          id: 'MyChallenges.Sections.Open.SliderTitle',
          defaultMessage: 'OPEN CHALLENGES',
        })}
        nothingToShowMessage={
          <span>
            <FormattedMessage
              id='MyChallenges.Sections.Open.NothingToShowMessage'
              defaultMessage='It seems like there are no open challenges at the moment. <br></br>
            Join new courses to take on new challenges.'
              values={{
                br: () => <br />,
              }}
            />
          </span>
        }
        slidesToShow={3}
        slidesToScroll={3}
        speed={500}
      />
    </div>
  );
};

export default React.memo(Open);
