/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';
import { Box } from '@material-ui/core';

import { styled } from '@material-ui/core';

const CornerBoxContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // width: '126px',
  // height: '100px',
  // flex: '0 1 auto',
  flexDirection: 'column',
});

export const HeaderCornerBox = ({ children }: { children: ReactNode }) => {
  return <CornerBoxContainer>{children}</CornerBoxContainer>;
};
