import React from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { SlideName } from '../../styledComponents';

interface TitleOverlayProps {
  item: any;
}

const TitleOverlay: React.FC<TitleOverlayProps> = ({ item }) => {
  const theme = useTheme();

  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      height='100%'
      width='100%'
      position='absolute'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Box
        maxWidth='40%'
        textAlign='center'
        p={2}
        style={{ background: 'rgba(0, 0, 0, 0.7)', border: '1px solid #ffffff' }}
      >
        <SlideName style={{ textAlign: 'center', fontSize: matchesMd ? '18px' : '12px' }}>
          {item.title ? item.title.toUpperCase() : item.name?.toUpperCase()}
        </SlideName>
      </Box>
    </Box>
  );
};

export default React.memo(TitleOverlay);
