/**  @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Slide,
} from '@material-ui/core';
import { styled, Theme, withStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeftSharp } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Courses } from 'state/views';
import SlickCarousel from '../../common/components/SlickCarousel';
import { pad } from '../../common/utils';
import { scrollToRef } from '../../utils/helpers';
import HeroPoster from '../Home/sections/HeroPoster';
import CourseOverviewOverlay from './CourseOverviewOverlay';

const Accordion = withStyles({
  root: {
    marginBottom: '15px',
    background: '#000',
  },
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(24),
    marginLeft: 30,
    marginRight: 30,
    padding: 0,
    borderBottom: '1px solid rgba(255, 255, 255, 0.6)',
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    fontWeight: 300,
    marginLeft: 30,
    marginRight: 30,
    padding: 0,
    paddingTop: 30,
  },
})(MuiAccordionDetails);

const BackButton = styled(Button)({
  marginLeft: 10,
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const CourseOverview = ({
  showChapters,
  courseToHighlight,
  courseOverview,
  heroPosterPosition,
}: {
  showChapters: any;
  courseToHighlight: Courses.ICourseDTO;
  courseOverview: boolean;
  heroPosterPosition: any;
}) => {
  const history = useHistory();

  const [expanded, setExpanded] = useState<string | null>(null);
  const chaptersBoxRef = useRef(null);

  useEffect(() => {
    if (showChapters) {
      if (chaptersBoxRef.current !== null) {
        scrollToRef(chaptersBoxRef);
      }
    }
  }, [showChapters]);

  const handleAccordionChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : '');
  };

  useEffect(() => (!showChapters ? window.scrollTo(0, 0) : undefined), [
    courseOverview,
    showChapters,
  ]);

  const { joined } = courseToHighlight;

  let episodeEssentials: Courses.IEpisodeEssentials | null = null;

  let courseToHighlightChallenges: Courses.IChallengeDTO[] = [];

  courseToHighlight.chapters.forEach((chapter) => {
    const challenges = chapter.challenges;
    challenges.forEach((challenge) => courseToHighlightChallenges.push(challenge));
  });

  if (joined) {
    let result: any = null;
    let some = courseToHighlight.chapters.some((chapter) => {
      return chapter.episodes.some((episode) => {
        if (episode?.id === joined?.toContinue?.subject?.id) {
          result = {
            ...episode,
            course: courseToHighlight,
            chapter,
          };
          return true;
        }
        return false;
      });
    });
    if (some) {
      episodeEssentials = result;
    } else {
      episodeEssentials = null;
    }
  }

  const chapterId = episodeEssentials?.chapter.id;
  useEffect(() => {
    setExpanded(chapterId || null);
  }, [courseToHighlight, chapterId]);

  return (
    <Slide direction='right' in={courseOverview}>
      <div style={{ background: '#000', position: 'relative' }}>
        <Box ml={[1, 1, 8]} position='absolute' top={0} zIndex={1}>
          <BackButton disableRipple disableTouchRipple onClick={() => history.go(-1)}>
            <KeyboardArrowLeftSharp />
            <FormattedMessage
              id='Literal.Back'
              defaultMessage='Back'
              description='Literally word Back or it equivalent, used as Literal for back button in overlays'
            />
          </BackButton>
        </Box>
        <Box>
          {episodeEssentials ? (
            <React.Fragment>
              <HeroPoster hero={episodeEssentials} />
              <CourseOverviewOverlay
                withSeeAllLink={false}
                hero={courseToHighlight}
                courseToHighlightChallenges={courseToHighlightChallenges}
                episodeTitle={episodeEssentials.title}
                episodeDescription={episodeEssentials.description}
                episodeNumber={episodeEssentials.ordinal}
                courseName={episodeEssentials.course.name}
                courseDescription={episodeEssentials.course.description}
                chapterNumber={episodeEssentials.chapter.ordinal}
              />
            </React.Fragment>
          ) : (
            <HeroPoster position={heroPosterPosition} hero={courseToHighlight} />
          )}
        </Box>
        <Box mt={8} mb={12}>
          <div ref={chaptersBoxRef}>
            {courseToHighlight.chapters.map((chapter: any) => {
              const indexOfEpisodeToContinue = chapter.episodes.findIndex(
                (episode: any) => episode?.id === joined?.toContinue?.subject?.id,
              );

              return (
                <Accordion
                  key={chapter.id}
                  expanded={chapter.id === expanded}
                  onChange={handleAccordionChange(chapter.id)}
                  square
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        fontSize='large'
                        style={{
                          color: '#fff',
                        }}
                      />
                    }
                  >
                    <Box fontSize={['16px', '16px', '20px']}>
                      <FormattedMessage
                        id='Literal.Chapter'
                        defaultMessage='Chapter'
                        description='Literally Chapter word or its equivalent'
                      />{' '}
                      {pad(chapter.ordinal, 2)} / {chapter.name}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box fontSize={['14px', '14px', '18px']}>{chapter.description}</Box>
                  </AccordionDetails>
                  <Box mt={6} mb={12} ml={2} mr={2}>
                    <SlickCarousel
                      initialSlide={
                        joined?.toContinue && indexOfEpisodeToContinue !== -1
                          ? indexOfEpisodeToContinue
                          : 0
                      }
                      sliderType='episodes'
                      items={[...chapter.episodes, ...chapter.challenges]
                        .filter((episode: any) => episode?.id)
                        .map((e: any) => {
                          return {
                            ...e,
                            courseId: courseToHighlight.id,
                            chapterId: chapter.id,
                            chapterOrdinal: chapter.ordinal,
                            isCurrent: e?.id === joined?.toContinue?.subject?.id,
                            showPlayButton: e?.id === joined?.toContinue?.subject?.id,
                          };
                        })}
                      sliderTitle=''
                      slidesToShow={3}
                      slidesToScroll={3}
                      speed={500}
                    />
                  </Box>
                </Accordion>
              );
            })}
          </div>
          {courseToHighlightChallenges.length > 0 && (
            <Box mt={12} ml={2} mr={2}>
              <SlickCarousel
                sliderType='challenges-mixed'
                items={courseToHighlightChallenges}
                shouldShowActionButton={joined}
                titleBright
                sliderTitle='CHALLENGES'
                titleCentered
                slidesToShow={3}
                slidesToScroll={3}
                speed={500}
              />
            </Box>
          )}
        </Box>
      </div>
    </Slide>
  );
};

export default CourseOverview;
