import * as React from 'react';
import { Score } from './Score';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

interface DownloadInstructionsProps {
  inlineLabel?: boolean;
  instructions: any;
  className?: string;
  arrowFontSize?: string;
}

export const DownloadInstructions = ({
  instructions,
  className,
  inlineLabel,
  arrowFontSize = '1.5rem',
}: DownloadInstructionsProps) => {
  return (
    <Score
      inlineLabel={inlineLabel}
      className={className}
      onClick={() => {
        if (!!instructions) {
          window.open(instructions.url);
        }
      }}
      label={inlineLabel ? 'Download instructions' : 'Instructions'}
      size='small'
      disabled={!!!instructions}
    >
      <ArrowDownwardIcon fontSizeAdjust='1rem' />
    </Score>
  );
};
