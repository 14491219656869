/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Dialog,
  DialogActions,
  DialogActionsClassKey,
  DialogActionsProps,
  DialogContent,
  DialogContentClassKey,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { createStyles, styled as styledMui, Theme, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface TitleProps {
  opaqueBackground?: boolean;
}

export const StyledDialogTitle = withStyles((theme) => ({
  root: {
    padding: '1rem',
    // backgroundColor: (props: any) => (props.transparent ? 'transparent' : theme.palette.grey[700]),
    background: '#101010', opacity: 0.8,
    textAlign: 'center',
  },
  // eslint-disable-next-line no-unused-vars
}))(({ transparent, ...rest }: any) => <DialogTitle {...rest} />);

export const StyledOpaqueDialogTitle = styledMui(DialogTitle)(({ theme }) => ({
  padding: '1rem',
  backgroundColor: theme.palette.grey[800],
  textAlign: 'center',
}));

export const TitleButton = styledMui(IconButton)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '1.25rem',
}));

interface StyledContentProps extends DialogContentProps {
  stickyBottom?: boolean;
  reduceHeight?: boolean;
}

export const StyledContent = withStyles((theme) =>
  createStyles({
    root: ({ stickyBottom = false, reduceHeight = false }: StyledContentProps) => {
      return {
        paddingTop: reduceHeight ? '30px' : theme.spacing(5),

        paddingBottom: stickyBottom ? '1px' : reduceHeight ? '30px' : theme.spacing(5),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        backgroundColor: theme.palette.grey[900],
      };
    },
  }),
)(DialogContent);

type Pad = 'small' | 'medium' | 'wide';
interface ITransparent {
  transparent?: boolean;
  paddindType?: Pad;
  reduceHeight?: boolean;
}

const getPadding = (
  type: Pad = 'medium',
  theme: Theme,
  breakpoint: 'xs' | 'sm' | 'normal' = 'normal',
) => {
  if (breakpoint === 'normal') {
    return type === 'small'
      ? theme.spacing(8)
      : type === 'medium'
      ? theme.spacing(11)
      : theme.spacing(19);
  } else if (breakpoint === 'sm') {
    return type === 'small'
      ? theme.spacing(6)
      : type === 'medium'
      ? theme.spacing(8)
      : theme.spacing(11);
  } else if (breakpoint === 'xs') {
    return type === 'small'
      ? theme.spacing(2)
      : type === 'medium'
      ? theme.spacing(4)
      : theme.spacing(6);
  }
};

export const StyledContentMedium = withStyles(
  (theme) =>
    createStyles<DialogContentClassKey, ITransparent>({
      dividers: {},
      root: ({ transparent, paddindType, reduceHeight }) => {
        return {
          // paddingTop: reduceHeight ? '30px' : theme.spacing(5),
          // paddingBottom: reduceHeight ? '30px' : theme.spacing(5),
          // paddingLeft: getPadding(paddindType, theme),
          // paddingRight: getPadding(paddindType, theme),
          [theme.breakpoints.down('sm')]: {
            paddingLeft: getPadding(paddindType, theme, 'sm'),
            paddingRight: getPadding(paddindType, theme, 'sm'),
          },
          [theme.breakpoints.down('xs')]: {
            paddingLeft: getPadding(paddindType, theme, 'xs'),
            paddingRight: getPadding(paddindType, theme, 'xs'),
          },
          backgroundColor: transparent ? theme.palette.grey[700] : theme.palette.grey[900],
        };
      },
    }),
  // eslint-disable-next-line no-unused-vars
)(({ paddindType = 'medium', reduceHeight = false, transparent, ...rest }: any) => (
  <DialogContent {...rest} />
));

export const StyledWideMarginContent = styledMui(DialogContent)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(19),
  paddingRight: theme.spacing(19),
  backgroundColor: theme.palette.grey[900],
}));

interface ISticky {
  stickyTop?: boolean;
  stickyBottom?: boolean;
}

const DialogActionsWithStyles = ({
  // eslint-disable-next-line no-unused-vars
  stickyBottom = false,
  // eslint-disable-next-line no-unused-vars
  stickyTop = false,
  // eslint-disable-next-line no-unused-vars
  transparent = false,
  ...rest
}: ISticky & ITransparent & DialogActionsProps) => {
  return <DialogActions {...rest} />;
};

export const StyledDialogActions = withStyles((theme) =>
  createStyles<DialogActionsClassKey, ISticky & ITransparent>({
    root: ({ stickyBottom, transparent }) => {
      return {
        backgroundColor: transparent ? theme.palette.grey[700] : theme.palette.grey[900],
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        justifyContent: 'center',
        paddingBottom: stickyBottom ? theme.spacing(3) : theme.spacing(6),
      };
    },
    spacing: {},
  }),
)(DialogActionsWithStyles);

export const StyledAgreements = styledMui('div')(({ theme }) => ({
  paddingTop: '0',
  backgroundColor: theme.palette.grey[900],
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  textAlign: 'center',
}));

interface IBackground {
  backgroundImage?: string;
  customOpacity?: number;
}

// eslint-disable-next-line no-unused-vars
const DialogWithStyles = ({ backgroundImage, ...rest }: IBackground & DialogProps) => (
  <Dialog {...rest} />
);

export const StyledDialog = styledMui(DialogWithStyles)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundImage: (props: IBackground) =>
      props.backgroundImage ? `url(${props.backgroundImage})` : 'none',
    backgroundSize: (props: IBackground) => (props.backgroundImage ? 'cover' : ''),
    backgroundPosition: 'center',
  },
  '& .MuiPaper-root': {
    width: '100%',
    opacity: (props: IBackground) => props.customOpacity || 1,
    backgroundColor: (props: IBackground) =>
      props.backgroundImage ? theme.palette.grey[700] : 'transparent',
  },
}));

export const StyledLink = styled(Link)`
  color: #ffffff;
  position: relative;
  right: 0;
  margin-left: auto;
  padding-bottom: 2px;
  text-decoration: none;
  border-bottom: solid 1px transparent;
  transition: border-bottom 0.5s ease;
  &:hover {
    border-bottom: solid 1px #ffffff;
    transition: border-bottom 0.5s ease;
  }
`;

export const FileLink = styled('div')`
  cursor: pointer;
  color: #ffffff;
  display: inline-block;
  position: relative;
  right: 0;
  margin-top: 10px;
  padding-bottom: 4px;
  text-decoration: none;
  border-bottom: solid 1px transparent;
  transition: border-bottom 1s ease;
  &:hover {
    border-bottom: solid 1px #ffffff;
    transition: border-bottom 1s ease;
  }
`;

export const ArrangedLink = styled(Link)`
  color: #ffffff;
  text-decoration: underline;
`;

export const ArrangedBoldLink = styled(Link)`
  color: #ffffff;
  text-decoration: underline;
  font-weight: 500;
`;
