import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import VideoPlayer from 'views/VideoPlayer';
import { Typography } from '@material-ui/core';
import { P2PIntroductionInfoBox } from './P2PIntroductionInfoBox';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../reducers';

export const P2PIntroductionPlayer = () => {
  const history = useHistory();
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.session.user, shallowEqual);

  const params = useParams<{ new?: string }>();

  const videoId = user?.company?.p2pInstructionsVideoId;

  return (
    <div id='__introductionPlayerContainer'>
      <VideoPlayer
        play
        src={videoId}
        type='introduction'
        endedTreshold={0}
        OverlayProps={{
          name: intl.formatMessage({
            id: 'VideoPlayer.P2PIntroductionPlayer.Name',
            defaultMessage: 'P2P FEEDBACK: How does it work?',
          }),
          description: intl.formatMessage({
            id: 'VideoPlayer.IntroductionPlayer.Desctiption',
            defaultMessage:
              'We are excited to have you here as an active member of our new learning platform. Before you start exploring Curjos, let Prof. Dr. Wolfgang Jenewein briefly introduce you to the main ideas behind the platform and how you can make most out of your online leadership journey.',
          }),
          withSplitBar: true,
          InfoBoxComponent: P2PIntroductionInfoBox,
          ExpandedElement: !!params.new ? (
            <Box maxWidth={'600px'}>
              <Typography variant='h2'>
                <FormattedMessage
                  id='VideoPlayer.IntroductionPlayer.GratulationsAfterIntroductionVideo'
                  defaultMessage='Now you are ready to start your personal journey with Curjos.
                <br></br>
                <br></br>
                Good luck!'
                  values={{
                    br: () => <br />,
                  }}
                />
              </Typography>
              <Button
                color='secondary'
                variant='contained'
                style={{ marginTop: '20px', padding: '20px 50px' }}
                onClick={() => history.push('/main/home')}
              >
                <FormattedMessage
                  id='VideoPlayer.IntroductionPlayer.Caption.GetStarted'
                  defaultMessage='GET STARTED'
                />
              </Button>
            </Box>
          ) : null,
          withBackButton: !params.new,
          backButtonLabel: 'Back',
          onBackClick: () => {
            history.go(-1);
          },
        }}
      />
    </div>
  );
};
