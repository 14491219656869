import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { Theme, useTheme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  StyledAgreements,
  StyledContent,
  StyledDialog,
  StyledDialogActions,
  StyledDialogTitle,
  StyledLink,
  TitleButton,
} from 'common/components/Dialogs/StyledDialogComponents';
import { StyledTextFieldAdapter } from 'common/components/Dialogs/TextFieldAdapter';
import { VisibilityAdornment } from 'common/components/Dialogs/VisibilityAdornment';
import { KairosSnackBar } from 'common/components/General/KairosSnackBar';
import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useCelebrationsActions } from 'state/celebrations/useCelebrationsActions';
import { devSwitches } from 'state/session/constants';
import { useSessionActions } from 'state/session/useSessionActions';
import {
  required,
  validatePasswordLength,
  validatePasswordsMatch,
  validatePasswordHasSpecialCharacterOrDigit,
  validatePasswordHasLowerLetter,
  validatePasswordHasUpperLetter,
  mandatory,
  validatePasswordHasWhiteSpace
} from 'utils/form-validation';
import PasswordRequirements from "../../../common/components/PasswordRequirements";
import {jsx} from "@emotion/core";

type RegisterModalProps = {
  open: boolean;
  email: string | null;
  onClose: () => void;
};

interface IRegisterValidations {
  password: string;
  confirmPassword: string;
}

const RegisterModal = ({ open, email, onClose }: RegisterModalProps) => {
  const initialValues = { email };
  const intl = useIntl();
  const [actionsBase] = useState({});
  const theme = useTheme();

  const bounded = Object.assign(actionsBase, {
    ...useSessionActions(
      'registerPassword',
      'pushErrorSnack',
      'pushMessageSnack',
      'getCurrentProfile',
    ),
    ...useCelebrationsActions('setupCelebration'),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const history = useHistory();

  return (
    <StyledDialog
      style={{ zIndex: 2010 }}
      open={open}
      maxWidth='xs'
      fullScreen={useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))}
    >
      <StyledDialogTitle>
        <TitleButton onClick={() => onClose()}>
          <ClearIcon color='primary' />
        </TitleButton>
      </StyledDialogTitle>
      <KairosSnackBar channel='register' />
      <StyledContent>
      <Form
        onSubmit={async (values: { email: string; password: string; confirmPassword: string }) => {
          await bounded
            .registerPassword({ username: values.email, password: values.password })
            .then(unwrapResult)
            .then(() => {
              onClose();
              // TODO: Celebrations disabled
              if (devSwitches.__celebrationsEnabled) {
                history.push('/main/home');
              } else {
                console.log('push to new intro')
                history.push('/watch/introduction/new');
              }
              ////
              bounded.pushMessageSnack({
                channel: 'register',
                message: intl.formatMessage({
                  id: 'RegisterModal.Snack.Success.SuccessfulRegistration',
                  defaultMessage: 'Successful registration',
                }),
              });
              bounded.setupCelebration({
                title: intl.formatMessage({
                  id: 'Celebrations.AfterRegistrations.Titile',
                  defaultMessage: "Let's celebrate!",
                }),
                content: intl.formatMessage({
                  id: 'Celebrations.AfterRegistrations.Content',
                  defaultMessage:
                    'Congratulations! You are now a member of the CURJOS community. Click below to celebrate with us!',
                }),
                video: intl.formatMessage({
                  id: 'Celebrations.AfterRegistrations.VideoId',
                  defaultMessage: '1e0c52a1356502550ce253e73808e8bc',
                }),
                callback: () => {
                  history.push('/watch/introduction/new');
                },
              });
            })
            .catch((error) => {
              bounded.pushErrorSnack({
                channel: 'register',
                message: error.message,
              });
              return { [FORM_ERROR]: 'error' };
            });
        }}
        validate={({ password, confirmPassword }: IRegisterValidations) => {
          const errors: Partial<Record<keyof IRegisterValidations, string>> = {};
          errors.password =
            (validatePasswordLength(password) ||
              validatePasswordHasSpecialCharacterOrDigit(password) ||
              validatePasswordHasLowerLetter(password) ||
              validatePasswordHasUpperLetter(password)) === undefined
              ? undefined
              : intl.formatMessage({
                id: 'RegisterModal.Validation.PasswordDoesntMeetRequirements',
                defaultMessage: "Password does not meet requirements.",
              });
          errors.confirmPassword =
            validatePasswordLength(confirmPassword) ||
            validatePasswordsMatch(password, confirmPassword, intl.formatMessage({
              id: 'FormValidation.PasswordsDoNotMatch',
              defaultMessage: 'Entered passwords do not match',
            }));
          return errors;
        }}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, hasValidationErrors, values }) => (
          <form onSubmit={handleSubmit}>
              <Box textAlign='center'>
                <Typography variant='h3'>
                  <FormattedMessage id='RegisterModal.Titile' defaultMessage='Welcome!' />
                </Typography>
                <Box mt={2} mb={2}>
                  <Typography variant='subtitle1'>
                    <FormattedMessage
                      id='RegistrationModal.Subtitile'
                      defaultMessage='We are happy that you want to join us! Set a password below to get started.'
                    />
                  </Typography>
                </Box>
              </Box>

              <Field
                name='email'
                component={StyledTextFieldAdapter}
                color='primary'
                validate={value => {
                  return mandatory(value, intl.formatMessage({
                    id: 'FormValidation.MandatoryField',
                    defaultMessage:
                      'This field is required.',
                  }))
                }}
                label='E-mail address'
                placeholder='Write your e-mail here'
                fullWidth
                variant='outlined'
                css={{ marginTop: theme.spacing(8) }}
                disabled={!!initialValues.email}
              />

              <Box mt={2} textAlign='center' color={theme.palette.primary.light} fontWeight={200}>
                <h6
                  style={{
                    color: '#ffffffff',
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: '1.75',
                    margin: 0,
                  }}
                >
                  <FormattedMessage
                    id='RegistrationModal.Subtitile2.SetPassword'
                    defaultMessage='Set password'
                  />
                </h6>
              </Box>

              <Box mt={1}>
                <Field
                  name='password'
                  component={StyledTextFieldAdapter}
                  color='primary'
                  label={intl.formatMessage({
                    id: 'RegisterModal.Field.Password.Label',
                    defaultMessage: 'Set password',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'RegisterModal.Field.Password.Placeholder',
                    defaultMessage: 'Write password here',
                  })}
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  variant='outlined'
                  validate={value => {
                    return mandatory(value, intl.formatMessage({
                      id: 'FormValidation.MandatoryField',
                      defaultMessage:
                        'This field is required.',
                    })) || validatePasswordHasWhiteSpace(value)
                  }}
                  InputProps={{
                    endAdornment: (
                      <VisibilityAdornment
                        onChange={({ isVisible }) => setShowPassword(isVisible)}
                      />
                    ),
                  }}
                />
              </Box>
              <PasswordRequirements password={values.password} />
              <Box mt={4}>
                <Field
                  name='confirmPassword'
                  component={StyledTextFieldAdapter}
                  color='primary'
                  label={intl.formatMessage({
                    id: 'RegisterModal.Field.ConfirmPassword.Label',
                    defaultMessage: 'Confirm password',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'RegisterModal.Field.ConfirmPassword.Placeholder',
                    defaultMessage: 'Confirm password here',
                  })}
                  type={showConfirmPassword ? 'text' : 'password'}
                  fullWidth
                  variant='outlined'
                  validate={value => {
                    return mandatory(value, intl.formatMessage({
                        id: 'FormValidation.MandatoryField',
                        defaultMessage:
                          'This field is required.',
                      })) || validatePasswordHasWhiteSpace(value)
                  }}
                  InputProps={{
                    endAdornment: (
                      <VisibilityAdornment
                        onChange={({ isVisible }) => setShowConfirmPassword(isVisible)}
                      />
                    ),
                  }}
                />
              </Box>
            <StyledDialogActions style={{paddingBottom: '12px'}}>
              <Button
                disabled={submitting || hasValidationErrors}
                type='submit'
                color='secondary'
                variant='contained'
                fullWidth
              >
                <FormattedMessage id='RegisterModal.Caption.SignIn' defaultMessage='Sign Up' />
              </Button>
            </StyledDialogActions>

            <StyledAgreements>
              <Typography variant='body2' style={{lineHeight: '20px'}}>
                <FormattedMessage
                  id='RegisterModal.Fotter'
                  defaultMessage='By signing up, you agree to our <terms>Terms of Service</terms>, and <privacy>Privacy Policy</privacy>.'
                  values={{
                    terms: (chunks: any) => <StyledLink to='/help/terms'>{chunks}</StyledLink>,
                    privacy: (chunks: any) => <StyledLink to='/help/privacy'>{chunks}</StyledLink>,
                  }}
                />
              </Typography>
            </StyledAgreements>
          </form>
        )}
      />
      </StyledContent>
    </StyledDialog>
  );
};

export default RegisterModal;
