/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Button,
  ButtonBaseProps,
  ButtonBaseTypeMap,
  makeStyles,
  styled,
  Box,
} from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { MouseEventHandler, useCallback, useState } from 'react';
import cx from 'classnames';
import { PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';

export const SmallButton = styled(Button)(({ theme }) => ({
  minWidth: '263px',
  height: '40px',
  margin: '5px',
}));

export const MediumButton = styled(Button)(({ theme }) => ({
  maxWidth: '350px',
  width: '100%',
  marginBottom: '5px',
}));

export const LargeButton = styled(Button)(({ theme }) => ({
  minWidth: '300px',
  height: '50px',
  margin: '5px',
  fontSize: '1.1rem',
}));

type KairosButtonSize = 'small' | 'medium' | 'large';

const useKairosButtonStyles = makeStyles({
  small: {
    width: '263px',
    height: '40px',
    margin: '5px',
  },
  medium: {
    width: '349px',
    height: '50px',
    margin: '5px',
  },
  learge: {
    width: '320px',
    height: '50px',
    margin: '5px',
    fontSize: '1.1rem',
  },
});

interface AsyncTwoStageButtonProps {
  size: KairosButtonSize;
  fulfilledMessage?: string;
  action: (() => Promise<any> | Promise<PayloadAction<any, string, any, any>>) | (() => any);
}

export const AsyncTwoStageButton = ({
  size = 'medium',
  fulfilledMessage,
  action,
  children,
  title,
  onClick,
  ...rest
}: ButtonBaseProps<ButtonBaseTypeMap['defaultComponent'], AsyncTwoStageButtonProps>) => {
  const classes = useKairosButtonStyles();
  const [waiting, setWaiting] = useState(false);
  const [fulfilled, setFulfilled] = useState(false);

  const sizeClass =
    size === 'medium' ? classes.medium : size === 'large' ? classes.learge : classes.small;

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      if (action && !waiting && !fulfilled) {
        const actionResult = action();

        if (actionResult && actionResult.then && actionResult.catch && actionResult.finally) {
          setWaiting(true);
          setFulfilled(false);
          actionResult
            .then((result: any) => {
              if (result.error || result.payload) {
                unwrapResult(result);
                setFulfilled(true);
              } else {
                setFulfilled(true);
              }
            })
            .catch((error: any) => {
              console.log('AsyncTwoStageButton error catched', error);
            })
            .finally(() => {
              setWaiting(false);
            });
        } else {
        }
      } else if (onClick) {
        onClick(event);
      }
    },
    [action, fulfilled, onClick, waiting],
  );

  const startIcon = waiting ? (
    <CircularProgress size={24} />
  ) : fulfilled ? (
    <CheckIcon style={{ marginTop: '-5px', marginBottom: '-5px' }} />
  ) : null;

  return (
    <Button startIcon={startIcon} className={cx(sizeClass)} onClick={handleClick}>
      {fulfilled && fulfilledMessage ? fulfilledMessage : children || title}
    </Button>
  );
};

interface BackButtonProps {
  onClick?: (e: any) => any;
  label?: string;
}

export const BackButton = (props: BackButtonProps) => {
  const handleClick = useCallback(
    (e: any) => {
      if (props.onClick) {
        props.onClick(e);
      }
    },
    [props],
  );
  return (
    <Box>
      <Typography
        style={{ cursor: 'pointer', textTransform: 'uppercase' }}
        variant='subtitle1'
        component='div'
        onClick={handleClick}
      >
        <ArrowBackIos color='primary' css={{ verticalAlign: 'middle', color: 'grey' }} />
        <span css={{ verticalAlign: 'middle' }}>{props.label}</span>
      </Typography>
    </Box>
  );
};
