import { Box, Portal } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { CelebrationModal } from 'common/components/Celebrations/CelebrationModal';
import ConfirmationDialog from 'common/components/ConfirmationDialog';
import { Footer } from 'common/components/Footer/Footer';
import { KairosSnackBar } from 'common/components/General/KairosSnackBar';
import Header from 'common/components/Header/Header';
import NavTabs from 'common/components/NavBar/NavTabs';
import TabContent from 'common/components/NavBar/TabContent';
import { EditProfileDialog } from 'common/components/Profile/EditProfileDialog';

import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useSessionActions } from 'state/session/useSessionActions';
import { SubmitChallengeDialog } from 'views/MyChallenges/SubmitChallengeDialog';
import { RootState } from '../../reducers';
import { Wrapper } from './styledComponents';

export const mainLayoutChannel = 'MainLayoutChannel';

const MainLayout = () => {
  const [footerOnTop, setFooterOnTop] = useState(false);
  const openEditProfileDialog = useSelector(
    (state: RootState) => state.session.flags.openEditProfileDialog,
    shallowEqual,
  );

  const navBarMounted = useSelector(
    (state: RootState) => state.session.dependencies.navBarMounted,
    shallowEqual,
  );

  const bounded = useSessionActions('getCurrentProfile');

  useEffect(() => {
    bounded
      .getCurrentProfile()
      .then(unwrapResult)
      .then((data) => data)
      .catch((error) => console.log('Error while fetching profile', error));
  }, [bounded]);

  return (
    <Wrapper>
      <Header withBackground withBoxShadow sticky={false} />

      {navBarMounted && (
        <Portal container={document.querySelector('.notificationsBar')}>
          <KairosSnackBar channel={mainLayoutChannel} />
        </Portal>
      )}

      <NavTabs />
      <div style={{ overflowX: 'hidden' }}>
        <Box minHeight={'100vh'}>
          <TabContent />
        </Box>

        <div
          style={{ position: 'relative', zIndex: footerOnTop ? 1 : 0 }}
          onMouseEnter={() => setFooterOnTop(true)}
          onMouseLeave={() => setFooterOnTop(false)}
        >
          <Footer />
        </div>
      </div>
      {openEditProfileDialog && <EditProfileDialog />}
      <ConfirmationDialog />
      <SubmitChallengeDialog />
    </Wrapper>
  );
};

export default MainLayout;
